import tools from '@/tools';
import { ChangePasswordRequest, SuccessResponse, SuccessTokenResponse } from '@/types';

export default class AuthApi {
  /**
   * Восстановление пароля
   * @param requestParams
   * @return {Promise<SuccessResponse>}
   */
  public forgotPassword(
    requestParams: {email: string},
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'auth/forgot-password/',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Изменение пароля
   * @param requestParams
   * @return {Promise<SuccessTokenResponse>}
   */
  public changePassword(
    requestParams: ChangePasswordRequest,
  ): Promise<SuccessTokenResponse> {
    return tools.apiRequest(
      'post',
      'auth/change-password/',
      requestParams,
    ) as Promise<SuccessTokenResponse>;
  }
}
