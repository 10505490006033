










































































































































































































import {
  BreadcrumbsItem,
  FormField,
  FormFieldAutocomplete, FormFieldSelect,
  FormFieldSelectMultiple,
  HandbookFullDataResponse, SuccessResponse, UserInfo,
  WithStartEnd,
} from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import DatePicker from '@/components/form-fields/DatePicker.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import FrequencyService from '@/services/FrequencyService';
import FrequencySetting from '@/components/modals/FrequencySetting.vue';
import PermissionEnums from '@/enums/PermissionEnums';
import { Frequency } from '@/enums/Frequency';
import HandbookService from '@/services/HandbookService';
import SelectWithSearch from '@/components/form-fields/SelectWithSearch.vue';
import ServiceAdd from '@/components/modals/ServiceAdd.vue';
import RepetitionsControls from '@/components/modals/RepetitionsControls.vue';
import { DataTableHeader } from 'vuetify';
import { HandbookCode } from '@/enums/HandbookList';
import { Program } from '@/types/program';
import { Service } from '@/types/service';
import DateField from '@/components/form-fields/DateField.vue';
import { EventsCode } from '@/enums/EventsCode';
import ItemWithFrequency = Service.ItemWithFrequency;

@Component({
  components: {
    RepetitionsControls,
    DateField,
    Breadcrumbs,
    ConfirmationDialog,
    CustomSelect,
    DataTableWrapper,
    DatePicker,
    FormActions,
    FrequencySetting,
    SelectWithSearch,
    ServiceAdd,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})
export default class ProgramDetail extends Vue {
  private user!: UserInfo;

  private get breadcrumbsItems(): BreadcrumbsItem[] {
    return [
      {
        text: 'Главная',
        href: '/',
        disabled: false,
      },
      {
        text: 'Список программ',
        href: '/program',
        disabled: false,
      },
      {
        text: this.pageTitle,
        href: '',
        disabled: true,
      },
    ];
  }

  private handbookFullData: HandbookFullDataResponse | null = null;

  private templateList: Array<Program.TemplateFullItem> = [];

  private participantSearchTimeout: number | undefined;

  private participantName: string | null = null;

  private programStatusField: FormFieldSelect = {
    name: 'is_current',
    label: 'Статус',
    value: null,
    error: '',
    readonly: true,
    disabled: true,
    clearable: false,
    options: [
      {
        value: '1',
        label: 'Активная',
      },
      {
        value: '0',
        label: 'Архивная',
      },
    ],
  };

  private participantField: FormFieldAutocomplete = {
    name: 'participant_id',
    placeholder: 'Выберите участника',
    value: null,
    error: '',
    options: [],
  }

  private templateField: FormFieldSelectMultiple = {
    name: 'template',
    placeholder: 'Выберите шаблон',
    value: null,
    error: '',
    options: [],
  }

  private dateStart: FormField = {
    name: 'starts_at',
    label: 'Дата начала',
    error: '',
    value: null,
    disabled: !this.canManageProgram,
    mask: '##.##.####',
  }

  private dateEnd: FormField = {
    name: 'ends_at',
    label: 'Дата завершения',
    error: '',
    value: '',
    disabled: !this.canManageProgram,
    mask: '##.##.####',
  }

  private allFields = [
    this.participantField,
    this.dateStart,
    this.dateEnd,
    this.templateField,
  ];

  private availableServices: Array<Service.ItemWithId> | null = null;

  private tableHeaders: DataTableHeader[] = [
    {
      value: 'name',
      text: 'Наименование',
    },
    {
      value: 'code',
      text: 'Код',
    },
    {
      value: 'category',
      text: 'Категория',
    },
    {
      value: 'repeat',
      text: 'Повторение',
    },
    {
      value: 'counts',
      text: 'Кол-во',
    },
  ].map((item) => ({
    ...item,
    sortable: false,
    class: 'custom-table-heading',
  }));

  private currentService: Service.ItemWithFrequency & WithStartEnd | null = null;

  private currentRepetitions: Array<{id: number, completed: boolean}>|null = null;

  private currentRepetitionsTitle = '';

  private serviceList: Array<Service.ItemWithFrequency & Program.ServiceFrequency> = [];

  private defaultFrequencyOptionByWeek = {
    days: ['0'],
  };

  private defaultFrequencyOptionByMonthAndYear = {
    dates: [{
      day_of_month: '1',
      day_of_week: '1',
      month: '1',
      n: '1',
    }],
  };

  private hasProgramChange = false;

  private showConfirmationPopup = false;

  /**
   * Настройки периодичности по умолчанию
   */
  private get defaultFrequencySettings(): Program.ServiceFrequency {
    return {
      frequency: '1',
      frequency_type: Frequency.Type.NO_REPEAT,
      frequency_options: null,
      starts_at: this.dateStart.value ?? '',
      ends_at: this.dateEnd.value ?? '',
      ends_after_repetitions: null,
      starts_with_program_start: true,
    };
  }

  private isServiceAddDialogOpen = false;

  private confirmationDialog = false;

  private minStartDate = this.$date().add(1, 'day').format('YYYY-MM-DD');

  /**
   * Минимальная дата окончания программы
   */
  private get minEndDate(): string {
    const baseDate = this.dateStart.value ?? this.minStartDate;
    return this.$date(baseDate).add(1, 'day').format('YYYY-MM-DD');
  }

  mounted(): void {
    this.addEventListeners();

    // Получаем начальный список участников (для автокомплита)
    if (this.isCreatePage) {
      this.getParticipantList();
    } else {
      this.getProgramInfo();
    }

    // Получаем значения всех справочников
    this.$handbookApi.getFullData().then((response) => {
      this.handbookFullData = response;
    });

    // Получаем список доступных шаблонов
    this.$programApi.getTemplateListFull().then((response) => {
      this.templateList = response;
      this.templateField.options = response.map((template) => ({
        label: template.name,
        value: template.id.toString(),
      }));
    });
  }

  beforeDestroy(): void {
    this.destroyEventListeners();
  }

  /**
   * Режим создания новой записи
   */
  private get isCreatePage(): boolean {
    return !this.$route.params.id;
  }

  /**
   * Получить текст для заголовка страницы / хлебных крошек
   */
  private get pageTitle(): string {
    if (this.isCreatePage) {
      return 'Добавление программы лечения';
    }
    return `Программа лечения: ${this.participantName}`;
  }

  /**
   * Выбран ли участник
   */
  private get isParticipantSelected(): boolean {
    return !!this.participantField.value;
  }

  /**
   * Установлены ли даты действия программы
   */
  private get isDateSet(): boolean {
    return !!this.dateStart.value && !!this.dateEnd.value;
  }

  /**
   * Получить трансформированный список программ для таблицы
   */
  private get tableItems(): Array<{
    id: number,
    name: string,
    code: string,
    category: string,
    repeat: string,
    counts: number,
  }> {
    const handbookFull = this.handbookFullData;
    if (!handbookFull) {
      return [];
    }
    return this.serviceList.map((service) => ({
      id: service.id,
      name: service.name,
      code: service.code,
      category: HandbookService.getTitleFromValues(
        handbookFull,
        HandbookCode.SERVICE_CATEGORIES,
          service.category?.id || null,
      ),
      repeat: FrequencyService.getRepeatTypeText(service.frequency_type),
      counts: service?.repetitions_count || 1,
    }));
  }

  /**
   * Добавления обработчиков событий
   */
  private addEventListeners(): void {
    this.onEventEscape();
  }

  /**
   * Удаления обработчиков событий
   */
  private destroyEventListeners(): void {
    this.removeEventEscape();
  }

  /**
   * Закрыть окно настроек периодичности
   */
  private openItemFrequencySettings(serviceId: number): void {
    this.currentService = this.serviceList.find((service) => service.id === +serviceId) || null;
    if (this.currentService) {
      // По умолчанию такие же ограничения по дате, как для программы
      if (!this.currentService?.starts_at) {
        this.currentService.starts_at = this.dateStart.value || '';
      }
      if (!this.currentService?.ends_at) {
        this.currentService.ends_at = this.dateEnd.value || '';
      }
    }
  }

  private openItemRepetitions(serviceId: number): void {
    const currentService = this.serviceList.find((service) => service.id === +serviceId) || null;
    const repetitions = currentService?.repetitions;
    if (!currentService || typeof repetitions === 'undefined' || !repetitions) {
      this.currentRepetitions = null;
      this.currentRepetitionsTitle = '';
      return;
    }

    this.currentRepetitionsTitle = currentService.name;
    this.currentRepetitions = Object.keys(repetitions)
      .filter((key) => Object.prototype.hasOwnProperty.call(repetitions, key))
      .map((key) => repetitions[+key]);
  }

  /**
   * Закрыть окно настроек периодичности
   */
  private closeItemFrequencySettings(): void {
    this.currentService = null;
    this.hasProgramChange = false;
  }

  /**
   * Сохранить настройки периодичности
   */
  private saveFrequencySettings(data: Program.ServiceFrequency): void {
    if (!this.currentService) {
      return;
    }
    const currentServiceId = this.currentService.id;
    const currentServiceIndex = this.serviceList.findIndex(
      (service) => service.id === currentServiceId,
    );
    if (currentServiceIndex !== -1) {
      this.serviceList.splice(currentServiceIndex, 1, {
        ...this.currentService,
        ...data,
      });
    }

    this.currentService = null;
    if (this.isCreatePage) {
      this.calculateServiceRepetitionsCounts();
    } else {
      this.saveProgram(false);
    }
  }

  /**
   * Получить список участников
   */
  private getParticipantList(): void {
    this.resetError();
    clearTimeout(this.participantSearchTimeout);
    this.participantSearchTimeout = setTimeout(() => {
      this.$participantApi.getList({
        full_name: this.participantField.search?.toString() || '',
      }).then((response) => {
        this.participantField.options = response.data.map((participant) => {
          const fullName = `${participant.info.last_name} ${participant.info.first_name} ${participant.info?.second_name || ''}`.trim();
          return {
            value: participant.id.toString(),
            label: fullName,
          };
        });
      });
    }, 300);
  }

  /**
   * Получить выбранные шаблоны программ
   */
  private get selectedTemplates(): Array<Program.TemplateFullItem> {
    return this.templateList.filter((template) => (
      (this.templateField.value as Array<unknown> || []).includes(template.id.toString())
    ));
  }

  /**
   * Получить список идентификаторов услуг в списке
   */
  private get selectedServiceIds(): Array<number> {
    return this.serviceList.map((service) => service.id);
  }

  /**
   * Определить может ли управлять программой
   * @private
   */
  private get canManageProgram(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.PROGRAM_MANAGE) !== -1;
  }

  /**
   * Выбор шаблона из списка
   */
  private onTemplateChange(): void {
    this.selectedTemplates.forEach((template) => {
      template.services.forEach((service) => {
        if (!this.selectedServiceIds.includes(service.id)) {
          if (service.category_id && this.handbookFullData) {
            // приводим данные о категории услуги к единому формату
            const category = HandbookService.getItemById({
              handbookFullData: this.handbookFullData,
              handbookCode: HandbookCode.SERVICE_CATEGORIES,
              itemId: service.category_id,
            });
            if (category) {
              service.category = category;
            }
          }
          service.frequency = String(service.frequency ?? 1);
          service.frequency_type = String(service.frequency_type ?? Frequency.Type.NO_REPEAT);

          let frequencyOption = service.frequency_options;
          const isTypeMonthlyOrYearly = service.frequency_type === Frequency.Type.MONTHLY
            || service.frequency_type === Frequency.Type.YEARLY;
          const isTypeWeekly = service.frequency_type === Frequency.Type.WEEKLY;

          if (isTypeMonthlyOrYearly && !frequencyOption) {
            frequencyOption = this.defaultFrequencyOptionByMonthAndYear;
          }

          if (isTypeWeekly && !frequencyOption) {
            frequencyOption = this.defaultFrequencyOptionByWeek;
          }

          this.serviceList.push({
            ...this.defaultFrequencySettings,
            ...service,
            frequency_options: frequencyOption,
          });
          this.calculateServiceRepetitionsCounts();
        }
      });
    });
  }

  /**
   * Добавление услуг в список
   * @param serviceIds
   */
  private onServiceAdd(Services: Service.Item[]): void {
    const servicesToAdd = Services;
    servicesToAdd.forEach((service) => {
      if (!this.selectedServiceIds.includes(service.id)) {
        this.serviceList.push({
          ...this.defaultFrequencySettings,
          ...service,
        });
      }
    });
    if (this.isCreatePage) {
      this.calculateServiceRepetitionsCounts();
    } else {
      this.saveProgram(false);
    }
  }

  /**
   * Удаление услуг из списка
   * @param serviceId
   */
  private onServiceDelete(serviceId: string): void {
    const serviceIndex = this.serviceList.findIndex(
      (service) => service.id === +serviceId,
    );
    if (serviceIndex !== -1) {
      this.serviceList.splice(serviceIndex, 1);
    }

    if (!this.isCreatePage) {
      this.saveProgram(false, true);
    }
  }

  /**
   * Вывод ошибок
   */
  private setError(message: Record<string, Array<string>>): void {
    this.resetError();
    const fieldNames = this.allFields.map((field) => field.name);
    Object.keys(message).forEach((messageKey) => {
      if (!fieldNames.includes(messageKey)) {
        let errorText = '';
        if (Array.isArray(message[messageKey])) {
          errorText = message[messageKey].join(', ');
        } else {
          errorText = message[messageKey] as unknown as string; // всякое бывает
        }
        this.$toast.error(errorText);
      } else {
        const field = this.allFields.find((field) => field.name === messageKey);
        if (field) {
          field.error = message[messageKey];
        }
      }
    });
  }

  /**
   * Сбросить ошибки
   */
  private resetError(): void {
    this.allFields.forEach((field) => {
      field.error = '';
    });
  }

  /**
   * Получить данные существующей программы
   */
  private getProgramInfo(): void {
    const programId = this.$route.params.id;
    if (programId) {
      this.$programApi.getProgram({
        id: +programId,
      }).then((response) => {
        this.participantName = [
          response.participant.last_name ?? '',
          response.participant.first_name ?? '',
          response.participant.second_name ?? '',
        ].join(' ').trim();
        this.participantField.value = response.participant.id.toString();
        this.dateStart.value = response.starts_at;
        this.dateEnd.value = response.ends_at;
        this.serviceList = response.services as Array<ItemWithFrequency & Program.ServiceFrequency>;
        this.programStatusField.value = response.is_current ? '1' : '0';
      });
    }
  }

  /**
   * Посчитать количество повторений
   * @private
   */
  private calculateServiceRepetitionsCounts(): void {
    const requestParams: Program.ServiceRepetitionCountRequest = {
      program: {
        starts_at: this.dateStart.value as string,
        ends_at: this.dateEnd.value as string,
      },
      services: this.serviceList,
    };

    this.$programApi.calculateServiceRepetitionCounts(requestParams)
      .then((response) => {
        if (!response || typeof response === 'undefined') {
          return;
        }
        this.serviceList = this.serviceList.map((service) => {
          const hasService = Object.prototype.hasOwnProperty.call(response, service.id);

          const cloneService = { ...service };

          if (!hasService) {
            return cloneService;
          }

          return {
            ...cloneService,
            repetitions_count: response[service.id].length,
          };
        });
      });
  }

  /**
   * Сохранить программу
   */
  private saveProgram(goToListOnSave: boolean, needUpdatePage = false): void {
    this.resetError();
    const requestParams: Program.CreateRequest = {
      starts_at: this.dateStart.value as string,
      ends_at: this.dateEnd.value as string,
      participant_id: +(this.participantField?.value ?? 0),
      services: this.serviceList,
    };
    let request: Promise<SuccessResponse>;

    if (this.isCreatePage) {
      request = this.$programApi.createProgram(requestParams);
    } else {
      request = this.$programApi.updateProgram(+this.$route.params.id, requestParams);
    }

    request.then((response) => {
      if (response.success) {
        this.$toast.success('Программа сохранена');
        if (this.isCreatePage) {
          this.clearFields();
        } else {
          this.getProgramInfo();
        }

        if (this.isCreatePage || goToListOnSave) {
          this.goToList();
        }
      }
    }).catch((error) => {
      if (typeof error.message !== 'undefined') {
        if (!error.isAxiosError) {
          this.setError(error.message);
        } else {
          this.setError({ error: error.message });
        }
      }

      if (!this.isCreatePage && needUpdatePage) {
        this.getProgramInfo();
      }

      window.console.dir(error);
    });
  }

  /**
   * Очистить поля формы
   */
  private clearFields(): void {
    this.participantField.value = '';
    this.dateStart.value = '';
    this.dateEnd.value = '';
    this.templateField.value = [];
    this.serviceList = [];
  }

  /**
   * Обработка нажатия на "Отмена"
   */
  private formActionCancel(): void {
    this.confirmationDialog = true;
  }

  /**
   * Закрыть диалог подтверждения
   */
  private closeConfirmationDialog(): void {
    this.confirmationDialog = false;
  }

  private closeRepetitionsDialog(): void {
    this.currentRepetitions = null;
    this.getProgramInfo();
  }

  /**
   * Перейти к списку программ
   */
  private goToList(): void {
    this.confirmationDialog = false;
    this.$router.push({
      name: 'program-list',
    });
  }

  private changeDateHandler(): void {
    window.console.log('change-date');
    if (this.isCreatePage) {
      return;
    }
    this.saveProgram(false);
  }

  /**
   * Прослушивание события клика на кнопку Esc
   */
  private onEventEscape():void {
    document.addEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Удаляем прослушивание события клика на кнопку Esc
   */
  private removeEventEscape():void {
    document.removeEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Хэндлер события клика на кнопку Esc
   */
  private eventEscapeHandler(event: KeyboardEvent):void {
    if (event.code === EventsCode.ESCAPE) {
      if (this.currentRepetitions) {
        if (this.hasProgramChange) {
          this.showConfirmationModal();
        } else {
          this.closeRepetitionsDialog();
        }
        return;
      }
      if (this.currentService) {
        if (this.hasProgramChange) {
          this.showConfirmationModal();
        } else {
          this.closeItemFrequencySettings();
        }
        return;
      }
      this.goToPageList();
    }
  }

  /**
   * Переход на страницу списка
   */
  private goToPageList(): void {
    if (this.$route.name === 'program-list') {
      return;
    }
    try {
      this.$router.push({ name: 'program-list' });
    } catch (error) {
      window.console.log(error);
    }
  }

  /**
   * Показать окно подтверждения действия
   */
  private showConfirmationModal():void {
    this.showConfirmationPopup = true;
  }

  /**
   * Хэндлер подтверждения
   */
  private confirmPopupHandler():void {
    this.showConfirmationPopup = false;
    if (this.currentService || this.currentRepetitions) {
      this.closeItemFrequencySettings();
      this.closeRepetitionsDialog();
      return;
    }
    this.goToPageList();
  }

  /**
   * Хэндлер отмены
   */
  private cancelPopupHandler():void {
    this.showConfirmationPopup = false;
  }
}
