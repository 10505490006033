import axios, { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-cycle
import AuthService from '../services/AuthService';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const axiosInstance: AxiosInstance = axios.create({
  baseURL: '/api/',
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const currentConfig: AxiosRequestConfig = config;

  currentConfig.params = config.params || {};
  const token = AuthService.getToken();
  if (token) {
    currentConfig.headers.Authorization = `Bearer ${token}`;
  }
  return currentConfig;
});

export default axiosInstance;
