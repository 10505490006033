




























import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';

@Component({
  props: {
    field: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '48',
    },
  },
})

export default class CustomSelect extends Vue {
  private field!: FormField;

  private get isClearable(): boolean {
    return this.field.clearable ?? true;
  }

  private changeHandler(): void {
    this.field.error = '';
    this.$emit('change');
  }

  /**
   * Обработчик клика на enter
   * @private
   */
  private clickEnterHandler(): void {
    this.$emit('submit-form');
  }
}
