import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-8"},[_c(VRadioGroup,{staticStyle:{"width":"100%"},attrs:{"column":_vm.$vuetify.breakpoint.xsOnly},on:{"change":function($event){return _vm.$emit('change', _vm.field.value)}},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}},[_c(VRow,{staticClass:"mx-md-n6 my-n5",attrs:{"no-gutters":""}},_vm._l((_vm.field.options),function(option,index){return _c(VCol,{key:index,staticClass:"px-md-6 my-5",attrs:{"cols":"auto"}},[_c(VRadio,{attrs:{"value":option.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(VBtn,{staticClass:"justify-start",attrs:{"color":"#A8A9AD","min-width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : '0',"outlined":"","height":"72"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":option.value === _vm.field.value ? '#006BA3' : '#68788A'}},[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',{staticClass:"black--text text-body-1 text-capitalize"},[_vm._v(" "+_vm._s(option.label)+" ")])],1)]},proxy:true}],null,true)})],1)}),1)],1),_c(VDivider,{staticClass:"mt-8",attrs:{"color":"#68788A"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }