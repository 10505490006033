import tools from '@/tools';
import {
  Help,
} from '@/types/help';
import { SuccessResponse } from '@/types';

/**
 * Апи по работе с инструкциями
 */
export default class HelpApi {
  private DEFAULT_URL = 'content/help-items';

  /**
   * поулчить список статей с постраничной навигацией
   */
  public getItemsAll(
    requestParams: Help.RequestItemsAll,
  ): Promise<Help.ResponseItemsAll> {
    return tools.apiRequest(
      'get',
      this.DEFAULT_URL,
      requestParams,
    ) as Promise<Help.ResponseItemsAll>;
  }

  /**
   * поулчить список опубликованных статей с постраничной навигацией
   */
  public getItemsPublished(
    requestParams: Help.RequestItemsAll,
  ): Promise<Help.ResponseItemsAll> {
    return tools.apiRequest(
      'get',
      `${this.DEFAULT_URL}/published`,
      requestParams,
    ) as Promise<Help.ResponseItemsAll>;
  }

  /**
   * поулчить Детальну информаци опубликованной статьи
   */
  public getDetailPublished(
    id: number,
  ): Promise<Help.Item> {
    return tools.apiRequest(
      'get',
      `${this.DEFAULT_URL}/published/${id}`,
    ) as Promise<Help.Item>;
  }

  /**
   * поулчить Детальну информаци любой статьи статьи
   */
  public getDetail(
    id: number,
  ): Promise<Help.Item> {
    return tools.apiRequest(
      'get',
      `${this.DEFAULT_URL}/${id}`,
    ) as Promise<Help.Item>;
  }

  /**
   * создание
   */
  public create(
    requestParams: Help.CreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `${this.DEFAULT_URL}/create`,
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * создание
   */
  public update(
    requestParams: Help.CreateRequest,
    id: string,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `${this.DEFAULT_URL}/${id}/update`,
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * удаление
   */
  public delete(
    id: number,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `${this.DEFAULT_URL}/${id}/delete`,
    ) as Promise<SuccessResponse>;
  }
}
