














































































































































import ParticipantSection from '@/components/participant/ParticipantSection.vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import FamilyMemberForm from '@/components/participant/FamilyMemberForm.vue';
import {
  FamilyMemberRequest, FamilyMembersRequest, SuccessResponse,
  FamilyMember, ErrorResponse, ParticipantInfo,
} from '@/types';
import FormFieldService from '@/services/FormFieldService';
import FormActions from '@/components/buttons/FormActions.vue';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';

type ViewFormItem = {
  title?: string,
  active: boolean,
  member: FamilyMemberRequest
}

@Component({
  components: { FamilyMemberForm, FormActions, DataWillNotSaved },
  props: {
    participantResidenceAddress: {
      type: Object,
      required: true,
    },
    participantFloor: {
      type: Number,
      required: true,
    },
    sectionIsReady: {
      type: Boolean,
      required: true,
    },
  },
})

export default class ParticipantSectionFamily extends ParticipantSection {
  private participantInfo: ParticipantInfo|null = null;

  private formErrors: {[ket: string]: string|string[]} = {};

  private familyMembers!: Array<FamilyMember>;

  private items: Array<ViewFormItem> = [];

  /**
   * На изменения данных о членах семьи
   */
  @Watch('familyMembers', { deep: true })
  onChangeFamilyMembers(familyMembers: FamilyMember[]): void {
    this.items = [...this.transformFamilyMembersToComponentItem(familyMembers)];
  }

  mounted(): void {
    this.getParticipantInfo();
  }

  /**
   * Получить заголовок секции
   */
  private get sectionTitle(): string {
    return this.participantIsActive
      ? '2. Члены семьи физического лица'
      : 'Шаг 3. Члены семьи физического лица';
  }

  /**
   * Собрать из данных о члене семьи компонент
   */
  private transformFamilyMembersToComponentItem(
    familyMembers: FamilyMember[],
  ): Array<ViewFormItem> {
    if (!familyMembers.length) {
      return [];
    }
    return familyMembers.map((member) => {
      const { second_name: secondName = '' } = member;
      return {
        title: `${member.last_name} ${member.first_name} ${secondName}`,
        active: false,
        member: {
          ...member,
          address: member.address,
          registration_address: member.registration_address,
        },
      };
    });
  }

  /**
   * Создать запрос
   */
  private createRequest(): FamilyMembersRequest {
    return {
      participantId: this.participantId,
      members: this.items.map((item) => item.member),
    };
  }

  /**
   * Отправить форму
   */
  private submitForm(): Promise<SuccessResponse|void> {
    return new Promise((resolve, reject) => {
      this.formErrors = {};

      if (!this.items.length) {
        resolve();
        return;
      }

      this.$participantApi.createOrUpdateFamilyMembers(this.createRequest())
        .then((response: SuccessResponse) => {
          this.getParticipantInfo()
            .then(() => {
              resolve(response);
            });
        })
        .catch((err: ErrorResponse) => {
          if (typeof err.message !== 'undefined' && err.code === 2) {
            this.formErrors = err.message;
          }

          reject(err);
        });
    });
  }

  /**
   * Создать нового члена семьи
   */
  private createNewFamilyFormItem(): void {
    const newFamilyMember = FormFieldService.createFamilyMemberFormTemplateRequest();
    this.items = [
      ...this.items.map((item) => ({ ...item, active: false })),
      {
        title: 'Сведения о члене семьи',
        active: true,
        member: newFamilyMember,
      }];
  }

  /**
   * Получить данные об участнике
   * @private
   */
  private getParticipantInfo(): Promise<SuccessResponse> {
    return new Promise((resolve) => {
      this.$participantApi.getParticipantInfo({ id: this.participantId })
        .then((response: ParticipantInfo) => {
          this.participantInfo = response;

          this.items = [
            ...this.transformFamilyMembersToComponentItem(this.participantInfo.family_members),
          ];

          resolve({ success: true });
        });
    });
  }

  /**
   * Удалить члена семьи
   * @param id
   * @private
   */
  private deleteFamilyMember(id: number): void {
    this.$participantApi.deleteFamilyMember({
      id: this.participantId.toString(),
      familyMemberId: id,
    })
      .then(() => {
        this.items = this.items.filter((item) => {
          if (typeof item.member.id === 'undefined') {
            return true;
          }
          return item.member.id !== id;
        });
        this.submitForm();
      });
  }

  /**
   * тригер того что шаг готов
   * @private
   */
  private emitReadyStep(): void {
    this.$emit('emit-ready-step');
  }

  private showSuccess(): void {
    this.$toast.success('Шаг сохранён!');
  }

  /**
   * Клик по кнопке добавить
   * @private
   */
  private clickAddButtonHandler(): void {
    if (!this.items.length) {
      this.createNewFamilyFormItem();
      return;
    }

    this.submitForm()
      .then(() => {
        this.createNewFamilyFormItem();
      });
  }

  /**
   * Клик по кнопке удаления члена семьи
   * @param id
   * @param index
   * @private
   */
  private clickDeleteFamilyMemberHandler(id: number, index: number): void {
    if (typeof id === 'undefined' && typeof this.items[index] !== 'undefined') {
      this.items.splice(index);
      return;
    }

    this.deleteFamilyMember(id);
  }

  /**
   * Клик по кнопке сохранить в черновик
   * @private
   */
  private clickSaveDraftHandler(): void {
    this.submitForm()
      .then(() => {
        this.showSuccess();
      });
  }

  /**
   * клик по кнопке продолжить
   * @private
   */
  private clickProceedButtonHandler(): void {
    this.submitForm()
      .then(() => {
        this.emitReadyStep();
      });
  }

  /**
   * Клик по кнопке пропустить
   * @private
   */
  private clickButtonSkipHandler(): void {
    this.emitReadyStep();
  }

  /**
   * Обработчик изменения члена семьи
   * @param data
   * @private
   */
  private changeFamilyMemberHandler(data:{index: number, data: FamilyMemberRequest}): void {
    const currentMember = this.items[data.index];

    if (typeof currentMember === 'undefined') {
      return;
    }

    const { title } = currentMember;

    this.$set(this.items, data.index, { title, active: true, member: data.data });
  }

  /**
   * Обработчик клика по кнопке сохранить в режиме созданого юзера
   * @private
   */
  private clickSaveButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.$emit('show-save-popup');
        this.showNotSaveDialog = false;
        this.statusBlock = 'readonly';
        this.emitEventEndEdit();
      });
  }

  /**
   * Обработчик клика * принять*
   */
  private clickApplyButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.$emit('show-save-popup');
      });
  }

  /**
   * Обработчик клика на *отмена*
   * @private
   */
  private closeClickHandle(): void {
    this.showNotSaveDialog = true;
  }

  /**
   * Обработчик клика не сохранять
   * @private
   */
  private clickNotSaveHandle(): void {
    this.showNotSaveDialog = false;
    this.statusBlock = 'readonly';
    this.emitEventEndEdit();

    this.items = [
      ...this.transformFamilyMembersToComponentItem(this.participantInfo?.family_members || []),
    ];
  }
}
