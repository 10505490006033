




























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';

  @Component({})

export default class Login extends Vue {
    private passwordShow = false;

    private email: FormField = {
      value: '',
      name: 'email',
      placeholder: 'Ведите адрес эл.почты',
      error: '',
    }

    private password: FormField = {
      value: '',
      name: 'password',
      placeholder: 'Введите пароль',
      error: '',
    }

    private fields = [
      this.email,
      this.password,
    ]

    private loading = false;

    /**
     * Отправить форму
     * @private
     */
    private onSubmit():void {
      this.loading = true;

      this.$authService.authRequest({
        email: this.email.value || '',
        password: this.password.value || '',
      })
        .then(() => {
          this.$eventBus.$emit(this.$eventBus.EVENT_AUTH_STATUS_CHANGED);
          this.$router.push('/');
        })
        .catch((err) => {
          this.clearError();
          if (typeof err.message !== 'undefined') {
            this.setError(err.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }

    /**
     * Установить ошибки
     * @param errors
     * @private
     */
    private setError(errors: {[key: string]: Array<string>}| string): void {
      if (typeof errors === 'string') {
        this.email.error = errors;
        return;
      }

      this.fields.forEach((field: FormField) => {
        const currentField = field;

        if (typeof errors[field.name] === 'undefined') {
          currentField.error = '';
          return;
        }

        currentField.error = errors[field.name];
      });
    }

    /**
     * Очистить ошибки
     * @private
     */
    private clearError(): void {
      this.email.error = '';
      this.password.error = '';
    }
}
