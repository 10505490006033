import { CurrentPageListNames } from '@/enums/CurrentPageListNames';
import store from '@/store';
import { StoreCurrentPageParams } from '@/types';

/**
 * Сервис по работе с постраничной навигацией, которую храним в сторе
 */
export default class ListPageSettingsService {
  /**
   * Сброс настроек страницы
   */
  static resetPageSettings(pageTitle: CurrentPageListNames): void {
    store.dispatch('setListPageSetting', {
      pageTitle,
      currentPage: null,
      sort: null,
    });
  }

  /**
   * Получаем настройки страницы из стора, либо null если в сторе нет настроек для этой страницы
   */
  static getListPageSettingsFromStore(
    pageTitle: CurrentPageListNames,
  ): StoreCurrentPageParams | null {
    return store.getters.getListPageSettings.find(
      (item: StoreCurrentPageParams) => item.pageTitle === pageTitle,
    ) ?? null;
  }
}
