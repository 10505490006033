import tools from '@/tools';
import {
  AddressDetails, CompaniesShortResponse,
  Company,
  CompanyCreateRequest,
  CompanyUpdateRequest, ExportParams, OrganisationsExportListResponse, OrganisationsListRequest,
  OrganizationsListResponse,
  SuccessResponse,
} from '@/types';

/**
 * Апи по работе с организациями
 */
export default class OrganizationApi {
  /**
   * Получить список организаций
   * @param requestParams
   * @returns {Promise<OrganizationsListResponse>}
   */
  public getList(
    requestParams: {
      page: number,
    } = { page: 0 },
  ): Promise<OrganizationsListResponse> {
    return tools.apiRequest(
      'get',
      'organisation/list',
      requestParams,
    ) as Promise<OrganizationsListResponse>;
  }

  /**
   * Получить краткий список организаций
   * @returns {Promise<ListItem[]>}
   */
  public getShortList(): Promise<CompaniesShortResponse> {
    return tools.apiRequest(
      'get',
      'organisation/short-list',
    ) as Promise<CompaniesShortResponse>;
  }

  /**
   * Получить информацию о компании
   * @param organisationId
   * @returns {Promise<Company>}
   */
  public getInfo(organisationId: number): Promise<Company> {
    return tools.apiRequest(
      'get',
      `organisation/info/${organisationId}`,
    ) as Promise<Company>;
  }

  /**
   * Создать организацию
   * @param requestParams
   * @returns {Promise<SuccessResponse>}
   */
  public createCompany(requestParams: CompanyCreateRequest): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'organisation/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Обновить организацию
   * @param requestParams
   * @returns {Promise<SuccessResponse>}
   */
  public updateCompany(requestParams: CompanyUpdateRequest): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'organisation/update',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Удалить организацию
   * @param requestParams
   * @returns {Promise<SuccessResponse>}
   */
  public deleteCompany(requestParams: {
    id: number
  }): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'organisation/delete',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить информацию о компании по ИНН
   * @param inn
   * @returns {Promise<Company>}
   */
  public getOrganisationByInn(inn: string): Promise<Company | null> {
    return tools.apiRequest(
      'post',
      'legal-data/organisation-by-inn',
      { inn },
    ) as Promise<Company>;
  }

  /**
   * Получить подсказки для адреса
   * @param address
   * @returns {Promise<Company>}
   */
  public getAddressSuggest(address: string): Promise<AddressDetails[]> {
    return tools.apiRequest(
      'post',
      'legal-data/address-suggest',
      { address },
    ) as Promise<AddressDetails[]>;
  }

  /**
   * Выполняем запрос на экспорт списка организаций
   * @param requestParams
   */
  public exportOrganisations(
    requestParams: OrganisationsListRequest & ExportParams,
  ): Promise<OrganisationsExportListResponse> {
    return tools.apiRequest(
      'get',
      'organisation/export',
      requestParams,
      false,
      {},
    ) as Promise<OrganisationsExportListResponse>;
  }
}
