


























































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import tools from '@/tools';

@Component({
  props: {},
})

export default class HelpImport extends Vue {
  private findItemMenu(id: string): void {
    tools.scrollTo(id);
  }
}

