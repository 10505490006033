







































































import Vue from 'vue';
import Component from 'vue-class-component';
import FormRow from '@/components/forms/FormRow.vue';
import TextField from '@/components/forms/TextField.vue';
import SelectWithSearch from '@/components/form-fields/SelectWithSearch.vue';
import CustomSelect from '@/components/forms/CustomSelect.vue';
import {
  BaseField,
  FilterSettingsGetResponse,
  FilterSettingsSaveRequest,
} from '@/types';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import DateFromTo from '@/components/forms/DateFromTo.vue';

@Component({
  components: {
    FormRow, TextField, CustomSelect, SelectWithSearch, DateFromTo,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    entityFilter: {
      type: String,
      default: '',
    },
    hasOneColumnForGrid: {
      type: Boolean,
      default: false,
    },
  },
})

export default class DataFilters extends Vue {
    private loading = false;

    private fields!: Array<BaseField & {value: unknown}>;

    private entityFilter!: FiltersEntityEnum;

    private filterSettingsResponse: FilterSettingsGetResponse|null = null;

    mounted(): void {
      this.getFilters();
    }

    /**
     * Создать запрос на сохранение фильтров
     */
    private createSaveRequest(): FilterSettingsSaveRequest {
      const settings: Record<string, unknown> = {};

      this.fields.forEach((field) => {
        settings[field.name] = field.value;
      });

      return {
        entity: this.entityFilter,
        settings,
      } as FilterSettingsSaveRequest;
    }

    /**
     * Заполнить фильтры
     */
    private fillFilters(): void {
      if (!this.filterSettingsResponse) {
        return;
      }

      this.fields.forEach((field) => {
        const hasProperty = Object
          .prototype.hasOwnProperty.call(this.filterSettingsResponse?.settings, field.name);

        if (!hasProperty) {
          return;
        }

        field.value = this.filterSettingsResponse?.settings[field.name];
      });
    }

    /**
     * Сохранить фильтры
     */
    private saveFilters(): void {
      if (!this.entityFilter) {
        return;
      }
      this.$filtersApi.saveFilters(this.createSaveRequest())
        .then(() => {
          window.console.log('Фильтры сохранены');
        })
        .catch(() => {
          window.console.log('Ошибка');
        });
    }

    /**
     * Получить фильтры
     */
    private getFilters(): void {
      this.loading = true;
      if (!this.entityFilter) {
        this.loading = false;
        return;
      }
      this.$filtersApi.getFilters({
        entity: this.entityFilter,
      }).then((response) => {
        this.filterSettingsResponse = response;
        this.fillFilters();
        this.$nextTick(() => {
          this.$emit('filters-loaded');
          this.loading = false;
        });
      });
    }

    /**
     * клик по кнопке поиска
     */
    private clickSearchFilterHandler(): void {
      this.saveFilters();
      this.$emit('filter-submit');
    }

    /**
   * Стригерить отправку формы
   * @private
   */
    private submitForm(): void {
      this.saveFilters();
      this.$emit('filter-submit');
    }

    /**
   * Клик по кнопке сбросить фильтры
   * @private
   */
    private clickResetFilterButtonHandler(): void {
      this.$emit('filter-reset');
      this.$nextTick(() => {
        this.saveFilters();
      });
    }
}
