




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  props: {
    title: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    participantIsActive: {
      type: Boolean,
      required: true,
    },
  },
})

export default class Steppers extends Vue {
  private title!: string;

  private currentStep!: number;

  private currentStepModel: number = this.currentStep;

  @Watch('currentStep')
  onCurrentStepChange(step: number): void {
    this.currentStepModel = step;
  }

  /**
   * Клик по шагу
   * @param step
   * @private
   */
  private clickStepHandler(step: number): void {
    this.$emit('click-step', step);
  }
}
