









import Vue from 'vue';
import Component from 'vue-class-component';
import Linear from '@/components/chartjs/Linear.vue';
import { Statistic } from '@/types/statistic';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Chart from 'chart.js';

dayjs.extend(weekOfYear);

@Component({
  components: { Linear },
  props: {
    participantsChartData: {
      type: Array,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
})

/**
 * Компонент с линейным графиком
 */
export default class LinearGraphWrapper extends Vue {
  private participantsChartData!: Array<Statistic.ParticipantChartItem>;

  private period!: string;

  /**
   * Получить данные для графика в формате chartjs
   * @private
   */
  private get chartData(): Chart.ChartData {
    const itemDatasetDefault = {
      pointRadius: 5,
      pointBorderColor: '#fff',
    };

    return {
      labels: this.labels,
      datasets: [
        {
          ...itemDatasetDefault,
          label: 'Участников всего',
          data: this.participantsChartData.map((item) => item.participant_total),
        },
        {
          ...itemDatasetDefault,
          label: 'Ветераны, зависящие от посторонней помощи',
          data: this.participantsChartData.map((item) => item.participant_dependent),
        },
      ],
    };
  }

  /**
   * Получить лейблы для графика в зависимости от типа групировки
   * @private
   */
  private get labels(): Array<string> {
    switch (this.period) {
      case 'day': {
        return this.participantsChartData
          .map((item) => dayjs(item.from_date).format('DD.MM'));
      }
      case 'week': {
        return this.participantsChartData.map((item) => {
          const startWeek = dayjs(item.from_date).format('DD.MM');
          const endWeek = dayjs(item.to_date).format('DD.MM');
          return `${startWeek} - ${endWeek}`;
        });
      }
      case 'month': {
        return this.participantsChartData
          .map((item) => dayjs(item.from_date).format('MMM`YY'));
      }
      default:
        return this.participantsChartData
          .map((item) => dayjs(item.from_date).format('DD.MM'));
    }
  }
}
