import tools from '@/tools';
import {
  FilterSettingsGetRequest,
  FilterSettingsGetResponse,
  FilterSettingsSaveRequest,
  SuccessResponse,
} from '@/types';

export default class FiltersApi {
  /**
   * получить фильтры
   * @param requestParams
   */
  public getFilters(
    requestParams: FilterSettingsGetRequest,
  ): Promise<FilterSettingsGetResponse> {
    return tools.apiRequest(
      'get',
      `user/datatable-settings/${requestParams.entity}`,
    ) as Promise<FilterSettingsGetResponse>;
  }

  /**
   * Сохранение фильтров
   * @param requestParams
   */
  public saveFilters(
    requestParams: FilterSettingsSaveRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'user/datatable-settings/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }
}
