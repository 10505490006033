































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import PeriodFilters from '@/components/forms/PeriodFilters.vue';
import Periods from '@/helpers/Periods';
import {
  CompaniesShortResponse,
  Dates,
  FormField, FormFieldDateRange,
  FormFieldSelectStringMultiple,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  Nullable,
  SelectOption,
} from '@/types';
import { Statistic } from '@/types/statistic';
import Loader from '@/components/widgets/Loader.vue';
import LinearGraphWrapper from '@/components/statistic/LinearGraphWrapper.vue';
import CustomSelect from '@/components/forms/CustomSelect.vue';
import SelectWithSearch from '@/components/form-fields/SelectWithSearch.vue';
import DoughnutWrapper from '@/components/statistic/DoughnutWrapper.vue';
import DoughnutNestedWrapper from '@/components/statistic/DoughnutNestedWrapper.vue';
import BarChartWrapper from '@/components/statistic/BarChartWrapper.vue';
import DateFromTo from '@/components/forms/DateFromTo.vue';
import FormRow from '@/components/forms/FormRow.vue';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import FormFieldTypeEnum from '@/enums/FormField';
import CompanyService from '@/services/CompanyService';
import { ChartDataSets } from 'chart.js';
import { StatusCode, StatusText } from '@/enums/ParticipantStatus';

@Component({
  components: {
    BarChartWrapper,
    DoughnutWrapper,
    DoughnutNestedWrapper,
    CustomSelect,
    LinearGraphWrapper,
    Loader,
    PeriodFilters,
    SelectWithSearch,
    DateFromTo,
    FormRow,
  },
})

export default class Dashboard extends Vue {
  private NAME_EXPORT_FILE = 'statisticExport';

  private companiesShortResponse: CompaniesShortResponse | null = null;

  private isSettingsLoading = false;

  private loadingChartData = false;

  private loadingExport = false;

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private periodModel: Dates = Periods.getToday();

  private participantsChart: Statistic.ParticipantsResponse|null = null;

  private participantsDistributionResponse: Statistic.ParticipantsDistributionResponse|null = null;

  private programTotalResponse: Statistic.ProgramsTotalsResponse|null = null;

  private servicesRepetition: Statistic.ServiceRepetitionResponse|null = null;

  private knuResponse: Statistic.KnuChartResponse|null = null;

  private fetchChuzStatisticTimeoutId: number | null = null;

  private knuAndKvipChartOptions = {
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10,
          suggestedMin: 0,
          beginAtZero: true,
        },
      }],
    },
  }

  private groupByFilter: FormField = {
    name: 'groupBy',
    label: '',
    clearable: false,
    value: 'day',
    options: [
      {
        value: 'day',
        label: 'По дням',
      },
      {
        value: 'week',
        label: 'По неделям',
      },
      {
        value: 'month',
        label: 'По месяцам',
      },
    ],
    error: '',
  }

  private disabilityGroupIdsIncludeNull: FormField = {
    name: 'disabilityGroupIdsIncludeNull',
    label: 'Включать участников без инвалидности',
    clearable: false,
    value: '0',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    error: '',
  }

  private genderFilter: FormField = {
    name: 'gender',
    label: 'Пол',
    clearable: true,
    value: null,
    options: [],
    error: '',
    placeholder: 'Выберите пол',
  }

  private disabilityGroupFilter: FormFieldSelectStringMultiple = {
    value: null,
    name: 'disability_group_ids',
    placeholder: 'Выберите группу инвалидности',
    error: '',
    label: 'Группа ивалидности',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private polygon: FormFieldSelectStringMultiple = {
    value: null,
    name: 'polygon_id',
    placeholder: 'Выберите полигон',
    error: '',
    label: 'Полигон',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private organizationField: FormFieldSelectStringMultiple = {
    value: null,
    name: 'organisations',
    placeholder: 'Выберите ЧУЗ',
    error: '',
    label: 'ЧУЗ',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private status: FormFieldSelectStringMultiple = {
    value: null,
    name: 'status',
    placeholder: 'Веберите статус',
    error: '',
    label: 'Статус',
    multiple: true,
    options: [
      {
        value: StatusCode.ACTIVE,
        label: StatusText.ACTIVE,
      },
      {
        value: StatusCode.DRAFT,
        label: StatusText.DRAFT,
      },
      {
        value: StatusCode.ARCHIVE,
        label: StatusText.ARCHIVE,
      },
    ],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private dateOfBirth: FormFieldDateRange = {
    name: 'dateRangeProgram',
    label: 'Дата рождения',
    error: '',
    value: {
      from: '',
      to: '',
    },
    mask: '##.##.####',
    fieldType: FormFieldTypeEnum.DATE_RANGE,
    clearable: true,
  }

  mounted(): void {
    this.initEventHandlers();
    this.fetchSettingsData()
      .then(() => {
        this.loadChartData();
      });
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unBindEventHandlers();
  }

  /**
   * собрать данные для графика услуг
   * @private
   */
  private getServiceDatasets(
    service: Statistic.ServiceRepetitionItem,
  ): { inner: Record<string, number>; outer: Record<string, number> } | null {
    if (!service) {
      return null;
    }
    let resultData: { inner: Record<string, number>; outer: Record<string, number> };
    resultData = {
      inner: {},
      outer: {},
    };
    const summService = service.repetitions_count;

    const resultKeys = Object.keys(service).filter((key) => key !== 'label'
      && key !== 'completed_repetitions_count_percentage'
      && key !== 'repetitions_count_percentage');

    resultKeys.forEach((key) => {
      const value = service[key as keyof Statistic.ServiceRepetitionItem] as number;
      if (key.includes('completed')) {
        resultData = {
          ...resultData,
          inner: {
            ...resultData.inner,
            [key]: this.getPercentageService(value, summService),
            [`${key}_value`]: value,
          },
        };
      } else {
        resultData = {
          ...resultData,
          outer: {
            ...resultData.outer,
            [key]: this.getPercentageService(value, summService),
            [`${key}_value`]: value,
          },
        };
      }
    });

    return resultData;
  }

  /**
   * Процент выполнених повторений
   * @private
   */
  private get repetitionsCompletedPercent(): number {
    if (!this.programTotalResponse) {
      return 0;
    }

    const completed = this.programTotalResponse.completed_repetitions_total;
    const total = this.programTotalResponse.repetitions_total;

    if (total === 0) {
      return 0;
    }

    return +((completed / total) * 100).toFixed(1);
  }

  /**
   * Лейблы для графика кну
   * @private
   */
  private get knuLabels(): Array<string> {
    if (!this.knuResponse || !this.knuResponse.length) {
      return [];
    }

    return this.knuResponse.map((item) => item.label);
  }

  /**
   * Данные для графика кну
   * @private
   */
  private get knuChartDatasets(): Array<ChartDataSets> {
    if (!this.knuResponse || !this.knuResponse.length) {
      return [];
    }

    return [
      {
        backgroundColor: '#10A1DC',
        label: 'КНУ, ед.',
        data: this.knuResponse.map((item) => item.knu),
      },
    ];
  }

  /**
   * Данные для графика кну
   * @private
   */
  private get knuMiddleLine(): number {
    if (!this.knuResponse || !this.knuResponse.length) {
      return 0;
    }

    // если у нас 1 чуз или полигон, то не выводим среднюю линию
    if (this.knuResponse.length === 1) {
      return 0;
    }

    let result = 0;
    this.knuResponse.forEach((item: Statistic.KnuChartItem) => {
      result += +item.knu;
    });

    result = Math.round(result / this.knuResponse.length);

    return result;
  }

  private get organizationOptions(): SelectOption[] {
    if (!this.companiesShortResponse) {
      return [];
    }

    const polygonIds = this.polygon.value?.map((value) => Number(value)) ?? [];

    const companies = CompanyService.filterOrganizationsByPolygonIds(
      polygonIds,
      this.companiesShortResponse,
    );

    return companies.map((item) => ({
      value: item.id,
      text: item.name,
      label: item.name,
    }));
  }

  /**
   * Вешаем обработчики
   */
  private initEventHandlers(): void {
    this.$eventBus.$on(this.$eventBus.UPDATE_CHART_DATA, () => {
      this.loadChartData();
    });
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING, this.changeAlreadyUnloadingHandler);
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * снимаем обработчики
   */
  private unBindEventHandlers(): void {
    this.$eventBus.$off(this.$eventBus.UPDATE_CHART_DATA);
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING, this.changeAlreadyUnloadingHandler);
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  private fetchSettingsData(): Promise<void> {
    return new Promise((resolve) => {
      this.isSettingsLoading = true;
      Promise.allSettled(
        [
          this.getHandbookFullData(),
          this.getCompaniesShortData(),
        ],
      ).then(() => {
        this.isSettingsLoading = false;
        resolve();
      });
    });
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): Promise<void> {
    return new Promise((resolve) => {
      this.isSettingsLoading = true;
      this.$handbookApi.getFullData()
        .then((response: HandbookFullDataResponse) => {
          this.handbookFullData = response;
          this.createFieldsOptions();
          resolve();
        });
    });
  }

  /**
   * Получить короткие данные о компании
   * @private
   */
  private getCompaniesShortData(): Promise<void> {
    return new Promise((resolve) => {
      this.$statisticApi.getOrganizationList().then((response) => {
        this.companiesShortResponse = response;
        resolve();
      }).catch((error) => {
        window.console.log(error);
      });
    });
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.polygon.options = this.createOptions(HandbookCode.POLYGON);
    this.genderFilter.options = this.createOptions(HandbookCode.GENDER);
    this.disabilityGroupFilter.options = this.createOptions(HandbookCode.DISABILITY_GROUP);
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  /**
   * Создать запрос
   * @private
   */
  private createRequest(): Statistic.BaseRequest {
    return {
      date_from: this.periodModel.from,
      date_to: this.periodModel.to,
      group_by: this.groupByFilter.value ?? 'day',
      polygons: this.polygon.value ?? null,
      organisations: this.organizationField.value ?? null,
      date_of_birth_from: this.dateOfBirth.value.from,
      date_of_birth_to: this.dateOfBirth.value.to,
      disability_group_ids: this.disabilityGroupFilter.value,
      gender: this.genderFilter.value,
      disability_group_ids_include_null: this.disabilityGroupIdsIncludeNull.value as string,
      statuses: this.status.value,
    };
  }

  /**
   * Загрузить данные для графика
   */
  private loadChartData(): void {
    this.loadingChartData = true;
    Promise.allSettled(
      [
        this.getParticipants(),
        this.getParticipantsDistribution(),
        this.getProgramsTotal(),
        this.getServicesRepetition(),
        this.getKnuChart(),
      ],
    ).then(() => {
      this.loadingChartData = false;
    });
  }

  /**
   * Получить сводку по программам
   * @private
   */
  private getProgramsTotal(): Promise<void> {
    return new Promise((resolve) => {
      this.$statisticApi.getProgramsTotals(this.createRequest()).then((response) => {
        this.programTotalResponse = response;
        resolve();
      }).catch(() => {
        resolve();
      });
    });
  }

  /**
   * Получить данные для графика по участникам
   * @private
   */
  private getParticipants(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.$statisticApi.getParticipants(this.createRequest()).then((response) => {
        this.participantsChart = response;
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  /**
   * Загрузить распределения участников
   * @private
   */
  private getParticipantsDistribution(): Promise<void> {
    return new Promise((resolve) => {
      this.$statisticApi
        .getParticipantsDistribution(this.createRequest()).then((response) => {
          this.participantsDistributionResponse = response;
          resolve();
        }).catch(() => {
          this.participantsDistributionResponse = null;
          resolve();
        });
    });
  }

  /**
   * Загрузить повторения услуг
   * @private
   */
  private getServicesRepetition(): Promise<void> {
    return new Promise((resolve) => {
      const params = this.createRequest();

      this.$statisticApi
        .getServicesRepetition(params).then((response) => {
          this.servicesRepetition = response;
          resolve();
        }).catch(() => {
          this.servicesRepetition = null;
          resolve();
        });
    });
  }

  /**
   * Получить данные для кну
   * @private
   */
  private getKnuChart(): Promise<void> {
    return new Promise((resolve) => {
      const params = this.createRequest();

      this.$statisticApi.getKnuChart(params)
        .then((response) => {
          this.knuResponse = response;
          resolve();
        }).catch(() => {
          resolve();
        });
    });
  }

  /**
   * Получить параметры для запроса на экспорт
   * @private
   */
  private getStatisticExportRequestParams(): Nullable<Statistic.ExportRequestParams> {
    return {
      group_by: this.groupByFilter.value,
      date_to: this.periodModel.to,
      date_from: this.periodModel.from,
      polygons: this.polygon.value,
      organisations: this.organizationField.value,
      date_of_birth_from: this.dateOfBirth.value.from,
      date_of_birth_to: this.dateOfBirth.value.to,
      disability_group_ids: this.disabilityGroupFilter.value,
      gender: this.genderFilter.value,
      disability_group_ids_include_null: this.disabilityGroupIdsIncludeNull.value as string,
      statuses: this.status.value,
    };
  }

  /**
   * Экспортировать статистику
   * @private
   */
  private exportStatistic(): void {
    this.loadingExport = true;

    this.$statisticApi.exportStatistic(
      this.getStatisticExportRequestParams(),
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    }).catch((e) => {
      window.console.log(e);
      this.loadingExport = false;
    });
  }

  /**
   * Клик по кнопке экспорта
   * @private
   */
  private clickExportHandler(): void {
    this.exportStatistic();
  }

  private changeAlreadyUnloadingHandler(): void {
    this.toggleLoadingExportFile(false);
  }

  /**
   * Изменения фильтра полигона
   * @private
   */
  private changePolygonFilterHandler(): void {
    // При изменение полигона, сбрасываем фильтры компании
    this.organizationField.value = null;
    this.loadChartData();
  }

  /**
   * Считаем проценты для диагарммы услуг
   * @private
   */
  private getPercentageService(value: number, summ: number):number {
    return parseFloat(((value / summ) * 100).toFixed(1));
  }

  /**
   * Клик на кнопку выбрать все в фильтрах по ЧУЗ
   * @private
   */
  private checkedAllOrganizationOptions(isSelectedAll: boolean): void {
    this.$nextTick(() => {
      if (isSelectedAll) {
        this.organizationField.value = null;
      } else {
        this.organizationField.value = this.organizationOptions
          .map((option) => option.value as string);
      }
      this.loadChartData();
    });
  }

  /**
   * Клик на кнопку выбрать все в фильтрах по Полигону
   * @private
   */
  private checkedAllPolygonOptions(isSelectedAll: boolean): void {
    this.$nextTick(() => {
      this.organizationField.value = null;
      if (isSelectedAll) {
        this.polygon.value = null;
      } else {
        this.polygon.value = this.polygon
          .options?.map((option) => option.value as string) ?? null;
      }
      this.loadChartData();
    });
  }

  private changeDayOfBirthFilterHandler(value: Dates | null): void {
    this.dateOfBirth.value = value ?? { from: '', to: '' };
    this.loadChartData();
  }
}

