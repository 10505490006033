import { Service } from '@/types/service';
import { DataTableItem, HandbookFullDataItem, HandbookValueItem } from '@/types';
import HandbookService from '@/services/HandbookService';
import { HandbookCode } from '@/enums/HandbookList';
import FrequencyService from '@/services/FrequencyService';

/**
 * Сервис по работе с услугами
 */
export default class ServiceService {
  /**
   * Превратить элемент услуги в элемент для таблицы
   * @param serviceItem
   * @param handbookFullData
   */
  static transformServiceItemToTableItem(
    serviceItem: Service.ItemWithFrequency|Service.Item,
    handbookFullData: HandbookFullDataItem[],
  ): DataTableItem {
    const {
      name, code, number,
    } = serviceItem;

    const hasCategoryModel = !!serviceItem.category;
    const hasCategoryId = !!serviceItem.category_id;

    let categoryId = 0;

    if (hasCategoryModel) {
      categoryId = (serviceItem.category as HandbookValueItem).id;
    }

    if (hasCategoryId) {
      categoryId = (serviceItem.category_id as number);
    }

    return {
      id: serviceItem.id.toString(),
      name,
      code,
      number,
      category: HandbookService
        .getTitleFromValues(
          (handbookFullData as HandbookFullDataItem[]),
          HandbookCode.SERVICE_CATEGORIES,
          categoryId,
        ),
    };
  }

  /**
   * Превратить отмеченный элемент услуги в элемент для таблицы
   * @param serviceItem
   * @param handbookFullData
   */
  static transformServiceSelectedItemToTableItem(
    serviceItem: Service.ItemWithFrequency,
    handbookFullData: HandbookFullDataItem[],
  ): DataTableItem {
    if (!serviceItem) {
      return {};
    }

    return {
      ...this.transformServiceItemToTableItem(serviceItem, handbookFullData),
      repeat: FrequencyService
        .getRepeatTypeText(serviceItem.frequency_type),
      count: serviceItem.frequency?.toString() || '-',
    };
  }
}
