














































































import Vue from 'vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Component from 'vue-class-component';
import TextField from '@/components/form-fields/TextField.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import SelectWithSearchAddress from '@/components/form-fields/SelectWithSearchAddress.vue';
import {
  BreadcrumbsItem, FormField,
} from '@/types';
import { Help } from '@/types/help';
import tinymce, { Editor } from 'tinymce';

@Component({
  components: {
    Breadcrumbs,
    TextField,
    CustomSelect,
    SelectWithSearchAddress,
  },
  props: {},
})
export default class HelpControlEdit extends Vue {
  private isEditPage = !!this.$route.params.id;

  private isActive = false;

  private loading = false;

  private htmlCode: string | null = null;

  private helpData: Help.Item | null = null;

  private fileUrl: null | string = null;

  private errorTextEditor: string | null = null;

  private editorCode: Promise<Editor[]> | null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
      exact: true,
    },
    {
      text: 'Список инструкций',
      href: '/help/control',
      disabled: false,
      exact: true,
    },
    {
      text: `${this.isEditPage ? 'Редактирование инструкции' : 'Добавление инструкции'}`,
      href: '',
      disabled: true,
    },
  ];

  private title: FormField = {
    placeholder: 'Введите название',
    value: null,
    error: '',
    name: 'title',
    label: 'Название',
  };

  private sort: FormField = {
    placeholder: 'Введите данные',
    value: null,
    error: '',
    name: 'sort',
    label: 'Сортировка',
  };

  private allFields = [
    this.title,
    this.sort,
  ]

  mounted(): void {
    window.initSummer();
    if (this.helpId) {
      this.loading = true;
      this.getLelpData();
    }
  }

  /**
   * Получить данные для запроса на создание/обновление организации
   */
  private get requestData(): Help.CreateRequest {
    return {
      text: window.getValue(),
      sort: this.sort.value ?? '',
      active: this.isActive,
      title: this.title.value ?? '',
    };
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private resetError(): void {
    this.allFields.forEach((field) => {
      field.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param errors
   * @private
   */
  private setError(errors: Record<string, string[]>): void {
    this.resetError();
    this.errorTextEditor = null;

    Object.keys(errors).forEach((key) => {
      const currentField = this.allFields.find((field) => field.name === key);
      const message = errors[key].join(', ');
      if (key === 'text') {
        this.errorTextEditor = message;
      }
      if (currentField && message) {
        currentField.error = message;
      }
    });
  }

  /**
   * Получить идентификатор статьи
   * @private
   */
  private get helpId(): string | null {
    return this.$route?.params?.id || null;
  }

  private checkLoadEditor(data: Help.Item) {
    const interval = setInterval(() => {
      if (tinymce.get().length > 0) {
        this.fillFormData(data);
        clearInterval(interval);
      }
    }, 1000);
  }

  /**
   * Заполнить форму в соответствии с полученными данными
   * @param data
   */
  private fillFormData(data: Help.Item): void {
    this.htmlCode = data.text;
    this.title.value = data.title;
    this.sort.value = data.sort.toString();
    window.setValue(this.htmlCode);
    this.isActive = data.active;
    this.loading = false;
    if (!tinymce.activeEditor) {
      return;
    }
    tinymce.activeEditor.setContent(data.text);
  }

  /**
   * Получить информацию о статье
   */
  private getLelpData(): void {
    if (this.isEditPage && this.helpId) {
      this.loading = true;
      this.$helpAPi.getDetail(+this.helpId)
        .then((response) => {
          if (response) {
            this.helpData = response;
            this.fillFormData(response);
          }
        })
        .catch((e) => {
          window.console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  /**
   * Обработка нажатия на кнопку "Сохранить"
   */
  private saveButtonClickHandler(): void {
    let request;
    if (this.helpId) {
      request = this.$helpAPi.update(
        this.requestData,
        this.helpId,
      );
    } else {
      request = this.$helpAPi.create(this.requestData);
    }
    request.then((result) => {
      if (result.success) {
        this.$router.push({ name: 'help-control' });
      }
    }).catch((error) => {
      window.console.log(error);
      if (error.message) {
        this.setError(error.message);
      }
    });
  }

  /**
   * Обработка нажатия на кнопку "Применить"
   */
  private applyButtonClickHandler(): void {
    if (!this.helpId) {
      return;
    }
    window.console.log(this.requestData);
    this.$helpAPi.update(
      this.requestData,
      this.helpId,
    ).then((response) => {
      if (response.success) {
        this.$toast.success('Данные успешно обновлены');
        window.location.reload();
      }
    }).catch((error) => {
      window.console.log(error);
      if (error.message) {
        this.setError(error.message);
      }
    });
  }

  /**
   * загрузка медиа
   */
  private changeFileHandler(event: File): void {
    const files = event;
    if (!files || this.loading) {
      return;
    }

    this.loading = true;
    this.$storage.uploadFile(files)
      .then((response) => {
        this.fileUrl = response.url;
      })
      .catch((err) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
