import Vue from 'vue';
import { HandbookFullDataItem, HandbookFullDataResponse } from '@/types';

export default class WithHandbooks extends Vue {
  protected handbookFullData: HandbookFullDataItem[]|null = null;

  protected loadingHandbooks = false;

  mounted(): void {
    this.getHandbookFullData();
  }

  /**
   * Получить все данные по спискам справочникам
   */
  protected getHandbookFullData(): void {
    this.loadingHandbooks = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingHandbooks = false;
        this.handbookFullData = response;
      })
      .finally(() => {
        this.loadingHandbooks = false;
      });
  }
}
