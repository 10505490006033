/**
 *
 * Права как на бекенде
 */

enum Permission {
  USER_VIEW = 'user-view',
  USER_MANAGEMENT = 'user-management',
  PARTICIPANT_MANAGEMENT = 'participant-management',
  ORGANISATION_MANAGEMENT = 'organisation-management',
  HANDBOOK_VALUE_MANAGEMENT = 'handbook-value-management',
  SERVICE_VIEW = 'service-view',
  SERVICE_MANAGE = 'service-manage',
  PROGRAM_TEMPLATE_MANAGE = 'program-template-manage',
  PROGRAM_TEMPLATE_VIEW = 'program-template-view',
  ROLE_MANAGEMENT = 'role-management',
  PROGRAM_MANAGE = 'program-manage',
  PROGRAM_VIEW = 'program-view',
  STATISTIC_VIEW = 'statistics-view',
  HELP_MANAGE = 'help-item-manage',
}

export default Permission;
