











































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormFieldRadioButtonWithIcon } from '@/types';

@Component({
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
})

export default class RadioButtonsLabelIcon extends Vue {
  private field!: FormFieldRadioButtonWithIcon;
}
