/**
 * Периодичность
 */
export namespace Frequency {
  export enum Type {
    DAILY = '0',
    WEEKLY = '1',
    MONTHLY = '2',
    YEARLY = '3',
    NO_REPEAT = '4',
  }

  export enum DateType {
    DAY = 'nthDay',
    DAY_OF_WEEK = 'nthDayOfWeek',
    DAY_IN_MONTH = 'nthDayInMonth',
    DAY_OF_WEEK_IN_MONTH = 'nthDayOfWeekInMonth',
  }
}
