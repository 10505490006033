



























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {
  AddressDetails,
  BreadcrumbsItem,
  Company,
  FormField,
  FormFieldCheckboxGroup,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  OrganisationsListRequest,
  OrganizationsListResponse,
  SelectOption,
  UserInfo,
} from '@/types';
import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import PermissionEnums from '@/enums/PermissionEnums';
import tools from '@/tools';
import { HandbookCode } from '@/enums/HandbookList';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';

@Component({
  components: { DataFilters, Breadcrumbs, ConfirmationDialog },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class OrganisationsList extends Vue {
  private user!: UserInfo;

  private NAME_EXPORT_FILE = 'organisationsList';

  private showFilter = true;

  private loading = false;

  private loadingStartConfig = true;

  private organisationsData: Company[]|null = null;

  private isDeleteConfirmDialogVisible = false;

  private pagesCount = 0;

  private currentPage = 1;

  private sortBy = [];

  private sortDesc = [];

  private companyCounts = {
    from: '',
    to: '',
    total: '',
  }

  private loadingExport = false;

  private currentCompanyId: number|null = null;

  private handbookFullData: HandbookFullDataItem[]|null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список организаций',
      href: '',
      disabled: true,
    },
  ]

  private address: FormField = {
    value: null,
    name: 'address',
    placeholder: 'Введите данные',
    error: '',
    label: 'Адрес',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private name: FormField = {
    value: null,
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Наименование',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private polygon: FormFieldCheckboxGroup = {
    value: null,
    name: 'polygon',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Полигон',
    fieldType: FormFieldTypeEnum.SELECT,
    options: [],
    multiple: false,
  }

  private allFields = [
    this.name,
    this.address,
    this.polygon,
  ]

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      width: '10%',
      class: 'custom-table-heading',
    },
    {
      text: 'Наименование(краткое)',
      value: 'name',
      width: '20%',
      class: 'custom-table-heading',
    },
    {
      text: 'Адрес',
      value: 'address',
      width: '40%',
      class: 'custom-table-heading',
    },
    {
      text: 'Полигон',
      value: 'polygon',
      width: '20%',
      class: 'custom-table-heading',
    },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  /**
   * Наблюдает за сменой сортировке в таблице
   */
  @Watch('sortDesc')
  onSortDescChange(): void {
    this.getOrganisations();
  }

  mounted(): void {
    this.getHandbookFullData();
    this.addEventListeners();
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  private get entityFilter(): string {
    return FiltersEntityEnum.ORGANISATIONS;
  }

  /**
   * Может ли юзер менеджерить организации
   */
  private get canManagementOrganisations(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.ORGANISATION_MANAGEMENT) !== -1;
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
        this.polygon.options = this.polygonOptions;
      });
  }

  /**
   * Опции для фильта полигона
   */
  private get polygonOptions(): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }

    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(HandbookCode.POLYGON, this.handbookFullData),
    );
  }

  /**
   * Создать запрос
   */
  private createRequest(): OrganisationsListRequest {
    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }

    return {
      page: this.currentPage,
      address: this.address.value || '',
      name: this.name.value || '',
      sort,
      polygon: this.polygon.value || '',
    };
  }

  /**
   * Получить организации
   */
  private getOrganisations(): void {
    this.loading = true;
    this.$organizationApi.getList(this.createRequest())
      .then((response: OrganizationsListResponse) => {
        this.organisationsData = response.data;
        this.companyCounts.from = response.from?.toString();
        this.companyCounts.to = response.to?.toString();
        this.companyCounts.total = response.total?.toString();
        this.pagesCount = response.last_page;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Удалить организацию
   * @param id
   * @private
   */
  private deleteCompany(id: number): void {
    this.$organizationApi.deleteCompany({ id })
      .then((response) => {
        if (response.success) {
          this.getOrganisations();
          this.hideDeleteConfirmDialog();
        }
      });
  }

  /**
   * Получить строку адреса из составного объекта адреса
   * @param addr
   * @return {string}
   */
  private getAddressStringFromComposite(addr: AddressDetails): string {
    return tools.getLabelFromAddressDetailType(addr);
  }

  /**
   * получить имя полигона
   */
  private getPolygonName(id: number|null): string {
    if (!id && id !== 0) {
      return '';
    }
    const name = this.polygonOptions.find((option) => option.value === id)?.text;

    return typeof name !== 'undefined' ? name.toString() : '';
  }

  /**
   * Сбросить фильтр
   */
  private resetFilters(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.value = null;
    });
  }

  /**
   * Экспорт компаний в файл
   */
  private exportCompany(): void {
    this.loadingExport = true;
    this.$organizationApi.exportOrganisations(
      {
        ...this.createRequest(),
        _fields: 'id,name,{physical_address.postal_code physical_address.region physical_address.city physical_address.street physical_address.house physical_address.flat},polygon',
        _labels: 'ID,Название,Адрес,Полигон',
      },
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    });
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик клика поиска в фильтах
   */
  private clickSearchFilterHandler(): void {
    this.currentPage = 1;
    this.getOrganisations();
  }

  /**
   * Обработчик клика кнопки сбросить
   * @private
   */
  private clickResetFilterButtonHandler(): void {
    this.resetFilters();
    this.currentPage = 1;
    this.getOrganisations();
  }

  /**
   * Загрузка фильтров
   */
  private loadedFilterHandler(): void {
    this.getOrganisations();
  }

  /**
   * обработчик клика удаления
   * @param id
   */
  private clickDeleteIconHandler(id: number): void {
    this.isDeleteConfirmDialogVisible = true;
    this.currentCompanyId = id;
  }

  /**
   * Клик по кнопке экспорта
   * @private
   */
  private clickExportHandler(): void {
    this.exportCompany();
  }

  /**
   * Скрыть диалог подтверждения
   */
  private hideDeleteConfirmDialog(): void {
    this.currentCompanyId = null;
    this.isDeleteConfirmDialogVisible = false;
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }
}
