















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem, DataTableItem, FormField, UserInfo,
} from '@/types';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import { Program } from '@/types/program';
import PermissionEnums from '@/enums/PermissionEnums';
import Delete from '@/components/modals/DeleteDialog.vue';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import dayjs from 'dayjs';
import { CurrentPageListNames } from '@/enums/CurrentPageListNames';
import ListPageSettingsService from '@/services/list-page-settings/ListPageSettingsService';

@Component({
  components: {
    DataFilters,
    DataTableWrapper,
    Breadcrumbs,
    Delete,
  },
  beforeRouteLeave(to, from, next): void {
    /**
     * Если мы переходим НЕ на детальную страницу, то сбрасываем пагиацию в сторе
     */
    if (to.name !== 'program-template-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PROGRAM_TEMPLATE);
    }
    next();
  },
  beforeRouteEnter(to, from, next): void {
    /**
     * Если мы пришли НЕ с детальной страницы, то сбрасываем пагиацию в сторе
     */
    if (from.name !== 'program-template-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PROGRAM_TEMPLATE);
    }
    next();
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ProgramTemplateList extends Vue {
  private user!: UserInfo;

  private showDeleteDialog = false;

  private idDeleteElement: string|null = null;

  private templatesList: Program.TemplateItem[]|null = null;

  private loading = false;

  private loadingFilters = true;

  private pagesCount = 0;

  private currentPage = 1;

  private sortBy = [];

  private sortDesc = [];

  private itemCounts = {
    from: '',
    to: '',
    total: '',
  }

  private showFilter = true;

  private nameField: FormField = {
    label: 'Название',
    name: 'name',
    error: '',
    value: null,
    placeholder: 'Введите данные',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private allFields = [
    this.nameField,
  ];

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Лечение',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список шаблонов',
      href: '/',
      disabled: true,
    },
  ]

  private headers = [
    {
      text: 'Название',
      value: 'name',
      class: 'custom-table-heading',
      sortable: false,
    },
    {
      text: 'Услуги (шт.)',
      value: 'services',
      class: 'custom-table-heading',
      sortable: false,
    },
  ]

  mounted(): void {
    const storeSettings = ListPageSettingsService.getListPageSettingsFromStore(
      CurrentPageListNames.PROGRAM_TEMPLATE,
    );
    this.currentPage = storeSettings?.currentPage ?? 1;
    this.sortBy = storeSettings?.sort?.sortBy ?? [];
    this.sortDesc = storeSettings?.sort?.sortDesc ?? [];
  }

  /**
   * Получить индефикацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.PROGRAM_TEMPLATES;
  }

  /**
   * Получить элементы таблицы
   */
  private get tableItems(): DataTableItem[] {
    if (!this.templatesList || !this.templatesList.length) {
      return [];
    }

    return this.templatesList.map((template) => ({
      id: template.id.toString(),
      name: template.name,
      services: template.services_count?.toString() ?? '-',
    }));
  }

  /**
   * Может ли управлять шаблонами
   */
  private get canManageTemplates(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.PROGRAM_TEMPLATE_MANAGE) !== -1;
  }

  /**
   * Создать параметры запроса
   */
  private createRequest(): Program.TemplateListRequest {
    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }

    return {
      page: this.currentPage,
      sort,
      name: this.nameField.value ? this.nameField.value : '',
    };
  }

  /**
   * Загрузить список шаблонов
   */
  private getListTemplates(): void {
    this.loading = true;
    this.$programApi.getTemplateList(this.createRequest())
      .then((response) => {
        const { to, from, total } = response;
        this.itemCounts = { to: to?.toString(), from: from?.toString(), total: total?.toString() };
        this.pagesCount = response.last_page;
        this.templatesList = response.data;
        this.setStoreSettingsPage();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Очищает фильтры
   */
  private clearFilters(): void {
    this.nameField.value = '';
  }

  /**
   * Экспорт шаблонов в файл
   */
  private exportTemplates(): void {
    this.$programApi.exportTemplates(
      {
        ...this.createRequest(),
        _fields: 'name,services_count',
        _labels: 'Название,Услуги(шт)',
      },
    )
      .then((response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${dayjs().format('YYYY-MM-DD-HH-mm-ss')}-export.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
  }

  /**
   * Обработать отмену удаления
   */
  private handleCancellationOfDeletion(): void {
    this.showDeleteDialog = false;
    this.idDeleteElement = null;
  }

  /**
   * Обработать принятие удаления
   */
  private handleAcceptanceOfDeletion(id: string): void {
    this.$programApi.deleteTemplate({ id })
      .then(() => {
        this.showDeleteDialog = false;
        this.idDeleteElement = null;
        this.getListTemplates();
      });
  }

  /**
   * Обработать клик удалить шаблон в таблице
   */
  private handlerDeleteClickTemplate(id: string): void {
    this.showDeleteDialog = true;
    this.idDeleteElement = id;
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик клика по поиску
   * @private
   */
  private clickSearchHandler(): void {
    this.getListTemplates();
  }

  /**
   * Обработчик клика на очистку фильтров
   * @private
   */
  private async clickClearFiltersHandler(): Promise<void> {
    await this.clearFilters();
    this.getListTemplates();
  }

  /**
   * Обработчик сортировки
   * @param sort
   * @private
   */
  private changeSortHandler(sort: {sortBy: string[], sortDesc: boolean[]}): void {
    this.sortBy = (sort.sortBy as never[]);
    this.sortDesc = (sort.sortDesc as never[]);

    this.setStoreSettingsPage();
    this.getListTemplates();
  }

  /**
   * Загрузка фильтров
   */
  private loadedFilterHandler(): void {
    this.getListTemplates();
    this.$nextTick(() => {
      this.loadingFilters = false;
    });
  }

  /**
   * Обработать клик по строке таблицы
   */
  private handleRowClick(id: string): void {
    this.$router.push({
      name: 'program-template-detail',
      params: {
        id,
      },
    });
  }

  /**
   * Клик по кнопке экспорта
   * @private
   */
  private clickExportHandler(): void {
    this.exportTemplates();
  }

  /**
   * Устанавливаем в стор, номер текущей страницы
   * @private
   */
  setStoreSettingsPage():void {
    this.$store.dispatch('setListPageSetting', {
      pageTitle: CurrentPageListNames.PROGRAM_TEMPLATE,
      currentPage: this.currentPage,
      sort: {
        sortDesc: this.sortDesc,
        sortBy: this.sortBy,
      },
    });
  }
}
