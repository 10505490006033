import Vue from 'vue';
import { Export } from '@/types/export';

type ExportFile = {
  nameFile: string,
  timerId: number|null,
  isAlreadyUnloading: boolean,
}

type ExportFilesList = ExportFile[];
/**
 * Сервис по работе с экспортом файлов
 */
export default class ExportFileServices extends Vue {
  private static settingsExportFiles: ExportFilesList = [];

  /**
   * Создаем новый файл с нужными параметрами
   */
  static createNewFile(name: string): ExportFile {
    return {
      nameFile: name,
      timerId: null,
      isAlreadyUnloading: false,
    };
  }

  /**
   * Удаляем файл
   */
  static deleteFile(name: string): void {
    const indexFile = this.settingsExportFiles.findIndex((item) => item.nameFile === name);
    this.settingsExportFiles.splice(indexFile, 1);
  }

  /**
   * Загрузка файла
   */
  public startUnLoading(name: string, params: Export.BaseRequest): void {
    // Создаём файл для отслеживания состояния
    const newFile = ExportFileServices.createNewFile(name);
    // Добавляем файл в кэш где он хранится
    ExportFileServices.settingsExportFiles.push(newFile);
    // Записываем айдишник интервала
    newFile.timerId = setInterval(async () => {
      let response;
      try {
        // Проверяем готов ли файл
        response = await this.$exportApi.getStatus(params);
      } catch (err) {
        newFile.isAlreadyUnloading = false;
        window.console.log(err);
      }
      if (response) {
        newFile.isAlreadyUnloading = response.result;
      }
      // Если файл готов очищаем интервал и скачиваем файл
      if (newFile.isAlreadyUnloading) {
        clearInterval(newFile.timerId as number);
        await this.$exportApi.getFile(params).then((response) => {
          this.$eventBus.$emit(this.$eventBus.ALREADY_UNLOADING, response, params.extension);
          ExportFileServices.deleteFile(name);
        });
      }
    }, 5000);
  }

  /**
   * Статус загрузки файла в кэше
   */
  public getStatusLoadingExportFileFromCache(name: string): boolean {
    let isLoading = false;
    ExportFileServices.settingsExportFiles.forEach((item) => {
      if (item.nameFile === name) {
        isLoading = true;
      }
    });
    return isLoading;
  }
}
