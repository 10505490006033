




































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';
import { FormField } from '@/types';

@Component({
  components: { FieldLabel },
  props: {
    field: {
      type: Object,
      required: true,
    },
    viewYear: {
      type: Boolean,
      default: false,
    },
    hasMaxDate: {
      type: Boolean,
      default: true,
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
  },
})

export default class DatePicker extends Vue {
  private min!: string;

  private max!: string;

  private inputModel = '';

  private timeoutId: number|null = null;

  private hasMaxDate!: boolean;

  private field!: FormField;

  private numberOfYear: number|null = null;

  private dateMenu = false;

  private inputFormat = 'DD.MM.YYYY';

  private outputFormat = 'YYYY-MM-DD';

  @Watch('field.value')
  onFieldValueChange(newValue: string): void {
    this.$nextTick(() => {
      if (!newValue) {
        this.inputModel = '';
      }
    });
  }

  mounted(): void {
    Vue.nextTick(() => {
      if (!this.field.value) {
        return;
      }
      this.inputModel = this.formattedValue;
      this.numberOfYear = this.getAge(this.field.value);
    });
  }

  private get isClear(): boolean {
    return !!this.field.clearable;
  }

  /**
   * На изменения даты
   * @param data
   * @private
   */
  private changeDatePickerHandler(data: string): void {
    this.dateMenu = false;
    this.numberOfYear = this.getAge(data);
    this.inputModel = this.formattedValue;
  }

  /**
   * Установить максимальную дату
   * @private
   */
  private get maxDate(): string {
    if (!this.hasMaxDate) {
      return '';
    }

    if (this.max) {
      return this.max;
    }

    return this.$date().format(this.outputFormat);
  }

  private get minDate(): string {
    if (this.min) {
      return this.min;
    }
    return this.$date('01-01.1900').format(this.outputFormat);
  }

  private get formattedValue(): string {
    if (!this.field.value) {
      return '';
    }
    return this.$date(this.field.value).format(this.inputFormat);
  }

  /**
   * Посчитать полных лет
   */
  private getAge(birthDay: string): number {
    const today = this.$date();
    const birthDayDayJs = this.$date(birthDay);

    if (!birthDayDayJs || !birthDayDayJs.isValid()) {
      return 0;
    }

    let age = today.year() - birthDayDayJs.year();
    const diffMonth = today.month() - birthDayDayJs.month();

    if (diffMonth < 0 || (diffMonth === 0 && today.date() < birthDayDayJs.date())) {
      age -= 1;
    }

    return age;
  }

  private onChange(): void {
    this.field.error = '';
    this.$emit('change');
  }

  /**
   * На ручное изменение даты
   * @private
   */
  private changeInputValueHandler(): void {
    this.$emit('change-period');

    const inputDate = this.$date(this.inputModel, this.inputFormat);
    this.numberOfYear = null;
    this.field.error = '';

    if (!this.inputModel) {
      this.field.value = '';
      this.$emit('change');
      return;
    }

    if (!inputDate.isValid()) {
      this.field.error = 'Некорректная дата';
      return;
    }
    const minUnix = this.$date(this.minDate).unix();

    if (inputDate.unix() < minUnix
      || (this.maxDate && this.$date(this.maxDate).unix() < inputDate.unix())) {
      this.field.error = 'Некорректная дата';
      return;
    }

    this.field.value = inputDate.format(this.outputFormat);
    this.inputModel = this.formattedValue;
    this.numberOfYear = this.getAge(inputDate.format(this.outputFormat));
    this.onChange();
  }
}

