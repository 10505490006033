




































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    elementId: {
      type: String,
      required: true,
    },
    handleAcceptance: {
      type: Function,
      default: null,
    },
    handleCancellation: {
      type: Function,
      default: null,
    },
  },
})

/**
 * Компонент диалогового окна подтверждения удаления
 */
export default class Delete extends Vue {
  private elementId!: string;

  private handleAcceptance!: (id: string) => void;

  private handleCancellation!: () => void;

  /**
   * принять удаления
   * @private
   */
  private clickAcceptHandler(): void {
    if (!this.handleAcceptance) {
      return;
    }

    this.handleAcceptance(this.elementId);
  }

  /**
   * Отменить удаление
   * @private
   */
  private clickNotHandler(): void {
    if (!this.handleCancellation) {
      return;
    }

    this.handleCancellation();
  }
}
