










import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    label: {
      type: String,
      default: '',
    },
  },
})

export default class FormRow extends Vue {
  private label!: string;
}
