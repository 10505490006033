
























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
})

export default class RowText extends Vue {
}

