import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"py-2 my-4 black--text",attrs:{"no-gutters":"","align":"start"}},[_c(VCol,{staticClass:"font-weight-medium pr-4",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltipText)?_c(VTooltip,{attrs:{"max-width":"250px","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"#006BA3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,false,1008853250)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])]):_vm._e()],1),_c(VCol,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.text ? _vm.text : '-')}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }