import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"420"},on:{"click:outside":_vm.clickNotHandler}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-h4 black--text"},[_vm._v(" Вы уверены, что хотите удалить элемент? ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickAcceptHandler}},[_vm._v("Да ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickNotHandler}},[_vm._v("Нет ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }