enum PeriodsPreset {
  TODAY = 0,
  YESTERDAY,
  WEEK,
  MONTH,
  QUARTER,
  CURRENT_YEAR
}

enum PeriodsPresetRuLabel {
  TODAY = 'Сегодня',
  YESTERDAY = 'Вчера',
  WEEK = 'Неделя',
  MONTH = 'Месяц',
  QUARTER = 'Квартал',
  CURRENT_YEAR = 'Год',
}

export { PeriodsPreset, PeriodsPresetRuLabel };
