








































import Vue from 'vue';
import Component from 'vue-class-component';
import DoughnutChart from '@/components/chartjs/DoughnutChart.vue';
import { ParticipantsDistributionName } from '@/enums/Statistics';
import Chart from 'chart.js';

@Component({
  components: { DoughnutChart },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
})

export default class DoughnutWrapper extends Vue {
  private defaultColors = ['#197EB1'];

  private doubleColors = ['#FF9AAF', '#fff'];

  private legendColor = ['#FF9AAF', '#197EB1'];

  private data!: {
    inner: Record<string, number>
    outer: Record<string, number>
  };

  private get unitedData(): Record<string, number> {
    return { ...this.data.inner, ...this.data.outer };
  }

  /**
   * Получить текст легенд двойного графика
   * @private
   */
  private get legendDoubleChartItems(): Array<Record<string, number>> {
    const result = this.unitedData;
    return Object.keys(result).filter((item) => !item.includes('value'))
      .map((item) => ({
        [item]: result[item],
      }));
  }

  /**
   * Получить лейблы для внутреннего графика
   * @private
   */
  private get innerChartLabels(): Array<string> {
    const legends = Object.keys(this.data.inner).filter((item) => !item.includes('value'))
      .map((item) => ({
        [item]: this.data.inner[item],
      }));

    return legends.map((legend) => `${this.getLegendsDoubleChartText(legend)}`);
  }

  /**
   * Получить лейблы для внешнего графика
   * @private
   */
  private get outerChartLabels(): Array<string> {
    const legends = Object.keys(this.data.outer).filter((item) => !item.includes('value'))
      .map((item) => ({
        [item]: this.data.outer[item],
      }));

    return legends.map((legend) => `${this.getLegendsDoubleChartText(legend)}`);
  }

  /**
   * Получить данные для графика
   * @private
   */
  private get innerChartData(): Chart.ChartData {
    return {
      labels: [[...this.innerChartLabels, '']],
      datasets: [
        {
          data: [this.dataForInnerChart, 100 - this.dataForInnerChart[0]],
          backgroundColor: this.doubleColors,
        },
      ],
    };
  }

  /**
   * Получить данные для графика
   * @private
   */
  private get outerChartData(): Chart.ChartData {
    return {
      labels: this.outerChartLabels,
      datasets: [
        {
          data: this.dataForOuterChart,
          backgroundColor: this.defaultColors,
        },
      ],
    };
  }

  /**
   * Преобразовать данные для двойного графика
   * @private
   */
  private get dataForOuterChart(): Array<number> {
    return Object.keys(this.data.outer)
      .filter((item) => !item.includes('value'))
      .map((key) => parseFloat(this.data.outer[key].toFixed(1)));
  }

  /**
   * Преобразовать данные для двойного графика
   * @private
   */
  private get dataForInnerChart(): Array<number> {
    return [...Object.keys(this.data.inner)
      .filter((item) => !item.includes('value'))
      .map((key) => parseFloat(this.data.inner[key].toFixed(1)))];
  }

  /**
   * Получить цвет по индексу
   * @param index
   * @private
   */
  private getColor(index: number): string {
    const colors = this.legendColor;

    if (typeof colors[index] === 'undefined') {
      return '';
    }

    return colors[index];
  }

  /**
   * Получить текст для легенд двойной графика
   * @private
   * @param item
   */
  private getLegendsDoubleChartText(item: Record<string, number>): string {
    const result = this.unitedData;
    const key = Object.keys(item)[0];
    const hasKey = Object.prototype.hasOwnProperty.call(ParticipantsDistributionName, key);
    if (!hasKey) {
      return `${key}`;
    }
    return `${ParticipantsDistributionName[key]} ${result[`${key}_value`]} (${item[key]}%)`;
  }
}
