

















































import Component from 'vue-class-component';
import ParticipantSection from '@/components/participant/ParticipantSection.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import {
  DetailMembers,
  ErrorResponse,
  FormFieldSelect,
  ParticipantArchiveRequest,
  ParticipantInfo, ParticipantUnArchiveRequest,
} from '@/types';
import { StatusCode, StatusText } from '@/enums/ParticipantStatus';
import FormActions from '@/components/buttons/FormActions.vue';
import { HandbookCode } from '@/enums/HandbookList';
import DetailedInformation from '@/components/DetailedInformation.vue';
import ErrorApi from '@/api/errorApi';
import DetailedMembersTransform from '@/services/transform-response/DetailedMembers';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';

@Component({
  components: {
    CustomSelect, FormActions, DetailedInformation, DataWillNotSaved,
  },
  props: {
    participantInfo: {
      type: Object,
      required: true,
    },
  },
})
export default class ParticipantSectionStatus extends ParticipantSection {
  private loading = false;

  private participantInfo!: ParticipantInfo;

  private statusField: FormFieldSelect = {
    name: 'status',
    value: this.participantInfo.status,
    error: '',
    label: 'Статус',
    required: false,
    clearable: false,
    options: [
      {
        value: StatusCode.ACTIVE,
        label: StatusText.ACTIVE,
      },
      {
        value: StatusCode.ARCHIVE,
        label: StatusText.ARCHIVE,
      },
    ],
  }

  private reasonField: FormFieldSelect = {
    name: 'reason_id',
    value: null,
    error: '',
    label: 'Причина',
    required: false,
  }

  public allFields = [this.reasonField];

  created(): void {
    this.reasonField.options = this.createOptions(HandbookCode.PARTICIPANT_ARCHIVE_REASON);
    this.fillData();
  }

  /**
   * Получить заголовок секции
   */
  private get sectionTitle(): string {
    return '5. Статус участника';
  }

  private get isArchive(): boolean {
    return this.statusField.value === StatusCode.ARCHIVE;
  }

  /**
   * Участник в архиве
   * @private
   */
  private get isParticipantArchive(): boolean {
    return this.participantInfo.status === StatusCode.ARCHIVE;
  }

  private get detailedData(): DetailMembers.Data|null {
    if (!this.participantInfo) {
      return null;
    }

    return DetailedMembersTransform.getDataFromDetailsView(
      this.participantInfo,
      this.handBooksData,
      'participantStatus',
    );
  }

  private fillData(): void {
    this.reasonField.value = this.participantInfo.archive_reason;
  }

  /**
   * параметры запроса
   * @private
   */
  private getParams(): ParticipantArchiveRequest | ParticipantUnArchiveRequest {
    const params: ParticipantArchiveRequest | ParticipantUnArchiveRequest = {
      id: this.participantId,
    };

    if (!this.isArchive) {
      return params;
    }

    return {
      ...params,
      reason_id: this.reasonField.value,
    };
  }

  /**
   * Отправка формы
   * @private
   */
  private submitForm(): Promise<void> {
    return new Promise((resolve) => {
      const request = this.isArchive
        ? this.$participantApi.archiveParticipant(this.getParams() as ParticipantArchiveRequest)
        : this.$participantApi.unArchiveParticipant(this.getParams());

      request
        .then((response) => {
          if (response.success) {
            this.$emit('change-participant-status');
            resolve();

            this.$toast.success(this.isArchive ? 'Участник заархивирован' : 'Участник активирован');
          }
        })
        .catch((err: ErrorResponse) => {
          if (err.code === ErrorApi.ERROR_VALIDATION && typeof err.message !== 'undefined') {
            this.showNotSaveDialog = false;
            this.setError(err.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * Обработчик клика по кнопке сохранить в режиме созданого юзера
   * @private
   */
  private clickSaveButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.statusBlock = 'readonly';
        this.showNotSaveDialog = false;
        this.emitEventEndEdit();
      });
  }

  /**
   * Клик по кнопке принять
   * @private
   */
  private clickApplyButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.showNotSaveDialog = false;
      });
  }

  /**
   * Клик по кнопке отмена
   * @private
   */
  private closeClickHandle(): void {
    this.showNotSaveDialog = true;
  }

  /**
   * Клик по кнопке не сохранять
   * @private
   */
  private clickNotSaveHandle(): void {
    this.fillData();
    this.emitEventEndEdit();
    this.showNotSaveDialog = false;
    this.statusBlock = 'readonly';
  }
}
