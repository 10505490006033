




























































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  ExportParams,
  FormField, FormFieldDateRange,
  FormFieldSelectStringMultiple,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  Participant,
  ParticipantListRequest,
  ParticipantListResponse,
  SelectOption,
  UserInfo,
} from '@/types';
import { AxiosError } from 'axios';
import PermissionEnums from '@/enums/PermissionEnums';
import { DataTableHeader } from 'vuetify';
import { Watch } from 'vue-property-decorator';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TablePropertyService from '@/services/TablePropertyService';
import properties from '@/data/participants/tableHeaders';
import SettingsName from '@/enums/SettingsName';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import ExportsHelper from '@/helpers/Exports';
import ValidationEnum from '@/enums/Validation';
import { StatusCode, StatusText } from '@/enums/ParticipantStatus';
import { CurrentPageListNames } from '@/enums/CurrentPageListNames';
import ListPageSettingsService from '@/services/list-page-settings/ListPageSettingsService';

@Component({
  components: {
    DataFilters, Breadcrumbs, CustomSelect, ConfirmationDialog,
  },
  beforeRouteLeave(to, from, next): void {
    /**
     * Если мы переходим НЕ на детальную страницу, то сбрасываем пагиацию в сторе
     */
    if (to.name !== 'member-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PARTICIPANT);
    }
    next();
  },
  beforeRouteEnter(to, from, next): void {
    /**
     * Если мы пришли НЕ с детальной страницы, то сбрасываем пагиацию в сторе
     */
    if (from.name !== 'member-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PARTICIPANT);
    }
    next();
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
})
export default class ParticipantList extends Vue {
  private user!: UserInfo;

  private firstLoading = true;

  private NAME_EXPORT_FILE = 'participantList';

  private showFilter = true;

  private loading = false;

  private loadingStartConfig = true;

  private loadingFilters = true;

  private isDeleteConfirmDialogVisible = false;

  private currentDeleteId: number|null = null

  private participantsData: Participant[] | null = null;

  private pagesCount = 0;

  private currentPage = 1;

  private sortBy = [];

  private sortDesc = [];

  private itemCounts = {
    from: '',
    to: '',
    total: '',
  }

  private loadingExport = false;

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private lastName: FormField = {
    value: null,
    name: 'last_name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Фамилия',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  };

  private firstName: FormField = {
    value: null,
    name: 'first_name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Имя',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  };

  private secondName: FormField = {
    value: null,
    name: 'second_name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Отчество',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  };

  private disabilityGroup: FormField = {
    value: null,
    name: 'disability_group',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Инвалидность',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private gender: FormField = {
    value: null,
    name: 'gender',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Пол',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private assistanceProgram: FormField = {
    value: null,
    name: 'assistanceProgram',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Программа помощи',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private category: FormField = {
    value: null,
    name: 'category',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Категория',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private polygon: FormField = {
    value: null,
    name: 'polygon',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Полигон',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private organizationId: FormField = {
    value: null,
    name: 'organization_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Организация',
    options: [],
    fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
  };

  private status: FormField = {
    value: null,
    name: 'status',
    placeholder: 'Введите из списка',
    error: '',
    label: 'Статус',
    options: [
      {
        value: StatusCode.ACTIVE,
        label: StatusText.ACTIVE,
      },
      {
        value: StatusCode.DRAFT,
        label: StatusText.DRAFT,
      },
      {
        value: StatusCode.ARCHIVE,
        label: StatusText.ARCHIVE,
      },
    ],
    fieldType: FormFieldTypeEnum.SELECT,
  };

  private dateRangeProgram: FormFieldDateRange = {
    name: 'dateRangeProgram',
    label: 'Период программы',
    error: '',
    value: {
      from: '',
      to: '',
    },
    mask: '##.##.####',
    fieldType: FormFieldTypeEnum.DATE_RANGE,
  }

  private fieldsColumns = [
    [
      this.lastName,
      this.firstName,
      this.secondName,
    ],
    [
      this.category,
      this.assistanceProgram,
    ],
  ];

  private renderFields: FormField[] = [
    this.polygon,
    this.organizationId,
  ];

  private allFields = [
    ...this.fieldsColumns.flat(),
    ...this.renderFields,
    this.status,
    this.dateRangeProgram,
  ];

  private defaultOptionsSelected = ['id',
    'first_name', 'last_name', 'second_name',
    'polygon', 'assistance_program',
  ];

  private defaultTableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      class: 'custom-table-heading',
    },
    {
      text: 'Фамилия',
      value: 'info.last_name',
      class: 'custom-table-heading',
    },
    {
      text: 'Имя',
      value: 'info.first_name',
      class: 'custom-table-heading',
    },
    {
      text: 'Отчество',
      value: 'info.second_name',
      class: 'custom-table-heading',
    },
    {
      text: 'Полигон',
      value: 'polygon',
      class: 'custom-table-heading',
      sortable: false,
    },
    {
      text: 'Программа помощи',
      value: 'assistance_program',
      class: 'custom-table-heading',
      sortable: false,
    },
  ];

  private headingActions: DataTableHeader = {
    text: '',
    value: 'actions',
    class: 'custom-table-heading',
    align: 'end',
    sortable: false,
  }

  private settingsSelect: FormFieldSelectStringMultiple = {
    label: 'Настройки колонок',
    name: SettingsName.PARTICIPANT,
    value: [],
    error: '',
  }

  /**
   * Наблюдает за сменой сортировке в таблице
   */
  @Watch('sortDesc')
  onSortDescChange(): void {
    if (!this.firstLoading) {
      this.setStoreSettingsPage();
      this.getParticipants();
    }
    this.firstLoading = false;
  }

  mounted(): void {
    const storeSettings = ListPageSettingsService.getListPageSettingsFromStore(
      CurrentPageListNames.PARTICIPANT,
    );
    this.currentPage = storeSettings?.currentPage ?? 1;
    this.sortBy = storeSettings?.sort?.sortBy ?? [];
    this.sortDesc = storeSettings?.sort?.sortDesc ?? [];
    this.getHandbookFullData();
    this.getCompanies();
    this.addEventListeners();
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  /**
   * Выбраны все полигоны
   */
  private get selectedAllTableColumns(): boolean {
    const hasPolygonValues = !!this.settingsSelect.value;
    const hasOptions = !!this.settingsSelect.options;

    if (!hasPolygonValues || !hasOptions) return false;

    return (this.settingsSelect.value as string[])
      .length === (this.settingsSelect.options as SelectOption[]).length;
  }

  /**
   * Выбрано несколько полигонов
   */
  private get selectedSomeTableColumns(): boolean {
    const hasPolygonValues = !!this.settingsSelect.value;
    const hasOptions = !!this.settingsSelect.options;
    if (!hasPolygonValues || !hasOptions) return false;

    return (this.settingsSelect.value?.length ?? 0) > 0 && !this.selectedAllTableColumns;
  }

  /**
   * Иконка для выбора всех полигонов
   */
  private get settingsIcon(): string {
    if (this.selectedAllTableColumns) return 'mdi-close-box';
    if (this.selectedSomeTableColumns) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  /**
   * Получить индефикацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.PARTICIPANTS;
  }

  private get tableHeadings(): DataTableHeader[] {
    const filtredHeading = TablePropertyService
      .getHeadings(properties, this.settingsSelect.value);

    return [
      ...filtredHeading,
      this.headingActions,
    ];
  }

  /**
   * Может ли пользователь управлять участниками
   */
  private get canManageParticipants(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.PARTICIPANT_MANAGEMENT) !== -1;
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Получить статус участника
   */
  private getItemStatus(status: number): string {
    switch (status) {
      case StatusCode.ACTIVE:
        return StatusText.ACTIVE;
      case StatusCode.DRAFT:
        return StatusText.DRAFT;
      case StatusCode.ARCHIVE:
        return StatusText.ARCHIVE;
      default: return '';
    }
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
        this.createFieldsOptions();
      });
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.gender.options = this.createOptions(HandbookCode.GENDER);
    this.polygon.options = this.createOptions(HandbookCode.POLYGON);
    this.assistanceProgram.options = this.getYesNoOptions();
    this.disabilityGroup.options = this.createOptions(HandbookCode.DISABILITY_GROUP);
    this.category.options = this.createOptions(HandbookCode.INDIVIDUAL_CATEGORY);

    const settingsSelectOption = TablePropertyService.getOptions(properties).map((option) => (
      {
        ...option,
        disabled: this.defaultOptionsSelected.indexOf(option.value.toString() ?? '') !== -1,
      }
    ));
    this.settingsSelect.options = settingsSelectOption;

    const defaultSettings = TablePropertyService
      .getSetting((this.settingsSelect.name as SettingsName));
    if (defaultSettings && JSON.parse(defaultSettings).length) {
      const allValue = this.settingsSelect.options?.map((option) => (option.value));

      if (typeof allValue === 'undefined') {
        return;
      }

      this.settingsSelect.value = (JSON.parse(defaultSettings) as Array<string>)
        .filter((value) => allValue.indexOf(value) !== -1);
      this.changeSettingsHandler(this.settingsSelect.value);
    } else {
      this.settingsSelect.value = this.defaultOptionsSelected;
    }
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  private getYesNoOptions(): SelectOption[] {
    return [
      {
        value: 1,
        text: 'Да',
        label: 'Да',
      },
      {
        value: 0,
        text: 'Нет',
        label: 'Нет',
      },
    ];
  }

  /**
   * Получить текст опции по её значению
   * @param optionList
   * @param value
   * @return {string}
   */
  private getOptionTextByValue(
    optionList: SelectOption[],
    value: number | null,
  ): string {
    if (!optionList || !value) {
      return '-';
    }
    const item = optionList.find((item) => item.value === value);
    if (!item) {
      return '-';
    }
    return (item.text && item.text.toString().trim()) || '-';
  }

  /**
   * Получить название полигона для участника
   * @param item
   * @return {string}
   */
  private getItemPolygon(item: Participant): string {
    return this.getOptionTextByValue(
      this.polygon.options || [],
      item?.organisation?.polygon,
    );
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * Участвует ли в программе помощи маломобильным
   * @param member
   * @return {boolean}
   */
  private hasAssistanceProgram(member: Participant): boolean {
    return !!member?.individual_category?.assistance_program;
  }

  private getCompanies(): void {
    this.$organizationApi.getShortList().then((response) => {
      this.organizationId.options = response.map((item) => ({
        value: item.id,
        text: item.name,
        label: item.name,
      }));
    }).catch((error) => {
      window.console.log(error);
    });
  }

  /**
   * Создать запрос
   */
  private createRequest(): ParticipantListRequest {
    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }

    return {
      page: this.currentPage,
      sort,
      id: null,
      first_name: this.firstName.value as string|null,
      last_name: this.lastName.value as string|null,
      second_name: this.secondName.value as string|null,
      gender: this.gender.value as number|null,
      disability_group: this.disabilityGroup.value as number|null,
      'individual_category-assistance_program': this.assistanceProgram.value as number|null,
      'individual_category-category': this.category.value as number|null,
      organisation_id: this.organizationId.value as number|null,
      'organisation-polygon': this.polygon.value as number|null,
      status: this.status.value as number|null,
      program_starts_at_from: this.dateRangeProgram.value?.from ?? null,
      program_ends_at_to: this.dateRangeProgram.value?.to ?? null,
    };
  }

  /**
   * Получить список участников
   */
  private getParticipants(loadWithSaveFilters = false): void {
    if (this.loading && !loadWithSaveFilters) {
      return;
    }
    this.loading = true;
    this.$participantApi.getList(this.createRequest())
      .then((response: ParticipantListResponse) => {
        this.participantsData = response.data;
        this.itemCounts.from = response.from?.toString();
        this.itemCounts.to = response.to?.toString();
        this.itemCounts.total = response.total?.toString();
        this.pagesCount = response.last_page;
        this.setStoreSettingsPage();
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Сбросить фильтры
   */
  private resetFilters(): void {
    this.allFields.forEach((field) => {
      field.value = null;
    });
  }

  /**
   * Экспортировать таблицу участников
   */
  private exportParticipants(): void {
    this.loadingExport = true;
    const exportParams = ExportsHelper.getExportParams(properties, this.settingsSelect.value);
    const requestParams = {
      ...this.createRequest(),
      ...exportParams,
    } as ParticipantListRequest & ExportParams;

    this.$participantApi.exportParticipants(
      requestParams,
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    });
  }

  /**
   * Удалить участника
   * @param id
   * @private
   */
  private deleteParticipant(id: number): void {
    this.$participantApi.delete(id).then((response) => {
      if (response.success) {
        this.hideDeleteConfirmDialog();
        this.getParticipants();
      }
    }).catch((error) => {
      window.console.log(error);
    });
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик клика поиска в фильтрах
   */
  private clickSearchFilterHandler(): void {
    this.currentPage = 1;
    this.getParticipants();
  }

  /**
   * Обработчик клика кнопки сбросить
   * @private
   */
  private clickResetFilterButtonHandler(): void {
    this.resetFilters();
    this.currentPage = 1;
    this.getParticipants();
  }

  /**
   * обработчик клика удаления
   * @param id
   */
  private clickDeleteIconHandler(id: number): void {
    this.isDeleteConfirmDialogVisible = true;
    this.currentDeleteId = id;
  }

  /**
   * Скрыть диалог подтверждения
   */
  private hideDeleteConfirmDialog(): void {
    this.currentDeleteId = null;
    this.isDeleteConfirmDialogVisible = false;
  }

  /**
   * Обработчик изменения колонок таблицы
   */
  private changeSettingsHandler(data: string[]): void {
    this.settingsSelect.value = data;

    TablePropertyService.setSettings((this.settingsSelect.name as SettingsName), data);
  }

  /**
   * Загрузка фильтров
   * @private
   */
  private loadedFilterHandler(): void {
    this.loadingFilters = false;
    this.$nextTick(() => {
      this.getParticipants(true);
    });
  }

  /**
   * Клик по кнопке экспорта
   * @private
   */
  private clickExportHandler(): void {
    this.exportParticipants();
  }

  /**
   * Клик на кнопку выбрать все в настройках столбцев
   * @private
   */
  private clickSelectAllTableColumnsHandler(): void {
    this.$nextTick(() => {
      if (this.selectedAllTableColumns) {
        this.settingsSelect.value = this.defaultOptionsSelected;
      } else {
        this.settingsSelect.value = this.settingsSelect.options ? this.settingsSelect
          .options.map((option) => option.value.toString()) : this.defaultOptionsSelected;
      }

      TablePropertyService
        .setSettings((this.settingsSelect.name as SettingsName), this.settingsSelect.value);
    });
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Устанавливаем в стор, номер текущей страницы
   * @private
   */
  setStoreSettingsPage():void {
    this.$store.dispatch('setListPageSetting', {
      pageTitle: CurrentPageListNames.PARTICIPANT,
      currentPage: this.currentPage,
      sort: {
        sortDesc: this.sortDesc,
        sortBy: this.sortBy,
      },
    });
  }
}
