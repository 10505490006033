import Vue from 'vue';
import './api';
import AuthService from '@/services/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMask from 'v-mask';
import NotificationService from '@/services/NotificationService';
import Toast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import * as Sentry from '@sentry/vue';
import Duration from 'dayjs/plugin/duration';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import tools, { Tools } from '@/tools';
import ExportFileServices from '@/services/ExportFileService';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

dayjs.extend(CustomParseFormat);
dayjs.extend(RelativeTime);
dayjs.extend(Duration);

declare module 'vue/types/vue' {
  interface Vue {
    $authService: AuthService,
    $notificationService: NotificationService,
    $date(date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
    $tools: Tools,
    $exportFileService: ExportFileServices,
  }
}

Vue.prototype.$date = dayjs;
Vue.prototype.$authService = new AuthService();
Vue.prototype.$notificationService = new NotificationService();
Vue.prototype.$tools = tools;
Vue.prototype.$exportFileService = new ExportFileServices();

Vue.use(VueMask, {
  placeholders: {
    Y: /[а-яА-Я]/,
  },
});
Vue.use(Toast, {
  position: 'top',
});

// Подключаем только если собирается не в dev моде
if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
