import tools from '@/tools';
import {
  UsersListResponse,
  UserSaveRequest,
  SuccessResponse,
  UserInfo,
  UserUpdateRequest,
  UsersListRequest, ExportParams, UsersFullListResponse, UsersExportListResponse,
} from '@/types';

/**
 * Класс для работы со списками пользователей
 */
export default class Users {
  public getUsers(
    requestParams: UsersListRequest,
  ): Promise<UsersListResponse> {
    return tools.apiRequest(
      'get',
      'user/list',
      requestParams,
    ) as Promise<UsersListResponse>;
  }

  /**
   * Создать юзера
   * @param requestParams
   */
  public createUser(
    requestParams: UserSaveRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'user/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Обновить юзера
   * @param requestParams
   */
  public updateUser(
    requestParams: UserUpdateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'user/update',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Загрузить данные о юзере
   * @param requestParams
   */
  public getUser(
    requestParams: {id: string},
  ): Promise<UserInfo> {
    return tools.apiRequest(
      'get',
      `user/info/${requestParams.id}`,
    ) as Promise<UserInfo>;
  }

  /**
   * Удалить пользователя
   * @param requestParams
   */
  public deleteUser(
    requestParams: {id: number},
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'user/delete',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Выполняем запрос на экспорт списка пользователей
   * @param requestParams
   */
  public exportUsers(
    requestParams: UsersListRequest & ExportParams,
  ): Promise<UsersExportListResponse> {
    return tools.apiRequest(
      'get',
      'user/export',
      requestParams,
      false,
      {},
    ) as Promise<UsersExportListResponse>;
  }

  /**
   * Получить всех пользователей, метод без прав доступа
   */
  public getAllUsers(): Promise<UsersFullListResponse> {
    return tools.apiRequest(
      'get',
      'user/full-list',
    ) as Promise<UsersFullListResponse>;
  }
}
