







































































import Vue from 'vue';
import Component from 'vue-class-component';
import { DataTableItem } from '@/types';
import { DataTableHeader } from 'vuetify';
import { Model } from 'vue-property-decorator';

@Component({
  props: {
    entryItems: {
      type: Array,
      required: true,
    },
    entryHeaders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    handleRowClick: {
      type: Function,
      default: null,
    },
    handleDeletion: {
      type: Function,
      default: null,
    },
    handleSettingsClick: {
      type: Function,
      default: null,
    },
    handleRepetitionsClick: {
      type: Function,
      default: null,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: true,
    },
    hasPencil: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: '',
    },
    entrySortBy: {
      type: Array,
      default: () => [],
    },
    entrySortDesc: {
      type: Array,
      default: () => [],
    },
  },
})

/**
 * Компонент обёртка над таблицей vuetify
 */
export default class DataTableWrapper extends Vue {
  @Model('change', { type: Array, default: () => [] })

  private selected!: DataTableItem[];

  private entrySortBy!: string[];

  private entrySortDesc!: boolean[];

  private itemsPerPage = 20;

  private currentPage = 1;

  private sortBy: string[] = [];

  private sortDesc: boolean[] = [];

  private handleRowClick!: (id: string) => void;

  private handleDeletion!: (id: string) => void;

  private handleSettingsClick!: (id: number) => void;

  private handleRepetitionsClick!: (id: number) => void;

  private entryHeaders!: DataTableHeader[];

  private entryItems!: DataTableItem[];

  private hasAction!: boolean;

  private defaultActionHeader: DataTableHeader = {
    text: '',
    value: 'actions',
    align: 'end',
    sortable: false,
    width: '10%',
  }

  private emitDebounce: number | undefined;

  mounted(): void {
    this.sortBy = this.entrySortBy;
    this.sortDesc = this.entrySortDesc;
  }

  /**
   * Получить заголовки
   */
  private get headers(): DataTableHeader[] {
    let { entryHeaders } = this;

    entryHeaders = [...entryHeaders];

    if (this.hasAction) {
      entryHeaders.push(this.defaultActionHeader);
    }
    return [
      ...entryHeaders,
    ];
  }

  /**
   * получить элементы таблицы
   */
  private get items(): DataTableItem[] {
    return this.entryItems;
  }

  /**
   * Есть ли встроенная пагинация
   */
  private get hasPagination(): boolean {
    return this.entryItems.length > this.itemsPerPage;
  }

  /**
   * Получить количество страниц встроенной пагинации
   */
  private get pageCount(): number {
    return Math.ceil(this.entryItems.length / this.itemsPerPage);
  }

  /**
   * Обработчик клика по строке таблицы
   */
  private clickRowHandler(item: Record<string, string>): void {
    if (!this.handleRowClick || !item?.id) {
      return;
    }

    this.handleRowClick(item.id.toString());
  }

  /**
   * Обработчик клика по иконке удаления
   */
  private clickDeleteHandler(id: string): void {
    if (!this.handleDeletion) {
      return;
    }

    this.handleDeletion(id);
  }

  /**
   * Клик по иконке редактирования
   */
  private clickSettingsHandler(id: string): void {
    if (!this.handleSettingsClick) {
      return;
    }

    this.handleSettingsClick(+id);
  }

  /**
   * Клик по иконке повторений
   */
  private clickRepetitionsHandler(id: string): void {
    if (!this.handleRepetitionsClick) {
      return;
    }

    this.handleRepetitionsClick(+id);
  }

  /**
   * Вызвать событие при обновлении сортировки
   */
  private emitSortChange(): void {
    clearTimeout(this.emitDebounce);
    this.emitDebounce = window.setTimeout(() => {
      this.$emit('sort-change', {
        sortDesc: this.sortDesc,
        sortBy: this.sortBy,
      });
    }, 50);
  }

  /**
   * Изменилось поле сортировки
   */
  private changeSortByHandler(sortBy: string[]): void {
    this.sortBy = sortBy;
    this.emitSortChange();
  }

  /**
   * Изменилось направление сортировки
   */
  private changeSortDescHandler(sortDesc: boolean[]): void {
    this.sortDesc = sortDesc;
    this.emitSortChange();
  }

  /**
   * Изменились выбранные элементы
   */
  private selectItemHandler(data: DataTableItem[]): void {
    this.$emit('change', data);
  }
}
