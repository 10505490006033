



































































































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {
  FormField,
  FormFieldWithAjaxSearch,
  ParticipantCreateRequest,
  ParticipantInfo,
  RadioButtonOption,
  FormFieldRadioButton,
  FormFieldSelect,
  FormFieldSelectMultiple,
  ParticipantUpdateRequest, DetailMembers, UserInfo,
} from '@/types';
import TextField from '@/components/form-fields/TextField.vue';
import DatePicker from '@/components/form-fields/DatePicker.vue';
import RadioButtonCustom from '@/components/form-fields/RadioButtonCustom.vue';
import SelectWithSearchAddress from '@/components/form-fields/SelectWithSearchAddress.vue';
import { HandbookCode } from '@/enums/HandbookList';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import tools from '@/tools';
import RowText from '@/components/RowText.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';
import ParticipantSection from '@/components/participant/ParticipantSection.vue';
import SelectsList from '@/components/form-fields/SelectsList.vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';
import DetailedInformation from '@/components/DetailedInformation.vue';
import DetailedMembersTransform from '@/services/transform-response/DetailedMembers';

@Component({
  components: {
    FieldLabel,
    FormActions,
    RowText,
    TextField,
    DatePicker,
    RadioButtonCustom,
    SelectWithSearchAddress,
    CustomSelect,
    DataWillNotSaved,
    SelectsList,
    DetailedInformation,
  },
  props: {
    participantInfo: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ParticipantSectionPersonalInformation extends ParticipantSection {
  private user!: UserInfo;

  private participantInfo!: ParticipantInfo|null;

  private registryAddressMatchesResidenceAddress = false;

  private gender: FormFieldRadioButton = {
    label: 'Пол',
    name: 'gender',
    error: '',
    options: [],
    required: true,
    value: null,
  }

  private floor: FormFieldRadioButton = {
    label: 'Этаж',
    error: '',
    name: 'floor',
    options: [],
    value: null,
    required: true,
  }

  private residenceAddress: FormFieldWithAjaxSearch = {
    name: 'address',
    label: 'Город, улица, дом, квартира',
    error: '',
    value: null,
    required: true,
    placeholder: 'Введите адрес в свободной форме',
    readonly: false,
    search: 'Москва',
  }

  private registrationAddress: FormFieldWithAjaxSearch = {
    name: 'registration_address',
    label: 'Город, улица, дом, квартира',
    error: '',
    value: null,
    required: true,
    placeholder: 'Введите адрес в свободной форме',
    readonly: false,
    search: '',
  }

  private lastName: FormField = {
    name: 'last_name',
    label: 'Фамилия',
    placeholder: 'Фамилия',
    error: '',
    value: '',
    disabled: false,
    required: true,
  }

  private name: FormField = {
    name: 'first_name',
    label: 'Имя',
    placeholder: 'Имя',
    error: '',
    value: '',
    disabled: false,
    required: true,
  }

  private secondName: FormField = {
    name: 'second_name',
    label: 'Отчество',
    placeholder: 'Отчество',
    error: '',
    value: '',
    disabled: false,
    required: false,
  }

  private birthDay: FormField = {
    name: 'date_of_birth',
    label: 'Дата рождения',
    error: '',
    value: '',
    disabled: false,
    required: true,
    mask: '##.##.####',
  }

  private deathDay: FormField = {
    name: 'date_of_death',
    label: 'Дата смерти',
    error: '',
    value: null,
    disabled: false,
    required: false,
    mask: '##.##.####',
  }

  private pensionInsuranceNumber: FormField = {
    name: 'personal_account_insurance',
    error: '',
    placeholder: '123-456-788 91',
    value: '',
    label: 'СНИЛС',
    required: true,
    mask: '###-###-### ##',
  }

  private medicalInsuranceNumber: FormField = {
    name: 'medical_insurance',
    error: '',
    placeholder: '1234-5678-9123-4567',
    value: '',
    label: 'ОМС',
    required: false,
    mask: '####-####-####-####',
  }

  private telephone: FormField = {
    name: 'phone',
    error: '',
    placeholder: '7 123 456-78-91',
    value: '',
    label: 'Контактный телефон',
    required: true,
    mask: '7-###-###-##-##',
  }

  private homeTelephone: FormField = {
    name: 'home_phone',
    error: '',
    placeholder: '7 123 456-78-91',
    value: '',
    label: 'Домашний телефон',
    required: false,
    mask: '7-###-###-##-##',
  }

  private email: FormField = {
    name: 'email',
    error: '',
    placeholder: 'Введите e-mail',
    value: '',
    label: 'Адрес эл.почты',
  }

  private maritalStatus: FormFieldRadioButton = {
    name: 'marital_status',
    label: 'Семейное положение',
    error: '',
    required: true,
    options: [],
    value: null,
  }

  private socialStatus: FormFieldSelect = {
    name: 'social_status',
    label: 'Социальный статус',
    required: true,
    error: '',
    value: null,
    options: [],
  }

  private honoraryTitles: FormFieldSelectMultiple = {
    name: 'honorary_titles',
    label: 'Почетные звания',
    error: '',
    value: [],
    options: [],
  }

  private education: FormFieldSelect = {
    name: 'education',
    label: 'Образование',
    required: false,
    error: '',
    value: null,
    options: [],
  }

  private organizationId: FormFieldSelect = {
    value: null,
    name: 'organisation_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Организация',
    options: [],
    required: true,
  };

  private renderFirstGroupFields = [
    this.lastName,
    this.name,
    this.secondName,
  ];

  private renderSecondGroupFields = [
    this.pensionInsuranceNumber,
    this.medicalInsuranceNumber,
  ];

  private renderThirdGroupFields = [
    this.telephone,
    this.homeTelephone,
    this.email,
  ]

  public allFields = [
    ...this.renderFirstGroupFields,
    ...this.renderSecondGroupFields,
    ...this.renderThirdGroupFields,
    this.gender,
    this.floor,
    this.registrationAddress,
    this.residenceAddress,
    this.birthDay,
    this.deathDay,
    this.socialStatus,
    this.education,
    this.honoraryTitles,
    this.maritalStatus,
    this.organizationId,
  ]

  created(): void {
    if (this.participantInfo) {
      this.fillFields();
    }

    if (this.participantIsActive) {
      this.statusBlock = 'readonly';
    }
  }

  mounted(): void {
    this.createFieldsOptions();
    this.getCompanies();
  }

  /**
   * Наблюдать за сменой чекбокса на совпадения адреса регистрации с адресом проживания
   */
  @Watch('registryAddressMatchesResidenceAddress')
  onMatchesAddressCheckBoxChange(val: boolean): void {
    if (val) {
      this.registrationAddress.value = this.residenceAddress.value;
      this.registrationAddress.search = this.residenceAddress.value?.label
        ? this.residenceAddress.value?.label : '';
      this.registrationAddress.readonly = true;
    } else {
      this.registrationAddress.readonly = false;
      this.registrationAddress.value = null;
      this.registrationAddress.search = '';
    }
  }

  /**
   * Наблюдатель за сменой регистрации проживания
   */
  @Watch('residenceAddress.value')
  onResidenceAddressChange(): void {
    if (this.registryAddressMatchesResidenceAddress) {
      const search = this.residenceAddress.value?.label ? this.residenceAddress.value?.label : '';

      this.registrationAddress.value = this.residenceAddress.value;
      this.registrationAddress.search = search;
    }
  }

  /**
   * Получить заголовок секции
   */
  private get sectionTitle(): string {
    return this.participantIsActive
      ? '1. Персональные данные физического лица'
      : 'Шаг 2. Персональные данные физического лица';
  }

  private get detailedData(): DetailMembers.Data|null {
    if (!this.participantInfo) {
      return null;
    }

    return DetailedMembersTransform.getDataFromDetailsView(
      this.participantInfo,
      this.handBooksData,
      'info',
    );
  }

  /**
   * Заполнить поля
   */
  private fillFields(): void {
    if (!this.participantInfo) {
      return;
    }

    const {
      first_name: firstName, last_name: lastName, second_name: secondName,
      date_of_birth: dateOfBirth,
      date_of_death: dateOfDeath,
      gender,
      personal_account_insurance: personalAccountInsurance,
      medical_insurance: medicalInsurance, floor, phone, email,
      marital_status: maritalStatus, social_status: socialStatus,
      education, honorary_titles: honoraryTitles, address,
      registration_address: registrationAddress,
    } = this.participantInfo.info;

    const addressLabel = tools.getLabelFromAddressDetailType(address);

    const addressRegistrationLabel = tools.getLabelFromAddressDetailType(registrationAddress);

    this.name.value = firstName;
    this.lastName.value = lastName;
    this.secondName.value = secondName;
    this.birthDay.value = dateOfBirth;
    this.deathDay.value = dateOfDeath;
    this.gender.value = gender;
    this.pensionInsuranceNumber.value = personalAccountInsurance;
    this.medicalInsuranceNumber.value = medicalInsurance;
    this.residenceAddress.search = addressLabel;
    this.residenceAddress.value = {
      value: address,
      label: addressLabel,
    };
    this.registrationAddress.search = addressRegistrationLabel;
    this.registrationAddress.value = {
      value: registrationAddress,
      label: addressRegistrationLabel,
    };

    this.floor.value = floor;
    this.telephone.value = phone;
    this.email.value = email;
    this.maritalStatus.value = maritalStatus;
    this.socialStatus.value = socialStatus;
    this.education.value = education || null;
    this.honoraryTitles.value = honoraryTitles;

    this.organizationId.value = this.participantInfo?.organisation?.id || null;
  }

  /**
   * создать запрос
   */
  private createRequest(): ParticipantCreateRequest {
    return {
      first_name: this.name.value,
      last_name: this.lastName.value,
      second_name: this.secondName.value,
      date_of_birth: this.birthDay.value,
      date_of_death: this.deathDay.value,
      gender: (this.gender.value as number),
      personal_account_insurance: this.pensionInsuranceNumber.value,
      medical_insurance: this.medicalInsuranceNumber.value,
      address: this.residenceAddress.value ? this.residenceAddress.value.value : null,
      registration_address: this.registrationAddress.value
        ? this.registrationAddress.value.value : null,
      floor: (this.floor.value as number),
      phone: this.telephone.value,
      email: this.email.value,
      marital_status: (this.maritalStatus.value as number),
      social_status: this.socialStatus.value ? +this.socialStatus.value : null,
      education: this.education.value ? +this.education.value : null,
      honorary_titles: this.honoraryTitles.value ? (this.honoraryTitles.value as number[]) : null,
      organisation_id: this.organizationId.value ? +this.organizationId.value : null,
    };
  }

  /**
   * Создать запрос обновленния данных
   */
  private createUpdateParticipantRequest(): ParticipantUpdateRequest {
    return {
      ...this.createRequest(),
      id: this.participantId.toString(),
    };
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.maritalStatus.options = (
      this.createOptions(HandbookCode.MARITAL_STATUS) as RadioButtonOption[]);
    this.socialStatus.options = this.createOptions(HandbookCode.SOCIAL_STATUS);
    this.education.options = this.createOptions(HandbookCode.EDUCATION);
    this.honoraryTitles.options = this.createOptions(HandbookCode.HONORARY_TITLES);
    this.floor.options = (this.createOptions(HandbookCode.FLOOR) as RadioButtonOption[]);
    this.gender.options = (this.createOptions(HandbookCode.GENDER) as RadioButtonOption[]);
  }

  /**
   * Заполнить значения организации
   * @private
   */
  private fillOrganizationValue(): void {
    if (this.user.organisations.length !== 1 || typeof this.user.organisations[0] === 'undefined') {
      return;
    }

    const organizationId = this.user.organisations[0].id;

    this.organizationId.value = organizationId;
  }

  /**
   * Получить компании
   */
  private getCompanies(): void {
    this.$organizationApi.getShortList().then((response) => {
      this.organizationId.options = response.map((item) => ({
        value: item.id,
        text: item.name,
        label: item.name,
      }));
      if (!this.participantId) {
        this.fillOrganizationValue();
      }
    }).catch((error) => {
      window.console.log(error);
    });
  }

  /**
   * Обновить участника
   */
  private updateParticipant(): Promise<void> {
    return new Promise((resolve) => {
      this.resetError();
      this.$participantApi.update(this.createUpdateParticipantRequest())
        .then(() => {
          resolve();
          this.$emit('update-participant');
          if (!this.participantIsActive) {
            this.showSuccess();
            return;
          }

          this.$emit('show-save-popup');
        })
        .catch((err) => {
          if (typeof err.message !== 'undefined') {
            this.setError(err.message);
          }
        });
    });
  }

  private showSuccess(): void {
    this.$toast.success('Шаг сохранён!');
  }

  /**
   * Создать участника
   */
  private createParticipant(): void {
    this.resetError();
    this.$participantApi.create(this.createRequest())
      .then((response) => {
        this.showSuccess();
        this.$router.push(
          {
            name: 'member-detail',
            params:
                    {
                      id: response.id.toString(),
                    },
          },
        );

        this.$emit('create-participant');
      })
      .catch((err) => {
        if (typeof err.message !== 'undefined') {
          this.setError(err.message);
        }
      });
  }

  /**
   * Отправить форму
   */
  private submitForm(): void {
    if (!this.participantId) {
      this.createParticipant();
      return;
    }

    this.updateParticipant();
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (name.includes('address')) {
        const errorsKey = Object.keys(message).filter((key) => {
          const keys = key.split('.');

          if (typeof keys[0] === 'undefined' || !keys.length) {
            return false;
          }

          return keys[0] === name;
        });

        if (typeof errorsKey === 'undefined' || !errorsKey.length) {
          return;
        }

        const errors = errorsKey.map((key) => message[key]);

        currentField.error = errors.length
          ? 'Проверьте формат: индекс, регион, город, дом, кв' : '';
        return;
      }

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });

    this.scrollToError();
  }

  /**
   * Обработчик клика по кнопке сохранить в режиме созданого юзера
   * @private
   */
  private clickSaveButtonHandle(): void {
    this.updateParticipant()
      .then(() => {
        this.showNotSaveDialog = false;
        this.statusBlock = 'readonly';
        this.emitEventEndEdit();
      });
  }

  /**
   * Клик по кнопке принять
   * @private
   */
  private clickApplyButtonHandle(): void {
    this.updateParticipant();
  }

  /**
   * Клик по кнопке отмена
   * @private
   */
  private closeClickHandle(): void {
    this.showNotSaveDialog = true;
  }

  /**
   * Клик по кнопке не сохранять
   * @private
   */
  private clickNotSaveHandle(): void {
    this.showNotSaveDialog = false;
    this.statusBlock = 'readonly';
    this.emitEventEndEdit();
  }
}
