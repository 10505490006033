import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-sm-8"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbsItems}}),_c('div',{staticClass:"mt-4"},[_c('h1',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Список справочников")])]),_c('section',{staticClass:"mt-8"},[_c(VBtn,{staticClass:"mb-8 white--text",attrs:{"color":"light-blue lighten-1","height":"40"},on:{"click":_vm.clickShowFilterButtonHandler}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-filter")]),_c('span',{staticClass:"text-body-1 text-capitalize"},[_vm._v(" Фильтр ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}]},[_c(VDivider,{staticClass:"mb-8"}),_c('DataFilters',{attrs:{"fields":_vm.allFields,"entity-filter":_vm.entityFilters,"has-one-column-for-grid":true},on:{"filter-reset":_vm.clickResetFormButtonHandler,"filter-submit":_vm.clickSubmitHandler,"filters-loaded":_vm.loadedFilterHandler}}),_c(VDivider,{staticClass:"mt-8"})],1)],1),(!_vm.loading)?_c('section',[_c(VRow,{staticClass:"mt-3 mb-8",attrs:{"align":"end","no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[(_vm.handbooksCounts)?_c('span',{staticClass:"grey--text lighten-1"},[_vm._v(" Количество справочников "+_vm._s(_vm.handbooksCounts)+" ")]):_vm._e()]),_c(VSpacer)],1),_c(VDataTable,{staticStyle:{"border":"1px solid #BFC5CF"},attrs:{"hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.handbookData,"item-key":"id","items-per-page":20},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.transformHandbooksCreatedValueDate(item.latest_value_created_at))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{attrs:{"title":"Изменить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){return _vm.$router.push({name: 'handbooksValuesList', params: {
              id: item.id,
            }})}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,1761870856)})],1):[_c('div',{staticClass:"mt-8 d-flex align-center justify-center flex-grow-1"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":true}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }