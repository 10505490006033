
















import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';
import ValidationService from '@/services/ValidationService';

@Component({
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
})

export default class TextField extends Vue {
  private field!: FormField;

  /**
   * Обработчик клика на enter
   * @private
   */
  private clickEnterHandler(): void {
    this.$emit('submit-form');
  }

  private changeField(): void {
    this.$emit('change');
  }

  /**
   * На ввод в поле
   * @private
   */
  private inputHandler(): void {
    this.$nextTick(() => {
      if (this.field.validation && this.field.value) {
        this.field.value = ValidationService
          .validateString(this.field.value, this.field.validation);
      }
    });
    this.$emit('input-field');
  }
}
