










import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    userPermissions: {
      type: Array,
      required: true,
    },
    routePermissions: {
      type: Array,
      required: true,
    },
  },
})

export default class PermissionPolicy extends Vue {
  private userPermissions!: Array<string>;

  private routePermissions!: Array<string>|undefined;

  /**
   * есть ли права доступа для просмотра страницы
   * @private
   */
  private get hasPermission(): boolean {
    const permission = this.routePermissions;

    if (!permission || !permission.length) {
      return true;
    }

    let hasPermission = false;

    permission.forEach((item) => {
      if (hasPermission) {
        return;
      }

      if (this.userPermissions.indexOf(item) === -1) {
        return;
      }

      hasPermission = true;
    });
    return hasPermission;
  }
}
