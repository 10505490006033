import { AxiosError, AxiosResponse } from 'axios';
import {
  AddressDetails,
  HandbookFullDataResponse, HandbookValueItem, Item, MenuItem, SelectOption,
} from '@/types';
import Vue from 'vue';
import { HandbookCode } from '@/enums/HandbookList';
import dayjs from 'dayjs';
import axiosInstance from './axios';

export class Tools extends Vue {
  public apiRequest = (
    method: string,
    url: string,
    params?: unknown,
    needTransformPostData = false,
    headers?: { [key: string]: string },
    responseType?: string,
    isFileDownload = false,
  ): Promise<unknown> => {
    const requestParams: { [key: string]: unknown } = {
      method,
      url,
    };

    if (params) {
      if (method === 'post') {
        requestParams.data = needTransformPostData
          ? this.getFormDataByObject((params as Item)) : params;
      } else {
        requestParams.params = params;
      }
    }

    if (headers) {
      requestParams.headers = headers;
    }

    if (responseType) {
      requestParams.responseType = responseType;
    }

    return new Promise((resolve, reject) => {
      axiosInstance.request(requestParams).then((result: AxiosResponse) => {
        if (typeof result.data.error === 'undefined') {
          resolve(!isFileDownload ? result.data.response : result.data);
        } else {
          reject(result.data.error);
        }
      }).catch((error: AxiosError) => {
        this.$errorApi.processErrors(error);
        reject(error);
      });
    });
  }

  /**
   * Скролит страницу элементу
   */
  public scrollTo(selector: string, offsetRange = 100): void {
    if (!document || !document.querySelector(selector)) {
      return;
    }
    const element = document.querySelector(selector);
    const offsetTop: number = (element as HTMLHtmlElement)
      .getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: offsetTop - offsetRange,
      behavior: 'smooth',
    });
  }

  public formDataApiRequest(
    url: string,
    params: { [key: string]: string | FileList | File[] | File },
  ): Promise<unknown> {
    const requestData = this.getFormDataByObject(params);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return this.apiRequest('post', url, requestData, false, headers);
  }

  /**
   * Получить значения справочника по коду из списка справочников
   * @param code
   * @param items
   */
  public getHandbookValues(
    code: HandbookCode,
    items: HandbookFullDataResponse,
  ): HandbookValueItem[] {
    const handbookItem = items.find((item) => item.code === code);
    if (typeof handbookItem === 'undefined') {
      return [];
    }
    return handbookItem.values;
  }

  /**
   * Трансформировать значения справочника к виду опшинов для селектов или чекбоксов
   * @param items
   */
  public transformHandbookValueToOption(items: HandbookValueItem[]): Array<SelectOption> {
    const currentItems = items;

    return currentItems.map((item: HandbookValueItem) => ({
      value: item.id,
      text: item.name,
      label: item.name,
    }));
  }

  /**
   * Делает FormData из произвольного объекта
   *
   * @param { [key: string]: string | FileList | Object} paramsObject
   * @param {FormData} formData
   * @param {string} previousFormDataKey
   * @return FormData
   * @private
   */
  private getFormDataByObject(
    paramsObject: Item,
    formData: FormData = new FormData(),
    previousFormDataKey = '',
  ): FormData {
    Object.keys(paramsObject).forEach((propName: string) => {
      let field = paramsObject[propName];
      let formDataKey = previousFormDataKey ? `${previousFormDataKey}[${propName}]` : propName;

      if (field) {
        if (field instanceof FileList) {
          field = Array.from(field);
        }

        if (Array.isArray(field)) {
          formDataKey = `${formDataKey}[]`;

          field.forEach((arrayFieldElement) => {
            formData.append(formDataKey, arrayFieldElement);
          });
        } else if (field && typeof field === 'object' && !(field instanceof File)) {
          this.getFormDataByObject(field, formData, formDataKey);
        } else {
          formData.append(formDataKey, field);
        }
      }
    });

    return formData;
  }

  /**
   * Собрать из объекта адресса, лейбл
   * @param address
   */
  public getLabelFromAddressDetailType(address: AddressDetails): string {
    if (!address) {
      return '';
    }
    const {
      postal_code,
      region,
      city,
      flat,
      house,
      street,
      floor,
      block,
    } = address;

    let result = [postal_code, region, city, street, house, block, floor, flat];

    if (city === region) {
      result = [...result.slice(0, 2), ...result.slice(3)];
    }

    return result
      .filter((string) => string).join(' ').trim();
  }

  /**
   * Вывод отладочной информации в консоль
   * @param data
   */
  public log(...data: Array<unknown>): void {
    window.console.log(...data);
  }

  /**
   * Получить размер файла в человеко-читаемом формате
   * @param fileSize
   * @return {string}
   */
  public getFileSizeString(fileSize: number): string {
    const units = ['Б', 'КБ', 'МБ', 'ГБ'];
    const step = 1024;
    let order = 0;
    let resultSize = fileSize;
    while (resultSize > step && step < 4) {
      order += 1;
      resultSize = fileSize / (step ** order);
    }
    return `${resultSize.toFixed(2)} ${units[order]}`;
  }

  /**
   * Получить строку из любого
   * @param value
   */
  public transformUnknownValueOnString(value: unknown): string {
    if (!value) {
      return '-';
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString();
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'object') {
      return Object.values((value as Record<string, unknown>)).join(' ');
    }

    return '';
  }

  /**
   * Получить текст значения справочника
   * @param options
   * @param fieldValue
   */
  public getTitleHandbookValues(
    options: SelectOption[],
    fieldValue: number[]|number|null,
  ): string {
    if (!fieldValue && typeof fieldValue === 'object') {
      return '';
    }

    if (!options || !options.length) {
      return '';
    }

    const searchValue = (
      id: number,
      handbookValues: SelectOption[],
    ): string => (
      handbookValues.find((option) => option.value.toString() === id.toString())?.text?.toString() || '');

    if (!Array.isArray(fieldValue)) {
      return searchValue(fieldValue, options);
    }

    return fieldValue.map((value:number) => searchValue(value, options)).join(', ');
  }

  /**
   * Преобразовать дату для интерфейса
   * @param date
   */
  public transformDateForView(date: string): string {
    const currentDate = dayjs(date);

    if (!currentDate.isValid()) {
      return '-';
    }

    return currentDate.format('DD.MM.YYYY');
  }

  /**
   * Конвертация цвета из хекса в rgb или rgba
   * @param hex
   * @param alpha
   */
  public hexToRGB(hex: string, alpha: string): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  /**
   * Получить заголовок значения справочника
   * @param code
   * @param items
   * @param value
   */
  public getHandbooksValueTitle(
    code: string,
    items: HandbookFullDataResponse,
    value: number,
  ): string {
    const values = this.getHandbookValues((code as HandbookCode), items);

    if (!values || !values.length || !value) {
      return '-';
    }

    return this.getTitleHandbookValues(
      this.transformHandbookValueToOption(values),
      value,
    );
  }

  /**
   * Сгенерировать опции для селекта от 1 до N
   * @param integer
   * @return {{label: string, value: string}[]}
   */
  public createNumericSelectOptions(integer: number): SelectOption[] {
    return [...Array(integer).keys()].map((item) => ({
      label: `${item + 1}`,
      value: `${item + 1}`,
    }));
  }

  /**
   * Фильтрует меню по правам доступа
   * @param items
   * @param permissions
   */
  public static filterMenuItemByPermissions(
    items: Array<MenuItem>,
    permissions: Array<string>,
  ): Array<MenuItem> {
    const result: Array<MenuItem> = [];

    items.forEach((menuItem) => {
      const hasItems = typeof menuItem.items !== 'undefined';

      if (!hasItems && !menuItem.permission.length) {
        result.push(menuItem);
        return;
      }
      if (hasItems) {
        const items = Tools.filterMenuItemByPermissions(
          (menuItem.items as Array<MenuItem>),
          permissions,
        );

        if (!items.length) {
          return;
        }
        result.push({
          ...menuItem,
          items,
        });
        return;
      }

      let hasPermission = false;

      menuItem.permission.forEach((item) => {
        if (hasPermission) {
          return;
        }

        if (permissions.indexOf(item) === -1) {
          return;
        }

        hasPermission = true;
      });

      if (!hasPermission) {
        return;
      }

      result.push(menuItem);
    });
    return result;
  }
}

const tools = new Tools();

export default tools;
