



















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import PermissionEnums from '@/enums/PermissionEnums';
import { Service } from '@/types/service';
import { Route } from 'vue-router';
import MaskService from '@/services/MaskService';
import Loader from '@/components/widgets/Loader.vue';
import {
  BreadcrumbsItem, ErrorResponse,
  FormField, FormFieldSelectMultiple,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  SelectOption, SuccessResponse, UserInfo,
} from '@/types';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import { EventsCode } from '@/enums/EventsCode';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    Loader,
    FormActions,
    Breadcrumbs,
    ConfirmationDialog,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ServiceDetail extends Vue {
  private user!: UserInfo;

  private serviceInfo: Service.Item|null = null;

  private loadingStartConfig = true;

  private loadingService = false;

  private showApplyDialog = false;

  private showSaveDialog = false;

  private showConfirmationPopup = false;

  private hasConfirmChange = false;

  private name: FormField = {
    label: 'Название услуги',
    name: 'name',
    value: null,
    error: '',
  }

  private number: FormField = {
    label: 'Номер',
    name: 'number',
    value: null,
    error: '',
    mask: MaskService.getOnlyNumberAndPoint,
  }

  private code: FormField = {
    label: 'Код',
    name: 'code',
    value: null,
    error: '',
    mask: MaskService.getMaskForServiceCode,
  }

  private polygon: FormFieldSelectMultiple = {
    value: null,
    name: 'polygon_ids',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Полигон',
    options: [],
  };

  private category: FormField = {
    value: null,
    name: 'category_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Раздел',
    options: [],
  }

  private allFields = [
    this.name,
    this.number,
    this.code,
    this.polygon,
    this.category,
  ]

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private hasChange = false;

  private toUrl: Route|null = null;

  /**
   * Хук для обработки ухода со страницы
   * если эта страница редактирования и есть изменения нужно показать диалоговое окно
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.hasChange) {
      next();
    }

    // Если уже показывали попап с подтверждением то не показываем этот попап
    if (this.hasConfirmChange) {
      next();
    }

    this.toUrl = to;
    this.showSaveDialog = true;
  }

  mounted(): void {
    this.addEventListeners();
    this.getHandbookFullData();

    if (this.serviceId) {
      this.getService();
    }
  }

  beforeDestroy(): void {
    this.destroyEventListeners();
  }

  /**
   * Иконка для выбора всех полигонов
   */
  private get polygonsIcon(): string {
    if (this.selectedAllPolygons) return 'mdi-close-box';
    if (this.selectedSomePolygons) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  /**
   * Выбраны все полигоны
   */
  private get selectedAllPolygons(): boolean {
    const hasPolygonValues = !!this.polygon.value;
    const hasOptions = !!this.polygon.options;

    if (!hasPolygonValues || !hasOptions) return false;

    return (this.polygon.value as number[])
      .length === (this.polygon.options as SelectOption[]).length;
  }

  /**
   * Выбрано несколько полигонов
   */
  private get selectedSomePolygons(): boolean {
    const hasPolygonValues = !!this.polygon.value;
    const hasOptions = !!this.polygon.options;

    if (!hasPolygonValues || !hasOptions) return false;

    return (this.polygon.value?.length ?? 0) > 0 && !this.selectedAllPolygons;
  }

  /**
   * Хлебные крошки
   */
  private get breadcrumbsItems(): BreadcrumbsItem[] {
    return [
      {
        text: 'Главная',
        href: '/',
        disabled: false,
      },
      {
        text: 'Список услуг',
        href: '/service',
        disabled: false,
      },
      {
        text: `${this.serviceId ? this.pageTitle : 'Добавление услуги'}`,
        href: '',
        disabled: false,
      },
    ];
  }

  /**
   * id услуги
   */
  private get serviceId(): string {
    return this.$route.params.id;
  }

  /**
   * Может ли управлять услугами
   */
  private get canManageService(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.SERVICE_MANAGE) !== -1;
  }

  /**
   * Заголовок страницы
   */
  private get pageTitle(): string {
    if (!this.serviceId || !this.serviceInfo) {
      return 'Добавление услуги';
    }

    return this.serviceInfo.name;
  }

  /**
   * поля для отрисовки
   */
  private get renderFields(): FormField[] {
    const fields = [this.category, this.number, this.code];

    if (!this.serviceId) {
      return [this.name, ...fields];
    }

    return fields;
  }

  /**
   * Добавления обработчиков событий
   */
  private addEventListeners(): void {
    this.onEventEscape();
  }

  /**
   * Удаления обработчиков событий
   */
  private destroyEventListeners(): void {
    this.removeEventEscape();
  }

  /**
   * Получить услугу
   */
  private getService(): void {
    if (!this.serviceId) {
      return;
    }
    this.loadingService = true;
    this.$serviceApi.getServiceInfo({ id: this.serviceId })
      .then((response: Service.DetailedResponse) => {
        this.serviceInfo = response;
        this.fillFields();
        this.loadingService = false;
      });
  }

  /**
   * Заполнить поля
   */
  private fillFields(): void {
    if (!this.serviceInfo) {
      return;
    }
    this.allFields.forEach((field) => {
      const { name } = field;

      const { serviceInfo } = this;

      Object.keys((serviceInfo as Service.DetailedResponse)).forEach((key) => {
        const newKey: keyof typeof serviceInfo = (key as never);

        if (!serviceInfo || newKey !== name) {
          return;
        }

        field.value = serviceInfo[newKey];
      });
    });
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
        this.createFieldsOptions();
      });
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.polygon.options = this.createOptions(HandbookCode.POLYGON);
    this.category.options = this.createOptions(HandbookCode.SERVICE_CATEGORIES);
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  /**
   * создать запрос
   */
  private createRequest(): Service.CreateRequest {
    const params: Record<string, unknown> = {};

    this.allFields.forEach((field) => {
      params[field.name] = field.value;
    });

    return (params as Service.CreateRequest);
  }

  /**
   * submit
   */
  private submit(): Promise<void> {
    return new Promise((resolve) => {
      this.clearError();

      let request: Promise<SuccessResponse>|null = null;
      request = this.serviceId
        ? this.$serviceApi.update(
          {
            id: this.serviceId,
            params: this.createRequest(),
          },
        )
        : this.$serviceApi.create(this.createRequest());

      request
        .then(() => {
          resolve();
        })
        .catch((err: ErrorResponse) => {
          this.showSaveDialog = false;
          if (typeof err.message === 'undefined') {
            window.console.log(err);
            return;
          }
          this.setError(err.message);
          this.scrollToError();
        });
    });
  }

  /**
   * проскролить страницу к ошибке
   */
  private scrollToError(): void {
    this.$nextTick(() => {
      tools.scrollTo('.has-error');
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private clearError(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.error = '';
    });
  }

  /**
   * Сбросить все фильтры
   */
  private resetFields(): void {
    this.loadingService = true;
    window.setTimeout(() => {
      this.allFields.forEach((field) => {
        const currentField = field;
        currentField.value = '';
      });
      this.loadingService = false;
    }, 500);
  }

  /**
   * Обработчик клика по сохранить
   */
  private clickSaveHandler(): void {
    this.submit()
      .then(() => {
        window.setTimeout(() => {
          this.hasChange = false;
          this.toUrl = null;
          this.$router.push({ name: 'service-list' });
        }, 500);
      });
  }

  /**
   * Обработчик клика по принять
   */
  private clickApplyHandler(): void {
    this.submit()
      .then(() => {
        this.clearError();

        if (!this.serviceId) {
          this.resetFields();
        }
        this.showApplyDialog = true;
      });
  }

  /**
   * Обработчик клика отмены
   */
  private clickCancelHandler(): void {
    this.$router.push({ name: 'service-list' });
  }

  /**
   * Обработчик клика по сохранить в диалоговом окне
   */
  private clickSaveInDialogHandler(): void {
    this.submit()
      .then(() => {
        window.setTimeout(() => {
          this.hasChange = false;

          if (!this.toUrl) {
            return;
          }
          const toUrlName = this.toUrl.name;

          if (toUrlName && typeof toUrlName !== 'undefined') {
            this.$router.push({ name: toUrlName });
          }
        }, 500);
      });
  }

  /**
   * Обработчик клика по не сохранять в диалоговом окне
   * @private
   */
  private clickNotSaveDialogButtonHandler(): void {
    if (!this.toUrl) {
      return;
    }
    this.hasChange = false;
    const toUrlName = this.toUrl.name;

    if (toUrlName && typeof toUrlName !== 'undefined') {
      this.$router.push({ name: toUrlName });
    }
  }

  /**
   * Обработчик изменений
   * @private
   */
  private changeHandler(): void {
    if (this.serviceId) {
      this.hasChange = true;
    }
  }

  /**
   * Обработчик клика по выбрать все полигоны
   * @private
   */
  private clickSelectAllPolygonsHandler(): void {
    this.$nextTick(() => {
      if (this.selectedAllPolygons) {
        this.polygon.value = [];
      } else {
        this.polygon.value = this.polygon.options
          ? this.polygon.options.map((option) => +option.value) : [];
      }
    });
  }

  /**
   * Прослушивание события клика на кнопку Esc
   */
  private onEventEscape():void {
    document.addEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Удаляем прослушивание события клика на кнопку Esc
   */
  private removeEventEscape():void {
    document.removeEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Хэндлер события клика на кнопку Esc
   */
  private eventEscapeHandler(event: KeyboardEvent):void {
    if (event.code === EventsCode.ESCAPE) {
      if (this.hasChange) {
        this.showConfirmationModal();
        return;
      }
      this.goToPageList();
    }
  }

  /**
   * Переход на страницу списка
   */
  private goToPageList(): void {
    if (this.$route.name === 'service-list') {
      return;
    }
    try {
      this.$router.push({ name: 'service-list' });
    } catch (error) {
      window.console.log(error);
    }
  }

  /**
   * Показать окно подтверждения действия
   */
  private showConfirmationModal():void {
    this.showConfirmationPopup = true;
  }

  /**
   * Хэндлер подтверждения
   */
  private confirmPopupHandler():void {
    this.showConfirmationPopup = false;
    this.hasConfirmChange = true;
    this.goToPageList();
  }

  /**
   * Хэндлер отмены
   */
  private cancelPopupHandler():void {
    this.showConfirmationPopup = false;
  }
}
