import { Frequency } from '@/enums/Frequency';
import { SelectOption } from '@/types';

/**
 * Сервис по работе с периодичностью
 */
export default class FrequencyService {
  /**
   * Получить название типа периодичности
   */
  static getRepeatTypeText(type: string|null): string {
    switch (`${type}`) {
      case Frequency.Type.DAILY:
        return 'Ежедневно';
      case Frequency.Type.WEEKLY:
        return 'Еженедельно';
      case Frequency.Type.MONTHLY:
        return 'Ежемесячно';
      case Frequency.Type.YEARLY:
        return 'Ежегодно';
      default:
        return 'Не повторять';
    }
  }

  /**
   * Получить опции с короткими названиями дней недели
   */
  static getShortDaysOptions(): SelectOption[] {
    return [
      'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс',
    ].map((item, index) => ({
      value: `${index + 1}`,
      label: item,
    }));
  }

  /**
   * Получить опции с полными названиями дней недели
   */
  static getDaysOptions(): SelectOption[] {
    return [
      'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье',
    ].map((item, index) => ({
      value: `${index + 1}`,
      label: item,
    }));
  }

  /**
   * Получить опции с названиями месяцев (в родительном падеже)
   */
  static getMonthOptions(): SelectOption[] {
    return [
      'Января',
      'Февраля',
      'Марта',
      'Апреля',
      'Мая',
      'Июня',
      'Июля',
      'Августа',
      'Сентября',
      'Октября',
      'Ноября',
      'Декабря',
    ].map((item, index) => ({
      value: `${index + 1}`,
      label: item,
    }));
  }
}
