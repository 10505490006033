






















import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';

@Component({
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
})

export default class CustomTextArea extends Vue {
  private field!: FormField;

  private onChange(): void {
    this.field.error = '';
    this.$emit('change');
  }
}

