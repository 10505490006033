import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"pt-16",attrs:{"dense":"","justify":"start","justify-sm":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"8","lg":"4"}},[_c('div',{staticClass:"text-h6 text-sm-h3 font-weight-bold mb-8 text-left"},[_vm._v(" Восстановить доступ ")]),_c(VForm,{ref:"form",on:{"submit":_vm.onSubmit}},[_c(VRow,{staticClass:"justify-space-between",attrs:{"dense":""}},[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"mt-4"},[_vm._v("Пароль")])]),_c(VCol,[_c(VTextField,{attrs:{"placeholder":_vm.password.placeholder,"type":"password","outlined":"","name":_vm.password.name,"error":!!_vm.password.error,"error-messages":_vm.password.error},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}})],1)],1),_c(VRow,{staticClass:"justify-space-between",attrs:{"dense":""}},[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"mt-4"},[_vm._v("Повторите пароль")])]),_c(VCol,[_c(VTextField,{attrs:{"placeholder":_vm.passwordConfirmation.placeholder,"type":"password","outlined":"","name":_vm.passwordConfirmation.name,"error":!!_vm.passwordConfirmation.error,"error-messages":_vm.passwordConfirmation.error},model:{value:(_vm.passwordConfirmation.value),callback:function ($$v) {_vm.$set(_vm.passwordConfirmation, "value", $$v)},expression:"passwordConfirmation.value"}})],1)],1),_c(VRow,{staticClass:"pt-8 align-center justify-end",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VBtn,{staticClass:"white--text",attrs:{"disabled":_vm.loading,"block":"","height":"43","color":"light-blue lighten-1"},on:{"click":_vm.onSubmit}},[_vm._v("Восстановить")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }