import { TProperty } from '@/types';

/**
 * Структура данных для колонок таблиц экспорта и таблиц на фронте
 */
const properties: TProperty = {
  id: {
    headerItem: {
      text: 'ID',
      value: 'id',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'id',
      label: 'ID',
    },
  },
  ends_at: {
    headerItem: {
      text: 'Дата завершения',
      value: 'ends_at',
      width: '15%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'ends_at',
      label: 'Дата завершения',
    },
  },
  last_name: {
    headerItem: {
      text: 'Фамилия',
      value: 'participant.last_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'participant.last_name.',
      label: 'Фамилия',
    },
  },
  first_name: {
    headerItem: {
      text: 'Имя',
      value: 'participant.first_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'participant.first_name',
      label: 'Имя',
    },
  },
  second_name: {
    headerItem: {
      text: 'Отчество',
      value: 'participant.second_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'participant.second_name',
      label: 'Отчество',
    },
  },
  category: {
    headerItem: {
      text: 'Категория',
      value: 'category',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'participant.individual_category.category',
      label: 'Категория',
    },
  },
  services_count: {
    headerItem: {
      text: 'Услуги (шт.)',
      value: 'service_count',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'services_count',
      label: 'Услуги (шт.)',
    },
  },
  organisation: {
    headerItem: {
      text: 'Организация',
      value: 'organisation',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'participant.organisation.name',
      label: 'Организация',
    },
  },
  status: {
    headerItem: {
      text: 'Статус',
      value: 'status',
      width: '12%',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'status',
      label: 'Статус',
    },
  },
};

export default properties;
