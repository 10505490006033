

















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Steppers from '@/components/Steppers.vue';
import ParticipantSectionHealthStatus from '@/components/participant/ParticipantSectionHealthStatus.vue';
import ParticipantSectionWayToAdd from '@/components/participant/ParticipantSectionWayToAdd.vue';
import ParticipantSectionPersonalInformation
  from '@/components/participant/ParticipantSectionPersonalInformation.vue';
import ParticipantSectionFamily from '@/components/participant/ParticipantSectionFamily.vue';
import ParticipantSectionIndividualCategory
  from '@/components/participant/ParticipantSectionIndividualCategory.vue';
import {
  BreadcrumbsItem, HandbookFullDataResponse, ParticipantInfo, StepItem, UserInfo,
} from '@/types';
import tools from '@/tools';
import {
  ParticipantFormType,
  getSectionClassByStepNumber,
} from '@/enums/ParticipantFormAdd';
import ParticipantFileImport from '@/components/participant/ParticipantFileImport.vue';
import { StatusCode } from '@/enums/ParticipantStatus';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import SuccessSave from '@/components/modals/SuccessSave.vue';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';
import ParticipantSectionStatus from '@/components/participant/ParticipantSerctionStatus.vue';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import { EventsCode } from '@/enums/EventsCode';

@Component({
  components: {
    DataWillNotSaved,
    SuccessSave,
    Breadcrumbs,
    ParticipantFileImport,
    Steppers,
    ParticipantSectionWayToAdd,
    ParticipantSectionPersonalInformation,
    ParticipantSectionFamily,
    ParticipantSectionHealthStatus,
    ParticipantSectionIndividualCategory,
    ParticipantSectionStatus,
    ConfirmationDialog,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ParticipantDetail extends Vue {
  private user!: UserInfo;

  private showModalSuccessSave = false;

  private formType = ParticipantFormType.MANUAL;

  private participantInfo: ParticipantInfo|null = null;

  private loadingStartConfig = true;

  private loadingParticipantInfo = false;

  private handbookFullData: HandbookFullDataResponse|null = null;

  private familyMemberSkipOrReadyStatus = false;

  private editSection = '';

  private showConfirmationPopup = false;

  private stepsAddingParticipants: StepItem[] = [
    {
      stepNumber: 1,
      text: 'Способ добавления',
    },
    {
      stepNumber: 2,
      text: 'Персональные данные физического лица',
    },
    {
      stepNumber: 3,
      text: 'Члены семьи физического лица',
    },
    {
      stepNumber: 4,
      text: 'Состояние здоровье физического лица',
    },
    {
      stepNumber: 5,
      text: 'Категория физического лица',
    },
  ];

  private stepsEditParticipant: StepItem[] = [
    {
      stepNumber: 1,
      text: 'Персональные данные физического лица',
    },
    {
      stepNumber: 2,
      text: 'Члены семьи физического лица',
    },
    {
      stepNumber: 3,
      text: 'Состояние здоровье физического лица',
    },
    {
      stepNumber: 4,
      text: 'Категория физического лица',
    },
    {
      stepNumber: 5,
      text: 'Программа лечения',
    },
  ];

  created(): void {
    if (this.participantId) {
      this.getParticipantInfo();
    }
    this.getHandbookFullData();
    this.scrollToActiveStep();

    this.$eventBus.$on(this.$eventBus.PARTICIPANT_FORM_TYPE_CHANGE, (type: ParticipantFormType) => {
      this.formType = type;
    });
  }

  mounted(): void {
    this.scrollToActiveStep();
    this.addEventListeners();
  }

  beforeDestroy(): void {
    this.destroyEventListeners();
  }

  /**
   * Активный ли это участник
   */
  private get isActiveParticipant(): boolean {
    if (!this.participantInfo?.status) {
      return false;
    }

    return this.participantInfo.status !== StatusCode.DRAFT;
  }

  /**
   * получить активный шаг
   */
  private get currentStep(): number {
    if (this.isActiveParticipant) {
      return 1;
    }

    if (!this.participantId) {
      return 2;
    }

    if (this.canShowIndividualCategory) {
      return 5;
    }

    if (this.canShowHealthStep) {
      return 4;
    }

    return 3;
  }

  /**
   * Выбрано добавление вручную
   * @return {boolean}
   */
  private get isManual(): boolean {
    return this.formType === 'manual';
  }

  private get actualSteps(): StepItem[] {
    if (this.isActiveParticipant) {
      return this.stepsEditParticipant;
    }
    return this.stepsAddingParticipants;
  }

  /**
   * Получить итемы для хребных крошек
   */
  private get breadCrumbsItem(): BreadcrumbsItem[] {
    const items: BreadcrumbsItem[] = [
      {
        disabled: false,
        href: '/',
        text: 'Главная',
      },
      {
        disabled: false,
        href: '/members',
        text: 'Список участников',
      },
    ];

    if (this.isActiveParticipant) {
      return [
        ...items,
        {
          disabled: true,
          href: '/',
          text: `Участник: ${this.pageTitle}`,
        }];
    }

    return [
      ...items,
      {
        disabled: true,
        href: '/',
        text: 'Добавление участника',
      },
    ];
  }

  /**
   * Получить id участника
   * @private
   */
  private get participantId(): string {
    return this.$route.params.id || '';
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
      });
  }

  /**
   * Получить заголовок страницы
   */
  private get pageTitle(): string {
    if (!this.isActiveParticipant || !this.participantInfo) {
      return 'Добавление участника';
    }

    const { first_name, last_name, second_name } = this.participantInfo.info;

    return `${first_name || ''} ${last_name || ''} ${second_name || ''}`;
  }

  /**
   * Можем ли показывать блок состояния здоровья
   */
  private get isReadyFamilyMemberStep(): boolean {
    if (!this.participantInfo) {
      return false;
    }

    if (this.participantInfo.health_status) {
      return true;
    }

    return this.familyMemberSkipOrReadyStatus;
  }

  /**
   * Можем ли показывать блок состояния здоровья
   */
  private get canShowHealthStep(): boolean {
    if (!this.participantInfo) {
      return false;
    }

    if (this.participantInfo.health_status) {
      return true;
    }

    return this.familyMemberSkipOrReadyStatus;
  }

  /**
   * Можем ли показывать блок категории физ лица
   */
  private get canShowIndividualCategory(): boolean {
    if (!this.participantInfo) {
      return false;
    }
    return !!this.participantInfo.health_status;
  }

  /**
   * получить селектор активного шага
   */
  private get activeStepSelector(): string {
    if (this.canShowIndividualCategory) {
      return '.step-individual-category';
    }

    if (this.familyMemberSkipOrReadyStatus) {
      return '.step-health-status';
    }

    return '.step-family-members';
  }

  /**
   * Добавления обработчиков событий
   */
  private addEventListeners(): void {
    this.destroyEventListeners();

    this.$eventBus.$on(
      this.$eventBus.PARTICIPANT_SECTION_ACTIVATION_EDIT,
      this.activeEditHandler,
    );
    this.$eventBus.$on(
      this.$eventBus.SHOW_ERROR_CANT_GO_TO_EDIT_PARTICIPANT_SECTION,
      this.showErrorCantGoToEditParticipantSectionHandler,
    );

    this.$eventBus.$on(
      this.$eventBus.PARTICIPANT_SECTION_EDIT_END,
      this.endEditSectionHandler,
    );

    this.onEventEscape();
  }

  /**
   * Удаления обработчиков событий
   */
  private destroyEventListeners(): void {
    this.$eventBus.$off(
      this.$eventBus.PARTICIPANT_SECTION_ACTIVATION_EDIT,
    );
    this.$eventBus.$off(
      this.$eventBus.SHOW_ERROR_CANT_GO_TO_EDIT_PARTICIPANT_SECTION,
    );

    this.$eventBus.$off(
      this.$eventBus.PARTICIPANT_SECTION_EDIT_END,
    );

    this.removeEventEscape();
  }

  /**
   * Получить класс секции
   */
  private getClassSection(step: number): string {
    return getSectionClassByStepNumber(step);
  }

  /**
   * Проскролить к активному шагу
   */
  private scrollToActiveStep(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        tools.scrollTo(this.activeStepSelector);
      }, 300);
    });
  }

  /**
   * Обработчик создания участника
   */
  private createParticipantHandler(): void {
    this.getParticipantInfo();
  }

  /**
   * Обработчик обновления информации о участнике
   */
  private updateParticipantHandler(): void {
    this.getParticipantInfo(this.isActiveParticipant);
  }

  /**
   * Получить данные об участнике
   * @private
   */
  private getParticipantInfo(disabledGlobalLoader = false): void {
    if (!disabledGlobalLoader) {
      this.loadingParticipantInfo = true;
    }
    this.$participantApi.getParticipantInfo({ id: this.participantId })
      .then((response: ParticipantInfo) => {
        this.loadingParticipantInfo = false;
        this.participantInfo = response;

        if (!this.isActiveParticipant) {
          this.scrollToActiveStep();
        }
      });
  }

  /**
   * Изменения членов семьи
   */
  private changeFamilyMembers(): void {
    this.getParticipantInfo(true);
  }

  /**
   * Обработчик готовности шага 3
   */
  private proceedFamilyMembersHandler(): void {
    this.familyMemberSkipOrReadyStatus = true;
    this.scrollToActiveStep();
  }

  /**
   * Обработчик готовности шага 4
   * @private
   */
  private readyHealthStepHandler(): void {
    this.getParticipantInfo(true);
  }

  /**
   * Готовность последнего шага
   */
  private readyLastStep(): void {
    this.getParticipantInfo();
  }

  /**
   * Обработчик изменения статуса участника
   * @private
   */
  private changeParticipantStatusHandler(): void {
    this.getParticipantInfo(true);
  }

  /**
   * Обработчик клика по шагу в степпере
   * @param step
   * @private
   */
  private clickStepStepperHandler(step: number): void {
    if (step > this.currentStep && !this.isActiveParticipant) {
      this.$toast.error('Необходимо заполнить предыдущие шаги');
      return;
    }

    this.$nextTick(() => {
      const currentStep = this.isActiveParticipant ? step + 1 : step;

      tools.scrollTo(`.${this.getClassSection(currentStep)}`);
    });
  }

  /**
   * Обработчик ошибки невозможности редактирования секции
   */
  private showErrorCantGoToEditParticipantSectionHandler(): void {
    if (!this.editSection) {
      return;
    }
    this.$toast.error('Завершите работу с редактируемым блоком.');
    this.$nextTick(() => {
      tools.scrollTo(`.${this.editSection}`);
    });
  }

  /**
   * Обработчик активации блока в состояние редактирования
   * @param sectionName
   * @private
   */
  private activeEditHandler(sectionName: string): void {
    this.editSection = sectionName;
  }

  /**
   * Обработчик конца редактирования секции
   */
  private endEditSectionHandler(): void {
    this.editSection = '';
  }

  /**
   * Прослушивание события клика на кнопку Esc
   */
  private onEventEscape():void {
    document.addEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Удаляем прослушивание события клика на кнопку Esc
   */
  private removeEventEscape():void {
    document.removeEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Хэндлер события клика на кнопку Esc
   */
  private eventEscapeHandler(event: KeyboardEvent):void {
    if (event.code === EventsCode.ESCAPE) {
      if (this.editSection) {
        this.showConfirmationModal();
        return;
      }
      this.goToPageList();
    }
  }

  /**
   * Переход на страницу списка
   */
  private goToPageList(): void {
    if (this.$route.name === 'member-list') {
      return;
    }
    try {
      this.$router.push({ name: 'member-list' });
    } catch (error) {
      window.console.log(error);
    }
  }

  /**
   * Показать окно подтверждения действия
   */
  private showConfirmationModal():void {
    this.showConfirmationPopup = true;
  }

  /**
   * Хэндлер подтверждения
   */
  private confirmPopupHandler():void {
    this.showConfirmationPopup = false;
    this.goToPageList();
  }

  /**
   * Хэндлер отмены
   */
  private cancelPopupHandler():void {
    this.showConfirmationPopup = false;
  }
}

