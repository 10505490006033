





























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Login from '@/views/Login.vue';
import { MenuItem, UserInfo } from '@/types';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import VersionsBar from '@/components/widgets/VersionsBar.vue';
import ErrorDialog from '@/components/modals/ErrorDialog.vue';
import PermissionEnums from '@/enums/PermissionEnums';
import { Tools } from '@/tools';
import PermissionPolicy from '@/components/PermissionPolicy.vue';
import dayjs from 'dayjs';
import { Help } from '@/types/help';
import { AxiosError } from 'axios';

@Component({
  components: {
    PermissionPolicy,
    ErrorDialog,
    VersionsBar,
    Login,
  },
})

export default class App extends Vue {
  private showErrorDialog = false;

  private errorDialogText = '';

  private miniDrawer = false;

  private user: UserInfo | null = null;

  private drawer = false;

  private loading = true;

  private timeoutId: number|null = null;

  private entryMenuItems: Array<MenuItem> = [
    {
      name: 'Аналитика',
      icon: 'mdi-desktop-mac-dashboard',
      link: 'dashboard',
      permission: [PermissionEnums.STATISTIC_VIEW],
    },
    {
      name: 'Участники',
      icon: 'mdi-account-supervisor',
      link: 'member-list',
      permission: [PermissionEnums.PARTICIPANT_MANAGEMENT],
    },
    {
      name: 'Организации',
      icon: 'mdi-domain',
      link: 'companies',
      permission: [PermissionEnums.ORGANISATION_MANAGEMENT],
    },
    {
      name: 'Услуги',
      icon: 'mdi-book-variant-multiple',
      link: 'service-list',
      permission: [PermissionEnums.SERVICE_VIEW, PermissionEnums.SERVICE_MANAGE],
    },
    {
      name: 'Лечение',
      icon: 'mdi-bookshelf',
      permission: [],
      items: [
        {
          name: 'Программы',
          link: 'program-list',
          permission: [PermissionEnums.PROGRAM_VIEW, PermissionEnums.PROGRAM_MANAGE],
        },
        {
          name: 'Шаблоны',
          link: 'program-templates',
          permission: [PermissionEnums.PROGRAM_TEMPLATE_VIEW,
            PermissionEnums.PROGRAM_TEMPLATE_MANAGE],
        },
      ],
    },
    {
      name: 'Управление',
      icon: 'mdi-cog',
      permission: [],
      items: [
        {
          name: 'Справочники',
          link: 'handbooks',
          permission: [PermissionEnums.HANDBOOK_VALUE_MANAGEMENT],
        },
        {
          name: 'Пользователи',
          link: 'users-list',
          permission: [PermissionEnums.USER_VIEW, PermissionEnums.USER_MANAGEMENT],
        },
        {
          name: 'Роли',
          link: 'role-list',
          permission: [PermissionEnums.ROLE_MANAGEMENT],
        },
      ],
    },
    {
      name: 'Помощь',
      icon: 'mdi-help-circle',
      permission: [],
      items: [
        {
          name: 'Управление данными',
          link: 'help-control',
          permission: [PermissionEnums.HELP_MANAGE],
        },
      ],
    },
  ];

  @Watch('$route', { immediate: true })
  private onRouteChange(to: Route) {
    document.title = `АИС "Ветеран" | ${to.meta?.title}`;
  }

  created(): void {
    this.checkUser();
    this.addEventListeners();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  private get menuItems(): Array<MenuItem> {
    if (!this.user) {
      return [];
    }

    return Tools.filterMenuItemByPermissions(this.entryMenuItems, this.user.permissions);
  }

  /**
   * Права для роута
   */
  private get routePermission(): Array<string> {
    const permission = this.$route.meta?.permission;

    return (permission as Array<string>) || [];
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.EVENT_AUTH_STATUS_CHANGED, this.checkUser);
    this.$eventBus.$on(this.$errorApi.EVENT_SHOW_SERVER_ERROR, this.eventServerErrorHandler);
    this.$eventBus
      .$on(this.$errorApi.EVENT_SHOW_UNAUTHORIZED_ERROR, this.eventUnauthorizedHandler);

    this.$eventBus.$on(this.$errorApi.EVENT_SHOW_FORBIDDEN_ERROR, this.eventForbiddenHandler);

    this.$eventBus.$on(this.$eventBus.EVENT_USER_INFO_CHANGE, this.updateUserHandler);

    this.$eventBus.$on(this.$eventBus.SHOW_GLOBAL_ERROR_MODAL, this.showModalErrorHandler);
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING, this.downloadFile);
  }

  /**
   * Обработчик ошибок нехватки прав
   * @param message
   * @private
   */
  private eventForbiddenHandler(message: string): void {
    this.$toast.error(message);
  }

  /**
   * Обработчик ошибок неавторизированного пользователя
   * @param message
   * @private
   */
  private eventUnauthorizedHandler(message: string): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    this.timeoutId = window.setTimeout(() => {
      this.$toast.error(message);
      this.$authService.logout();
    }, 400);
  }

  /**
   * Обработчик серверных ошибок
   * @param message
   * @private
   */
  private eventServerErrorHandler(message: string): void {
    this.$toast.error(message);
  }

  /**
   * проверить наличия юзера
   * @private
   */
  private checkUser(): void {
    this.$authService.getUser().then((user) => {
      this.user = user;
      this.getMenuItems();
    }).catch(() => {
      this.user = null;

      if (this.$route.name === 'login') {
        return;
      }
      if (this.$route.name === 'restore-password') {
        return;
      }
      this.$router.push({ name: 'login' });
    }).finally(() => {
      this.loading = false;
    });
  }

  /**
   * Обработчик события обновления пользователя
   * @private
   */
  private updateUserHandler(): void {
    this.$authService.updateUserInfo()
      .then(() => {
        this.checkUser();
      });
  }

  /**
   * Обработчик события показа модального окна ошибки
   * @param message
   * @private
   */
  private showModalErrorHandler(message: string): void {
    this.showErrorDialog = true;
    this.errorDialogText = message;
  }

  /**
   * Закрыть модалку ошибки
   * @private
   */
  private closeModalErrorHandler(): void {
    this.showErrorDialog = false;
    this.errorDialogText = '';
  }

  /**
   * Скачиваем файл
   * @private
   */
  // eslint-disable-next-line no-undef
  private downloadFile(blobParts: BlobPart, extension: string):void {
    const blob = new Blob([blobParts], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${dayjs().format('YYYY-MM-DD-HH-mm-ss')}-export.${extension}`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.EVENT_AUTH_STATUS_CHANGED, this.checkUser);
    this.$eventBus
      .$off(this.$errorApi.EVENT_SHOW_SERVER_ERROR, this.eventServerErrorHandler);
    this.$eventBus
      .$off(this.$errorApi.EVENT_SHOW_UNAUTHORIZED_ERROR, this.eventUnauthorizedHandler);
    this.$eventBus.$off(this.$errorApi.EVENT_SHOW_FORBIDDEN_ERROR, this.eventForbiddenHandler);
    this.$eventBus.$off(this.$eventBus.EVENT_USER_INFO_CHANGE, this.updateUserHandler);
    this.$eventBus.$off(this.$eventBus.SHOW_GLOBAL_ERROR_MODAL, this.showModalErrorHandler);
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING, this.downloadFile);
  }

  /**
   * Создать запрос
   */
  private createRequest(): Help.RequestItemsAll {
    return {
      page: 1,
      sort: '',
      title: '',
    };
  }

  /**
   * элемент меню импорт для добавления в коне списка блока инструкций
   */
  private helpTemplateMenuitem = {
    name: 'Импорт',
    link: 'help-import',
    permission: [],
  };

  /**
   * заполняем меню
   */
  private getMenuItems():void {
    this.$helpAPi.getItemsPublished(this.createRequest())
      .then((response: Help.ResponseItemsAll) => {
        this.entryMenuItems.forEach((item) => {
          if (item.name === 'Помощь') {
            response.data.forEach((responseItem) => {
              if (!item || !item.items) {
                return;
              }
              item.items.push({
                name: responseItem.title,
                link: 'help-detail',
                params: {
                  id: responseItem.id,
                },
                permission: [],
              });
            });
            if (item && item.items) {
              item.items.push(this.helpTemplateMenuitem);
            }
          }
        });
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
