import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBreadcrumbs,{staticClass:"pl-0",attrs:{"light":"","items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{attrs:{"exact":"","to":item.href,"disabled":item.disabled}},[_c('span',{class:{
            'grey--text': !item.disabled,
            'black--text': item.disabled
          }},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }