











































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { FormField } from '@/types';

@Component({})
export default class DateField extends Vue {
  @Prop({
    required: true,
  }) private readonly field!: FormField;

  @Prop() private readonly minDate!: string;

  @Prop() private readonly maxDate!: string;

  @Prop({
    default: () => false,
  }) private readonly initialOpened!: boolean;

  @Watch('initialOpened')
  onChangeInitialOpened(value: boolean): void {
    this.isOpen = value;
  }

  private isOpen = false;

  private inputFormat = 'DD.MM.YYYY';

  private outputFormat = 'YYYY-MM-DD';

  private inputValue = this.formattedValue;

  private closeTimeoutId: number|null = null;

  /**
   * Получить отформатированное значение
   */
  private get formattedValue(): string {
    if (!this.field.value) {
      return '';
    }
    return this.$date(this.field.value).format(this.inputFormat);
  }

  /**
   * Сброс ошибок
   */
  private resetError(): void {
    this.field.error = '';
  }

  /**
   * Обработка изменения значения текстового поля
   */
  private onInputChange(value: string): void {
    this.isOpen = false;
    if (!this.inputValue) {
      this.field.value = '';
      this.field.error = '';
      return;
    }
    const date = this.$date(value, this.inputFormat);
    if (!date.isValid()) {
      this.field.error = 'Некорректная дата';
      return;
    }
    this.field.value = date.format(this.outputFormat);
    this.inputValue = this.formattedValue;

    if (this.closeTimeoutId) {
      window.clearTimeout(this.closeTimeoutId);
    }

    this.emitChangeValue();
  }

  /**
   * Обработка изменения значения датапикера
   */
  private onDatePickerChange(): void {
    this.isOpen = false;
    this.inputValue = this.formattedValue;
    this.emitChangeValue();
  }

  /**
   * На фокус поля
   * @private
   */
  private onFocusTextField(): void {
    this.$emit('field-in-focus');
  }

  private onCloseMenu(): void {
    if (this.isOpen) {
      return;
    }
    this.closeTimeoutId = window.setTimeout(() => {
      this.$emit('close-menu');
    }, 0);
  }

  created(): void {
    this.isOpen = this.initialOpened;
  }

  mounted(): void {
    Vue.nextTick(() => {
      this.inputValue = this.formattedValue;
    });
  }

  updated(): void {
    Vue.nextTick(() => {
      this.inputValue = this.formattedValue;
    });
  }

  private emitChangeValue(): void {
    this.$emit('change');
  }
}
