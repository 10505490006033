















































import { FormFieldSelect, FormFieldSelectStringMultiple, SelectOption } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SelectWithSearch extends Vue {
    @Prop({
      required: true,
    }) private readonly field!: FormFieldSelect | FormFieldSelectStringMultiple;

    @Prop({
      required: true,
    }) private readonly options!: SelectOption[];

    @Prop({
      default: () => '48',
    }) private readonly height!: string;

    @Prop({
      default: false,
    }) private readonly hasCheckedAllOptions!: boolean;

    private changeFieldValueHandler(): void {
      this.$emit('change-value');
    }

    private onInput(): void {
      this.$emit('input');
    }

    private checkedAllFieldsValueHandler(): void {
      this.$emit('checked-all', this.selectedAllFieldsValue);
    }

    /**
   * Иконка для выбора всех полей
   */
    private get settingsIcon(): string {
      if (this.selectedAllFieldsValue) return 'mdi-close-box';
      if (this.selectedSomeFieldsValue) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }

    /**
   * Выбраны все поля
   */
    private get selectedAllFieldsValue(): boolean {
      const hasFieldValues = !!this.field.value;
      const hasOptions = !!this.options;

      if (!hasFieldValues || !hasOptions) return false;

      return (this.field.value as string[])
        .length === (this.options as SelectOption[]).length;
    }

    /**
     * Выбрано несколько полей
     */
    private get selectedSomeFieldsValue(): boolean {
      const hasPolygonValues = !!this.field.value;
      const hasOptions = !!this.options;
      if (!hasPolygonValues || !hasOptions) return false;

      return ((this.field.value as string[])?.length ?? 0) > 0 && !this.selectedAllFieldsValue;
    }
}
