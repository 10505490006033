










































































































import Vue from 'vue';
import { BreadcrumbsItem } from '@/types';
import { Help } from '@/types/help';
import Component from 'vue-class-component';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';

@Component({
  components: { ConfirmationDialog, Breadcrumbs },
  props: {},
})
export default class HelpControlList extends Vue {
  private loadingStartConfig = true;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
  ]

  private loading = false;

  private currentPage = 1;

  private sortBy = [];

  private sortDesc = [];

  private helpItems: Help.Item[] | null = null;

  private pagesCount = 0;

  private isDeleteConfirmDialogVisible = false;

  private currentHelpItemId: number | null = null;

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      width: '15%',
      class: 'custom-table-heading',
    },
    {
      text: 'Активность',
      value: 'active',
      width: '15%',
      class: 'custom-table-heading',
    },
    {
      text: 'Сортировка',
      value: 'sort',
      width: '15%',
      class: 'custom-table-heading',
    },
    {
      text: 'Название',
      value: 'title',
      width: '35%',
      class: 'custom-table-heading',
    },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ]

  mounted(): void {
    this.getAllHelpList();
  }

  /**
   * Создать запрос
   */
  private createRequest(): Help.RequestItemsAll {
    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }

    return {
      page: this.currentPage,
      sort,
      title: '',
    };
  }

  /**
   * Поулчаем список статей
   */
  private getAllHelpList(): void {
    this.loading = true;
    this.$helpAPi.getItemsAll(this.createRequest())
      .then((response: Help.ResponseItemsAll) => {
        this.helpItems = response.data;
        this.pagesCount = response.last_page;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * обработчик клика удаления
   * @param id
   */
  private clickDeleteIconHandler(id: number): void {
    this.isDeleteConfirmDialogVisible = true;
    this.currentHelpItemId = id;
  }

  /**
   * Скрыть диалог подтверждения
   */
  private hideDeleteConfirmDialog(): void {
    this.currentHelpItemId = null;
    this.isDeleteConfirmDialogVisible = false;
  }

  /**
   * Удалить элемент
   * @param id
   * @private
   */
  private deleteHelpItem(id: number): void {
    this.$helpAPi.delete(id)
      .then((response) => {
        if (response.success) {
          this.getAllHelpList();
          this.hideDeleteConfirmDialog();
        }
      });
  }

  private getStatusActiveText(status: boolean): string {
    if (status) {
      return 'Да';
    }
    return 'Нет';
  }
}
