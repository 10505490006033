import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import AuthService from '@/services/AuthService';
import Login from '@/views/Login.vue';
import RoleList from '@/views/role/RoleList.vue';
import RoleEdit from '@/views/role/RoleEdit.vue';
import RecoverProfile from '@/views/profile/RecoverProfile.vue';
import RestoreProfile from '@/views/profile/RestoreProfile.vue';
import UsersList from '@/views/users/UsersList.vue';
import UsersDetail from '@/views/users/UsersDetail.vue';
import HandbookList from '@/views/handbook/HandbookList.vue';
import HandbookValuesList from '@/views/handbook/HandbookValuesList.vue';
import HandbookValueDetail from '@/views/handbook/HandbookValueDetail.vue';
import ParticipantList from '@/views/participant/ParticipantList.vue';
import ParticipantDetail from '@/views/participant/ParticipantDetail.vue';
import OrganisationsList from '@/views/companies/OrganisationsList.vue';
import OrganisationDetailed from '@/views/companies/OrganisationDetailed.vue';
import ServiceList from '@/views/service/ServiceList.vue';
import ServiceDetail from '@/views/service/ServiceDetail.vue';
import ProgramTemplateList from '@/views/programm/ProgramTemplateList.vue';
import ProgramTemplateDetail from '@/views/programm/ProgramTemplateDetail.vue';
import ProgramList from '@/views/programm/ProgramList.vue';
import ProgramDetail from '@/views/programm/ProgramDetail.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import PermissionEnums from '@/enums/PermissionEnums';
import HelpImport from '@/views/help/HelpImport.vue';
import Error404 from '@/views/Error404.vue';
import EventLog from '@/views/logs/EventLog.vue';
import HelpControlList from '@/views/help/HelpControlList.vue';
import HelpControlEdit from '@/views/help/HelpControlEdit.vue';
import HelpDetail from '@/views/help/HelpDetail.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'member-list' },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Страница авторизации',
      authRequired: false,
    },
  },
  {
    path: '/roles',
    name: 'role-list',
    component: RoleList,
    meta: {
      title: 'Список ролей',
      authRequired: true,
      permission: [PermissionEnums.ROLE_MANAGEMENT],
    },
  },
  {
    path: '/roles/add',
    name: 'role-add',
    component: RoleEdit,
    meta: {
      title: 'Создание роли',
      authRequired: true,
      permission: [PermissionEnums.ROLE_MANAGEMENT],
    },
  },
  {
    path: '/roles/:id',
    name: 'role-edit',
    component: RoleEdit,
    meta: {
      title: 'Редактирование роли',
      authRequired: true,
    },
  },
  {
    path: '/recover',
    name: 'recover',
    component: RecoverProfile,
    meta: {
      title: 'Восстановление пароля',
      authRequired: false,
    },
  },
  {
    path: '/restore-password/:id/:hash',
    name: 'restore-password',
    component: RestoreProfile,
    meta: {
      title: 'Изменение пароля',
      authRequired: false,
    },
  },
  {
    path: '/users',
    name: 'users-list',
    component: UsersList,
    meta: {
      title: 'Список пользователей',
      authRequired: true,
      permission: [PermissionEnums.USER_VIEW, PermissionEnums.USER_MANAGEMENT],
    },
  },
  {
    path: '/users/add',
    name: 'user-add',
    component: UsersDetail,
    meta: {
      title: 'Создание пользователя',
      authRequired: true,
      permission: [PermissionEnums.USER_VIEW, PermissionEnums.USER_MANAGEMENT],
    },
  },
  {
    path: '/users/:id',
    name: 'user-edit',
    component: UsersDetail,
    meta: {
      title: 'Редактирование пользователя',
      authRequired: true,
      permission: [PermissionEnums.USER_VIEW, PermissionEnums.USER_MANAGEMENT],
    },
  },
  {
    path: '/handbooks',
    name: 'handbooks',
    component: HandbookList,
    meta: {
      title: 'Список справочников',
      authRequired: true,
      permission: [PermissionEnums.HANDBOOK_VALUE_MANAGEMENT],
    },
  },
  {
    path: '/handbooks/:id',
    name: 'handbooksValuesList',
    component: HandbookValuesList,
    meta: {
      title: 'Справочник',
      authRequired: true,
      permission: [PermissionEnums.HANDBOOK_VALUE_MANAGEMENT],
    },
  },
  {
    path: '/handbooks/:id/add',
    name: 'handbooksValueAdd',
    component: HandbookValueDetail,
    meta: {
      title: 'Добавить значение справочника',
      authRequired: true,
      permission: [PermissionEnums.HANDBOOK_VALUE_MANAGEMENT],
    },
  },
  {
    path: '/handbooks/:id/:valueId',
    name: 'handbooksValueEdit',
    component: HandbookValueDetail,
    meta: {
      title: 'Изменить значение справочника',
      authRequired: true,
      permission: [PermissionEnums.HANDBOOK_VALUE_MANAGEMENT],
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: OrganisationsList,
    meta: {
      title: 'Список организаций',
      authRequired: true,
      permission: [PermissionEnums.ORGANISATION_MANAGEMENT],
    },
  },
  {
    path: '/companies/add',
    name: 'companies-add',
    component: OrganisationDetailed,
    meta: {
      title: 'Добавление организации',
      authRequired: true,
      permission: [PermissionEnums.ORGANISATION_MANAGEMENT],
    },
  },
  {
    path: '/companies/:id',
    name: 'companies-edit',
    component: OrganisationDetailed,
    meta: {
      title: 'Изменение организации',
      authRequired: true,
      permission: [PermissionEnums.ORGANISATION_MANAGEMENT],
    },
  },
  {
    path: '/members/add',
    name: 'member-add',
    component: ParticipantDetail,
    meta: {
      title: 'Создание участника',
      authRequired: true,
      permission: [PermissionEnums.PARTICIPANT_MANAGEMENT],
    },
  },
  {
    path: '/members',
    name: 'member-list',
    component: ParticipantList,
    meta: {
      title: 'Список участников',
      authRequired: true,
      permission: [PermissionEnums.PARTICIPANT_MANAGEMENT],
    },
  },
  {
    path: '/members/:id',
    name: 'member-detail',
    component: ParticipantDetail,
    meta: {
      title: 'Создание участника',
      authRequired: true,
      permission: [PermissionEnums.PARTICIPANT_MANAGEMENT],
    },
  },
  {
    path: '/service',
    name: 'service-list',
    component: ServiceList,
    meta: {
      title: 'Список услуг',
      authRequired: true,
      permission: [PermissionEnums.SERVICE_VIEW, PermissionEnums.SERVICE_MANAGE],
    },
  },
  {
    path: '/service/add',
    name: 'service-add',
    component: ServiceDetail,
    meta: {
      title: 'Добавление услуг',
      authRequired: true,
      permission: [PermissionEnums.SERVICE_VIEW, PermissionEnums.SERVICE_MANAGE],
    },
  },
  {
    path: '/service/:id',
    name: 'service-detail',
    component: ServiceDetail,
    meta: {
      title: 'Детальная услуги',
      authRequired: true,
      permission: [PermissionEnums.SERVICE_VIEW],
    },
  },
  {
    path: '/program/templates',
    name: 'program-templates',
    component: ProgramTemplateList,
    meta: {
      title: 'Список шаблонов',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_TEMPLATE_VIEW, PermissionEnums.PROGRAM_TEMPLATE_MANAGE],
    },
  },
  {
    path: '/program/templates/add',
    name: 'program-template-add',
    component: ProgramTemplateDetail,
    meta: {
      title: 'Шаблон программы лечения',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_TEMPLATE_VIEW, PermissionEnums.PROGRAM_TEMPLATE_MANAGE],
    },
  },
  {
    path: '/program/templates/:id',
    name: 'program-template-detail',
    component: ProgramTemplateDetail,
    meta: {
      title: 'Шаблон программы лечения',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_TEMPLATE_VIEW, PermissionEnums.PROGRAM_TEMPLATE_MANAGE],
    },
  },
  {
    path: '/program',
    name: 'program-list',
    component: ProgramList,
    meta: {
      title: 'Список программ',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_VIEW, PermissionEnums.PROGRAM_MANAGE],
    },
  },
  {
    path: '/program/add',
    name: 'program-add',
    component: ProgramDetail,
    meta: {
      title: 'Программа лечения',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_VIEW, PermissionEnums.PROGRAM_MANAGE],
    },
  },
  {
    path: '/program/:id',
    name: 'program-detail',
    component: ProgramDetail,
    meta: {
      title: 'Программа лечения',
      authRequired: true,
      permission: [PermissionEnums.PROGRAM_VIEW, PermissionEnums.PROGRAM_MANAGE],
    },
  },
  {
    path: '/help/import',
    name: 'help-import',
    component: HelpImport,
    meta: {
      title: 'Помощь | Импорт',
      authRequired: true,
    },
  },
  {
    path: '/help/control',
    name: 'help-control',
    component: HelpControlList,
    meta: {
      title: 'Помощь | Управление данными',
      authRequired: true,
      permission: [PermissionEnums.HELP_MANAGE],
    },
  },
  {
    path: '/help/control/create',
    name: 'help-create',
    component: HelpControlEdit,
    meta: {
      title: 'Помощь | Создание статьи',
      authRequired: true,
      permission: [PermissionEnums.HELP_MANAGE],
    },
  },
  {
    path: '/help/control/:id',
    name: 'help-edit',
    component: HelpControlEdit,
    meta: {
      title: 'Помощь | Редактирвоание статьи',
      authRequired: true,
      permission: [PermissionEnums.HELP_MANAGE],
    },
  },
  {
    path: '/help/detail/:id',
    name: 'help-detail',
    component: HelpDetail,
    meta: {
      title: 'Помощь | Редактирвоание статьи',
      authRequired: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Аналитика',
      authRequired: true,
      permission: [PermissionEnums.STATISTIC_VIEW],
    },
  },
  {
    path: '/log/index',
    name: 'log',
    component: EventLog,
    meta: {
      title: 'Журнал событий',
      authRequired: true,
    },
  },
  {
    path: '*', // Должен быть последним роутом
    name: 'error-404',
    component: Error404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next) => {
  if (to.meta?.authRequired && !AuthService.getToken()) {
    next({
      path: '/login',
    });
  }

  if (to.name === 'login' && AuthService.getToken()) {
    next({
      path: '/',
    });
  }

  next();
});

export default router;
