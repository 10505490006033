import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"persistent":true,"max-width":"780"},on:{"click:outside":_vm.clickOutsideHandler}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Услуга: "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('div',{staticClass:"mt-6"},[_c('b',{staticClass:"font-weight-bold text--black"},[_vm._v("№ повторения")]),_c(VDivider,{staticClass:"my-2"}),_vm._l((_vm.repetitions),function(item,index){return _c(VCheckbox,{key:index,attrs:{"label":_vm.$date(item.repeat_at).format('DD.MM.YYYY'),"value":item.id,"hide-details":""},on:{"change":function($event){return _vm.changeCheckboxHandler(item.id)}},model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}})}),_c(VBtn,{staticClass:"mt-6 white--text text-capitalize",attrs:{"height":"40","color":"#10A1DC"},on:{"click":_vm.clickSaveHandler}},[_vm._v(" Сохранить ")])],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }