



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormFieldCheckboxGroup } from '@/types';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

@Component({
  components: { FieldLabel },
  props: {
    field: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    fieldHeight: {
      type: Number,
      default: 48,
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    hasErrorState: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: () => 'start',
    },
  },
})

export default class CustomSelect extends Vue {
  private hasLabel!: boolean;

  private field!: FormFieldCheckboxGroup;

  private tooltipText!: string;

  private multiple!: boolean;

  private fieldHeight!: number;

  private get colsSmField(): number {
    if (!this.hasLabel) {
      return 12;
    }

    if (!this.tooltipText) {
      return 8;
    }
    return 7;
  }

  private onChange(): void {
    this.field.error = '';
    this.$emit('change');
  }
}

