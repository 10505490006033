




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Watch } from 'vue-property-decorator';
import SelectWithSearch from '@/components/form-fields/SelectWithSearch.vue';
import FormFieldService from '@/services/FormFieldService';
import { FormFieldSelect, SelectOption } from '@/types';

@Component({
  components: {
    SelectWithSearch,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    error: {
      default: '',
    },
  },
})

export default class SelectsList extends Vue {
  @Model('change', { type: Array }) private values!: Array<number>

  private error!: string[]|string;

  private allFields: FormFieldSelect[] = [];

  private options!: SelectOption[];

  @Watch('error')
  onChangeError(): void {
    this.allFields = this.allFields.map((field) => ({
      ...field,
      error: this.error,
    }));
  }

  mounted(): void {
    if (!this.values || !this.values.length) {
      this.createNewSelect();
      return;
    }

    this.fillFields();
    this.filterOptions();
  }

  /**
   * Получить ошибки
   */
  private get errorText(): string {
    if (!this.error) {
      return '';
    }

    if (typeof this.error === 'string') {
      return this.error;
    }

    return this.error.join(' ');
  }

  /**
   * Заполнить поля
   */
  private fillFields(): void {
    this.allFields = this.values.map((value) => ({
      ...FormFieldService.createSelectField(value),
      options: this.options,
    }));
  }

  /**
   * Создать новый селект
   */
  private createNewSelect(): void {
    this.allFields.push({
      ...FormFieldService.createSelectField(),
      options: this.options.filter(
        (option: SelectOption) => this.values.indexOf(+option.value) === -1,
      ),
    });
  }

  /**
   * Отфильтровать опции в селектах
   */
  private filterOptions(): void {
    this.allFields = this.allFields.map((field) => {
      const allValues = this.allFields.map((field) => field.value)
        .filter((value) => value !== field.value);

      return {
        ...field,
        options: this.options.filter((option) => allValues.indexOf(option.value) === -1),
      };
    });
  }

  /**
   * Тригерить события изменения значений
   */
  private emitChangeValue(): void {
    const filterFields = this.allFields.filter((field) => typeof field.value === 'number');
    this.$emit('change', filterFields.map((field) => field.value));
    this.filterOptions();
  }

  /**
   * Клик на кнопку добавить
   */
  private clickAddButtonHandler(): void {
    this.createNewSelect();
  }

  /**
   * Обработчик изменения значений в селектах
   * @private
   */
  private changeSelectValueHandler(): void {
    this.emitChangeValue();
  }

  /**
   * Обработчик клика по удалить
   * @param index
   * @private
   */
  private clickDeleteHandler(index: number): void {
    this.allFields = this.allFields.filter((field, indexField) => indexField !== index);
    this.emitChangeValue();
  }
}
