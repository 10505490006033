





























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Model } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import Loader from '@/components/widgets/Loader.vue';
import {
  DataTableItem, FormField, HandbookFullDataItem, SelectOption,
} from '@/types';
import FormFieldTypeEnum from '@/enums/FormField';
import FormRow from '@/components/forms/FormRow.vue';
import TextField from '@/components/forms/TextField.vue';
import CustomSelect from '@/components/forms/CustomSelect.vue';
import { Program } from '@/types/program';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import { Service } from '@/types/service';
import ServiceService from '@/services/ServiceService';
import ValidationEnum from '@/enums/Validation';

@Component({
  components: {
    FormRow,
    Loader,
    DataTableWrapper,
    TextField,
    CustomSelect,
  },
  props: {
    handbookFullData: {
      type: Array,
      required: true,
    },
    serviceUrl: {
      type: String,
      required: true,
    },
  },
})

export default class ServiceAdd extends Vue {
  @Model('change-dialog', { type: Boolean, default: () => false })
  private opened!: boolean;

  private inputTimeoutId: number|null = null;

  private serviceUrl!: string;

  private loadingList = false;

  private handbookFullData!: HandbookFullDataItem[];

  private currentPage = 1;

  private pagesCount = 1;

  private selectedModel: DataTableItem[] = [];

  private selectedServiceModel: Service.Item[] = [];

  private serviceDataResponse: Service.Item[] = [];

  private code: FormField = {
    value: null,
    name: 'code',
    placeholder: 'Введите данные',
    error: '',
    label: 'Код',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.SERVICE_CODE,
  }

  private number: FormField = {
    value: null,
    name: 'number',
    placeholder: 'Введите данные',
    error: '',
    label: 'Номер',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.NUMBER_AND_POINT,
  }

  private name: FormField = {
    value: null,
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Наименование',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private category: FormField = {
    value: null,
    name: 'category_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Раздел',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private allFields = [
    this.name,
    this.code,
    this.number,
    this.category,
  ]

  /**
   * Заголовки таблицы
   * @private
   */
  private get tableHeaders(): DataTableHeader[] {
    return [
      {
        value: 'number',
        text: 'Номер',
        width: '20%',
      },
      {
        value: 'name',
        text: 'Наименование',
        width: '30%',
      },
      {
        value: 'code',
        text: 'Код',
        width: '20%',
      },
      {
        value: 'category',
        text: 'Категория',
        width: '30%',
      },
    ];
  }

  private get tableItems(): Array<DataTableItem> {
    if (!this.serviceDataResponse || !this.handbookFullData) {
      return [];
    }

    const result = this.serviceDataResponse
      .map((service) => (ServiceService.transformServiceItemToTableItem(
        service,
        this.handbookFullData,
      )));

    return result;
  }

  mounted(): void {
    this.createFieldsOptions();
    this.getServiceList();
  }

  /**
   * Создать параметры запроса
   */
  private createRequest(): Program.ServiceListRequest {
    const request: Record<string, unknown> = {};
    this.allFields.forEach((field) => {
      request[field.name] = field.value ?? '';
    });

    return ({
      ...request,
      page: this.currentPage,
    } as Program.ServiceListRequest);
  }

  private getServiceList(): void {
    this.loadingList = true;

    const params = this.createRequest();

    this.$programApi.getServiceList(
      this.serviceUrl,
      params,
    ).then((response) => {
      this.serviceDataResponse = response.data;
      this.pagesCount = response.last_page;
    })
      .finally(() => {
        this.loadingList = false;
      });
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.category.options = this.createOptions(HandbookCode.SERVICE_CATEGORIES);
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  /**
   * Сбросить выбранные услуги
   * @private
   */
  private resetSelected(): void {
    this.selectedModel = [];
  }

  private changeSelectTableHandler(items: DataTableItem[]): void {
    const service = this.selectedServiceModel.filter((item) => {
      const hasServiceInPage = this.serviceDataResponse
        .find((service) => service.id.toString() === item.id.toString());
      return !hasServiceInPage;
    });

    const selectedService = this.serviceDataResponse.filter((service) => {
      const ids = items.map((item) => item.id.toString());
      return ids.indexOf(service.id.toString()) !== -1;
    });
    this.selectedServiceModel = [
      ...service,
      ...selectedService,
    ];
  }

  /**
   * Обработчик клика по закрыть
   * @private
   */
  private clickCloseHandler(): void {
    this.$emit('change-dialog', false);
    this.resetSelected();
  }

  /**
   * Обработчик клика вне зоны окна
   * @private
   */
  private clickOutsideHandler(): void {
    this.$emit('change-dialog', false);
    this.resetSelected();
  }

  /**
   * Обработчик клика по добавить
   * @private
   */
  private clickAddHandler(): void {
    this.$emit('click-add', this.selectedServiceModel);
    this.$emit('change-dialog', false);
    this.resetSelected();
  }

  /**
   * Клик по выбранной услуге
   * @param service
   * @private
   */
  private clickChipHandler(service: Service.Item): void {
    this.selectedServiceModel = this.selectedServiceModel
      .filter((serviceSelected) => serviceSelected.id !== service.id);
    this.selectedModel = this.selectedModel
      .filter((tableItem) => tableItem.id !== service.id.toString());
  }

  /**
   * Событие change на фильтрах
   * @private
   */
  private changeFilters(): void {
    this.currentPage = 1;
    this.getServiceList();
  }

  /**
   * Обработчик смены пагинации
   */
  private changePaginationHandler(): void {
    this.getServiceList();
  }

  /**
   * Обработка ввыода в полях
   * @private
   */
  private inputHandler(): void {
    if (this.inputTimeoutId) {
      window.clearTimeout(this.inputTimeoutId);
    }

    this.inputTimeoutId = window.setTimeout(() => {
      this.getServiceList();
    }, 300);
  }
}
