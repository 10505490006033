import Vue from 'vue';

/**
 * Класс по работе с событиями
 */

export default class EventBus extends Vue {
    public readonly EVENT_USER_INFO_CHANGE = 'event_user_info_change';

    public readonly EVENT_AUTH_STATUS_CHANGED = 'event_auth_status_changed';

    public readonly PARTICIPANT_FORM_TYPE_CHANGE = 'participant_form_type_change';

    public readonly PARTICIPANT_SECTION_ACTIVATION_EDIT = 'participant_section_activation_edit';

    public readonly SHOW_GLOBAL_ERROR_MODAL = 'show_global_error_modal';

    public readonly SHOW_ERROR_CANT_GO_TO_EDIT_PARTICIPANT_SECTION = 'show_error_cant_go_to_edit_participant_section';

    public readonly PARTICIPANT_SECTION_EDIT_END = 'participant_section_edit_end';

    public readonly VERSION_PANEL_CLOSE = 'version_panel_close';

    public readonly VERSION_PANEL_OPEN = 'version_panel_open';

    public readonly VERSION_ITEM_CLICK = 'version_item_click';

    public readonly UPDATE_CHART_DATA = 'update_chart_data';

    public readonly ALREADY_UNLOADING = 'already_unloading';
}
