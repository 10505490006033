export enum HandbookCode {
  MARITAL_STATUS = 'marital_status',
  SOCIAL_STATUS = 'social_status',
  EDUCATION = 'education',
  HONORARY_TITLES = 'honorary_titles',
  FLOOR = 'floor',
  GENDER = 'gender',
  POLYGON = 'polygon',
  RELATION_DEGREE = 'relation_degree',
  INDIVIDUAL_CATEGORY = 'individual_category',
  DISABILITY_GROUP = 'disability_group',
  DISPENSARY_OBSERVATION_GROUP = 'dispensary_observation_group',
  DISABILITY_REASON = 'disability_reason',
  PHARMACOLOGY_GROUPS = 'pharmacological_groups',
  SMOKING = 'smoking',
  ALCOHOL_CONSUMPTION = 'alcohol_consumption',
  PHYSICAL_ACTIVITY = 'physical_activity',
  AUTONOMY = 'autonomy',
  SERVICE_CATEGORIES = 'service_categories',
  PARTICIPANT_ARCHIVE_REASON = 'participant_archive_reason'
}

const AdditionalHandbookNames = {
  autonomy: ['autonomy_group'],
  physical_activity: ['physical_activity_group', 'physical_activity_frequency'],
  alcohol_consumption: ['alcohol_frequency'],
  smoking: ['smoking_frequency'],
  participant_archive_reason: ['archive_reason'],
};

export default class HandbookHelper {
  public static getAllHandBookName(): Array<string> {
    return Object.values(HandbookCode);
  }

  public static getOriginalHandbooksName(name: string): string {
    let originalHandbookName = '';

    Object.keys(AdditionalHandbookNames).forEach((key) => {
      const currentKey: keyof typeof AdditionalHandbookNames = (key as never);
      const values = AdditionalHandbookNames[currentKey];

      if (!values || typeof values === 'undefined') {
        return;
      }

      if ((values as string[]).indexOf(name) === -1) {
        return;
      }
      originalHandbookName = currentKey;
    });

    return originalHandbookName;
  }
}
