












import { Vue, Component, Prop } from 'vue-property-decorator';
import { BaseField } from '@/types';

@Component({})
export default class FieldLabel extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) private field!: BaseField;

  @Prop({
    type: Number,
    required: false,
    default: 40,
  }) private fieldHeight!: number;
}

