import type { UploadFileResponse, UploadFilesResponse } from '@/types';
import tools from '@/tools';

/**
 * Класс по работе с хранилещем
 */
export default class StorageApi {
  /**
   * Загрузить файл
   * @param file
   */
  public uploadFile(
    file: File,
  ): Promise<UploadFileResponse> {
    return tools.apiRequest(
      'post',
      '/storage/upload-one',

      {
        needTransformPostData: true,
        isFileDownload: true,
        responseType: 'json',
        file,
      },
      true,
    ) as Promise<UploadFileResponse>;
  }

  /**
   * Загрузить массив файлы
   * @param files
   */
  public uploadFilesArray(
    files: FileList,
    type: number,
  ): Promise<UploadFilesResponse> {
    return tools.apiRequest(
      'post',
      '/storage/upload-multiple',
      {
        isFile: true,
        params: {
          files,
          type,
        },
      },
    ) as Promise<UploadFilesResponse>;
  }
}
