import tools from '@/tools';
import {
  FamilyMembersRequest,
  ParticipantCreateRequest,
  ParticipantCreateResponse,
  ParticipantInfo,
  SuccessResponse,
  ParticipantListRequest,
  ParticipantListResponse,
  HealthStatusCreateOrUpdateRequest,
  ParticipantIndividualCategoryCreateOrUpdateRequest,
  ParticipantUpdateRequest,
  CalculateIndividualCategoryResponse,
  ParticipantFileImportResponse,
  ExportParams,
  ParticipantArchiveRequest,
  ParticipantArchiveResponse,
  ParticipantUnArchiveRequest,
  ParticipantUnArchiveResponse, ParticipantExportListResponse,
} from '@/types';

/**
 * Класс по работе с участниками
 */
export default class ParticipantApi {
  /**
   * Создать участника
   * @param requestParams
   */
  public create(
    requestParams: ParticipantCreateRequest,
  ): Promise<ParticipantCreateResponse> {
    return tools.apiRequest(
      'post',
      'participant/create',
      requestParams,
    ) as Promise<ParticipantCreateResponse>;
  }

  /**
   * Создать участника
   * @param requestParams
   */
  public update(
    requestParams: ParticipantUpdateRequest,
  ): Promise<ParticipantCreateResponse> {
    return tools.apiRequest(
      'post',
      'participant/update',
      requestParams,
    ) as Promise<ParticipantCreateResponse>;
  }

  /**
   * Удалить участника
   * @param {number} id
   */
  public delete(id: number): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'participant/delete',
      { id },
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получение данных об участнике
   * @param requestParams
   */
  public getParticipantInfo(
    requestParams: {id: string},
  ): Promise<ParticipantInfo> {
    return tools.apiRequest(
      'get',
      `participant/info/${requestParams.id}`,
    ) as Promise<ParticipantInfo>;
  }

  /**
   * Метод создания или обновления членов семьи
   * @param requestParams
   */
  public createOrUpdateFamilyMembers(
    requestParams: FamilyMembersRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `participant/${requestParams.participantId}/family-members/create-or-update`,
      { members: requestParams.members },
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить список участников
   * @param requestParams
   * @return {Promise<ParticipantListResponse>}
   */
  public getList(
    requestParams: ParticipantListRequest,
  ): Promise<ParticipantListResponse> {
    return tools.apiRequest(
      'get',
      'participant/list',
      requestParams,
    ) as Promise<ParticipantListResponse>;
  }

  /**
   * Удалить члена семьи
   * @param requestParams
   */
  public deleteFamilyMember(
    requestParams: {id: string, familyMemberId: number},
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `participant/${requestParams.id}/family-members/delete`,
      { id: requestParams.familyMemberId },
    ) as Promise<SuccessResponse>;
  }

  /**
   * Создать или обновить состояние здоровья физ лица
   * @param requestParams
   */
  public createOrUpdateHealthStatus(
    requestParams: {
      id: number,
      data: HealthStatusCreateOrUpdateRequest,
    },
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `participant/${requestParams.id}/health-status/create-or-update`,
      requestParams.data,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Создать или обновить категорию участника
   * @param requestParams
   */
  public createOrUpdateIndividualCategory(
    requestParams: {
      id: number,
      data: ParticipantIndividualCategoryCreateOrUpdateRequest,
    },
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `participant/${requestParams.id}/individual-category/create-or-update`,
      requestParams.data,
      true,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Расчет категории
   * @param requestParams
   * @return {Promise<CalculateIndividualCategoryResponse>}
   */
  public calculateIndividualCategory(
    requestParams: {
      physical_activity_group: number,
      autonomy_group: number,
    },
  ): Promise<CalculateIndividualCategoryResponse> {
    return tools.apiRequest(
      'post',
      'participant/individual-category/calculate',
      requestParams,
    ) as Promise<CalculateIndividualCategoryResponse>;
  }

  /**
   * Выполняем запрос на экспорт списка участников
   * @param requestParams
   */
  public exportParticipants(
    requestParams: ParticipantListRequest & ExportParams,
  ): Promise<ParticipantExportListResponse> {
    return tools.apiRequest(
      'get',
      'participant/export',
      requestParams,
      false,
      {},
    ) as Promise<ParticipantExportListResponse>;
  }

  /**
   * Импорт участников из CSV-файла
   * @param requestData
   * @return {Promise<ParticipantFileImportResponse>}
   */
  public importFromFile(requestData: {
    file: File
  }): Promise<ParticipantFileImportResponse> {
    return tools.formDataApiRequest(
      'participant/import',
      requestData,
    ) as Promise<ParticipantFileImportResponse>;
  }

  /**
   * Архивировать участника
   * @param params
   */
  public archiveParticipant(
    params: ParticipantArchiveRequest,
  ): Promise<ParticipantArchiveResponse> {
    return tools.apiRequest(
      'post',
      'participant/archive',
      params,
    ) as Promise<ParticipantArchiveResponse>;
  }

  /**
   * Разархивировать участника
   * @param params
   */
  public unArchiveParticipant(
    params: ParticipantUnArchiveRequest,
  ): Promise<ParticipantUnArchiveResponse> {
    return tools.apiRequest(
      'post',
      'participant/unarchive',
      params,
    ) as Promise<ParticipantUnArchiveResponse>;
  }
}
