
import { Component, Vue } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';
import Chart from 'chart.js';

@Component({
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    hasTooltip: {
      type: Boolean,
      default: () => true,
    },
    cutoutPercentage: {
      type: Number,
      default: () => 70,
    },
  },
})

export default class DoughnutChart extends Vue {
  private chartData!: Chart.ChartData;

  private hasTooltip!: boolean;

  private cutoutPercentage!: number;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (this as Doughnut).renderChart(
      this.chartData,
      {
        responsive: true,
        legend: {
          display: false,
        },
        cutoutPercentage: this.cutoutPercentage,
        tooltips: {
          enabled: this.hasTooltip,
          callbacks: {
            label: (tooltipItem, data) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              let label = data.labels[tooltipItem.index];

              if (typeof label !== 'undefined') {
                label = label.length < 15 ? label : `${label.slice(0, 15)}...`;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const string = `${label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`;
              return string;
            },
          },
        },
      },
    );
  }
}

