/**
 * Типы фильтров как на бекенде
 */

enum FiltersEntityEnum {
  ROLES = 'roles',
  USERS = 'users',
  HANDBOOKS = 'handbooks',
  ORGANISATIONS = 'organisations',
  PARTICIPANTS = 'participants',
  SERVICES = 'services',
  PROGRAM_TEMPLATES = 'program-templates',
  PROGRAMS = 'programs',
  LOG = 'log',
}

export default FiltersEntityEnum;
