
import { Component, Vue } from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const { reactiveProp } = mixins;

@Component({
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    middleLine: {
      type: Number,
      required: true,
    },
  },
})

export default class BarChart extends Vue {
  private chartData!:Chart.ChartData;

  private options!: Chart.ChartOptions;

  private middleLine!: number;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (this as Bar).addPlugin(ChartDataLabels);

    if (this.middleLine) {
      // Плагин для отрисовки горизонтальной линии линии
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (this as Bar).addPlugin({
        id: 'horizontalLine',
        afterDraw: (chart: Chart) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { ctx, scales } = chart;

          // Значение, на котором будет отображаться горизонтальная линия
          const value = this.middleLine;
          const y = scales['y-axis-0'].getPixelForValue(value);

          if (!ctx) return;

          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([18, 18]);
          ctx.moveTo(chart.chartArea.left, y);
          ctx.lineTo(chart.chartArea.right, y);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#68788A';
          ctx.stroke();

          // Отображение значения рядом с линией
          ctx.fillStyle = '#231F20';
          ctx.textAlign = 'right';
          ctx.textBaseline = 'middle';
          const textMargin = 15; // Отступ текста от линии
          const textY = y - textMargin;
          ctx.font = 'bold 12px Arial';
          ctx.fillText(value.toString(), chart.chartArea.right - textMargin, textY);

          ctx.restore();
        },
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (this as Bar).renderChart(
      this.chartData,
      {
        ...this.options,
        plugins: {
          // для отрисовки значений столбцов
          datalabels: {
            anchor: 'end', // якорная точка значений столбцов (над столбцами)
            align: 'end', // выравнивание значений столбцов относительно якорной точки
            offset: 4, // отступ значений столбцов от верхней границы столбца
            color: '#231F20', // цвет текста значений столбцов
            font: {
              weight: 'bold', // настройки шрифта значений столбцов (жирный шрифт в данном случае)
            },
          },
        },
      },
    );
  }
}
