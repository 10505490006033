import { HandbookValueItem, SelectOption, HandbookFullDataResponse } from '@/types';
import { HandbookCode } from '@/enums/HandbookList';

export default class HandbookService {
  /**
   * Получить значения справочника по коду из списка справочников
   * @param code
   * @param handbooks
   */
  private static getHandbookValues(
    handbooks: HandbookFullDataResponse,
    code: HandbookCode,
  ): HandbookValueItem[] {
    const handbookItem = handbooks.find((item) => item.code === code);
    if (typeof handbookItem === 'undefined') {
      return [];
    }
    return handbookItem.values;
  }

  /**
   * Трансформировать значения справочника к виду опшинов для селектов или чекбоксов
   * @param handbooks
   * @param code
   */
  static getOptions(handbooks: HandbookFullDataResponse, code: HandbookCode): Array<SelectOption> {
    const currentItems = this.getHandbookValues(handbooks, code);

    return currentItems.map((item: HandbookValueItem) => ({
      value: item.id,
      text: item.name,
      label: item.name,
    }));
  }

  /**
   * Получить запись справочника по ID
   * @param params
   * @return {HandbookValueItem | null}
   */
  static getItemById(params: {
    handbookFullData: HandbookFullDataResponse,
    handbookCode: HandbookCode,
    itemId: number,
  }): HandbookValueItem | null {
    const items = this.getHandbookValues(
      params.handbookFullData,
      params.handbookCode,
    );
    return items.find((item) => item.id === params.itemId) || null;
  }

  /**
   * Получить текст значения справочника
   * @param handbooks
   * @param code
   * @param fieldValue
   */
  static getTitleFromValues(
    handbooks: HandbookFullDataResponse,
    code: HandbookCode,
    fieldValue: number[]|number|null,
  ): string {
    if (!fieldValue && typeof fieldValue === 'object') {
      return '';
    }

    const handbooksValues = this.getHandbookValues(handbooks, code);

    const searchValue = (
      id: number,
    ): string => (
      handbooksValues.find((item) => item.id.toString() === id.toString())?.name?.toString() || '');

    if (!Array.isArray(fieldValue)) {
      return searchValue(fieldValue);
    }

    return fieldValue.map((value:number) => searchValue(value)).join(', ');
  }
}
