



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Prop, Watch } from 'vue-property-decorator';
import DatePicker from '@/components/form-fields/DatePicker.vue';
import { Dates, FormField } from '../../types';

@Component({
  components: { DatePicker },
})

export default class DateFromTo extends Vue {
  @Model('change', {
    required: true,
  }) private readonly dates!: Dates | null;

  @Prop(
    {
      type: Boolean,
      default: () => false,
    },
  ) private readonly hasLabels!: boolean;

  @Watch('dates')
  onDatesChange(newValue: Dates | null): void {
    if (!newValue) {
      this.fromField.value = '';
      this.toField.value = '';
    }
  }

  private fromField: FormField = {
    name: 'dateFrom',
    label: '',
    value: this.dates?.from ?? '',
    error: '',
    clearable: true,
  }

  private toField: FormField = {
    name: 'toFrom',
    label: '',
    value: this.dates?.to || '',
    error: '',
    clearable: true,
  }

  /**
   * Минимальная дата
   */
  private get minDate(): string {
    return this.fromField.value
      ? this.fromField.value : '';
  }

  /**
   * Максимальная дата
   */
  private get maxDate(): string {
    return this.toField.value
      ? this.toField.value : '';
  }

  /**
   * Изменения периода
   * @private
   */
  private changePeriodHandler(): void {
    window.console.log('changePeriodHandler');
    this.$emit('change', {
      from: this.fromField.value,
      to: this.toField.value,
    });
  }
}
