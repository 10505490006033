










import Vue from 'vue';
import Component from 'vue-class-component';
import BarChart from '@/components/chartjs/BarChart.vue';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Chart, { ChartDataSets } from 'chart.js';

dayjs.extend(weekOfYear);

@Component({
  components: { BarChart },
  props: {
    period: {
      type: String,
      required: true,
    },
    entryLabels: {
      type: Array,
      default: [],
    },
    entryData: {
      type: Array,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    middleLine: {
      type: Number,
      required: true,
    },
  },
})
export default class BarChartWrapper extends Vue {
  private period!: string;

  private entryLabels!: Array<string|{date_from: string, date_to: string}>;

  private entryData!: Array<ChartDataSets>;

  private chartOptions!: Chart.ChartOptions;

  private defaultOptions: Chart.ChartOptions = {
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
        },
      }],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        padding: 20,
      },
    },
  };

  /**
   * Собрать данные для графика
   * @private
   */
  private get chartData(): Chart.ChartData {
    return {
      labels: this.labels,
      datasets: this.entryData,
    };
  }

  private get computedOptions(): Chart.ChartOptions {
    return {
      ...this.defaultOptions,
      ...this.chartOptions,
    };
  }

  /**
   * Получить заголовки
   * @private
   */
  private get labels(): Array<string> {
    return this.entryLabels.map((label) => {
      if (typeof label === 'string') {
        return label;
      }

      switch (this.period) {
        case 'day': {
          return dayjs(label.date_from).format('DD.MM');
        }
        case 'week': {
          const startWeek = dayjs(label.date_from).format('DD.MM');
          const endWeek = dayjs(label.date_to).format('DD.MM');
          return `${startWeek} - ${endWeek}`;
        }
        case 'month': {
          return dayjs(label.date_from).format('MMM`YY');
        }
        default:
          return dayjs(label.date_from).format('DD.MM');
      }
    });
  }
}

