









































import Vue from 'vue';
import Component from 'vue-class-component';
import { AddressDetails, FormFieldWithAjaxSearch, OptionAddress } from '@/types';
import tools from '@/tools';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

@Component({
  components: { FieldLabel },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
})

export default class SelectWithSearchAddress extends Vue {
    private field!: FormFieldWithAjaxSearch;

    private isLoading = false;

    private timeoutId: number|null = null;

    private entryAddress: AddressDetails[] = [];

    mounted(): void {
      if (!this.field.value) {
        return;
      }

      this.entryAddress = [this.field.value.value];
    }

    /**
   * обработчик изменения в строке поиска
   * @param data
   * @private
   */
    private changeSearchInputHandler(data: string): void {
      this.field.search = data;
      if (!data) {
        this.field.value = null;
        this.entryAddress = [];
        return;
      }
      this.queryAddress(data);
    }

    /**
   * Поиск адреса из строки
   * @param search
   * @private
   */
    private queryAddress(search: string): void {
      this.isLoading = true;

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.$organizationApi.getAddressSuggest(search)
          .then((response: AddressDetails[]) => {
            this.entryAddress = [...response];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 500);
    }

    /**
   * собрать опции для выпадашки из найденных данных
   * @private
   */
    private get addressOptions(): Array<OptionAddress> {
      if (this.field.readonly) {
        return this.field.value ? [this.field.value] : [];
      }

      if (!this.entryAddress.length) {
        return [];
      }

      return this.entryAddress.map((item) => ({
        label: tools.getLabelFromAddressDetailType(item),
        value: item,
      }));
    }

    private onChange(): void {
      this.field.error = '';
      this.$emit('change');
    }
}

