













































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import {
  FormField,
  Role,
  SelectOption,
  UserSaveRequest,
  UserUpdateRequest, ErrorResponse, FormFieldCheckboxGroup, UserInfo,
  BreadcrumbsItem, ListItem,
} from '@/types';
import { AxiosError } from 'axios';
import PermissionEnums from '@/enums/PermissionEnums';
import tools from '@/tools';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: { Breadcrumbs },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class UsersDetail extends Vue {
  private user!: UserInfo;

  private isDetailPage = !!this.$route.params.id;

  private showApplyDialog = false;

  private passwordShow = false;

  private roles: string[] = [];

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список пользователей',
      href: '/users',
      disabled: false,
    },
    {
      text: this.isDetailPage ? 'Детальная пользователя' : 'Создание пользователя',
      href: '',
      disabled: false,
    },
  ]

  private email: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'email',
    label: 'Email',
    disabled: !this.canManageUsers,
    required: true,
  };

  private name: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'name',
    label: 'Имя',
    disabled: !this.canManageUsers,
    required: true,
  };

  private lastName: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'last_name',
    label: 'Фамилия',
    disabled: !this.canManageUsers,
    required: true,
  };

  private patronymic: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'patronymic',
    label: 'Отчество',
    disabled: !this.canManageUsers,
  };

  private telephone: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'phone',
    label: 'Телефон',
    disabled: !this.canManageUsers,
    mask: '7-###-###-##-##',
    required: true,
  };

  private worksTelephone: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'work_phone',
    label: 'Рабочий телефон',
    disabled: !this.canManageUsers,
    mask: '7-###-###-##-##',
  };

  private password: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'password',
    label: this.isDetailPage ? 'Новый пароль' : 'Пароль',
    disabled: !this.canManageUsers,
    required: true,
  };

  private rolesField: FormFieldCheckboxGroup = {
    name: 'roles',
    value: [],
    error: '',
    disabled: !this.canManageUsers,
  };

  private organisationField: FormFieldCheckboxGroup = {
    name: 'organisations',
    value: [],
    error: '',
    disabled: !this.canManageUsers,
    required: true,
  }

  private organizations: Array<ListItem>|null = null;

  private rolesList: Role[]|null = null;

  private fieldsRender = [
    this.email,
    this.name,
    this.lastName,
    this.patronymic,
    this.telephone,
    this.worksTelephone,
  ];

  private allFields = [
    ...this.fieldsRender,
    this.password,
    this.rolesField,
    this.organisationField,
  ]

  private pageType = '';

  private PAGE_TYPE_NEW = 'new';

  private PAGE_TYPE_EDIT = 'edit';

  private hasChanges = false;

  private showDialog = false;

  private toUrl: Route|null = null;

  /**
   * Хук для обработки ухода со страницы
   * если эта страница редактирования и есть изменения нужно показать диалоговое окно
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.hasChanges || this.pageType === this.PAGE_TYPE_NEW) {
      next();
    }
    this.toUrl = to;
    this.showDialog = true;
  }

  mounted(): void {
    this.pageType = this.PAGE_TYPE_NEW;

    this.getRoleList();
    this.getOrganizationList();

    if (this.userId) {
      this.pageType = this.PAGE_TYPE_EDIT;
      this.getUser();
    }
  }

  /**
   * Получить айди пользоватедя
   * @private
   */
  private get userId(): string|null {
    if (typeof this.$route.params.id === 'undefined') {
      return null;
    }

    return this.$route.params.id;
  }

  /**
   * Создать запрос на сохранения пользователя
   * @private
   */
  private createSaveRequest(): UserSaveRequest {
    return {
      email: this.email.value || '',
      name: this.name.value || '',
      last_name: this.lastName.value || '',
      organisations: this.organisationField.value || [],
      password: this.password.value || '',
      phone: this.telephone.value || '',
      work_phone: this.worksTelephone.value || '',
      roles: this.rolesField.value || [],
      patronymic: this.patronymic.value || '',
    };
  }

  /**
   * Создать запрос на обновления пользователя
   * @param id
   * @private
   */
  private createUpdateRequest(id: string): UserUpdateRequest {
    return {
      ...this.createSaveRequest(),
      id,
    };
  }

  /**
   * Сбросить все фильтры
   */
  private resetFields(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.value = null;
    });
  }

  /**
   * Загрузить данные пользователя
   * @private
   */
  private getUser(): void {
    if (!this.userId) {
      return;
    }

    this.$users.getUser({ id: this.userId })
      .then((response: UserInfo) => {
        this.setUserInfo(response);
      })
      .catch(() => {
        this.$router.push({ name: 'users-list' });
      });
  }

  /**
   * установить в поля данные пользователя
   * @param info
   * @private
   */
  private setUserInfo(info: UserInfo): void {
    this.email.value = info.email;
    this.name.value = info.name;
    this.lastName.value = info.last_name;
    this.patronymic.value = info.patronymic;
    this.telephone.value = info.phone;
    this.worksTelephone.value = info.work_phone;
    this.organisationField.value = info.organisations.map((item) => item.id.toString());
    this.rolesField.value = info.roles ? info.roles : [];
  }

  /**
   * Изменить пользоватедя
   * @param type
   * @private
   */
  private editUser(type = 'apply'): void {
    if (!this.userId) {
      return;
    }
    this.clearError();
    this.$users.updateUser(this.createUpdateRequest(this.userId))
      .then(() => {
        this.hasChanges = false;
        this.showApplyDialog = true;
        const toUrlName = this.toUrl?.name;

        if (type === 'save') {
          const nextUrlName = toUrlName && typeof toUrlName !== 'undefined' ? toUrlName : 'users-list';

          this.$router.push({ name: nextUrlName });
        }
      })
      .catch((err: ErrorResponse) => {
        if (typeof err.message === 'undefined') {
          window.console.log(err);
          return;
        }
        this.setError(err.message);
        this.scrollToError();
      });
  }

  /**
   * Сохранить пользователя
   * @param type
   * @private
   */
  private submit(type = 'apply'): void {
    this.clearError();
    this.$users.createUser(this.createSaveRequest())
      .then(() => {
        this.hasChanges = false;
        this.showApplyDialog = true;
        this.resetFields();
        if (type === 'save') {
          this.$router.push({ name: 'users-list' });
        }
      })
      .catch((err: ErrorResponse) => {
        if (typeof err.message === 'undefined') {
          window.console.log(err);
          return;
        }
        this.setError(err.message);
        this.scrollToError();
      });
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private clearError(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * Определить может ли юзер менеджерить пользоватеей
   * @private
   */
  private get canManageUsers(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.USER_MANAGEMENT) !== -1;
  }

  /**
   * Обработчик клика по кнопке сохранения
   * @private
   */
  private clickSaveButtonHandler(): void {
    const typeSubmit = 'save';
    if (this.pageType === this.PAGE_TYPE_EDIT) {
      this.editUser(typeSubmit);
      return;
    }
    this.submit(typeSubmit);
  }

  /**
   * Обработчик клика по кнопке применения
   * @private
   */
  private clickApplyButtonHandler(): void {
    if (this.pageType === this.PAGE_TYPE_EDIT) {
      this.editUser();
      return;
    }
    this.submit();
  }

  /**
   * Трансформировать данные в опции селекта
   * @private
   */
  private get transformOrganizationListToSelectOption(): SelectOption[] {
    if (!this.organizations) {
      return [];
    }
    return this.organizations.map((organization) => ({
      text: organization.name,
      value: organization.id.toString(),
    }));
  }

  /**
   * проскролить страницу к ошибке
   */
  private scrollToError(): void {
    this.$nextTick(() => {
      tools.scrollTo('.has-error');
    });
  }

  /**
   * Получить список ролей
   * @private
   */
  private getRoleList(): void {
    this.$roleApi.getRoleList()
      .then((response: Role[]) => {
        this.rolesList = response;
      })
      .catch((error: AxiosError) => {
        window.console.log(error);
      });
  }

  /**
   * получить список организаций
   * @private
   */
  private getOrganizationList(): void {
    this.$organizationApi.getShortList()
      .then((response: Array<ListItem>) => {
        if (response && !response.length) {
          return;
        }
        this.organizations = response;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      });
  }

  private changeForm(): void {
    this.hasChanges = true;
  }

  /**
   * Обработчик клика по не сохранять в диалоговм окне
   * @private
   */
  private clickNotSaveDialogButtonHandler(): void {
    if (!this.toUrl) {
      return;
    }
    this.hasChanges = false;
    const toUrlName = this.toUrl.name;

    if (toUrlName && typeof toUrlName !== 'undefined') {
      this.$router.push({ name: toUrlName });
    }
  }
}

