import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":"300"},on:{"click:outside":_vm.closeModalHandler}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]),_c('p',{staticClass:"ma-0 text-h6 text-center"},[_vm._v(_vm._s(_vm.text))])],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"px-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.closeModalHandler}},[_vm._v("Закрыть ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }