

























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem, ErrorResponse, HandbookFullDataItem, HandbookFullDataResponse, HandbookItem,
  HandbookListResponse, UserInfo,
} from '@/types';
import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import PermissionEnums from '@/enums/PermissionEnums';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ErrorApi from '@/api/errorApi';

@Component({
  components: { Breadcrumbs },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class HandbookValuesList extends Vue {
  private deleteUserDialog = false;

  private currentDeleteValue: number|null = null;

  private user!: UserInfo;

  private loading = true;

  private handbookData: HandbookItem[]|null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список справочников',
      href: '/handbooks',
      disabled: false,
    },
    {
      text: 'Детальная справочника',
      href: '/',
      disabled: true,
    },
  ]

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'Код',
      value: 'code',
      width: '15%',
    },
    {
      text: 'Значение',
      value: 'name',
      width: '50%',
    },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  private handbookFullData: HandbookFullDataItem[]|null = null;

  private loadingStartConfig = false;

  created(): void {
    this.getHandbookFullData();
    this.getValues();
  }

  private get handbookName(): string {
    if (!this.handbookFullData) {
      return '';
    }

    const item = this.handbookFullData.find((item) => item.id.toString() === this.handbookId);

    if (typeof item === 'undefined') {
      return '';
    }

    return item.name;
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
      })
      .finally(() => {
        this.loadingStartConfig = false;
      });
  }

  /**
   * Может ли пользователь управлять справочниками
   * @private
   */
  private get canManageHandbook(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.HANDBOOK_VALUE_MANAGEMENT) !== -1;
  }

  /**
   * Получить значения
   * @private
   */
  private getValues(): void {
    this.loading = true;

    this.$handbookApi.getValues(this.handbookId)
      .then((response: HandbookListResponse) => {
        this.handbookData = response;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * удалить значения
   * @param id
   * @private
   */
  private deleteValue(id: number): void {
    this.$handbookApi.deleteHandbookValue({ id })
      .then(() => {
        this.getValues();
      })
      .catch((err: ErrorResponse) => {
        let error = '';
        if (err.code === ErrorApi.ERROR_VALIDATION && err.message) {
          Object.values(err.message)
            .forEach((errors) => {
              error = `${error} ${errors.join(', ')}`;
            });
        }
        this.$eventBus.$emit(this.$eventBus.SHOW_GLOBAL_ERROR_MODAL, error);
      });
  }

  /**
   * Отправится на страницу редактирования
   * @param valueId
   * @private
   */
  private goToEditPage(valueId: string): void {
    this.$router.push({
      name: 'handbooksValueEdit',
      params: {
        id: this.handbookId,
        valueId,
      },
    });
  }

  /**
   * id справочника
   * @private
   */
  private get handbookId(): string {
    return this.$route.params.id.toString();
  }

  /**
   * Клик по кнопке изменения значения
   * @param valueId
   * @private
   */
  private clickEditValueHandler(valueId: number): void {
    this.goToEditPage(valueId.toString());
  }

  /**
   * обработчик клика по строке таблицы
   * @param handbookItem
   * @private
   */
  private clickTableRowHandler(handbookItem: HandbookItem): void {
    this.goToEditPage(handbookItem.id.toString());
  }

  /**
   * Клик по кнопке удаления
   */
  private clickDeleteIconHandler(id: number): void {
    this.currentDeleteValue = id;
    this.deleteUserDialog = true;
  }

  /**
   * Клик по кнопке согласия на удаления юзера
   */
  private clickAcceptDeleteValueHandler(): void {
    if (!this.currentDeleteValue) {
      return;
    }
    this.deleteUserDialog = false;
    this.deleteValue(this.currentDeleteValue);
  }

  /**
   * Клик по кнопке закрытия диалогового окна
   */
  private clickDeleteValueDialogCloseHandler(): void {
    this.currentDeleteValue = null;
    this.deleteUserDialog = false;
  }
}

