/**
 * Сервис авторизации
 */
import { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import { AuthRequest, UserInfo } from '../types';
// eslint-disable-next-line import/no-cycle
import axiosInstance from '../axios/index';

export default class AuthService extends Vue {
  private static user: UserInfo|null = null;

  private static token: string|null = null;

  static readonly tokenName: string = 'auth_token';

  /**
   * Авторизация
   * @param request
   */
  public authRequest(request: AuthRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosInstance.post('auth/login', {
        email: request.email,
        password: request.password,
      }).then((response: AxiosResponse) => {
        if (typeof response.data.error !== 'undefined') {
          reject(response.data.error);
        } else {
          AuthService.setToken(response.data.response.token);
          resolve(true);
        }
      }).catch((error: AxiosError) => {
        reject(error);
      });
    });
  }

  /**
   * Данные текущего пользователя
   */
  public getUser(): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      if (AuthService.user) {
        resolve(AuthService.user);
      } else {
        axiosInstance.get('user/current').then((response: AxiosResponse) => {
          AuthService.user = response.data.response as UserInfo;
          resolve(AuthService.user);
        }).catch((error: AxiosError) => {
          AuthService.clearUserData();
          reject(error);
        });
      }
    });
  }

  /**
   * Обновить данные о юзере
   */
  public updateUserInfo(): Promise<void> {
    return new Promise((resolve) => {
      axiosInstance.get('user/current').then((response: AxiosResponse) => {
        AuthService.user = response.data.response as UserInfo;
        resolve();
      }).catch(() => {
        AuthService.clearUserData();
      });
    });
  }

  /**
   * Выход
   */
  public logout(): void {
    AuthService.setToken(null);
    AuthService.clearUserData();
    this.$eventBus.$emit(this.$eventBus.EVENT_AUTH_STATUS_CHANGED);
  }

  /**
   * очистить данные о пользователи
   * @private
   */
  private static clearUserData(): void {
    AuthService.user = null;
  }

  /**
   * Получить токен авторизации
   */
  public static getToken(): string | null {
    if (!this.token) {
      this.token = localStorage.getItem(this.tokenName);
    }

    return this.token;
  }

  /**
   * Устанавливает токен авторизации
   * @param token
   * @private
   */
  private static setToken(token: string|null): void {
    AuthService.token = token;
    if (token) {
      localStorage.setItem(this.tokenName, token);
    } else {
      localStorage.removeItem(this.tokenName);
    }
  }
}
