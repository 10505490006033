



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Watch } from 'vue-property-decorator';
import Periods from '@/helpers/Periods';
import { PeriodsPreset } from '@/enums/Periods';
import { Dates } from '@/types';
import DateFromTo from '@/components/forms/DateFromTo.vue';

@Component({
  components: { DateFromTo },
})

export default class PeriodFilters extends Vue {
  @Model('change-period', { type: Object, required: true })
  readonly periodModel!: Dates|null;

  private periodPresetModel: PeriodsPreset|null = PeriodsPreset.TODAY;

  private datepickerModel: Dates|null = this.periodModel;

  private periodsPreset = Periods.getPeriodsOptions();

  /**
   * Наблюдаем за значением модели, если модель сменилась
   * тригерим изменения периода
   */
  @Watch('datepickerModel', { deep: true })
  onDatepickerModelChange(value: Dates): void {
    this.periodPresetModel = null;
    this.$emit('change-period', value);
    this.emitUpdateChartDataEvent();
  }

  /**
   * Проверить активный ле пресет
   * @param value
   * @private
   */
  private checkActivePeriod(value: PeriodsPreset|string|number): boolean {
    const periodDates = Periods.getRangeDates((value as PeriodsPreset));

    if (!this.periodModel) {
      return false;
    }
    return periodDates.to === this.periodModel.to && periodDates.from === this.periodModel.from;
  }

  /**
   * сгенерировать событие обновления данных по графикам
   */
  private emitUpdateChartDataEvent(): void {
    this.$eventBus.$emit(this.$eventBus.UPDATE_CHART_DATA);
  }

  /**
   * Клик по прессету периода
   * @param value
   * @private
   */
  private clickPeriodHandler(value: PeriodsPreset|string|number) {
    this.periodPresetModel = (value as PeriodsPreset);
    const dates = Periods.getRangeDates((value as PeriodsPreset));
    this.$emit('change-period', dates);
    this.emitUpdateChartDataEvent();
  }

  /**
   * Клик на произвольный период
   * @private
   */
  private clickRandomPeriodHandler(): void {
    this.periodPresetModel = null;
    this.$emit('change-period', this.datepickerModel);
    this.emitUpdateChartDataEvent();
  }
}
