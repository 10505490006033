/**
 * Класс по работе апи услуг
 */
import { Service } from '@/types/service';
import tools from '@/tools';
import {
  ExportParams,
  SuccessResponse,
} from '@/types';

export default class ServiceApi {
  /**
   * Получить список услуг с постраничной навигацией
   * @param requestParams
   */
  public getList(
    requestParams: Service.ListRequest,
  ): Promise<Service.List> {
    return tools.apiRequest(
      'get',
      'service',
      requestParams,
    ) as Promise<Service.List>;
  }

  /**
   * Получить информацию об услуге
   * @param requestParams
   */
  public getServiceInfo(
    requestParams: {id: string},
  ): Promise<Service.DetailedResponse> {
    return tools.apiRequest(
      'get',
      `service/${requestParams.id}`,
    ) as Promise<Service.DetailedResponse>;
  }

  /**
   * Создать
   * @param requestParams
   */
  public create(
    requestParams: Service.CreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'service/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Обновить
   * @param requestParams
   */
  public update(
    requestParams: Service.UpdateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `service/${requestParams.id}/update`,
      requestParams.params,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Удалить
   * @param requestParams
   */
  public delete(
    requestParams: { id: string },
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `service/${requestParams.id}/delete`,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить полный список услуг
   */
  public getFullData(): Promise<Service.FullDataResponse> {
    return tools.apiRequest(
      'get',
      'service/full-data',
    ) as Promise<Service.FullDataResponse>;
  }

  /**
   * Выполняем запрос на экспорт списка услуг
   * @param requestParams
   */
  public exportServices(
    requestParams: Service.ListRequest & ExportParams,
  ): Promise<Service.ExportListResponse> {
    return tools.apiRequest(
      'get',
      'service/export',
      requestParams,
      false,
      {},
    ) as Promise<Service.ExportListResponse>;
  }
}
