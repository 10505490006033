import { DetailedResponseType, DetailedSchemaItem } from '@/types';

const ParticipantInfoSchema: DetailedSchemaItem[] = [{
  title: 'Сведения об участнике',
  rows: [
    'last_name',
    'first_name',
    'second_name',
    'date_of_birth',
    'date_of_death',
    'gender',
    'personal_account_insurance',
    'medical_insurance',
  ],
},
{
  title: 'Адрес проживания',
  rows: [
    'address',
    'floor',
  ],
},
{
  title: 'Адрес регистрации',
  rows: [
    'registration_address',
  ],
},
{
  title: 'Контактные данные физического лица',
  rows: [
    'phone',
    'home_phone',
    'email',
  ],
},
{
  title: 'Статусы физического лица',
  rows: [
    'marital_status',
    'social_status',
    'education',
    'honorary_titles',
    'organisation',
  ],
},
];

const ParticipantFamilyMemberSchema: DetailedSchemaItem[] = [
  {
    title: 'Сведения о члене семьи',
    rows: [
      'last_name',
      'first_name',
      'second_name',
      'date_of_birth',
      'gender',
      'relation_degree',
      'contact_person',
    ],
  },
  {
    title: 'Контактные данные физического лица',
    rows: [
      'phone',
      'email',
    ],
  },
  {
    title: 'Адрес проживания',
    rows: [
      'address',
      'floor',
    ],
  },
  {
    title: 'Адрес регистрации',
    rows: [
      'registration_address',
    ],
  },
];

const ParticipantHealthStatusSchema: DetailedSchemaItem[] = [
  {
    title: '',
    rows: [
      'dispensary_observation_group',
      'observation_reason',
      'disability',
      'disability_group',
      'disability_series',
      'disability_number',
      'disability_reason',
      'disability_date_start',
      'disability_date_end',
      'rehabilitation_program',
    ],
  },
  {
    title: 'Приём лекарственных средств',
    rows: [
      'pharmacological_groups',
      'medications_taken',
      'additional_devices',
      'additional_devices_comment',
    ],
  },
  {
    title: 'Образ жизни',
    rows: [
      'smoking',
      'smoking_frequency',
      'alcohol',
      'alcohol_frequency',
    ],
  },
];

const ParticipantIndividualSchema: DetailedSchemaItem[] = [
  {
    title: '',
    rows: [
      'physical_activity_group',
      'autonomy_group',
    ],
  },
  {
    title: 'Комплекс по процедурам и манипуляциям',
    rows: [
      'category',
      'assistance_program',
    ],
  },
];

const ParticipantStatusSchema: DetailedSchemaItem[] = [
  {
    title: '',
    rows: [
      'status',
      'archive_reason',
    ],
  },
];

export default class MembersDetailsSchema {
  static getSchema(type: DetailedResponseType): DetailedSchemaItem[] {
    switch (type) {
      case 'info':
        return ParticipantInfoSchema;
      case 'familyMembers':
        return ParticipantFamilyMemberSchema;
      case 'healthStatus':
        return ParticipantHealthStatusSchema;
      case 'individualCategory':
        return ParticipantIndividualSchema;
      case 'participantStatus':
        return ParticipantStatusSchema;
      default:
        return [];
    }
  }
}
