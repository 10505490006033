import { Statistic } from '@/types/statistic';
import tools from '@/tools';
import { CompaniesShortResponse, Nullable } from '@/types';

export default class StatisticApi {
  /**
   * Получить данные для графика по участникам
   * @param requestParams
   */
  public getParticipants(
    requestParams: Statistic.ParticipantsRequest,
  ): Promise<Statistic.ParticipantsResponse> {
    return tools.apiRequest(
      'get',
      'statistics/participants-count',
      requestParams,
    ) as Promise<Statistic.ParticipantsResponse>;
  }

  /**
   * Получить распределение участников
   * @param requestParams
   */
  public getParticipantsDistribution(
    requestParams: Statistic.ParticipantsDistributionRequest,
  ): Promise<Statistic.ParticipantsDistributionResponse> {
    return tools.apiRequest(
      'get',
      'statistics/participants-distribution',
      requestParams,
    ) as Promise<Statistic.ParticipantsDistributionResponse>;
  }

  /**
   * Сводка по программам и повторениям(услуг)
   * @param requestParams
   */
  public getProgramsTotals(
    requestParams: Statistic.ProgramsTotalsRequest,
  ): Promise<Statistic.ProgramsTotalsResponse> {
    return tools.apiRequest(
      'get',
      'statistics/programs/totals',
      requestParams,
    ) as Promise<Statistic.ProgramsTotalsResponse>;
  }

  /**
   * Получить данные для графика повторений услуг
   * @param requestParams
   */
  public getServicesRepetition(
    requestParams: Statistic.ServiceRepetitionRequest,
  ): Promise<Statistic.ServiceRepetitionResponse> {
    return tools.apiRequest(
      'get',
      'statistics/repetitions/chart',
      requestParams,
    ) as Promise<Statistic.ServiceRepetitionResponse>;
  }

  /**
   * Получить данные для КнУ
   * @param requestParams
   */
  public getKnuChart(
    requestParams: Statistic.KnuChartRequest,
  ): Promise<Statistic.KnuChartResponse> {
    return tools.apiRequest(
      'get',
      'statistics/knu/chart',
      requestParams,
    ) as Promise<Statistic.KnuChartResponse>;
  }

  /**
   * Получить данные для КВИП
   * @param requestParams
   */
  public getKvipChart(
    requestParams: Statistic.KvipChartRequest,
  ): Promise<Statistic.KvipChartResponse> {
    return tools.apiRequest(
      'get',
      'statistics/kvip/chart',
      requestParams,
    ) as Promise<Statistic.KvipChartResponse>;
  }

  /**
   * Выполняем запрос на экспорт списка услуг
   * @param requestParams
   */
  public exportStatistic(
    requestParams: Nullable<Statistic.ExportRequestParams>,
  ): Promise<Statistic.ExportResponse> {
    return tools.apiRequest(
      'get',
      'statistics/export',
      requestParams,
      false,
      {},
    ) as Promise<Statistic.ExportResponse>;
  }

  /**
   * Получить список организация для статистики
   */
  public getOrganizationList(): Promise<CompaniesShortResponse> {
    return tools.apiRequest(
      'get',
      'statistics/organisations-list',
    ) as Promise<CompaniesShortResponse>;
  }
}
