import { CompaniesShortItem } from '@/types';

/**
 * Сервис по работе с компаниями
 */
export default class CompanyService {
  /**
   * Отфильтровать компании по полигону
   * @param polygonIds
   * @param companies
   */
  static filterOrganizationsByPolygonIds(
    polygonIds: number[],
    companies: CompaniesShortItem[],
  ): CompaniesShortItem[] {
    /**
     * Если полигонов не передано, возвращаем оригинальный список
     */
    if (!polygonIds.length) {
      return companies;
    }

    return companies.filter((company) => polygonIds.includes(company.polygon_id));
  }
}
