import { Dates, SelectOption } from '@/types';
import { PeriodsPreset, PeriodsPresetRuLabel } from '@/enums/Periods';
import dayjs from 'dayjs';

export default class Periods {
  static periodOptions: Array<SelectOption> = [
    {
      label: PeriodsPresetRuLabel.TODAY,
      value: PeriodsPreset.TODAY,
    },
    {
      label: PeriodsPresetRuLabel.YESTERDAY,
      value: PeriodsPreset.YESTERDAY,
    },
    {
      label: PeriodsPresetRuLabel.WEEK,
      value: PeriodsPreset.WEEK,
    },
    {
      label: PeriodsPresetRuLabel.MONTH,
      value: PeriodsPreset.MONTH,
    },
    {
      label: PeriodsPresetRuLabel.CURRENT_YEAR,
      value: PeriodsPreset.CURRENT_YEAR,
    },
  ];

  static getPeriodsOptions(): Array<SelectOption> {
    return this.periodOptions;
  }

  /**
   * Получить диапазон дат
   * @param value
   */
  static getRangeDates(value: PeriodsPreset): Dates {
    const dates: Dates = {
      from: '',
      to: '',
    };

    switch (value) {
      case PeriodsPreset.TODAY:
        dates.from = dayjs().format('YYYY-MM-DD');
        dates.to = dayjs().format('YYYY-MM-DD');
        break;
      case PeriodsPreset.YESTERDAY:
      {
        const yeasterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

        dates.from = yeasterday;
        dates.to = yeasterday;
        break;
      }
      case PeriodsPreset.WEEK:
      {
        const today = dayjs().format('YYYY-MM-DD');

        dates.to = today;
        dates.from = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
        break;
      }
      case PeriodsPreset.MONTH:
        {
          const today = dayjs().format('YYYY-MM-DD');

          dates.from = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
          dates.to = today;
        }
        break;
      case PeriodsPreset.CURRENT_YEAR:
        {
          const today = dayjs().format('YYYY-MM-DD');

          dates.from = dayjs().subtract(365, 'day').format('YYYY-MM-DD');
          dates.to = today;
        }
        break;
      default:
        return dates;
    }

    return dates;
  }

  /**
   * Получить текущий день
   */
  static getToday(): Dates {
    const dates = {
      from: '',
      to: '',
    };

    dates.from = dayjs().format('YYYY-MM-DD');
    dates.to = dayjs().format('YYYY-MM-DD');

    return dates;
  }
}
