import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-sm-8"},[(_vm.user && !_vm.loading)?[_c('div',{staticClass:"mt-4 d-sm-flex"},[_c('h1',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Журнал событий")])]),_c('section',{staticClass:"mt-8"},[_c(VBtn,{staticClass:"mb-4 white--text",attrs:{"color":"light-blue lighten-1","height":"40"},on:{"click":_vm.clickShowFilterButtonHandler}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-filter")]),_c('span',{staticClass:"text-body-1 text-capitalize"},[_vm._v(" Фильтр ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}]},[_c(VDivider,{staticClass:"mb-8"}),_c('DataFilters',{key:_vm.filterKey,attrs:{"fields":_vm.allFields},on:{"filter-reset":_vm.clickResetFilterButtonHandler,"filter-submit":_vm.clickSearchFilterHandler,"filters-loaded":_vm.loadedFilterHandler}}),_c(VDivider,{staticClass:"mt-8"})],1)],1),_c('section',{staticClass:"mt-4"},[_c(VRow,{staticClass:"mb-8",attrs:{"align":"end","no-gutters":""}},[_c('span',{staticClass:"grey--text lighten-1 mb-3"},[(_vm.itemList && _vm.itemCounts.total > 0)?[_vm._v(" Показано записей "+_vm._s(_vm.itemCounts.from)+" - "+_vm._s(_vm.itemCounts.to)+" из "+_vm._s(_vm.itemCounts.total)+" ")]:[_vm._v(" Записей пока нет.. ")]],2),_c(VSpacer)],1),_c('DataTableWrapper',{attrs:{"entry-items":_vm.itemListTransformed,"entry-headers":_vm.itemListHeaders,"has-pencil":false},on:{"sort-change":_vm.updateSort}}),(_vm.itemList && _vm.pagesCount > 1)?_c(VPagination,{staticClass:"mt-8",attrs:{"color":"light-blue lighten-1","length":_vm.pagesCount},on:{"input":_vm.loadItems},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]:[_c('Loader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }