




































































































































































































































import ParticipantSection from '@/components/participant/ParticipantSection.vue';
import Component from 'vue-class-component';
import {
  DetailMembers,
  ErrorResponse,
  FormField,
  FormFieldRadioButton,
  FormFieldSelect, FormFieldSelectMultiple,
  HealthStatusCreateOrUpdateRequest, HealthStatusResponse,
  SuccessResponse,
} from '@/types';
import RadioButtonCustom from '@/components/form-fields/RadioButtonCustom.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import DatePicker from '@/components/form-fields/DatePicker.vue';
import CustomTextArea from '@/components/form-fields/CustomTextArea.vue';
import RowText from '@/components/RowText.vue';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import SelectsList from '@/components/form-fields/SelectsList.vue';
import { HandbookCode } from '@/enums/HandbookList';
import DetailedInformation from '@/components/DetailedInformation.vue';
import DetailedMembersTransform from '@/services/transform-response/DetailedMembers';
import MaskService from '@/services/MaskService';

@Component({
  components: {
    DetailedInformation,
    RadioButtonCustom,
    CustomSelect,
    DatePicker,
    CustomTextArea,
    RowText,
    FormActions,
    DataWillNotSaved,
    SelectsList,
  },
  props: {
    healthStatus: {
      type: Object,
      default: {},
    },
  },
})

export default class ParticipantSectionHealthStatus extends ParticipantSection {
  private loading = false;

  private healthStatus!: HealthStatusResponse|null;

  private alcohol: FormFieldRadioButton = {
    label: 'Алкоголь',
    name: 'alcohol',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: true,
    value: null,
  }

  private alcoholFrequency: FormFieldSelect = {
    name: 'alcohol_frequency',
    value: null,
    error: '',
    label: 'Как часто употребляет ?',
    required: true,
  }

  private indefiniteDisability: FormField = {
    value: '0',
    label: 'Бессрочная',
    name: 'indefinite_disability',
    error: '',
  };

  private smokingFrequency: FormFieldSelect = {
    name: 'smoking_frequency',
    value: null,
    error: '',
    label: 'Как часто употребляете ?',
    required: true,
  }

  private additionalDevicesComment: FormField = {
    name: 'additional_devices_comment',
    label: 'Укажите используемые дополнительные устройства',
    error: '',
    value: '',
    disabled: false,
    required: true,
    placeholder: 'Введите данные',
  }

  private smoking: FormFieldRadioButton = {
    label: 'Курение',
    name: 'smoking',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: true,
    value: null,
  }

  private additionalDevices: FormFieldRadioButton = {
    label: 'Дополнительные устройства',
    name: 'additional_devices',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: true,
    value: null,
  }

  private rehabilitationProgram: FormFieldRadioButton = {
    label: 'ИПРА',
    name: 'rehabilitation_program',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: false,
    value: null,
  }

  private dispensaryObservationGroup: FormFieldRadioButton = {
    label: 'Группа диспансерного наблюдения',
    name: 'dispensary_observation_group',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: true,
    value: null,
  }

  private observationReason: FormFieldSelect = {
    name: 'observation_reason',
    value: null,
    error: '',
    label: 'Причина наблюдения',
  }

  private disability: FormFieldRadioButton = {
    label: 'Инвалидность',
    name: 'disability',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: true,
    value: null,
  }

  private disabilityGroup: FormFieldSelect = {
    value: null,
    name: 'disability_group',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Инвалидность',
    options: [],
    required: true,
  };

  private disabilitySeries: FormField = {
    name: 'disability_series',
    label: '',
    placeholder: 'МСЭ-2021',
    error: '',
    value: '',
    disabled: false,
    required: true,
    mask: 'YYY-####',
  }

  private disabilityNumber: FormField = {
    name: 'disability_number',
    label: '',
    placeholder: '01234567',
    error: '',
    value: '',
    disabled: false,
    required: true,
    mask: MaskService.getOnlyNumber,
  }

  private disabilityDateStart: FormField = {
    name: 'disability_date_start',
    label: 'Дата установления инвалидности',
    error: '',
    value: '',
    disabled: false,
    required: true,
    mask: '##.##.####',
  }

  private disabilityReason: FormFieldSelect = {
    name: 'disability_reason',
    value: null,
    error: '',
    label: 'Причина инвалидности (МКБ-10)',
    placeholder: 'Выберите из списка',
    required: true,
  }

  private disabilityDateEnd: FormField = {
    name: 'disability_date_end',
    label: 'Срок действия',
    error: '',
    value: '',
    disabled: false,
    required: true,
    mask: '##.##.####',
  }

  private pharmacologicalGroups: FormFieldSelectMultiple = {
    name: 'pharmacological_groups',
    value: [],
    error: '',
    label: 'Фармакологическая группа лекарственных средств (ГРЛС)',
    placeholder: 'Выберите из списка',
    required: true,
  }

  private medicationsTaken: FormField = {
    name: 'medications_taken',
    label: 'Укажите принимаемые лекарственные средства',
    error: '',
    value: '',
    disabled: false,
    placeholder: 'Введите данные',
  }

  public allFields = [
    this.dispensaryObservationGroup,
    this.observationReason,
    this.disability,
    this.disabilityGroup,
    this.disabilitySeries,
    this.disabilityNumber,
    this.disabilityReason,
    this.disabilityDateStart,
    this.disabilityDateEnd,
    this.rehabilitationProgram,
    this.medicationsTaken,
    this.additionalDevices,
    this.additionalDevicesComment,
    this.smoking,
    this.smokingFrequency,
    this.alcohol,
    this.alcoholFrequency,
    this.pharmacologicalGroups,
    this.indefiniteDisability,
  ]

  private disabilityGroupFields = [
    this.disabilityGroup,
    this.disabilitySeries,
    this.disabilityNumber,
    this.disabilityReason,
    this.disabilityDateStart,
    this.disabilityDateEnd,
  ];

  created(): void {
    this.createFieldsOptions();
    this.fillData();
    this.changeDisabilityEndRequired();
  }

  /**
   * Получить заголовок секции
   */
  private get sectionTitle(): string {
    return this.participantIsActive
      ? '3. Состояние здоровья физического лица'
      : 'Шаг 4. Состояние здоровья физического лица';
  }

  private get detailedData(): DetailMembers.Data|null {
    if (!this.healthStatus) {
      return null;
    }

    return DetailedMembersTransform.getDataFromDetailsView(
      this.healthStatus,
      this.handBooksData,
      'healthStatus',
    );
  }

  /**
   * Заполнить поля значениями если они есть
   */
  private fillData(): void {
    if (!this.healthStatus || typeof this.healthStatus === 'undefined') {
      return;
    }

    this.allFields.forEach((field) => {
      const currentField = field;
      const { name } = currentField;

      const { healthStatus } = this;

      Object.keys((healthStatus as HealthStatusResponse)).forEach((key) => {
        const newKey: keyof typeof healthStatus = (key as never);

        if (!healthStatus || key !== name) {
          return;
        }

        const startValue = healthStatus[newKey];

        if (typeof startValue === 'boolean') {
          currentField.value = startValue ? '1' : '0';
          return;
        }

        currentField.value = healthStatus[newKey];
      });
    });
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.alcoholFrequency.options = this.createOptions(HandbookCode.ALCOHOL_CONSUMPTION);
    this.smokingFrequency.options = this.createOptions(HandbookCode.SMOKING);
    this.pharmacologicalGroups.options = this.createOptions(HandbookCode.PHARMACOLOGY_GROUPS);
    this.disabilityReason.options = this.createOptions(HandbookCode.DISABILITY_REASON);
    this.disabilityGroup.options = this.createOptions(HandbookCode.DISABILITY_GROUP);
    this.observationReason.options = this.createOptions(HandbookCode.DISPENSARY_OBSERVATION_GROUP);
  }

  /**
   * Показать успех
   */
  private showSuccess(): void {
    this.$toast.success('Шаг сохранён!');
  }

  /**
   * Создать строковый параметр из любых значений
   */
  private createStringParam(value: string|number|null): string {
    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString();
    }

    return '';
  }

  /**
   * Создать запрос
   */
  private createRequest(): HealthStatusCreateOrUpdateRequest {
    return {
      dispensary_observation_group: this.createStringParam(this.dispensaryObservationGroup.value),
      observation_reason: this.observationReason.value ? +this.observationReason.value : null,
      disability: this.createStringParam(this.disability.value),
      disability_group: this.disabilityGroup.value ? +this.disabilityGroup.value : null,
      disability_series: this.disabilitySeries.value,
      disability_number: this.disabilityNumber.value,
      disability_reason: this.disabilityReason.value ? +this.disabilityReason.value : null,
      disability_date_start: this.disabilityDateStart.value,
      disability_date_end: this.disabilityDateEnd.value,
      rehabilitation_program: this.createStringParam(this.rehabilitationProgram.value),
      medications_taken: this.medicationsTaken.value,
      additional_devices: this.createStringParam(this.additionalDevices.value),
      additional_devices_comment: this.additionalDevicesComment.value,
      smoking: this.createStringParam(this.smoking.value),
      smoking_frequency: this.smokingFrequency.value ? +this.smokingFrequency.value : null,
      alcohol: this.createStringParam(this.alcohol.value),
      alcohol_frequency: this.alcoholFrequency.value ? +this.alcoholFrequency.value : null,
      indefinite_disability: typeof this.indefiniteDisability.value === 'string'
        ? this.indefiniteDisability.value : '0',
      pharmacological_groups: this.pharmacologicalGroups.value
        ? (this.pharmacologicalGroups.value as number[]) : null,
    };
  }

  /**
   * Отправить форму
   */
  private submitForm(): Promise<void> {
    return new Promise((resolve) => {
      this.loading = true;
      this.resetError();

      let params = this.createRequest();

      if (this.healthStatus && this.healthStatus.id) {
        params = {
          ...params,
          id: this.healthStatus.id,
        };
      }

      this.$participantApi.createOrUpdateHealthStatus({
        id: +this.participantId,
        data: params,
      }).then((response: SuccessResponse) => {
        if (response.success) {
          this.$emit('ready-health-step');
          if (!this.participantIsActive) {
            this.showSuccess();
          }

          resolve();
        }
      })
        .catch((err: ErrorResponse) => {
          if (err.code === 2 && typeof err.message !== 'undefined') {
            this.setError(err.message);
            this.showNotSaveDialog = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });

    this.scrollToError();
  }

  private resetFields(fields: Array<FormField>): void {
    fields.forEach((field) => {
      field.value = null;
    });
  }

  /**
   * Изменить обязательность поля конца инвалидности
   * @private
   */
  private changeDisabilityEndRequired(): void {
    if (typeof this.indefiniteDisability.value === 'object') {
      this.disabilityDateEnd.required = true;
      return;
    }

    this.disabilityDateEnd.required = this.indefiniteDisability.value === '0';
  }

  /**
   * Обработчик клика по кнопке сохранить в режиме созданого юзера
   * @private
   */
  private clickSaveButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.$emit('show-save-popup');
        this.statusBlock = 'readonly';
        this.showNotSaveDialog = false;
        this.emitEventEndEdit();
      })
      .catch(() => {
        this.showNotSaveDialog = false;
      });
  }

  /**
   * обработчик клик по принять
   * @private
   */
  private clickApplyButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.$emit('show-save-popup');
      });
  }

  /**
   * Обработчик клика по отмена
   * @private
   */
  private closeClickHandle(): void {
    this.showNotSaveDialog = true;
  }

  /**
   * Обработчик клика по не сохранять
   * @private
   */
  private clickNotSaveHandle(): void {
    this.fillData();
    this.emitEventEndEdit();
    this.showNotSaveDialog = false;
    this.statusBlock = 'readonly';
  }

  /**
   * На изменения наличия инвалидности
   * @private
   */
  private changeDisabilityStatusHandler(): void {
    if (this.disability.value === '1') {
      return;
    }

    this.resetFields((this.disabilityGroupFields as FormField[]));
  }
}

