export default class MaskService {
  /**
   * Получаем маску только для цифр
   * @param value
   */
  static getOnlyNumber(value: string): Array<RegExp> {
    const numberParts: Array<string> = value
      .replace(/[^0-9]/g, '').split('');

    if (numberParts.length > 2) {
      value = [...numberParts].join('');
    }

    const regexArray = [...value].map(() => (/\d/));

    return (regexArray as RegExp[]);
  }

  /**
   * Получаем маску только для цифр и точек
   * @param value
   */
  static getOnlyNumberAndPoint(value: string): Array<RegExp> {
    const numberParts: Array<string> = value
      .replace(/[^0-9.]/g, '').split('');

    if (numberParts.length > 2) {
      value = [...numberParts].join('');
    }

    const regexArray = [...value].map((char) => (char === '.' ? '.' : /\d/));

    return (regexArray as RegExp[]);
  }

  /**
   * Маска для внутреннего кода услуги
   * @param value
   */
  static getMaskForServiceCode(value: string): Array<RegExp> {
    return [/[a-zA-Z]/, ...MaskService.getOnlyNumberAndPoint(value)];
  }
}
