











































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem,
  Company,
  FormField,
  FormFieldCheckboxGroup,
  Role,
  SelectOption,
  UserInfo,
  UserInfoTableItem,
  UsersListRequest,
  UsersListResponse,
} from '@/types';
import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import PermissionEnums from '@/enums/PermissionEnums';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';

@Component({
  components: { DataFilters, Breadcrumbs },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class UsersList extends Vue {
  private user!: UserInfo;

  private NAME_EXPORT_FILE = 'usersList';

  private showFilter = true;

  private loading = true;

  private usersData: UserInfo[]|null = null;

  private pagesCount = 1;

  private currentPage = 1;

  private usersCounts = {
    from: '',
    to: '',
    total: '',
  }

  private loadingExport = false;

  private deleteUserDialog = false;

  private currentDeleteUser: number|null = null;

  private rolesList: Array<Role>|null = null;

  private role: FormFieldCheckboxGroup = {
    label: 'Роль',
    value: [],
    name: 'roles',
    error: '',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
    placeholder: 'Выберите значения',
  }

  private email: FormField = {
    value: null,
    name: 'email',
    placeholder: 'Введите данные',
    error: '',
    label: 'Email',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private firstName: FormField = {
    value: null,
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Имя',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private lastName: FormField = {
    value: null,
    name: 'lastName',
    placeholder: 'Введите данные',
    error: '',
    label: 'Фамилия',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private renderFields: FormField[] = [
    this.email,
    this.firstName,
    this.lastName,
  ]

  private allFields = [
    ...this.renderFields,
    this.role,
  ]

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список пользователей',
      href: '/users',
      disabled: true,
    },
  ]

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      width: '5%',
      class: 'custom-table-heading',
    },
    {
      text: 'Email',
      value: 'email',
      width: '15%',
      class: 'custom-table-heading',
    },
    {
      text: 'Роль',
      value: 'roles',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Организация',
      value: 'organisations',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Имя',
      value: 'name',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Фамилия',
      value: 'last_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Телефон',
      value: 'phone',
      sortable: false,
      width: '10%',
      class: 'custom-table-heading',
    },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
      width: '10%',
    },
  ];

  mounted(): void {
    this.getRolesList();
    this.addEventListeners();
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  /**
   * Получить индефикацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.USERS;
  }

  /**
   * Преобразовать данные для таблицы
   */
  private get userDataForTableComponent(): Array<UserInfoTableItem> {
    if (!this.usersData) {
      return [];
    }
    return this.usersData.map((user) => ({
      email: user.email,
      name: user.name,
      id: user.id,
      last_name: user.last_name,
      organisations: UsersList.getCompanyName(user.organisations),
      roles: this.getRole(user.roles ? user.roles : []),
      patronymic: user.patronymic,
      phone: user.phone,
      work_phone: user.work_phone,
    }));
  }

  /**
   * Может ли пользователь управлять пользователями
   */
  private get canManagementUsers(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.USER_MANAGEMENT) !== -1;
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Создать данные для запроса
   */
  private createRequest(): UsersListRequest {
    return {
      page: this.currentPage,
      email: this.email.value ? this.email.value : '',
      name: this.firstName.value ? this.firstName.value : '',
      last_name: this.lastName.value ? this.lastName.value : '',
      roles: this.role.value ? this.role.value : [],
    };
  }

  /**
   * Преобразовать данные бекенда о ролях в данные для селекта
   */
  private get transformRoleListInOptionsSelect(): SelectOption[] {
    if (!this.rolesList) {
      return [];
    }

    return this.rolesList.map((role) => ({
      label: role.title,
      text: role.title,
      value: role.name,
    }));
  }

  /**
   * Экспорт пользователей в файл
   */
  private exportUsers(): void {
    this.loadingExport = true;
    this.$users.exportUsers(
      {
        ...this.createRequest(),
        _fields: 'id,email,roles,organisations[].name,name,last_name,phone',
        _labels: 'ID,Почта,Роль,Организации,Имя,Фамилия,Телефон',
      },
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    });
  }

  /**
   * Получить список ролей
   */
  private getRolesList(): void {
    this.$roleApi.getRoleList()
      .then((response: Array<Role>) => {
        this.rolesList = response;
        this.role.options = this.transformRoleListInOptionsSelect;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      });
  }

  /**
   * Клик по кнопке удаления
   */
  private clickDeleteIconHandler(id: number): void {
    this.currentDeleteUser = id;
    this.deleteUserDialog = true;
  }

  /**
   * Клик по кнопке согласия на удаления юзера
   */
  private clickAcceptDeleteUserHandler(): void {
    if (!this.currentDeleteUser) {
      return;
    }
    this.deleteUserDialog = false;
    this.deleteUser(this.currentDeleteUser);
  }

  /**
   * Клик по кнопке закрытия диалогового окна
   */
  private clickDeleteUserDialogCloseHandler(): void {
    this.currentDeleteUser = null;
    this.deleteUserDialog = false;
  }

  /**
   * Удалить пользователя
   */
  private deleteUser(id: number): void {
    this.$users.deleteUser({ id })
      .then((response) => {
        if (response.success) {
          this.currentDeleteUser = null;
          this.getUsers();
        }
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      });
  }

  /**
   * Получить пользователей
   */
  private getUsers(): void {
    this.loading = true;
    this.$users.getUsers(this.createRequest())
      .then((response: UsersListResponse) => {
        this.usersData = response.data;
        this.usersCounts.from = response.from?.toString() || '';
        this.usersCounts.to = response.to?.toString() || '';
        this.usersCounts.total = response.total?.toString() || '';
        this.pagesCount = response.last_page;
      })
      .catch((err: AxiosError) => {
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Получить название компании
   */
  static getCompanyName(company: Company[]): string {
    if (typeof company === 'undefined' || !company.length) {
      return '';
    }
    return company.map((item) => item.name).join(', ');
  }

  /**
   * Сбросить фильтр
   */
  private resetFilters(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.value = null;
    });
  }

  /**
   * Получить роли для таблицы
   */
  private getRole(roles: Array<string>): string {
    if (!roles || !roles.length || !this.rolesList) {
      return 'Нет роли';
    }
    let roleString = '';

    roles.forEach((role, index) => {
      const roleItem: Role|undefined = (this.rolesList as Role[])
        .find((roleListItem) => roleListItem.name === role);

      let separator = roles.length === 1 && index === 0 ? '' : ', ';

      if (index > 0 && index < (roles.length - 1)) {
        separator = ', ';
      } else if (index === (roles.length - 1)) {
        separator = '';
      }

      if (!roleItem) {
        return;
      }

      roleString += `${roleItem.title}${separator}`;
    });

    return roleString;
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик клика кнопки сбросить
   * @private
   */
  private clickResetFilterButtonHandler(): void {
    this.resetFilters();
    this.currentPage = 1;
    this.getUsers();
  }

  /**
   * обработчик клика по строке таблицы
   * @param user
   * @private
   */
  private clickTableRowHandler(user: UserInfoTableItem): void {
    this.$router.push({ name: 'user-edit', params: { id: user.id.toString() } });
  }

  /**
   * Обработчик загрузки фильтров
   */
  private loadedFilterHandler(): void {
    this.getUsers();
  }

  /**
   * Клик по кнопке экспорта
   * @private
   */
  private clickExportHandler(): void {
    this.exportUsers();
  }

  /**
   * Обработчик клика по кнопке поиска
   */
  private clickSearchFilterHandler(): void {
    this.currentPage = 1;
    this.getUsers();
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }
}
