
































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem, FormField, Permission, Role,
} from '@/types';
import { Route } from 'vue-router';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: { Breadcrumbs },
})
export default class RoleEdit extends Vue {
  private titleField: FormField = {
    value: '',
    name: 'title',
    label: '',
    error: '',
  }

  private nameField: FormField = {
    value: '',
    name: 'name',
    label: '',
    error: '',
  }

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список ролей',
      href: '/roles',
      disabled: false,
    },
    {
      text: 'Создание роли',
      href: '/',
      disabled: true,
    },
  ]

  private isEditPage = !!this.$route.params.id;

  private statusDialog = false;

  private rolePermissions: string[] = [];

  private permissionList: Array<Permission> = [];

  private allFields = [
    this.titleField,
    this.nameField,
  ];

  private hasChanges = false;

  private showDialogSave = false;

  private toUrl: Route|null = null;

  /**
   * Хук для обработки ухода со страницы
   * если эта страница редактирования и есть изменения нужно показать диалоговое окно
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.hasChanges || !this.isEditPage) {
      next();
    }
    this.toUrl = to;
    this.showDialogSave = true;
  }

  mounted(): void {
    if (this.isEditPage) {
      this.$roleApi.getRole(this.$route.params.id).then((response: Role) => {
        this.titleField.value = response.title;
        this.nameField.value = response.name;
        this.rolePermissions = response.permissions;
      });
    }
    this.$permissionApi.getList().then((response) => {
      this.permissionList = response;
    });
  }

  /**
   * Создание/обновление роли
   */
  private editRole(): void {
    const requestParams: Role = {
      name: this.nameField.value ? this.nameField.value : '',
      title: this.titleField.value ? this.titleField.value : '',
      permissions: this.rolePermissions,
    };

    this.clearError();

    if (this.isEditPage) {
      this.$roleApi.updateRole(requestParams).then(() => {
        this.$eventBus.$emit(this.$eventBus.EVENT_USER_INFO_CHANGE);
        this.hasChanges = false;
        if (this.toUrl) {
          const toUrlName = this.toUrl.name;

          if (toUrlName && typeof toUrlName !== 'undefined') {
            this.$router.push({ name: toUrlName });
          }
        }

        this.statusDialog = true;
      }).catch((error) => {
        if (typeof error.message !== 'undefined') {
          this.setError(error.message);
        }
        window.console.log(error);
      });
    } else {
      this.$roleApi.createRole(requestParams).then(() => {
        this.$eventBus.$emit(this.$eventBus.EVENT_USER_INFO_CHANGE);
        this.statusDialog = true;
      }).catch((error) => {
        if (typeof error.message !== 'undefined') {
          this.setError(error.message);
        }
        window.console.log(error);
      });
    }
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private clearError(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * Закрыть диалоговое окно
   */
  private closeDialogHandler(): void {
    this.statusDialog = false;

    this.$router.push({ name: 'role-list' });
  }

  /**
   * На изменения поля
   */
  private onChangeField(): void {
    this.hasChanges = true;
  }

  /**
   * Обработчик клика по не сохранять в диалоговм окне
   * @private
   */
  private clickNotSaveDialogButtonHandler(): void {
    if (!this.toUrl) {
      return;
    }
    this.hasChanges = false;
    const toUrlName = this.toUrl.name;

    if (toUrlName && typeof toUrlName !== 'undefined') {
      this.$router.push({ name: toUrlName });
    }
  }
}
