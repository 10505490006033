



import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AddressDetails, BaseField, HandbookFullDataResponse, SelectOption,
} from '@/types';
import tools from '@/tools';
import { HandbookCode } from '@/enums/HandbookList';

@Component({
  props: {
    handBooksData: {
      type: Array,
      required: true,
    },
    editSectionName: {
      type: String,
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    participantIsActive: {
      type: Boolean,
      required: true,
    },
    participantId: {
      type: String,
      required: true,
    },
  },
})

export default class ParticipantSection extends Vue {
  protected allFields: BaseField[] = [];

  protected participantId!: string;

  protected showNotSaveDialog = false;

  protected sectionName!: string;

  protected editSectionName!: string;

  protected statusBlock: 'edit'|'readonly' = 'readonly';

  protected handBooksData!: HandbookFullDataResponse;

  protected participantIsActive!: boolean;

  /**
   * Можно ли показать блок редактирования
   */
  protected get canShowEditBlock(): boolean {
    if (!this.participantIsActive) {
      return false;
    }

    return this.statusBlock === 'edit';
  }

  /**
   * Создать опции для поля
   */
  protected createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handBooksData || !this.handBooksData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handBooksData),
    );
  }

  /**
   * Обработчик клика по кнопке редактировать
   */
  protected clickGoToEditHandler(): void {
    if (this.editSectionName && this.editSectionName !== this.sectionName) {
      this.$eventBus.$emit(this.$eventBus.SHOW_ERROR_CANT_GO_TO_EDIT_PARTICIPANT_SECTION);
      return;
    }
    this.$eventBus.$emit(this.$eventBus.PARTICIPANT_SECTION_ACTIVATION_EDIT, this.sectionName);
    this.statusBlock = 'edit';
  }

  /**
   * Стригерить событие конца редактирования
   */
  protected emitEventEndEdit(): void {
    this.$eventBus.$emit(this.$eventBus.PARTICIPANT_SECTION_EDIT_END);
  }

  /**
   * получить адресс в виде текста
   */
  protected getTransformAddressInText(address: AddressDetails): string {
    if (!address) {
      return '';
    }
    return tools.getLabelFromAddressDetailType(address);
  }

  /**
   * Вернуть текст опции
   */
  protected getHandbookItemTitle(options: SelectOption[], value: string|number|null): string {
    if (!value && typeof value === 'object') {
      return '';
    }

    const option = options.find((option) => option.value.toString() === value.toString());

    if (typeof option === 'undefined') {
      return '';
    }

    return option?.text?.toString() || '';
  }

  /**
   * проскролить страницу к ошибке
   */
  protected scrollToError(): void {
    this.$nextTick(() => {
      tools.scrollTo('.has-error');
    });
  }

  /**
   * Сбросить ошибки
   */
  protected resetError(): void {
    this.allFields.forEach((field) => {
      const currentField = field;

      currentField.error = '';
    });
  }
}
