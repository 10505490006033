import { TProperty } from '@/types';

const properties: TProperty = {
  id: {
    headerItem: {
      text: 'ID',
      value: 'id',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'id',
      label: 'ID',
    },
  },
  first_name: {
    headerItem: {
      text: 'Имя',
      value: 'info.first_name',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'info.first_name',
      label: 'Имя',
    },
  },
  last_name: {
    headerItem: {
      text: 'Фамилия',
      value: 'info.last_name',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'info.last_name',
      label: 'Фамилия',
    },
  },
  second_name: {
    headerItem: {
      text: 'Отчество',
      value: 'info.second_name',
      class: 'custom-table-heading',
    },
    exportItem: {
      field: 'info.second_name',
      label: 'Отчество',
    },
  },
  category: {
    headerItem: {
      text: 'Категория',
      value: 'category',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'individual_category.category',
      label: 'Категория',
    },
  },
  polygon: {
    headerItem: {
      text: 'Полигон',
      value: 'polygon',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'organisation.polygon',
      label: 'Полигон',
    },
  },
  organization: {
    headerItem: {
      text: 'Организация',
      value: 'organisation',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'organisation.name',
      label: 'Организация',
    },
  },
  assistance_program: {
    headerItem: {
      text: 'Программа помощи',
      value: 'assistance_program',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'individual_category.assistance_program',
      label: 'Программа',
    },
  },
  dateOfBirth: {
    headerItem: {
      text: 'Дата рождения',
      value: 'birth',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.date_of_birth',
      label: 'Дата рождения',
    },
  },
  gender: {
    headerItem: {
      text: 'Пол',
      value: 'gender',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.gender',
      label: 'Пол',
    },
  },
  phone: {
    headerItem: {
      text: 'Телефон',
      value: 'info.phone',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.phone',
      label: 'Телефон',
    },
  },
  email: {
    headerItem: {
      text: 'Email',
      value: 'email',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.email',
      label: 'Email',
    },
  },
  status: {
    headerItem: {
      text: 'Статус',
      value: 'status',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'status',
      label: 'Статус',
    },
  },
  maritalStatus: {
    headerItem: {
      text: 'Семейное положение',
      value: 'marital_status',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.marital_status',
      label: 'Семейное положение',
    },
  },
  socialStatus: {
    headerItem: {
      text: 'Социальный статус',
      value: 'social_status',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.social_status',
      label: 'Социальный статус',
    },
  },
  education: {
    headerItem: {
      text: 'Образование',
      value: 'education',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'info.education',
      label: 'Образование',
    },
  },
  observationReason: {
    headerItem: {
      text: 'Группа диспансерного наблюдения',
      value: 'observation_reason',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.observation_reason',
      label: 'Группа диспансерного наблюдения',
    },
  },
  disabilityGroup: {
    headerItem: {
      text: 'Группа инвалидности',
      value: 'disability_group',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.disability_group',
      label: 'Группа инвалидности',
    },
  },
  disabilitySeries: {
    headerItem: {
      text: 'Серия и номер справки',
      value: 'disability_series',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: '{health_status.disability_series health_status.disability_number}',
      label: 'Серия и номер справки',
    },
  },
  pharmacologicalGroups: {
    headerItem: {
      text: 'Фармакологическая группа лекарственных средств',
      value: 'pharmacological_groups',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.pharmacological_groups',
      label: 'Фармакологическая группа лекарственных средств',
    },
  },
  medicationsTaken: {
    headerItem: {
      text: 'Принимаемые лекарственные средства',
      value: 'medication_taken',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.medications_taken',
      label: 'Принимаемые лекарственные средства',
    },
  },
  smokingFrequency: {
    headerItem: {
      text: 'Курение',
      value: 'smoking_frequency',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.smoking_frequency',
      label: 'Курение',
    },
  },
  alcoholConsumption: {
    headerItem: {
      text: 'Алкоголь',
      value: 'alcohol_consumption',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'health_status.alcohol_frequency',
      label: 'Алкоголь',
    },
  },
  physicalActivityFrequency: {
    headerItem: {
      text: 'Физическая активность',
      value: 'physical_activity',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'individual_category.physical_activity_group',
      label: 'Физическая активность',
    },
  },
  autonomyGroup: {
    headerItem: {
      text: 'Группа автономности',
      value: 'autonomy_group',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'individual_category.autonomy_group',
      label: 'Группа автономности',
    },
  },
  archiveReason: {
    headerItem: {
      text: 'Причина архивации',
      value: 'archive_reason',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'archive_reason',
      label: 'Причина архивации',
    },
  },
  programStartsAtFrom: {
    headerItem: {
      text: 'Дата начала программы',
      value: 'program.starts_at',
      class: 'custom-table-heading',
      sortable: true,
    },
    exportItem: {
      field: 'program.starts_at',
      label: 'Дата начала программы',
    },
  },

  programEndsAtTo: {
    headerItem: {
      text: 'Дата конца программы',
      value: 'program.ends_at',
      class: 'custom-table-heading',
      sortable: true,
    },
    exportItem: {
      field: 'program.ends_at',
      label: 'Дата конца программы',
    },
  },
  total_service_repetitions: {
    headerItem: {
      text: 'Услуги всего',
      value: 'program.total_service_repetitions',
      class: 'custom-table-heading',
      sortable: false,
    },
    exportItem: {
      field: 'program.total_service_repetitions',
      label: 'Услуги всего',
    },
  },

  total_completed_service_repetitions: {
    headerItem: {
      text: 'Услуги выполнено',
      value: 'program.total_completed_service_repetitions',
      class: 'custom-table-heading',
      sortable: true,
    },
    exportItem: {
      field: 'program.total_completed_service_repetitions',
      label: 'Услуги выполнено',
    },
  },
};

export default properties;
