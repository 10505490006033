
























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {
  AddressDetails, DetailMembers,
  FamilyMemberRequest,
  FormField, FormFieldRadioButton, FormFieldSelect,
  FormFieldWithAjaxSearch, HandbookFullDataResponse, RadioButtonOption, SelectOption,
} from '@/types';
import TextField from '@/components/form-fields/TextField.vue';
import DatePicker from '@/components/form-fields/DatePicker.vue';
import RadioButtonCustom from '@/components/form-fields/RadioButtonCustom.vue';
import SelectWithSearchAddress from '@/components/form-fields/SelectWithSearchAddress.vue';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import RowText from '@/components/RowText.vue';
import DetailedMembersTransform from '@/services/transform-response/DetailedMembers';
import DetailedInformation from '@/components/DetailedInformation.vue';

@Component({
  components: {
    DetailedInformation,
    CustomSelect,
    SelectWithSearchAddress,
    RadioButtonCustom,
    DatePicker,
    TextField,
    RowText,
  },
  props: {
    handBooksData: {
      type: Array,
      required: true,
    },
    participantResidenceAddress: {
      type: Object,
      required: true,
    },
    participantFloor: {
      type: Number,
      required: true,
    },
    formErrors: {
      type: Object,
      default: {},
    },
    index: {
      type: Number,
      required: true,
    },
    request: {
      type: Object,
      required: true,
    },
    participantIsActive: {
      type: Boolean,
      required: true,
    },
    canEditBlock: {
      type: Boolean,
      required: true,
    },
  },
})

export default class FamilyMemberForm extends Vue {
  private participantFloor!: number;

  private participantIsActive!: boolean;

  private participantResidenceAddress!: AddressDetails;

  private request!: FamilyMemberRequest;

  private index!: number;

  private defaultStringErrorKey = 'members';

  private handBooksData!: HandbookFullDataResponse;

  private formErrors!: {[key: string]: string|string[]};

  private residenceAddressMatchesParticipantResidenceAddress = false;

  private registryAddressMatchesResidenceAddress = false;

  private contactPerson: FormFieldRadioButton = {
    label: 'Контактное лицо',
    name: 'contact_person',
    error: '',
    options: [
      {
        value: '1',
        label: 'Да',
      },
      {
        value: '0',
        label: 'Нет',
      },
    ],
    required: false,
    value: this.request.contact_person ? 1 : 0,
  }

  private gender: FormFieldRadioButton = {
    label: 'Пол',
    name: 'gender',
    error: '',
    options: [],
    required: true,
    value: this.request.gender,
  }

  private floor: FormFieldRadioButton = {
    label: 'Этаж',
    error: '',
    name: 'floor',
    options: [],
    value: this.request.floor,
  }

  private residenceAddress: FormFieldWithAjaxSearch = {
    name: 'address',
    label: 'Город, улица, дом, квартира',
    error: '',
    value: null,
    placeholder: 'Введите адрес в свободной форме',
    readonly: false,
    search: '',
  }

  private registrationAddress: FormFieldWithAjaxSearch = {
    name: 'registration_address',
    label: 'Город, улица, дом, квартира',
    error: '',
    value: null,
    placeholder: 'Введите адрес в свободной форме',
    readonly: false,
    search: '',
  }

  private lastName: FormField = {
    name: 'last_name',
    label: 'Фамилия',
    placeholder: 'Фамилия',
    error: '',
    value: this.request.last_name,
    disabled: false,
    required: true,
  }

  private name: FormField = {
    name: 'first_name',
    label: 'Имя',
    placeholder: 'Имя',
    error: '',
    value: this.request.first_name,
    disabled: false,
    required: true,
  }

  private secondName: FormField = {
    name: 'second_name',
    label: 'Отчество',
    placeholder: 'Отчество',
    error: '',
    value: this.request.second_name,
    disabled: false,
    required: false,
  }

  private birthDay: FormField = {
    name: 'date_of_birth',
    label: 'Дата рождения',
    error: '',
    value: this.request.date_of_birth,
    disabled: false,
    required: true,
    mask: '##.##.####',
  }

  private telephone: FormField = {
    name: 'phone',
    error: '',
    placeholder: '7 123 456-78-91',
    value: this.request.phone,
    label: 'Контактный телефон',
    mask: '7-###-###-##-##',
  }

  private email: FormField = {
    name: 'email',
    error: '',
    placeholder: 'Введите e-mail',
    value: this.request.email,
    label: 'Адрес эл.почты',
  }

  private relationDegree: FormFieldSelect = {
    name: 'relation_degree',
    label: 'Кем приходится',
    required: true,
    error: '',
    value: this.request.relation_degree,
    options: [],
  }

  private renderFirstGroupFields = [
    this.lastName,
    this.name,
    this.secondName,
  ];

  private renderThirdGroupFields = [
    this.telephone,
    this.email,
  ]

  private allFields = [
    ...this.renderFirstGroupFields,
    ...this.renderThirdGroupFields,
    this.gender,
    this.floor,
    this.registrationAddress,
    this.residenceAddress,
    this.birthDay,
    this.contactPerson,
    this.relationDegree,
  ]

  private timeoutId: number|null = null;

  @Watch('formErrors')
  onFormErrorsChange(): void {
    this.setErrors();
  }

  @Watch('allFields', { deep: true })
  onFieldsChange(): void {
    this.timeoutId = window.setTimeout(() => {
      this.changeFieldsHandle();
    }, 500);
  }

  @Watch('residenceAddressMatchesParticipantResidenceAddress')
  onResidenceAddressMatchesParticipantAddressChange(val: boolean): void {
    if (val) {
      const label = tools.getLabelFromAddressDetailType(this.participantResidenceAddress);

      this.residenceAddress.value = this.participantResidenceAddress
        ? {
          value: this.participantResidenceAddress,
          label,
        }
        : null;
      this.residenceAddress.search = label;
      this.residenceAddress.readonly = true;
      this.floor.value = this.participantFloor;
      this.floor.readonly = true;
    } else {
      this.residenceAddress.readonly = false;
      this.residenceAddress.value = null;
      this.residenceAddress.search = '';
      this.floor.value = '';
      this.floor.readonly = false;
    }
  }

  /**
   * Наблюдать за сменой чекбокса на совпадения адреса регистрации с адресом проживания
   */
  @Watch('registryAddressMatchesResidenceAddress')
  onMatchesAddressCheckBoxChange(val: boolean): void {
    if (val) {
      this.registrationAddress.value = this.residenceAddress.value;
      this.registrationAddress.search = this.residenceAddress.value?.label
        ? this.residenceAddress.value?.label : '';
      this.registrationAddress.readonly = true;
    } else {
      this.registrationAddress.readonly = false;
      this.registrationAddress.value = null;
      this.registrationAddress.search = '';
    }
  }

  /**
   * Наблюдатель за сменой регистрации проживания
   */
  @Watch('residenceAddress.value')
  onResidenceAddressChange(): void {
    if (this.registryAddressMatchesResidenceAddress) {
      const search = this.residenceAddress.value?.label ? this.residenceAddress.value?.label : '';

      this.registrationAddress.value = this.residenceAddress.value;
      this.registrationAddress.search = search;
    }
  }

  created(): void {
    this.createFieldsOptions();
    this.initStartValues();
  }

  private get detailedData(): DetailMembers.Data|null {
    if (!this.request) {
      return null;
    }

    return DetailedMembersTransform.getDataFromDetailsView(
      this.request,
      this.handBooksData,
      'familyMembers',
    );
  }

  /**
   * заполнения стартовых значений
   */
  private initStartValues(): void {
    if (this.request.registration_address) {
      this.registrationAddress.value = {
        value: this.request.registration_address,
        label: tools.getLabelFromAddressDetailType(this.request.registration_address),
      };
      this.registrationAddress.search = tools
        .getLabelFromAddressDetailType(this.request.registration_address);
    }

    if (this.request.address) {
      this.residenceAddress.value = {
        value: this.request.address,
        label: tools.getLabelFromAddressDetailType(this.request.address),
      };
      this.residenceAddress.search = tools.getLabelFromAddressDetailType(this.request.address);
    }
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.floor.options = (this.createOptions(HandbookCode.FLOOR) as RadioButtonOption[]);
    this.gender.options = (this.createOptions(HandbookCode.GENDER) as RadioButtonOption[]);
    this.relationDegree.options = this.createOptions(HandbookCode.RELATION_DEGREE);
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handBooksData || !this.handBooksData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handBooksData),
    );
  }

  /**
   * проскролить страницу к ошибке
   */
  private scrollToError(): void {
    this.$nextTick(() => {
      tools.scrollTo('.has-error');
    });
  }

  /**
   * Установить ошибки
   * @private
   */
  private setErrors(): void {
    this.allFields.forEach((field) => {
      const currentField = field;
      const { name } = currentField;

      if (name.includes('address')) {
        const errorsKey = Object.keys(this.formErrors).filter((key) => {
          const currentKey = key.replace(`${this.defaultStringErrorKey}.`, '');
          const keys = currentKey.split('.');

          if (typeof keys[1] === 'undefined' || !keys.length) {
            return false;
          }

          return keys[1] === name;
        });

        if (typeof errorsKey === 'undefined' || !errorsKey.length) {
          return;
        }

        const errors = errorsKey.map((key) => {
          const error = this.formErrors[key];
          if (typeof error === 'string') {
            return this.formErrors[key];
          }
          return error.join(' ');
        });

        currentField.error = errors.length
          ? 'Проверьте формат обязателен индекс, регион, город, дом, кв' : '';
        return;
      }

      const error = this.formErrors[`${this.defaultStringErrorKey}.${this.index}.${name}`];

      if (typeof error === 'undefined') {
        currentField.error = '';
        return;
      }
      currentField.error = error;
    });

    this.scrollToError();
  }

  /**
   * Создать изменения полей
   * @private
   */
  private createChangeField(): FamilyMemberRequest {
    const { id } = this.request;

    const requestWithoutId: FamilyMemberRequest = {
      first_name: this.name.value || '',
      last_name: this.lastName.value || '',
      second_name: this.secondName.value || '',
      date_of_birth: this.birthDay.value || '',
      gender: (this.gender.value as number),
      address: this.residenceAddress.value ? this.residenceAddress.value.value : null,
      registration_address: this.registrationAddress.value
        ? this.registrationAddress.value.value : null,
      floor: (this.floor.value as number),
      phone: this.telephone.value,
      email: this.email.value,
      relation_degree: this.relationDegree.value ? +this.relationDegree.value : null,
      contact_person: !!this.contactPerson.value,
    };

    if (typeof id !== 'undefined') {
      return {
        ...requestWithoutId,
        id,
      };
    }

    return {
      ...requestWithoutId,
    };
  }

  /**
   * Обработчик изменения полей
   * @private
   */
  private changeFieldsHandle(): void {
    this.$emit('change-family-member', { index: this.index, data: this.createChangeField() });
  }
}

