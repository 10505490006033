






















































































import Vue from 'vue';
import Component from 'vue-class-component';
import getFormFieldLabels from '@/enums/FormFieldLabels';
import {
  UserInfo, VersionData, Diff,
} from '@/types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

@Component({
  props: {
    versionsData: {
      type: Object,
      required: true,
    },
  },
})

export default class VersionsBar extends Vue {
  private selected: number|null = null;

  private isOpen = true;

  private versionsData!: VersionData;

  private getName(user: UserInfo): string {
    const { name, last_name } = user;

    return `${last_name || ''} ${name || ''}`;
  }

  private getDate(date: string): string {
    return dayjs(date).format('DD MMMM YYYY');
  }

  /**
   * Получить лейбл поля по его имени с бэка
   */
  private getFieldLabel(name: string): string {
    const field = this.versionsData.allFields.find((field) => field.name === name);

    if (name === 'mailing_address') {
      return '';
    }

    if (name.includes('address')) {
      return getFormFieldLabels(name);
    }

    return field?.label || '';
  }

  private clickVersion(diffs: Diff[]): void {
    this.$eventBus.$emit(this.$eventBus.VERSION_ITEM_CLICK, {
      type: this.versionsData.type,
      payload: diffs,
    });
  }

  /**
   * Закрыть панель
   */
  private closePanel(): void {
    this.isOpen = false;
    this.$eventBus.$emit(this.$eventBus.VERSION_PANEL_CLOSE);
  }
}

