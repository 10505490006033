import tools from '@/tools';
import { Export } from '@/types/export';

/**
 * Класс по работе с экспортом
 */
export class ExportApi {
  /**
   * Проверяем статус экспорта
   * @param requestParams
   */
  public getStatus(
    requestParams: Export.StatusRequest,
  ): Promise<Export.StatusResponse> {
    return tools.apiRequest(
      'get',
      'export/status',
      requestParams,
      false,
      {},
    ) as Promise<Export.StatusResponse>;
  }

  /**
   * Скачивание файла
   * @param requestParams
   */
  public getFile(
    requestParams: Export.FileRequest,
  ): Promise<BlobPart> {
    return tools.apiRequest(
      'get',
      'export/download',
      requestParams,
      false,
      {},
      'blob',
      true,
    ) as Promise<BlobPart>;
  }
}
const exportApi = new ExportApi();
export default exportApi;
