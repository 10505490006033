import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-sm-8"},[(!_vm.loadingStartConfig)?[(_vm.handbookName)?_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbsItems}}):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('h1',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Справочник: "+_vm._s(_vm.handbookName))])]),(!_vm.loading)?_c('section',{staticClass:"mt-8"},[_c(VRow,{staticClass:"mb-8",attrs:{"align":"end","no-gutters":""}},[(_vm.canManageHandbook)?_c(VBtn,{staticClass:"text-capitalize white--text",attrs:{"depressed":"","color":"light-blue lighten-1 blue","height":"40"},on:{"click":function($event){return _vm.$router.push({
        name: 'handbooksValueAdd',
       params: {id: _vm.handbookId}
        })}}},[_vm._v(" Добавить ")]):_vm._e()],1),_c(VDataTable,{staticStyle:{"border":"1px solid #BFC5CF"},attrs:{"hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.handbookData,"item-key":"id","items-per-page":_vm.handbookData.length},on:{"click:row":_vm.clickTableRowHandler},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.canManageHandbook)?_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{attrs:{"title":"Изменить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){return _vm.clickEditValueHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"title":"Удалить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDeleteIconHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}],null,false,284206629)})],1):[_c('div',{staticClass:"mt-8 d-flex align-center justify-center flex-grow-1"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":true}})],1)]]:[_c('div',{staticClass:"mt-8 d-flex align-center justify-center flex-grow-1"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":true}})],1)],_c(VDialog,{attrs:{"max-width":"420"},model:{value:(_vm.deleteUserDialog),callback:function ($$v) {_vm.deleteUserDialog=$$v},expression:"deleteUserDialog"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-h4 black--text"},[_vm._v(" Вы уверены, что хотите удалить элемент? ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickAcceptDeleteValueHandler}},[_vm._v("Да ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickDeleteValueDialogCloseHandler}},[_vm._v("Нет ")])],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }