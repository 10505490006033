import tools from '@/tools';
import {
  LogListRequest,
  LogListResponse,
  LogRecord,
} from '@/types';

export default class LogApi {
  /**
   * Получить список событий
   */
  public getList(requestParams: LogListRequest): Promise<LogListResponse> {
    return tools.apiRequest(
      'get',
      'logs',
      requestParams,
    ) as Promise<LogListResponse>;
  }

  /**
   * Получить данные события
   */
  public getItem(id: number): Promise<LogRecord> {
    return tools.apiRequest(
      'get',
      `logs/${id}`,
    ) as Promise<LogRecord>;
  }
}
