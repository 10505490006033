











































































































import Component from 'vue-class-component';
import {
  FormField, SuccessResponse, ErrorResponse,
  HandbookValueItem, BreadcrumbsItem,
} from '@/types';
import { AxiosResponse } from 'axios';
import { Route } from 'vue-router';
import WithHandbooks from '@/abstract/WithHandbooks';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    Breadcrumbs,
  },
})

export default class HandbookValueDetail extends WithHandbooks {
  private name: FormField = {
    name: 'name',
    label: 'Название',
    value: '',
    error: '',
  }

  private code: FormField = {
    name: 'code',
    label: 'Код',
    value: '',
    error: '',
  }

  private renderFields = [
    this.name,
    this.code,
  ];

  private hasChanges = false;

  private showDialog = false;

  private toUrl: Route|null = null;

  /**
   * Хук для обработки ухода со страницы
   * если эта страница редактирования и есть изменения нужно показать диалоговое окно
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.hasChanges || !this.valueId) {
      next();
    }
    this.toUrl = to;
    this.showDialog = true;
  }

  mounted(): void {
    super.mounted();
    if (this.valueId) {
      this.getValueData();
    }
  }

  private get handbookName(): string {
    if (!this.handbookFullData) {
      return '';
    }

    const item = this.handbookFullData.find((item) => item.id.toString() === this.handbookId);

    if (typeof item === 'undefined') {
      return '';
    }

    return item.name;
  }

  private get breadcrumbsItems(): BreadcrumbsItem[] {
    return [
      {
        text: 'Главная',
        href: '/',
        disabled: false,
      },
      {
        text: 'Список справочников',
        href: '/handbooks',
        disabled: false,
      },
      {
        text: 'Детальная справочника',
        href: `/handbooks/${this.handbookId}`,
        disabled: false,
      },
      {
        text: `${this.handbookName}: Добавление данных`,
        href: '/',
        disabled: true,
      },
    ];
  }

  /**
   * Получить данные значения справочника
   */
  private getValueData(): void {
    this.$handbookApi.getHandbookValueInfo({ id: this.valueId })
      .then((response: HandbookValueItem) => {
        this.name.value = response.name;
        this.code.value = response.code;
      })
      .catch((err: AxiosResponse) => {
        const { status } = err;
        if (status === 400) {
          this.$router.push({ name: 'handbooksValuesList', params: { id: this.handbookId } });
        }
      });
  }

  /**
   * Создать значение справочника
   */
  private createValue(): Promise<SuccessResponse> {
    return new Promise((resolve) => {
      this.clearError();
      this.$handbookApi.createHandbookValue({
        handbook_id: this.handbookId,
        code: this.code.value || '',
        name: this.name.value || '',
      }).then((response: SuccessResponse) => {
        resolve(response);
      })
        .catch((err: ErrorResponse) => {
          if (typeof err.message === 'undefined') {
            window.console.log(err);
            return;
          }
          this.setError(err.message);
        });
    });
  }

  /**
   * Обновить значения справочника
   */
  private updateValue(): Promise<SuccessResponse> {
    return new Promise((resolve) => {
      this.clearError();
      this.$handbookApi.updateHandbookValue({
        handbook_id: this.handbookId,
        code: this.code.value || '',
        name: this.name.value || '',
        id: this.valueId,
      }).then((response: SuccessResponse) => {
        this.hasChanges = false;
        resolve(response);
      })
        .catch((err: ErrorResponse) => {
          if (typeof err.message === 'undefined') {
            window.console.log(err);
            return;
          }
          this.setError(err.message);
        });
    });
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private clearError(): void {
    this.renderFields.forEach((field) => {
      const currentField = field;

      currentField.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.renderFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * id справочника
   * @private
   */
  private get handbookId(): string {
    return this.$route.params.id;
  }

  /**
   * id значения справочника
   * @private
   */
  private get valueId(): string {
    return this.$route.params.valueId;
  }

  /**
   * Отправится к списку справочников
   * @private
   */
  private goToHandbooksList(): void {
    this.$router.push({ name: 'handbooksValuesList', params: { id: this.handbookId } });
  }

  /**
   * Клик на кнопку сохранения справочника
   * @private
   */
  private clickSaveButtonHandler(): void {
    if (this.valueId) {
      this.updateValue()
        .then(() => {
          this.goToHandbooksList();
        });
      return;
    }
    this.createValue()
      .then(() => {
        this.goToHandbooksList();
      });
  }

  /**
   * Клик по кнопке сохранить в диалоговом окне
   */
  private clickSaveButtonInDialogHandler(): void {
    this.updateValue()
      .then(() => {
        if (!this.toUrl) {
          return;
        }
        const toUrlName = this.toUrl.name;

        if (toUrlName && typeof toUrlName !== 'undefined') {
          this.$router.push({ name: toUrlName });
        }
      });
  }

  /**
   * Клик на кнопку применить
   * @private
   */
  private clickApplyButtonHandler(): void {
    if (this.valueId) {
      this.updateValue();
      return;
    }
    this.createValue()
      .then(() => {
        this.name.value = '';
        this.code.value = '';
      });
  }

  /**
   * Клик на кнопку отмены
   * @private
   */
  private clickCancelButtonHandler(): void {
    this.goToHandbooksList();
  }

  /**
   * Обработчик клика по не сохранять в диалоговм окне
   * @private
   */
  private clickNotSaveDialogButtonHandler(): void {
    if (!this.toUrl) {
      return;
    }
    this.hasChanges = false;
    const toUrlName = this.toUrl.name;

    if (toUrlName && typeof toUrlName !== 'undefined') {
      this.$router.push({ name: toUrlName });
    }
  }

  /**
   * на изменения поля
   * @private
   */
  private onChangeField(): void {
    this.hasChanges = true;
  }
}

