


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BreadcrumbsItem } from '@/types';

@Component({})
export default class Breadcrumbs extends Vue {
  @Prop({
    required: true,
    type: Array,
  }) private items!: BreadcrumbsItem[];
}
