



























































































import Vue from 'vue';
import Component from 'vue-class-component';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import {
  HandbookItem, FormField, HandbookListResponse, BreadcrumbsItem,
} from '@/types';
import { DataTableHeader } from 'vuetify';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import FormFieldTypeEnum from '@/enums/FormField';
import DataFilters from '@/components/DataFilters.vue';
import FiltersEntityEnum from '@/enums/FiltersEntity';

@Component({
  components: {
    DataFilters,
    Breadcrumbs,
  },
})

export default class HandbookList extends Vue {
  private showFilter = true;

  private loading = true;

  private handbookData: HandbookItem[]|null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список справочников',
      href: '/',
      disabled: true,
    },
  ]

  private name: FormField = {
    value: '',
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Название',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private allFields: FormField[] = [
    this.name,
  ]

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      width: '7%',
      class: 'custom-table-heading',
    },
    {
      text: 'Дата изменения',
      value: 'date',
      width: '10%',
      class: 'custom-table-heading',
    },
    {
      text: 'Название',
      value: 'name',
      width: '50%',
      class: 'custom-table-heading',
    },
    {
      text: 'Значение(шт.)',
      value: 'values_count',
      class: 'custom-table-heading',
    },
    {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false,
      class: 'custom-table-heading',
    },
  ];

  /**
   * Получить индефикацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.HANDBOOKS;
  }

  /**
   * Трансформация даты
   */
  private transformHandbooksCreatedValueDate(date: string|null): string {
    if (!date) {
      return 'Значений нет';
    }

    return `
      <span>
       ${dayjs(date).format('DD.MM.YYYY')}
      </span><br>
      <span>
        ${dayjs(date).format('HH:mm:ss')}
      </span>
    `;
  }

  /**
   * Количество справочников
   */
  private get handbooksCounts(): number {
    if (!this.handbookData?.length) {
      return 0;
    }
    return this.handbookData.length;
  }

  /**
   * Получить список справочников
   */
  private getHandbooks(): void {
    this.loading = true;
    this.$handbookApi.getList({ name: this.name.value ? this.name.value : '' })
      .then((response: HandbookListResponse) => {
        this.handbookData = response;
      })
      .catch((err: AxiosError) => {
        window.console.log('test');
        window.console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Клик по кнопке фильтров
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Отправить фильтры
   */
  private clickSubmitHandler(): void {
    this.getHandbooks();
  }

  /**
   * Обработчик клика по кнопке сбросить
   * @private
   */
  private clickResetFormButtonHandler(): void {
    this.name.value = '';
    this.getHandbooks();
  }

  /**
   * Загрузка фильтров
   * @private
   */
  private loadedFilterHandler(): void {
    this.getHandbooks();
  }
}
