export enum StatusCode {
  DRAFT = 1,
  ACTIVE = 2,
  ARCHIVE = 3,
}

export enum StatusText {
  DRAFT = 'Черновик',
  ACTIVE = 'Активный участник',
  ARCHIVE = 'Архивный',
}
