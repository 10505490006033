import tools, { Tools } from '@/tools';
import Vue from 'vue';

import AuthApi from '@/api/auth';
import ErrorApi from '@/api/errorApi';
import EventBus from '@/api/event-bus';
import FiltersApi from '@/api/filtersApi';
import HandbookApi from '@/api/handbookApi';
import LogApi from '@/api/logApi';
import OrganizationApi from '@/api/organization';
import ParticipantApi from '@/api/participantApi';
import PermissionApi from '@/api/permission';
import ProgramApi from '@/api/programApi';
import RoleApi from '@/api/role';
import ServiceApi from '@/api/serviceApi';
import StatisticApi from '@/api/statisticApi';
import Users from '@/api/users';
import exportApi, { ExportApi } from '@/api/exportApi';
import HelpApi from '@/api/HelpApi';
import StorageApi from '@/api/StorageApi';

declare module 'vue/types/vue' {
    interface Vue {
        $authApi: AuthApi,
        $errorApi: ErrorApi,
        $eventBus: EventBus,
        $filtersApi: FiltersApi,
        $handbookApi: HandbookApi,
        $logApi: LogApi,
        $organizationApi: OrganizationApi
        $participantApi: ParticipantApi,
        $permissionApi: PermissionApi,
        $programApi: ProgramApi,
        $roleApi: RoleApi,
        $serviceApi: ServiceApi,
        $statisticApi: StatisticApi,
        $tools: Tools,
        $users: Users,
        $exportApi: ExportApi,
        $helpAPi: HelpApi,
        $storage: StorageApi,
    }
}

const eventBus = new EventBus(); // должен инициализироваться в единственном экземпляре

const errorApi = new ErrorApi();

Vue.prototype.$authApi = new AuthApi();
Vue.prototype.$errorApi = errorApi;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$filtersApi = new FiltersApi();
Vue.prototype.$handbookApi = new HandbookApi();
Vue.prototype.$logApi = new LogApi();
Vue.prototype.$organizationApi = new OrganizationApi();
Vue.prototype.$participantApi = new ParticipantApi();
Vue.prototype.$permissionApi = new PermissionApi();
Vue.prototype.$programApi = new ProgramApi();
Vue.prototype.$roleApi = new RoleApi();
Vue.prototype.$serviceApi = new ServiceApi();
Vue.prototype.$statisticApi = new StatisticApi();
Vue.prototype.$users = new Users();
Vue.prototype.$tools = tools;
Vue.prototype.$exportApi = exportApi;
Vue.prototype.$helpAPi = new HelpApi();
Vue.prototype.$storage = new StorageApi();
