import { Role, WithId } from '@/types';
import tools from '@/tools';

export default class RoleApi {
  /**
   * Создать роль
   * @param requestParams
   * @return {Promise<Role>}
   */
  public createRole(
    requestParams: Role,
  ): Promise<Role> {
    return tools.apiRequest(
      'post',
      'role/create',
      requestParams,
    ) as Promise<Role>;
  }

  /**
   * Редактировать роль
   * @param requestParams
   * @return {Promise<Role>}
   */
  public updateRole(
    requestParams: Role,
  ): Promise<Role> {
    return tools.apiRequest(
      'post',
      'role/update',
      requestParams,
    ) as Promise<Role>;
  }

  /**
   * Получить данные роли
   * @param id
   * @return {Promise<Role>}
   */
  public getRole(
    id: string,
  ): Promise<Role> {
    return tools.apiRequest(
      'get',
      `role/info/${id}`,
    ) as Promise<Role>;
  }

  /**
   * Получить список ролей
   * @return {Promise<Role[]>}
   */
  public getRoleList(): Promise<Array<Role & WithId>> {
    return tools.apiRequest(
      'get',
      'role/list',
    ) as Promise<Array<Role & WithId>>;
  }
}
