










































import Vue from 'vue';
import Component from 'vue-class-component';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { DataTableHeader } from 'vuetify';
import { BreadcrumbsItem, Role } from '@/types';

@Component({
  components: {
    Breadcrumbs,
  },
})
export default class RoleList extends Vue {
  private roleList: Array<Role> = [];

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список ролей',
      href: '/',
      disabled: true,
    },
  ]

  private headers: Array<DataTableHeader> = [
    {
      text: 'Название',
      value: 'title',
      class: 'custom-table-heading',
    },
    {
      text: 'Код',
      value: 'name',
      class: 'custom-table-heading',
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ];

  mounted(): void {
    this.$roleApi.getRoleList().then((response) => {
      this.roleList = response;
    });
  }

  /**
   * Клик по строке таблицы
   * @param item
   */
  private rowClick(item: Role): void {
    this.$router.push({
      name: 'role-edit',
      params: {
        id: item.name,
      },
    });
  }
}
