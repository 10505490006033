





















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  FormField,
  FrequencyDate,
  FrequencyOptions,
  SelectOption,
  WithFrequency,
} from '@/types';
import { Frequency } from '@/enums/Frequency';
import FrequencyItem from '@/components/modals/FrequencyItem.vue';
import DateField from '@/components/form-fields/DateField.vue';
import tools from '@/tools';
import { Program } from '@/types/program';

@Component({
  components: {
    DateField,
    FrequencyItem,
  },
})

export default class FrequencySetting extends Vue {
  @Prop({
    required: true,
  }) private readonly frequencyData!: Program.ServiceFrequency;

  @Prop() private readonly minDate!: string;

  @Prop() private readonly maxDate!: string;

  @Prop({
    default: true,
  }) private readonly hasTiming!: boolean;

  private frequencyType: FormField = {
    label: '',
    name: 'frequency_type',
    error: '',
    value: null,
    options: [
      {
        value: Frequency.Type.DAILY,
        label: 'Ежедневно',
      },
      {
        value: Frequency.Type.WEEKLY,
        label: 'Еженедельно',
      },
      {
        value: Frequency.Type.MONTHLY,
        label: 'Ежемесячно',
      },
      {
        value: Frequency.Type.YEARLY,
        label: 'Ежегодно',
      },
      {
        value: Frequency.Type.NO_REPEAT,
        label: 'Не повторять',
      },
    ],
  }

  private frequency: FormField = {
    name: 'frequency',
    error: '',
    label: this.frequencyLabel,
    options: this.frequencyOptions,
    value: null,
  }

  private frequencyDaysOptions: SelectOption[] = [
    'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс',
  ].map((item, index) => ({
    value: `${index}`,
    label: item,
  }));

  private frequencyDays = [] as Array<string>;

  private frequencyDateType: Frequency.DateType = this.detectedDateType;

  private frequencyDateTypes: Array<Frequency.DateType> = Object.values(Frequency.DateType);

  private defaultFrequencyDate: FrequencyDate = {
    n: '1',
    month: '1',
    day_of_week: '1',
    day_of_month: '1',
  };

  private frequencyDates: Record<Frequency.DateType, Array<FrequencyDate>> = {
    [Frequency.DateType.DAY]: [this.defaultFrequencyDate],
    [Frequency.DateType.DAY_OF_WEEK]: [this.defaultFrequencyDate],
    [Frequency.DateType.DAY_IN_MONTH]: [this.defaultFrequencyDate],
    [Frequency.DateType.DAY_OF_WEEK_IN_MONTH]: [this.defaultFrequencyDate],
  };

  private serviceStartsAt: FormField = {
    name: 'starts_at',
    value: '',
    error: '',
  };

  private serviceEndsAt: FormField = {
    name: 'starts_at',
    value: '',
    error: '',
  };

  private completionType = '';

  private repetitionOptions = tools.createNumericSelectOptions(10);

  private repetitions = '1';

  private hasStartWithStartProgram = true;

  private isOpenStartCalendar = false;

  created(): void {
    this.hasStartWithStartProgram = this.frequencyData.starts_with_program_start;
  }

  /**
   * Ограничение минимальной даты
   */
  private get computedMinDate(): string {
    return this.serviceStartsAt.value
      || this.minDate
      || '';
  }

  /**
   * Ограничение максимальной даты
   */
  private get computedMaxDate(): string {
    return this.serviceEndsAt.value
      || this.maxDate
      || '';
  }

  /**
   * Получить лейбл периодичности, зависит от типа периодичности
   */
  private get frequencyLabel(): string {
    const value: unknown = this.frequencyType.value as unknown;

    if (!value) {
      return '';
    }

    switch (value) {
      case Frequency.Type.DAILY:
        return 'день';
      case Frequency.Type.WEEKLY:
        return 'неделю';
      case Frequency.Type.MONTHLY:
        return 'месяц';
      case Frequency.Type.YEARLY:
        return 'год';
      default:
        return '';
    }
  }

  /**
   * Получить настройки периодичности, зависит от типа периодичности
   */
  private get frequencyOptions(): SelectOption[] {
    const { value } = this.frequencyType;

    if (!value) {
      return [];
    }

    switch (value as Frequency.Type) {
      case Frequency.Type.DAILY:
        return tools.createNumericSelectOptions(7);
      case Frequency.Type.WEEKLY:
        return tools.createNumericSelectOptions(5);
      case Frequency.Type.MONTHLY:
        return tools.createNumericSelectOptions(12);
      case Frequency.Type.YEARLY:
        return tools.createNumericSelectOptions(5);
      default:
        return [{
          value: '1',
          label: '1',
        }];
    }
  }

  /**
   * Показать ли настройки периодичности
   */
  private get isFrequencySettingsVisible(): boolean {
    return this.frequencyType.value !== Frequency.Type.NO_REPEAT;
  }

  /**
   * Выбрано еженедельное повторение
   */
  private get isWeekly(): boolean {
    return this.frequencyType.value === Frequency.Type.WEEKLY;
  }

  /**
   * Выбрано ежемесячное повторение
   */
  private get isMonthly(): boolean {
    return this.frequencyType.value === Frequency.Type.MONTHLY;
  }

  /**
   * Выбрано ежегодное повторение
   */
  private get isYearly(): boolean {
    return this.frequencyType.value === Frequency.Type.YEARLY;
  }

  /**
   * Выбран тип даты для ежегодного повторения
   */
  private isMatchingDateType(dateType: Frequency.DateType): boolean {
    const isMonthlyMatch = this.isMonthly && [
      Frequency.DateType.DAY,
      Frequency.DateType.DAY_OF_WEEK,
    ].includes(dateType);
    const isYearlyMatch = this.isYearly && [
      Frequency.DateType.DAY_IN_MONTH,
      Frequency.DateType.DAY_OF_WEEK_IN_MONTH,
    ].includes(dateType);
    return isMonthlyMatch || isYearlyMatch;
  }

  /**
   * Тип даты, определяемый по первой дате из массива
   * (нужен для выбора правильного радиобаттона при загрузке)
   */
  private get detectedDateType(): Frequency.DateType {
    let typeName = 'nthDay';
    const dates = this.frequencyData.frequency_options?.dates;
    if (dates && dates.length) {
      const sampleDate = dates[0];
      if (sampleDate.day_of_week) {
        typeName = `${typeName}OfWeek`;
      }
      if (sampleDate.month) {
        typeName = `${typeName}InMonth`;
      }
    }
    return typeName as Frequency.DateType;
  }

  /**
   * Тип завершения программы
   * (для выбора правильного радиобаттона при загрузке)
   */
  private get detectedCompletionType(): string {
    if (this.frequencyData.ends_at) {
      return 'date';
    }
    if (this.frequencyData.ends_after_repetitions) {
      return 'repetitions';
    }
    return 'none';
  }

  mounted(): void {
    this.frequencyType.value = this.frequencyData.frequency_type?.toString() ?? null;
    this.frequency.value = (this.frequencyData.frequency ?? '').toString();
    this.serviceStartsAt.value = this.frequencyData.starts_at || '';
    this.serviceEndsAt.value = this.frequencyData.ends_at || '';
    this.repetitions = this.frequencyData.ends_after_repetitions || '';

    this.completionType = this.detectedCompletionType;

    const days = this.frequencyData.frequency_options?.days;
    const dates = this.frequencyData.frequency_options?.dates;
    if (days) {
      this.frequencyDays = days;
    }
    if (dates) {
      this.frequencyDates[this.frequencyDateType] = dates;
    }
  }

  /**
   * Обработчик изменения типа периодичности
   */
  private onFrequencyTypeChange(): void {
    const currentValue = this.frequencyOptions[0]?.value || '';
    this.frequency.value = currentValue.toString();
    this.frequencyDays = [];
    this.changeFrequencySettingsHandler();
  }

  /**
   * Форматирование настроек повторений в соответствии с выбранным типом повторений
   */
  private get frequencyOptionsValue(): FrequencyOptions | null {
    const type = this.frequencyType.value;
    if (type === Frequency.Type.WEEKLY) {
      return { days: this.frequencyDays };
    }
    if (type === Frequency.Type.MONTHLY || type === Frequency.Type.YEARLY) {
      return { dates: this.frequencyDates[this.frequencyDateType] };
    }
    return null;
  }

  /**
   * Клик на сохранить
   * @private
   */
  private save(): void {
    const params: WithFrequency & {
      starts_at: string,
      ends_at: string | null,
      ends_after_repetitions: string | null,
      starts_with_program_start: boolean,
    } = {
      frequency_type: this.frequencyType.value,
      frequency: this.frequency.value || '1',
      frequency_options: this.frequencyOptionsValue,
      starts_at: this.serviceStartsAt.value || '',
      ends_at: null,
      ends_after_repetitions: null,
      starts_with_program_start: this.hasStartWithStartProgram,
    };
    const { completionType } = this;
    if (completionType === 'date') {
      params.ends_at = this.serviceEndsAt.value;
    }
    if (completionType === 'repetitions') {
      params.ends_after_repetitions = this.repetitions;
    }
    this.$emit('click-save', params);
  }

  /**
   * Клик на отменить
   * @private
   */
  private cancel(): void {
    this.$emit('click-cancel');
  }

  /**
   * На фокус в поле даты начала
   * @private
   */
  private onFocusDateStartField(): void {
    // снимаем визуально чекбокс по бизнес логике
    this.hasStartWithStartProgram = false;
  }

  /**
   * На изменения даты начала
   * @private
   */
  private onChangeDateStartField(): void {
    this.changeFrequencySettingsHandler();
    this.$nextTick(() => {
      this.hasStartWithStartProgram = this.serviceStartsAt.value === this.minDate;
    });
  }

  /**
   * Клик на чекбокс совпадает с началом программы
   * @private
   */
  private onClickStartWithProgram(): void {
    this.changeFrequencySettingsHandler();
    if (!this.hasStartWithStartProgram) {
      this.isOpenStartCalendar = true;
      return;
    }

    this.isOpenStartCalendar = false;
    this.serviceStartsAt.value = this.minDate;
  }

  /**
   * На закрытие выбора даты в календари старта
   * @private
   */
  private onCloseDatePickerStartMenu(): void {
    this.isOpenStartCalendar = false;
    this.hasStartWithStartProgram = this.serviceStartsAt.value === this.minDate;
  }

  /**
   * Обработчик изменения программы
   * @private
   */
  private changeFrequencySettingsHandler(): void{
    this.$emit('change-frequency-setting');
  }
}
