































import Vue from 'vue';
import Component from 'vue-class-component';
import DoughnutChart from '@/components/chartjs/DoughnutChart.vue';
import { ParticipantsDistributionName } from '@/enums/Statistics';
import Chart from 'chart.js';

@Component({
  components: { DoughnutChart },
  props: {
    dataType: {
      type: String,
      default: 'default',
    },
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isRow: {
      type: Boolean,
      default: false,
    },
  },
})

export default class DoughnutWrapper extends Vue {
  private defaultColors = ['#10A1DC', '#197EB1', '#BFC5CF', '#1B4358',
    '#2e7396', '#3942B7', '#61C4D7', '#0B0F70', '#251D60', '#1735C2', '#46AAAC', '#005E65',
    '#052D34', '#161EA9', '#0091BC', '#59788C', '#1C436C'];

  private genderColors = ['#197EB1', '#FF9AAF'];

  private doubleColors = ['#FF9AAF', '#197EB1'];

  private data!: Record<string, number>;

  private dataType!: string;

  /**
   * Получить текст легенд
   * @private
   */
  private get legendItems(): Array<string> {
    return Object.keys(this.data);
  }

  /**
   * Получить лейблы для графика
   * @private
   */
  private get chartLabels(): Array<string> {
    return this.legendItems.map((legend) => `${this.getLegendsText(legend)}`);
  }

  /**
   * Получить данные для графика
   * @private
   */
  private get chartData(): Chart.ChartData {
    return {
      labels: this.chartLabels,
      datasets: [
        {
          data: this.dataForChart,
          backgroundColor: this.getBackgroundColor(),
        },
      ],
    };
  }

  /**
   * Преобразовать данные для графика
   * @private
   */
  private get dataForChart(): Array<number> {
    return Object.values(this.data).map((value) => parseFloat(value.toFixed(1)));
  }

  /**
   * Получить цвет по индексу
   * @param index
   * @private
   */
  private getColor(index: number): string {
    const colors = this.getBackgroundColor();

    if (typeof colors[index] === 'undefined') {
      return '';
    }

    return colors[index];
  }

  /**
   * Получить текст для легенд
   * @param key
   * @private
   */
  private getLegendsText(key: string): string {
    const hasKey = Object.prototype.hasOwnProperty.call(ParticipantsDistributionName, key);
    if (!hasKey) {
      return `${key}`;
    }
    return ParticipantsDistributionName[key];
  }

  /**
   * Получить цвет фона для сегментов
   * @private
   */
  private getBackgroundColor(): string[] {
    switch (this.dataType) {
      case 'gender':
        return this.genderColors;
      case 'double':
        return this.doubleColors;
      default:
        return this.defaultColors;
    }
  }
}
