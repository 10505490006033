/**
 * Класс хелпер для работы с экспортом
 */
import { ExportParams, TProperty } from '@/types';

interface GetExportsParamsFunction {
  <T extends TProperty, K extends keyof T>(properties: T, keys: K[]|null): ExportParams,
}

export default class ExportsHelper {
  /**
   * Собрать параметры для экспорта в файл по списку ключей,
   * и модельки значений
   * @param properties
   * @param keys
   */
  static getExportParams: GetExportsParamsFunction = (properties, keys) => {
    const fields: Array<string> = [];
    const labels: Array<string> = [];

    if (!keys || !keys.length) {
      return {
        _fields: '',
        _labels: '',
      };
    }

    Object.keys(properties).forEach((key) => {
      if ((<string[]>keys).indexOf(key) !== -1) {
        const { exportItem } = properties[key];
        fields.push(exportItem.field);
        labels.push(exportItem.label);
      }
    });

    return {
      _fields: fields.join(','),
      _labels: labels.join(','),
    };
  };
}
