




















































import Vue from 'vue';
import Component from 'vue-class-component';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';
import { FormFieldRadioButtonWithIcon } from '../../types';

@Component({
  components: { FieldLabel },
  props: {
    field: {
      type: Object,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
})

export default class RadioButtonCustom extends Vue {
  private field!: FormFieldRadioButtonWithIcon;

  private get isRowPositionElement(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private onChange(): void {
    this.field.error = '';
    this.$emit('change');
  }
}

