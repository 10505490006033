import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"py-4",attrs:{"fixed":"","right":"","overlay-opacity":"0","width":"420","stateless":true},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c(VBtn,{staticClass:"text-capitalize blue--text mt-1",attrs:{"text":""},on:{"click":_vm.closePanel}},[_vm._v(" Скрыть ")])],1),(_vm.versionsData.payload.length)?_c(VList,[_c(VListItemGroup,{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.versionsData.payload),function(version,index){return [(version.diff.length)?_c(VListItem,{key:version.created_at,on:{"click":function($event){return _vm.clickVersion(version.diff)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-body-1 font-weight-bold",domProps:{"textContent":_vm._s(_vm.getDate(version.created_at))}}),_c(VListItemSubtitle,{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"mr-3",attrs:{"x-large":""}},[_vm._v(" mdi-account-circle ")]),_c(VListItemSubtitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.getName(version.user))+" ")])],1),(active)?[_c(VListItemSubtitle,{staticClass:"text-body-1 text--primary"},[_vm._v(" Изменил(а) ")]),_c('ul',{staticClass:"px-0"},_vm._l((version.diff),function(item,index){return _c('li',{key:index,staticClass:"px-0"},[(_vm.getFieldLabel(item.attribute))?_c(VListItemContent,{staticClass:"py-1"},[_c(VListItemTitle,{staticClass:"text-body-1",domProps:{"textContent":_vm._s(_vm.getFieldLabel(item.attribute))}})],1):_vm._e()],1)}),0)]:_vm._e()],2)]}}],null,true)}):_vm._e(),(index < _vm.versionsData.payload.length - 1)?_c(VDivider,{key:index}):_vm._e()]})],2)],1):_c('div',{staticClass:"text--black"},[_vm._v(" Пока нет никаких изменений... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }