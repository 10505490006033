import Vue from 'vue';
import Vuex from 'vuex';
import { StoreCurrentPageParams, StoreStateInterface } from '@/types';

Vue.use(Vuex);

export default new Vuex.Store({
  state: <StoreStateInterface> {
    listPageSettings: [],
  },
  mutations: {
    /**
     * Сменить пагинацию для страницы
     * @param {Object} state
     * @param {StoreCurrentPageParams} currentPageParams
     * @constructor
     */
    changeListPageSettings(
      state,
      currentPageParams: StoreCurrentPageParams,
    ) {
      state.listPageSettings = state.listPageSettings.filter((page) => page.pageTitle
        !== currentPageParams.pageTitle);
      if (currentPageParams.currentPage) {
        state.listPageSettings = [...state.listPageSettings, currentPageParams];
      }
    },
  },
  actions: {
    /**
     * Установить новую пагинацию для страницы
     * @param context
     * @param currentPageParams
     */
    setListPageSetting(context, currentPageParams: StoreCurrentPageParams) {
      context.commit('changeListPageSettings', currentPageParams);
    },
  },
  modules: {},
  getters: {
    getListPageSettings: (state) => state.listPageSettings,
  },
});
