





































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem,
  DataTableItem,
  ErrorResponse,
  FormField,
  FrequencySettingsFieldValues,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  SuccessResponse, UserInfo,
} from '@/types';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import ServiceAdd from '@/components/modals/ServiceAdd.vue';
import { Service } from '@/types/service';
import Loader from '@/components/widgets/Loader.vue';
import ServiceService from '@/services/ServiceService';
import FrequencySetting from '@/components/modals/FrequencySetting.vue';
import { Frequency } from '@/enums/Frequency';
import { Program } from '@/types/program';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';
import SuccessSave from '@/components/modals/SuccessSave.vue';
import PermissionEnums from '@/enums/PermissionEnums';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import { EventsCode } from '@/enums/EventsCode';

@Component({
  components: {
    SuccessSave,
    DataWillNotSaved,
    FrequencySetting,
    Loader,
    FormActions,
    DataTableWrapper,
    Breadcrumbs,
    ServiceAdd,
    ConfirmationDialog,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})
export default class ProgramTemplateDetail extends Vue {
  private user!: UserInfo;

  private templateInfo: Program.TemplateItemResponse|null = null;

  private showSuccessSaveDialog = false;

  private showWillNotSaveDialog = false;

  private selectedService: Service.ItemWithFrequency[] = [];

  private openedServiceDialogModel = false;

  private loading = true;

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private currentSettingsFrequencyId: number|null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Лечение',
      href: '/program',
      disabled: false,
    },
    {
      text: 'Список шаблонов',
      href: '/program/templates',
      disabled: false,
    },
    {
      text: 'Шаблон программы лечения',
      href: '/program/templates/add',
      disabled: true,
    },
  ]

  private name: FormField = {
    label: 'Название',
    error: '',
    value: null,
    placeholder: 'Введите данные',
    name: 'name',
  }

  private servicesField = {
    name: 'services',
    error: '',
  }

  private allFields = [
    this.name,
    this.servicesField,
  ]

  private tableHeaders = [
    {
      value: 'name',
      text: 'Наименование',
      width: '40%',
    },
    {
      value: 'code',
      text: 'Код',
    },
    {
      value: 'category',
      text: 'Категория',
    },
    {
      value: 'repeat',
      text: 'Повторения',
    },
    {
      value: 'count',
      text: 'Кол-во',
    },
  ]

  private hasProgramTemplateChange = false;

  private showConfirmationPopup = false;

  /**
   * Получить члены таблицы
   */
  private get tableItems(): DataTableItem[] {
    if (!this.handbookFullData) {
      return [];
    }

    return this.selectedService
      .map((item) => ServiceService
        .transformServiceSelectedItemToTableItem(
          item,
          (this.handbookFullData as HandbookFullDataItem[]),
        ));
  }

  /**
   * Может ли управлять услугами
   */
  private get canManageProgramTemplate(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.PROGRAM_TEMPLATE_MANAGE) !== -1;
  }

  /**
   * Получить настройки периодичности
   */
  private get frequencySettings(): FrequencySettingsFieldValues|null {
    if (!this.currentSettingsFrequencyId) {
      return null;
    }

    const settingsService = this.selectedService
      .find((service) => service.id === this.currentSettingsFrequencyId);

    if (!settingsService) {
      return null;
    }

    return {
      frequency: settingsService.frequency?.toString() || '',
      frequency_type: settingsService.frequency_type,
      frequency_options: settingsService.frequency_options,
    };
  }

  private get programTemplateId(): string {
    return this.$route.params.id;
  }

  mounted(): void {
    this.loadConfig();
    this.addEventListeners();
  }

  beforeDestroy(): void {
    this.destroyEventListeners();
  }

  /**
   * Добавления обработчиков событий
   */
  private addEventListeners(): void {
    this.onEventEscape();
  }

  /**
   * Удаления обработчиков событий
   */
  private destroyEventListeners(): void {
    this.removeEventEscape();
  }

  /**
   * Загрузить стартовый конфиг
   */
  private loadConfig(): void {
    this.loading = true;
    const allPromise = [
      this.getHandbookFullData(),
    ];

    if (this.programTemplateId) {
      allPromise.push(this.getTemplateInfo());
    }

    Promise.all(allPromise).then(() => {
      this.fillFields();
      this.loading = false;
    });
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): Promise<void> {
    return new Promise((resolve) => {
      this.$handbookApi.getFullData()
        .then((response: HandbookFullDataResponse) => {
          this.handbookFullData = response;
          resolve();
        });
    });
  }

  /**
   * Заполнить поля
   */
  private fillFields(): void {
    if (!this.templateInfo) {
      return;
    }

    this.name.value = this.templateInfo.name;
    this.selectedService = this.templateInfo.services;
  }

  /**
   * Получить данные по шаблону
   */
  private getTemplateInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.programTemplateId) {
        reject();
      }

      this.$programApi.getProgramTemplateInfo({ id: this.programTemplateId })
        .then((response) => {
          this.templateInfo = response;
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Получить все id выбранных услуг
   */
  private getSelectedServiceIds(): string[] {
    if (!this.selectedService || !this.selectedService.length) {
      return [];
    }

    return this.selectedService.map((service) => service.id.toString());
  }

  /**
   * Обработать выбранные услуги
   */
  private processSelectedService(data: Service.Item[]): void {
    const filteredData = data.filter(({ id }) => {
      if (!this.selectedService.length) {
        return true;
      }

      return this.getSelectedServiceIds().indexOf(id.toString()) === -1;
    });

    const services: Service.ItemWithFrequency[] = filteredData.map((service) => ({
      ...service,
      frequency_type: Frequency.Type.NO_REPEAT,
      frequency: '1',
      frequency_options: null,
    }));

    this.selectedService = [...this.selectedService, ...services];
  }

  /**
   * Создать параметры запроса
   */
  private createRequest(): Program.TemplateCreateRequest {
    return {
      name: this.name.value ? this.name.value : '',
      services: this.selectedService.map((service) => ({
        id: service.id.toString(),
        frequency_type: service.frequency_type,
        frequency: service.frequency,
        frequency_options: service.frequency_options,
      })),
    };
  }

  /**
   * Отправить форму
   */
  private submitForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clearError();
      let request: Promise<SuccessResponse>;

      if (!this.programTemplateId) {
        request = this.$programApi.createTemplate(this.createRequest());
      } else {
        request = this.$programApi.updateProgramTemplate(
          {
            ...this.createRequest(),
            id: this.programTemplateId,
          },
        );
      }

      request
        .then(() => {
          resolve();
        })
        .catch((err: ErrorResponse) => {
          if (err.code === 2 && typeof err.message !== 'undefined') {
            this.setError(err.message);
          }

          reject();
        });
    });
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private clearError(): void {
    this.allFields.forEach((field) => {
      field.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * Обработать удаления из таблицы выбранных услуг
   */
  private handleDeletionTableItem(id: string): void {
    this.selectedService = this.selectedService.filter((service) => service.id !== (+id));
  }

  /**
   * Обработать клик по шестёрки в таблице услуг
   */
  private handleSettingsClick(id: number): void {
    this.currentSettingsFrequencyId = id;
  }

  /**
   * Обработчик клика на кнопку добавить услугу
   */
  private clickAddServicesHandler(): void {
    this.openedServiceDialogModel = true;
  }

  /**
   * Обработчик клика добавить в модалке добавления услуг
   */
  private clickAddingClickHandler(data: Array<Service.Item>): void {
    if (!data.length) {
      return;
    }

    this.processSelectedService(data);
  }

  /**
   * Обработчик клика сохранить в модалке настроек периодичности
   */
  private clickSaveFrequencySettingHandler(data: FrequencySettingsFieldValues): void {
    this.selectedService = this.selectedService.map((service) => {
      if (service.id !== this.currentSettingsFrequencyId) {
        return service;
      }
      return {
        ...service,
        ...data,
      };
    });
    this.currentSettingsFrequencyId = null;
  }

  /**
   * Обработчик клика отменить в модалке настроек периодичности
   */
  private clickCancelFrequencySetting(): void {
    this.currentSettingsFrequencyId = null;
    this.hasProgramTemplateChange = false;
  }

  /**
   * Обработчик клика на сохранить в действиях формы
   */
  private clickSaveTemplateHandler(): void {
    this.submitForm()
      .then(() => {
        this.$router.push({ name: 'program-templates' });
      });
  }

  /**
   * Обработчик клика применить в действиях формы
   */
  private clickApplyTemplateHandler(): void {
    this.submitForm()
      .then(() => {
        this.showSuccessSaveDialog = true;

        if (!this.programTemplateId) {
          this.name.value = '';
          this.selectedService = [];
        }
      });
  }

  /**
   * Обработчик закрытия модалки подтверждения сохранения
   */
  private clickCloseSuccessDialogHandler(): void {
    this.showSuccessSaveDialog = false;
  }

  /**
   * Обработчик клика закрыть в действиях формы
   */
  private clickCloseFormActionHandler(): void {
    if (!this.canManageProgramTemplate) {
      this.$router.push({ name: 'program-templates' });
      return;
    }

    this.showWillNotSaveDialog = true;
  }

  /**
   * Обработчик клика по кнопке не сохранять в диалоговом окне при выходе на закрыть
   */
  private clickNotSaveInDialogHandler(): void {
    this.$router.push({ name: 'program-templates' });
  }

  /**
   * Обработчик клика по кнопке сохранить в диалоговом окне при выходе на закрыть
   * @private
   */
  private clickSaveInDialogHandler(): void {
    this.submitForm()
      .then(() => {
        this.$router.push({ name: 'program-templates' });
      })
      .catch(() => {
        this.showWillNotSaveDialog = false;
      });
  }

  /**
   * Прослушивание события клика на кнопку Esc
   */
  private onEventEscape():void {
    document.addEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Удаляем прослушивание события клика на кнопку Esc
   */
  private removeEventEscape():void {
    document.removeEventListener('keydown', this.eventEscapeHandler);
  }

  /**
   * Хэндлер события клика на кнопку Esc
   */
  private eventEscapeHandler(event: KeyboardEvent):void {
    if (event.code === EventsCode.ESCAPE) {
      if (this.frequencySettings) {
        if (this.hasProgramTemplateChange) {
          this.showConfirmationModal();
        } else {
          this.clickCancelFrequencySetting();
        }
        return;
      }
      if (this.hasProgramTemplateChange) {
        this.showConfirmationModal();
        return;
      }

      this.goToPageList();
    }
  }

  /**
   * Переход на страницу списка
   */
  private goToPageList(): void {
    if (this.$route.name === 'program-templates') {
      return;
    }
    try {
      this.$router.push({ name: 'program-templates' });
    } catch (error) {
      window.console.log(error);
    }
  }

  /**
   * Показать окно подтверждения действия
   */
  private showConfirmationModal():void {
    this.showConfirmationPopup = true;
  }

  /**
   * Хэндлер подтверждения
   */
  private confirmPopupHandler():void {
    this.showConfirmationPopup = false;
    if (this.frequencySettings) {
      this.clickCancelFrequencySetting();
      return;
    }
    this.goToPageList();
  }

  /**
   * Хэндлер отмены
   */
  private cancelPopupHandler():void {
    this.showConfirmationPopup = false;
  }
}
