





























import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';
import { Prop } from 'vue-property-decorator';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

@Component({
  components: { FieldLabel },
})

export default class TextField extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) private field!: FormField;

  @Prop({
    type: Number,
    default: 4,
  }) private labelSmCols!: number;

  private onChange(): void {
    this.field.error = '';
    this.$emit('change');
  }
}

