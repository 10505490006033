







































import Vue from 'vue';
import Component from 'vue-class-component';
import FormActions from '@/components/buttons/FormActions.vue';
import { RepetitionsItem } from '@/types';

@Component({
  components: { FormActions },
  props: {
    title: {
      type: String,
      default: '',
    },
    entryRepetitions: {
      type: Array,
      required: true,
    },
  },
})

export default class RepetitionsControls extends Vue {
  private selectedModel: Array<number> = [];

  private entryRepetitions!: Array<RepetitionsItem>;

  private repetitions: Array<RepetitionsItem> = [];

  mounted(): void {
    this.selectedModel = this.entryRepetitions
      .filter((item) => item.completed).map((item) => item.id);

    this.repetitions = this.entryRepetitions.sort((a, b) => {
      const dateA = this.$date(a.repeat_at).valueOf();
      const dateB = this.$date(b.repeat_at).valueOf();

      return dateA - dateB;
    });
  }

  /**
   * Переключить состояние элемента
   * @param repetitionId
   * @private
   */
  private toggleStatusRepetition(repetitionId: number): void {
    this.$programApi.toggleServiceRepetitionStatus({ id: repetitionId });
  }

  /**
   * изменилось состояние элемента
   * @param repetitionId
   * @private
   */
  private changeCheckboxHandler(repetitionId: number): void {
    this.$emit('change-program');
    this.toggleStatusRepetition(repetitionId);
  }

  /**
   * Клик вне элемента
   * @private
   */
  private clickOutsideHandler(): void {
    this.$emit('click-cancel');
  }

  /**
   * Клик по сохранить
   * @private
   */
  private clickSaveHandler(): void {
    this.$emit('click-cancel');
  }
}
