import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-8"},[(_vm.loadingStartConfig)?[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbsItems}}),_c('div',{staticClass:"mt-4 d-sm-flex"},[_c('h1',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Управление данными")])]),_c('section',{staticClass:"mt-6"},[_c(VRow,{staticClass:"mb-8",attrs:{"align":"end","no-gutters":""}},[_c('span',{staticClass:"grey--text lighten-1"},[(!_vm.loading && !_vm.helpItems)?[_vm._v(" Записей пока нет.. ")]:_vm._e()],2),_c(VSpacer),_c(VBtn,{staticClass:"text-capitalize mt-4 mt-sm-0 mx-sm-3 white--text",attrs:{"to":{name: 'help-create'},"height":"40","color":"light-blue lighten-1"}},[_vm._v(" Добавить ")])],1),(_vm.helpItems)?_c(VDataTable,{staticStyle:{"border":"1px solid #BFC5CF"},attrs:{"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":20,"hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.helpItems,"item-key":"id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusActiveText(item.active))+" ")]}},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sort)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{attrs:{"title":"Изменить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){return _vm.$router.push({name: 'help-edit', params: {
              id: item.id,
            }})}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"title":"Удалить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){return _vm.clickDeleteIconHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)]}}],null,false,1952843039)}):_vm._e(),(_vm.helpItems && _vm.pagesCount > 1)?_c(VPagination,{staticClass:"mt-8",attrs:{"color":"light-blue lighten-1","length":_vm.pagesCount},on:{"input":_vm.getAllHelpList},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]:[_c('div',{staticClass:"mt-8 d-flex align-center justify-center flex-grow-1"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":true}})],1)],(_vm.isDeleteConfirmDialogVisible && (_vm.currentHelpItemId || _vm.currentHelpItemId === 0))?_c('ConfirmationDialog',{on:{"confirm":function($event){return _vm.deleteHelpItem(_vm.currentHelpItemId)},"cancel":_vm.hideDeleteConfirmDialog}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }