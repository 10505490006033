import { StatusCode, StatusText } from '@/enums/ParticipantStatus';

/**
 * Сервис по работе с участником
 */
export default class ParticipantService {
  /**
   * Получить текст статуса участника
   */
  static getItemStatus(status: number): string {
    switch (status) {
      case StatusCode.ACTIVE:
        return StatusText.ACTIVE;
      case StatusCode.DRAFT:
        return StatusText.DRAFT;
      case StatusCode.ARCHIVE:
        return StatusText.ARCHIVE;
      default: return '';
    }
  }
}
