import tools from '@/tools';
import {
  HandbookListResponse,
  HandbookValueCreateRequest,
  HandbookValueItem,
  SuccessResponse,
  HandbookValueUpdateRequest, HandbookFullDataResponse,
} from '@/types';

export default class HandbookApi {
  public getFullData(): Promise<HandbookFullDataResponse> {
    return tools.apiRequest(
      'get',
      'handbook/full-data',
    ) as Promise<HandbookFullDataResponse>;
  }

  /**
   * Получить список справочников
   * @param requestParams
   */
  public getList(
    requestParams: { name: string },
  ): Promise<HandbookListResponse> {
    return tools.apiRequest(
      'get',
      'handbook/list',
      requestParams,
    ) as Promise<HandbookListResponse>;
  }

  /**
   * Получить значения справочника по id
   * @param id
   */
  public getValues(id: string): Promise<HandbookListResponse> {
    return tools.apiRequest(
      'get',
      `handbook/${id}/values`,
    ) as Promise<HandbookListResponse>;
  }

  /**
   * Создать значение справочника
   * @param requestParams
   */
  public createHandbookValue(
    requestParams: HandbookValueCreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'handbook/value/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * удалить значения справочника
   * @param requestParams
   */
  public deleteHandbookValue(
    requestParams: { id: number },
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'handbook/value/delete',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить информацию о значение справочника
   * @param requestParams
   */
  public getHandbookValueInfo(
    requestParams: {id: string},
  ): Promise<HandbookValueItem> {
    return tools.apiRequest(
      'get',
      `handbook/value/info/${requestParams.id}`,
    ) as Promise<HandbookValueItem>;
  }

  /**
   * Обновить данные значение справочника
   * @param requestParams
   */
  public updateHandbookValue(
    requestParams: HandbookValueUpdateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'handbook/value/update',
      requestParams,
    ) as Promise<SuccessResponse>;
  }
}
