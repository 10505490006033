





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  FormField,
  FormFieldDateRange, ListItem,
  LogListRequest,
  LogRecord,
  SelectOption,
  UserInfo,
} from '@/types';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import { DataTableHeader } from 'vuetify';
import EventCategory from '@/enums/EventCategory';
import Loader from '@/components/widgets/Loader.vue';

type ItemList = {
  id: string,
  date: string,
  message: string,
  user: {
    name: string,
  },
  organizations: string,
  role: string,
  url: string,
}

@Component({
  components: {
    Loader,
    DataFilters,
    DataTableWrapper,
  },
})
export default class EventLog extends Vue {
  @Prop({
    default: null,
  }) private readonly user!: UserInfo;

  private loading = false;

  private loadingFilters = true;

  private loadingItems = false;

  private showFilter = true;

  private filterKey = 1;

  private pagesCount = 0;

  private currentPage = 1;

  private sort = '';

  private itemCounts = {
    from: '',
    to: '',
    total: '',
  }

  private organizations: Array<ListItem>|null = null;

  private itemList: Array<LogRecord> = [];

  private itemListHeaders: Array<DataTableHeader> = [
    {
      value: 'id',
      text: 'ID',
      width: 60,
      class: 'custom-table-heading',
    },
    {
      value: 'date',
      text: 'Дата',
      class: 'custom-table-heading',
    },
    {
      value: 'message',
      text: 'Событие',
      class: 'custom-table-heading',
    },
    {
      value: 'user.name',
      text: 'Пользователь',
      class: 'custom-table-heading',
    },
    {
      value: 'organizations',
      text: 'Организация',
      class: 'custom-table-heading',
      sortable: false,
    },
    {
      value: 'role',
      text: 'Роль в системе',
      class: 'custom-table-heading',
      sortable: false,
    },
    {
      value: 'url',
      text: 'Источник',
      class: 'custom-table-heading',
    },
  ]

  private eventCategories: Array<SelectOption> = [
    {
      label: 'Вход в систему',
      value: EventCategory.AUTH,
    },
    {
      label: 'Действия с участниками',
      value: EventCategory.PARTICIPANT,
    },
    {
      label: 'Действия с пользователями',
      value: EventCategory.USER,
    },
    {
      label: 'Действия с программами',
      value: EventCategory.PROGRAM,
    },
    {
      label: 'Действия с шаблонами программ',
      value: EventCategory.PROGRAM_TEMPLATE,
    },
  ];

  // region Filter Fields

  private date: FormFieldDateRange = {
    label: 'Дата',
    name: 'dateStart',
    value: {
      from: '',
      to: '',
    },
    error: '',
    fieldType: FormFieldTypeEnum.DATE_RANGE,
  }

  private eventUser: FormField = {
    label: 'Пользователь',
    name: 'filterUser',
    value: '',
    error: '',
    fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
    placeholder: 'Выберете из списка',
  }

  private eventCategory: FormField = {
    label: 'Тип события',
    name: 'eventType',
    value: '',
    error: '',
    fieldType: FormFieldTypeEnum.SELECT,
    options: this.eventCategories,
    placeholder: 'Выберете из списка',
  }

  private eventUserRole: FormField = {
    label: 'Роль в системе',
    name: 'accessLevel',
    value: '',
    error: '',
    fieldType: FormFieldTypeEnum.SELECT,
    options: [],
    placeholder: 'Выберете из списка',
  }

  private eventUrl: FormField = {
    label: 'Источник',
    name: 'eventSource',
    value: '',
    error: '',
    fieldType: FormFieldTypeEnum.TEXT,
    placeholder: 'Введите данные',
  }

  // endregion

  private allFields = [
    this.date,
    this.eventUser,
    this.eventCategory,
    this.eventUserRole,
    this.eventUrl,
  ];

  mounted(): void {
    this.loadingStartData();
  }

  private get itemListTransformed(): Array<ItemList> {
    const transformedOrganizations = (organizationsIds: Array<number>) => {
      if (!organizationsIds.length || !this.organizations) {
        return '-';
      }

      return organizationsIds.map((organizationId) => {
        const organizationsShortList = (this.organizations as Array<ListItem>);
        const shortIds = organizationsShortList
          .map((organization) => organization.id);
        const index = shortIds.indexOf(organizationId);

        if (index === -1 || typeof organizationsShortList[index] === 'undefined') {
          return '';
        }
        return organizationsShortList[index].name;
      }).join(', ');
    };

    const result = this.itemList.map((item) => ({
      id: item.id.toString(),
      date: item.date_formatted,
      message: item.message,
      user: {
        name: `${item.user.last_name || ''} ${item.user.name || ''} ${item.user.patronymic || ''}`,
      },
      organizations: transformedOrganizations(item.organization_ids),
      role: item.roles.map((role) => role.title).join(', '),
      url: item.url,
    }));

    return result;
  }

  /**
   * Загрузить стартовые данные
   * @private
   */
  private loadingStartData(): void {
    this.loading = true;
    Promise.allSettled([
      this.getRoleList(),
      this.getAllUsers(),
      this.getOrganizations(),
    ]).then(() => {
      this.loadItems();
      this.loading = false;
    });
  }

  /**
   * Сбросить фильтры
   */
  private resetFilters(): void {
    this.filterKey += 1;
    this.allFields.forEach((field) => {
      if (field.fieldType === FormFieldTypeEnum.DATE_RANGE) {
        field.value = {
          from: '',
          to: '',
        };
      } else {
        field.value = null;
      }
    });
  }

  /**
   * Загрузить список событий
   */
  private loadItems(): void {
    const requestParams: LogListRequest = {
      page: this.currentPage,
      date_from: this.date.value.from,
      date_to: this.date.value.to,
      url: this.eventUrl.value || '',
      sort: this.sort,
    };
    if (this.eventUser.value) {
      requestParams.user_id = +this.eventUser.value;
    }
    if (this.eventUserRole.value) {
      requestParams.user_role_id = +this.eventUserRole.value;
    }
    if (this.eventCategory.value) {
      requestParams.category = this.eventCategory.value;
    }

    this.loadingItems = true;

    this.$logApi.getList(
      requestParams,
    ).then((response) => {
      this.itemList = response.data;
      this.itemCounts = {
        total: response.total?.toString() || '',
        from: response.from?.toString() || '',
        to: response.to?.toString() || '',
      };
      this.pagesCount = response.last_page;
      this.currentPage = response.current_page;
    })
      .finally(() => {
        this.loadingItems = false;
      });
  }

  /**
   * Обновить список в соответствии с выбранной сортировкой
   */
  private updateSort(sort: {
    sortBy: Array<string>,
    sortDesc: Array<boolean>,
  }): void {
    this.sort = sort.sortBy.map((sortField, index) => (
      `${sort.sortDesc[index] ? '-' : '+'}${sortField}`
    )).join(',');

    this.loadItems();
  }

  /**
   * получить список организаций
   * @private
   */
  private getOrganizations(): Promise<void> {
    return new Promise((resolve) => {
      this.$organizationApi.getShortList()
        .then((response) => {
          this.organizations = response;
          resolve();
        });
    });
  }

  /**
   * Получить всех пользователей для фильтра
   * @private
   */
  private getAllUsers(): Promise<void> {
    return new Promise((resolve) => {
      this.$users.getAllUsers().then((response) => {
        this.eventUser.options = response.map((user) => ({
          label: `${user.last_name || ''} ${user.name || ''} ${user.patronymic || ''}`,
          value: user.id,
        }));
        resolve();
      });
    });
  }

  /**
   * Получить список ролей
   * @private
   */
  private getRoleList(): Promise<void> {
    return new Promise((resolve) => {
      this.$roleApi.getRoleList().then((response) => {
        this.eventUserRole.options = response.map((role) => ({
          label: role.title,
          value: role.id,
        }));
        resolve();
      });
    });
  }

  /**
   * Загрузка фильтров
   */
  private loadedFilterHandler(): void {
    this.loadingFilters = false;
    this.$nextTick(() => {
      this.loadItems();
    });
  }

  /**
   * Обработчик клика показа фильтров
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик клика поиска в фильтрах
   */
  private clickSearchFilterHandler(): void {
    this.currentPage = 1;
    this.loadItems();
  }

  /**
   * Обработчик клика кнопки сбросить
   */
  private clickResetFilterButtonHandler(): void {
    this.resetFilters();
    this.currentPage = 1;
    this.loadItems();
  }
}
