
import { Component, Vue } from 'vue-property-decorator';
import { Line } from 'vue-chartjs';
import Chart from 'chart.js';
import tools from '@/tools';

@Component({
  extends: Line,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
})

/**
 * Класс работающий напрямую с chartjs
 */
export default class LineChart extends Vue {
  // доступные цвета в графике, пока что только два
  private colors = ['#10A1DC', '#FF9AAF'];

  private chartData!: Chart.ChartData;

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (this as Line).renderChart(
      this.addColorSettingToChartData,
      {
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
            },
          }],
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'end',
        },
      },
    );
  }

  /**
   * Трансформировать переданные данные и добавить к ним цвета
   * @private
   */
  private get addColorSettingToChartData(): Chart.ChartData {
    let { datasets } = this.chartData;

    datasets = datasets?.map((item, index) => {
      const color = this.colors[index];
      if (typeof color === 'undefined') {
        return {
          ...item,
        };
      }
      return {
        ...item,
        borderColor: color,
        pointBackgroundColor: color,
        backgroundColor: this.getGradient(color),
      };
    });
    return {
      labels: this.chartData.labels,
      datasets,
    };
  }

  /**
   * получить градиент для графика
   * @param color
   * @private
   */
  private getGradient(color: string): CanvasGradient {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const gradient = (this.$refs.canvas as HTMLCanvasElement).getContext('2d')
      .createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, tools.hexToRGB(color, '1'));
    gradient.addColorStop(0.5, tools.hexToRGB(color, '0.25'));
    gradient.addColorStop(1, tools.hexToRGB(color, '0'));

    return gradient;
  }
}
