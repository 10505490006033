



































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    saveCallback: {
      type: Function,
      default: null,
    },
    applyCallback: {
      type: Function,
      default: null,
    },
    closeCallback: {
      type: Function,
      default: null,
    },
  },
})

export default class FormActions extends Vue {
  private saveCallback!: () => void;

  private applyCallback!: () => void;

  private closeCallback!: () => void;
}
