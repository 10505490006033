import tools from '@/tools';
import { PermissionListResponse } from '@/types';

export default class PermissionApi {
  /**
   * Получить список прав
   * @return {Promise<PermissionListResponse>}
   */
  public getList(): Promise<PermissionListResponse> {
    return tools.apiRequest(
      'get',
      'permission/list',
    ) as Promise<PermissionListResponse>;
  }
}
