import {
  ExportParams,
  Nullable,
  SuccessResponse,
} from '@/types';
import { Program } from '@/types/program';
import tools from '@/tools';

export default class ProgramApi {
  /**
   * Создать шаблон программы
   * @param requestParams
   */
  public createTemplate(
    requestParams: Program.TemplateCreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'program/template/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить список шаблонов
   * @param requestParams
   */
  public getTemplateList(
    requestParams: Program.TemplateListRequest,
  ): Promise<Program.TemplateListResponse> {
    return tools.apiRequest(
      'get',
      'program/template',
      requestParams,
    ) as Promise<Program.TemplateListResponse>;
  }

  /**
   * Получить полный список шаблонов
   * @return {Promise<Program.TemplateListFullResponse>}
   */
  public getTemplateListFull(): Promise<Program.TemplateListFullResponse> {
    return tools.apiRequest(
      'get',
      'program/template/full-data',
    ) as Promise<Program.TemplateListFullResponse>;
  }

  /**
   * Удаление шаблона
   * @param requestParams
   */
  public deleteTemplate(
    requestParams: {id: string},
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `program/template/${requestParams.id}/delete`,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить информацию о шаблоне программы
   * @param requestParams
   */
  public getProgramTemplateInfo(
    requestParams: {id: string},
  ): Promise<Program.TemplateItemResponse> {
    return tools.apiRequest(
      'get',
      `program/template/${requestParams.id}`,
    ) as Promise<Program.TemplateItemResponse>;
  }

  /**
   * Обновить шаблоны программы
   * @param requestParams
   */
  public updateProgramTemplate(
    requestParams: Program.TemplateUpdateRequest,
  ): Promise<SuccessResponse> {
    const { name, services } = requestParams;
    return tools.apiRequest(
      'post',
      `program/template/${requestParams.id}/update`,
      {
        name,
        services,
      },
    ) as Promise<SuccessResponse>;
  }

  /**
   * Экспорт шаблонов в xlsx
   * @param requestParams
   */
  public exportTemplates(
    requestParams: Program.TemplateListRequest & ExportParams,
  ): Promise<BlobPart> {
    return tools.apiRequest(
      'get',
      'program/template/export',
      requestParams,
      false,
      {},
      'blob',
      true,
    ) as Promise<BlobPart>;
  }

  /**
   * Получить список программ
   * @param requestParams
   * @return {Promise<Program.ListResponse>}
   */
  public getList(
    requestParams: Nullable<Program.ListRequest>,
  ): Promise<Program.ListResponse> {
    return tools.apiRequest(
      'get',
      'program',
      requestParams,
    ) as Promise<Program.ListResponse>;
  }

  /**
   * Выполняем запрос на экспорт списка программ
   * @param requestParams
   */
  public exportList(
    requestParams: Nullable<Program.ListRequest> & ExportParams,
  ): Promise<Program.ExportListResponse> {
    return tools.apiRequest(
      'get',
      'program/export',
      requestParams,
      false,
      {},
    ) as Promise<Program.ExportListResponse>;
  }

  /**
   * Создать программу
   * @param requestParams
   * @return {Promise<SuccessResponse>}
   */
  public createProgram(
    requestParams: Program.CreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      'program/create',
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Обновить программу
   * @param id
   * @param requestParams
   * @return {Promise<SuccessResponse>}
   */
  public updateProgram(
    id: number,
    requestParams: Program.CreateRequest,
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `program/${id}/update`,
      requestParams,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Получить данные программы
   * @param requestParams
   * @return {Promise<Program.Item>}
   */
  public getProgram(requestParams: {
    id: number,
  }): Promise<Program.Item> {
    return tools.apiRequest(
      'get',
      `program/${requestParams.id}`,
    ) as Promise<Program.Item>;
  }

  /**
   * Удалить программу
   * @param requestParams
   * @return {Promise<SuccessResponse>}
   */
  public deleteProgram(requestParams: {
    id: number,
  }): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `program/${requestParams.id}/delete`,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Переключить состояние выполнения
   * @param requestParams
   */
  public toggleServiceRepetitionStatus(
    requestParams: {id: number},
  ): Promise<SuccessResponse> {
    return tools.apiRequest(
      'post',
      `program/service/repetition/${requestParams.id}/toggle-completed`,
    ) as Promise<SuccessResponse>;
  }

  /**
   * Посчитать количество повторений
   * @param requestParams
   */
  public calculateServiceRepetitionCounts(
    requestParams: Program.ServiceRepetitionCountRequest,
  ): Promise<Program.ServiceRepetitionCountResponse> {
    return tools.apiRequest(
      'post',
      'program/service-repetitions',
      requestParams,
    ) as Promise<Program.ServiceRepetitionCountResponse>;
  }

  /**
   * Получить список услуг
   */
  public getServiceList(
    url = 'program/template/services-list',
    requestParams: Program.ServiceListRequest,
  ): Promise<Program.ServiceListResponse> {
    return tools.apiRequest(
      'get',
      url,
      requestParams,
    ) as Promise<Program.ServiceListResponse>;
  }
}
