import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","offset-overflow":"","min-width":"290","transition":"fade-transition","close-on-content-click":false},on:{"input":_vm.onCloseMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.field.mask ? _vm.field.mask : '##.##.####'),expression:"field.mask ? field.mask : '##.##.####'"}],staticStyle:{"max-width":"200px"},attrs:{"dense":"","outlined":"","aria-autocomplete":"inline","placeholder":"дд.мм.гггг","append-icon":"mdi-calendar-blank","disabled":_vm.field.disabled,"error":!!_vm.field.error,"error-messages":_vm.field.error,"hide-details":!_vm.field.error},on:{"input":_vm.resetError,"change":_vm.onInputChange,"focus":_vm.onFocusTextField},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VDatePicker,{attrs:{"no-title":"","locale":"ru","first-day-of-week":1,"min":_vm.minDate,"max":_vm.maxDate},on:{"change":_vm.onDatePickerChange},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }