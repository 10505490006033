import { FamilyMemberRequest, FormFieldSelect } from '@/types';

const FormFieldNames = {
  last_name: 'Фамилия',
  first_name: 'Имя',
  second_name: 'Отчество',
  date_of_birth: 'День рождения',
  date_of_death: 'Дата смерти',
  gender: 'Пол',
  personal_account_insurance: 'СНИЛС',
  medical_insurance: 'ОМС',
  address: 'Город, улица, дом, квартира',
  registration_address: 'Город, улица, дом, квартира',
  floor: 'Этаж',
  phone: 'Контактный телефон',
  home_phone: 'Домашний телефон',
  email: 'Адрес эл.почты',
  marital_status: 'Семейное положение',
  social_status: 'Социальный статус',
  education: 'Образование',
  organisation: 'Организация',
  honorary_titles: 'Заслуги',
  relation_degree: 'Кем приходится',
  contact_person: 'Контактное лицо',
  dispensary_observation_group: 'Группа диспансерного наблюдения',
  observation_reason: 'Причина наблюдения',
  disability: 'Инвалидность',
  disability_group: 'Группа инвалидности',
  disability_series: 'Серия инвалидности',
  disability_number: 'Номер инвалидности',
  disability_reason: 'Причина инвалидности',
  disability_date_start: 'Дата установления инвалидности',
  disability_date_end: 'Срок действия инвалидности',
  rehabilitation_program: 'ИПРА',
  pharmacological_groups: 'Фармакологическая группа лекарственных средств (ГРЛС)',
  medications_taken: 'Укажите принимаемые лекарственные средства',
  additional_devices: 'Укажите используемые дополнительные устройства',
  additional_devices_comment: 'Укажите используемые дополнительные устройства',
  smoking: 'Курение',
  smoking_frequency: 'Группа курения',
  alcohol: 'Алкоголь',
  alcohol_frequency: 'Употребление алкоголя',
  physical_activity: 'Физическая активность',
  physical_activity_group: 'Группа физической активности',
  physical_activity_frequency: 'Группа физической активности',
  autonomy_group: 'Автономная группа',
  category: 'Категория',
  assistance_program: 'Программа помощи',
  status: 'Статус',
  archive_reason: 'Причина',
};

export default class FormFieldService {
  /**
   * Создать шаблон формы для члена семьи
   */
  static createFamilyMemberFormTemplateRequest(id?: number): FamilyMemberRequest {
    const startField = {
      first_name: '',
      last_name: '',
      second_name: '',
      date_of_birth: '',
      gender: null,
      address: null,
      registration_address: null,
      floor: null,
      phone: '',
      email: '',
      relation_degree: null,
      contact_person: null,
    };

    if (id || id === 0) {
      return {
        ...startField,
        id,
      };
    }

    return {
      ...startField,
    };
  }

  /**
   * Создаёт объект для формы выпадашки
   * @param value
   */
  static createSelectField(value: number|string|null = null): FormFieldSelect {
    return {
      name: '',
      error: '',
      value,
    };
  }

  /**
   * Получить лейбл поля по его name
   * @param name
   */
  static getLabelField(name: string): string {
    let fieldName = '';

    Object.keys(FormFieldNames).forEach((key) => {
      const currentKey: keyof typeof FormFieldNames = (key as never);
      if (currentKey !== name) {
        return;
      }
      fieldName = FormFieldNames[currentKey];
    });

    return fieldName || `${name} - лейбел не найден`;
  }
}
