import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"date-field",attrs:{"no-gutters":"","align":"start"}},[(_vm.hasLabel)?_c(VCol,{staticClass:"d-flex pr-4",attrs:{"cols":"12","sm":"4"}},[_c('FieldLabel',{attrs:{"field":_vm.field}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":_vm.hasLabel ? 4 : 12}},[_c(VMenu,{attrs:{"offset-y":"","transition":"scale-transition","close-on-content-click":false,"min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.field.mask ? _vm.field.mask : '##.##.####'),expression:"field.mask ? field.mask : '##.##.####'"}],staticStyle:{"max-width":"200px"},attrs:{"placeholder":"дд.мм.гггг","append-icon":"mdi-calendar-blank","aria-autocomplete":"none","outlined":"","dense":"","hide-details":!_vm.field.error,"error":!!_vm.field.error,"error-messages":_vm.field.error,"clearable":_vm.isClear},on:{"change":_vm.changeInputValueHandler,"click:append":function($event){_vm.dateMenu = true}},model:{value:(_vm.inputModel),callback:function ($$v) {_vm.inputModel=$$v},expression:"inputModel"}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"max":_vm.maxDate,"min":_vm.minDate,"no-title":"","locale":"ru","first-day-of-week":1},on:{"input":_vm.changeDatePickerHandler,"change":_vm.onChange},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})],1)],1),(_vm.numberOfYear && _vm.viewYear)?_c(VCol,{staticClass:"pt-sm-2 d-flex align-center justify-sm-end ml-sm-auto"},[_vm._v(" Возраст "),_c(VChip,{staticClass:"pa-3 mx-3",staticStyle:{"border-radius":"4px","border":"1px solid #A8A9AD"},attrs:{"color":"#F0F0F0","text-color":"black"}},[_vm._v(" "+_vm._s(_vm.numberOfYear)+" ")]),_vm._v(" лет ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }