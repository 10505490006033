






















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem, ExportParams,
  FormField,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  IHandleDeletionFunction,
  SelectOption,
  UserInfo,
} from '@/types';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import { DataTableHeader } from 'vuetify';
import { Service } from '@/types/service';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import HandbookService from '@/services/HandbookService';
import PermissionEnums from '@/enums/PermissionEnums';
import Delete from '@/components/modals/DeleteDialog.vue';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import ValidationEnum from '@/enums/Validation';
import { CurrentPageListNames } from '@/enums/CurrentPageListNames';
import ListPageSettingsService from '@/services/list-page-settings/ListPageSettingsService';

@Component({
  components: {
    DataFilters,
    Breadcrumbs,
    DataTableWrapper,
    Delete,
  },
  beforeRouteLeave(to, from, next): void {
    /**
     * Если мы переходим НЕ на детальную страницу, то сбрасываем пагиацию в сторе
     */
    if (to.name !== 'service-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.SERVICE);
    }
    next();
  },
  beforeRouteEnter(to, from, next): void {
    /**
     * Если мы пришли НЕ с детальной страницы, то сбрасываем пагиацию в сторе
     */
    if (from.name !== 'service-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.SERVICE);
    }
    next();
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ServiceList extends Vue {
  private NAME_EXPORT_FILE = 'serviceList';

  private loadedFilters = true;

  private showDeleteDialog = false;

  private user!: UserInfo;

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private serviceList: Service.Item[]|null = null;

  private isSettingsLoading = false;

  private isListLoading = false;

  private showFilter = true;

  private pagesCount = 0;

  private currentPage = 1;

  private sortBy: string[] = [];

  private sortDesc: boolean[] = [];

  private idDeleteElement: string|null = null;

  private itemCounts = {
    from: '',
    to: '',
    total: '',
  }

  private loadingExport = false;

  private code: FormField = {
    value: null,
    name: 'code',
    placeholder: 'Введите данные',
    error: '',
    label: 'Код',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.SERVICE_CODE,
  }

  private number: FormField = {
    value: null,
    name: 'number',
    placeholder: 'Введите данные',
    error: '',
    label: 'Номер',
    validation: ValidationEnum.NUMBER_AND_POINT,
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private name: FormField = {
    value: null,
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Наименование',
    fieldType: FormFieldTypeEnum.TEXT,
  }

  private category: FormField = {
    value: null,
    name: 'category_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Раздел',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private polygon: FormField = {
    value: null,
    name: 'polygon_id',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Полигон',
    options: [],
    fieldType: FormFieldTypeEnum.SELECT,
    multiple: true,
  }

  private allFields = [
    this.code,
    this.number,
    this.name,
    this.category,
    this.polygon,
  ]

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список услуг',
      href: '/service',
      disabled: true,
    },
  ]

  private tableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      class: 'custom-table-heading',
    },
    {
      text: 'Номер',
      value: 'number',
      class: 'custom-table-heading',
    },
    {
      text: 'Наименование',
      value: 'name',
      class: 'custom-table-heading',
    },
    {
      text: 'Код',
      value: 'code',
      class: 'custom-table-heading',
    },
    {
      text: 'Раздел',
      value: 'section',
      class: 'custom-table-heading',
    },
    {
      text: 'Полигон',
      value: 'polygon',
      class: 'custom-table-heading',
    },
  ]

  mounted(): void {
    const storeSettings = ListPageSettingsService.getListPageSettingsFromStore(
      CurrentPageListNames.SERVICE,
    );
    this.currentPage = storeSettings?.currentPage ?? 1;
    this.sortBy = storeSettings?.sort?.sortBy ?? [];
    this.sortDesc = storeSettings?.sort?.sortDesc ?? [];
    this.getHandbookFullData();
    this.addEventListeners();
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  /**
   * Получить индефикацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.SERVICES;
  }

  /**
   * Может ли управлять услугами
   */
  private get canManageService(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.SERVICE_MANAGE) !== -1;
  }

  /**
   * Обработчик удаления
   */
  private get handleDeletion(): null| IHandleDeletionFunction {
    if (!this.canManageService) {
      return null;
    }

    return (id: string) => {
      this.showDeleteDialog = true;
      this.idDeleteElement = id.toString();
    };
  }

  /**
   * Элементы таблицы
   */
  private get tableItems(): Record<string, unknown>[] {
    if (!this.serviceList || !this.serviceList.length) {
      return [];
    }
    return this.serviceList.map((item) => {
      const polygons = HandbookService.getTitleFromValues(
        this.handbookFullData ? this.handbookFullData : [],
        HandbookCode.POLYGON,
        item.polygon_ids,
      );

      const hasAllPolygons = item.polygon_ids.length === (this.polygon.options?.length ?? 0);

      return {
        id: item.id,
        number: item.number,
        name: item.name,
        code: item.code,
        section: HandbookService.getTitleFromValues(
          this.handbookFullData ? this.handbookFullData : [],
          HandbookCode.SERVICE_CATEGORIES,
          +(item.category?.id ?? 0),
        ),
        polygon: hasAllPolygons ? 'Все полигоны' : polygons,
      };
    });
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Создать параметры запроса
   */
  private createRequest(): Service.ListRequest {
    const request: Record<string, unknown> = {};
    this.allFields.forEach((field) => {
      request[field.name] = field.value ?? '';
    });

    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }

    return ({
      ...request,
      page: this.currentPage,
      sort,
    } as Service.ListRequest);
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.category.options = this.createOptions(HandbookCode.SERVICE_CATEGORIES);
    this.polygon.options = this.createOptions(HandbookCode.POLYGON);
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.isSettingsLoading = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.isSettingsLoading = false;
        this.handbookFullData = response;
        this.createFieldsOptions();
      });
  }

  /**
   * Получить список услуг
   */
  private getList(): void {
    this.isListLoading = true;
    this.$serviceApi.getList(this.createRequest())
      .then((response) => {
        this.serviceList = response.data;
        this.itemCounts.from = response.from?.toString();
        this.itemCounts.to = response.to?.toString();
        this.itemCounts.total = response.total?.toString();
        this.pagesCount = response.last_page;
        this.setStoreSettingsPage();
      })
      .finally(() => {
        this.isListLoading = false;
      });
  }

  /**
   * Удалить услугу
   */
  private deleteService(id: string): void {
    this.$serviceApi.delete({ id })
      .then(() => {
        this.showDeleteDialog = false;
        this.idDeleteElement = null;
        this.getList();
      });
  }

  /**
   * Обработать клик по строку таблицы
   */
  private handleRowClick(id: string): void {
    this.$router.push({
      name: 'service-detail',
      params: {
        id,
      },
    });
  }

  /**
   * Обработать принятие удаления
   */
  private handleAcceptanceOfDeletion(id: string): void {
    this.deleteService(id);
  }

  /**
   * Обработать отмену удаления
   */
  private handleCancellationOfDeletion(): void {
    this.showDeleteDialog = false;
    this.idDeleteElement = null;
  }

  /**
   * Сбросить фильтры
   */
  private resetFilters(): void {
    this.allFields.forEach((field) => {
      field.value = null;
    });
  }

  /**
   * экспорт услуг
   * @private
   */
  private exportService(): void {
    let link: HTMLAnchorElement|null = document.createElement('a');
    link.href = 'sample-data/enmu.xlsx';
    link.download = 'enmu.xlsx';
    link.click();
    setTimeout(() => {
      link = null;
    }, 0);
  }

  /**
   * Экспорт списка услуг
   */
  private exportServicesList(): void {
    this.loadingExport = true;
    this.$serviceApi.exportServices(
      {
        ...this.createRequest(),
        _fields: 'id,number,name,code,category_id,polygon_ids',
        _labels: 'ID,Номер,Наименование,Код,Раздел,Полигон',
      } as Service.ListRequest & ExportParams,
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    });
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private clickShowFilterButtonHandler(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработчик смены пагинации
   */
  private changePaginationHandler(): void {
    this.getList();
  }

  /**
   * Обработчик клика поиска
   */
  private clickSearchHandler(): void {
    this.currentPage = 1;
    this.getList();
  }

  /**
   * Обработчик клика сбросить
   */
  private clickResetHandler(): void {
    this.resetFilters();
    this.currentPage = 1;
    this.getList();
  }

  /**
   * Обработчик сортировки
   * @param sort
   * @private
   */
  private changeSortHandler(sort: {sortBy: string[], sortDesc: boolean[]}): void {
    this.sortBy = sort.sortBy;
    this.sortDesc = sort.sortDesc;

    this.setStoreSettingsPage();
    this.getList();
  }

  /**
   * обработчик загрузки фильтров
   * @private
   */
  private loadedFilterHandler(): void {
    this.loadedFilters = false;
    this.getList();
  }

  /**
   * Обработчик клика экспорт ЕНМУ
   * @private
   */
  private clickExportHandler(): void {
    this.exportService();
  }

  /**
   * Обработчик клика экспорт списка услуг
   * @private
   */
  private clickExportListHandler(): void {
    this.exportServicesList();
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Устанавливаем в стор, номер текущей страницы
   * @private
   */
  setStoreSettingsPage():void {
    this.$store.dispatch('setListPageSetting', {
      pageTitle: CurrentPageListNames.SERVICE,
      currentPage: this.currentPage,
      sort: {
        sortDesc: this.sortDesc,
        sortBy: this.sortBy,
      },
    });
  }
}
