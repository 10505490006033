






















import Vue from 'vue';
import Component from 'vue-class-component';
import { DetailMembers } from '@/types';
import RowText from '@/components/RowText.vue';

@Component({
  components: { RowText },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
})

export default class DetailedInformation extends Vue {
  private data!: DetailMembers.Data;
}
