















import Vue from 'vue';
import Component from 'vue-class-component';
import RadioButtonsLabelIcon from '@/components/form-fields/RadioButtonsLabelIcon.vue';
import { FormFieldRadioButtonWithIcon } from '@/types';
import { ParticipantFormType } from '@/enums/ParticipantFormAdd';

@Component({
  components: {
    RadioButtonsLabelIcon,
  },
})

export default class ParticipantSectionWayToAdd extends Vue {
  private wayToAddRadioField: FormFieldRadioButtonWithIcon = {
    name: 'way-to-add',
    error: '',
    options: [
      {
        label: 'Добавить из файла',
        value: ParticipantFormType.FILE_IMPORT,
        icon: 'mdi-format-list-bulleted-square',
      },
      {
        label: 'Добавить вручную',
        value: ParticipantFormType.MANUAL,
        icon: 'mdi-file-plus',
      },
    ],
    value: ParticipantFormType.MANUAL,
  }

  private participantFormTypeChangeHandler(type: ParticipantFormType): void {
    this.$eventBus.$emit(this.$eventBus.PARTICIPANT_FORM_TYPE_CHANGE, type);
  }
}

