const ParticipantsDistributionName: Record<string, string> = {
  to_60: 'до 60 лет',
  between_61_to_70: 'от 61 до 70 лет',
  between_71_to_80: 'от 71 до 80 лет',
  from_81: 'от 81 года',
  male: 'Мужчины',
  female: 'Женщины',
  disability_false: 'Отсутствует',
  disability_group_1: '1 группа',
  disability_group_2: '2 группа',
  disability_group_3: '3 группа',
  category_active: 'Активные ветераны',
  category_on_the_verge: 'Ветераны, на пороге зависимого состояния',
  category_help_dependent: 'Ветераны, зависимые от посторонней помощи',
  completed_repetitions_count: 'Оказано услуг',
  repetitions_count: 'Запланировано услуг',
};

enum ParticipantsDistributionType {
  BY_AGE = 'by_age',
  BY_CATEGORY = 'by_category',
  BY_DISABILITY_GROUP = 'by_disability_group',
  BY_GENDER = 'by_gender',
}

enum ParticipantsDistributionTypeRu {
  BY_AGE = 'По возрасту',
  BY_CATEGORY = 'По категории',
  BY_DISABILITY_GROUP = 'По группе инвалидности',
  BY_GENDER = 'По полу',
}

export {
  ParticipantsDistributionType,
  ParticipantsDistributionTypeRu,
  ParticipantsDistributionName,
};
