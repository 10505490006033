import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"flat":"","dense":"","outlined":"","сlearable":"","full-width":"","item-text":"label","item-value":"value","height":_vm.height,"items":_vm.options,"search-input":_vm.field.search,"placeholder":_vm.field.placeholder,"disabled":_vm.field.readonly,"error":!!_vm.field.error,"multiple":_vm.field.multiple,"error-messages":_vm.field.error,"hide-details":!_vm.field.error,"no-data-text":"Данные не найдены"},on:{"update:searchInput":function($event){return _vm.$set(_vm.field, "search", $event)},"update:search-input":[function($event){return _vm.$set(_vm.field, "search", $event)},_vm.onInput],"change":_vm.changeFieldValueHandler,"input":_vm.onInput},scopedSlots:_vm._u([(_vm.hasCheckedAllOptions)?{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""},on:{"click":_vm.checkedAllFieldsValueHandler}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":'indigo darken-4'}},[_vm._v(" "+_vm._s(_vm.settingsIcon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Выбрать все ")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}:null,(_vm.field.multiple)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.field.value.length)+" выбрано ")]):_vm._e()]}}:null],null,true),model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})}
var staticRenderFns = []

export { render, staticRenderFns }