


















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import tools from '@/tools';

@Component({})
export default class ParticipantFileImport extends Vue {
  private file: File | null = null;

  private isLoading = false;

  private isSuccess = false;

  private isResultDialogVisible = false;

  private resultDialogMessage = '';

  private errorMessage: string[] = [];

  private importedRows: number|null = null;

  private processedRows: number|null = null;

  private errorAmount: number|null = null;

  /**
   * Получить строку с размером файла
   * @return {string}
   */
  private get fileSizeString(): string {
    if (!this.file) {
      return '';
    }
    return tools.getFileSizeString(this.file.size);
  }

  /**
   * Обработка изменений в файловом инпуте
   */
  private fileChangeHandler(): void {
    this.errorMessage = [];
    if (!this.file) {
      return;
    }
    if (this.file.name.slice(-3) !== 'csv') {
      this.errorMessage.push('Нужно загрузить csv файл');
    } else if (this.file.size > 5 * (1024 ** 2)) {
      this.errorMessage.push('Объем файла не должен превышать 5 МБ');
      this.file = null;
    }
  }

  /**
   * Отправка файла на сервер
   */
  private sendFile(): void {
    if (!this.file) {
      return;
    }
    this.isLoading = true;
    this.$participantApi.importFromFile({
      file: this.file,
    }).then((response) => {
      this.isSuccess = !response.errors.length;
      this.importedRows = response.imported_rows_count ?? 0;
      this.processedRows = response.processed_rows_count ?? 0;
      this.errorAmount = response.errors.length;
      this.openDialog(response.errors.join(', '));

      if (!response.errors.length) {
        this.file = null;
      }
    }).catch((error) => {
      this.isSuccess = false;
      if (error.message) {
        this.errorMessage = error.message.file as string[];
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private openDialog(message?: string): void {
    this.isResultDialogVisible = true;
    this.resultDialogMessage = message || '';
  }

  private closeDialog(): void {
    this.isResultDialogVisible = false;
    if (this.isSuccess) {
      this.$router.push({
        name: 'member-list',
      });
    }
  }
}
