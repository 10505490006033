import ValidationEnum from '@/enums/Validation';

/**
 * класс для валидации
 */
export default class ValidationService {
  /**
   * Валидировать строку
   * @param value
   * @param type
   */
  static validateString(value: string, type: ValidationEnum): string {
    switch (type) {
      case ValidationEnum.CYRILLIC:
        return value.replace(/[^а-яё]/ig, '');
      case ValidationEnum.NUMBER_AND_POINT:
        return value.replace(/[^0-9.]/g, '');
      case ValidationEnum.SERVICE_CODE:
        // eslint-disable-next-line no-case-declarations
        const firstChar = value.slice(0, 1).replace(/[^a-zA-Z]/, '');
        // eslint-disable-next-line no-case-declarations
        const result = value.slice(1)
          .replace(/[^0-9.]/g, '');
        return firstChar + result;
      default:
        return value;
    }
  }
}
