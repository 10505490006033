



































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  BreadcrumbsItem,
  ExportParams,
  FormField,
  FormFieldCheckboxGroup,
  FormFieldSelectStringMultiple,
  HandbookFullDataItem,
  HandbookFullDataResponse,
  Nullable,
  SelectOption,
  UserInfo,
} from '@/types';
import { DataTableHeader } from 'vuetify';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import DataTableWrapper from '@/components/DataTableWrapper.vue';
import TablePropertyService from '@/services/TablePropertyService';
import { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import SettingsName from '@/enums/SettingsName';
import DataFilters from '@/components/DataFilters.vue';
import FormFieldTypeEnum from '@/enums/FormField';
import FiltersEntityEnum from '@/enums/FiltersEntity';
import { Program } from '@/types/program';
import properties from '@/data/program/TableHeaders';
import ExportsHelper from '@/helpers/Exports';
import PermissionEnums from '@/enums/PermissionEnums';
import ValidationEnum from '@/enums/Validation';
import { ProgramStatus, ProgramStatusText } from '@/enums/ProgramStatus';
import { CurrentPageListNames } from '@/enums/CurrentPageListNames';
import ListPageSettingsService from '@/services/list-page-settings/ListPageSettingsService';

@Component({
  components: {
    DataFilters,
    ConfirmationDialog,
    CustomSelect,
    Breadcrumbs,
    DataTableWrapper,
  },
  beforeRouteLeave(to, from, next): void {
    /**
     * Если мы переходим НЕ на детальную страницу, то сбрасываем пагиацию в сторе
     */
    if (to.name !== 'program-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PROGRAM);
    }
    next();
  },
  beforeRouteEnter(to, from, next): void {
    /**
     * Если мы пришли НЕ с детальной страницы, то сбрасываем пагиацию в сторе
     */
    if (from.name !== 'program-detail') {
      ListPageSettingsService.resetPageSettings(CurrentPageListNames.PROGRAM);
    }
    next();
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class ProgramList extends Vue {
  private user!: UserInfo;

  private NAME_EXPORT_FILE = 'programList';

  private loading = true;

  private loadingStartConfig = true;

  private loadingFilters = true;

  private showFilter = true;

  private handbookFullData: HandbookFullDataItem[] | null = null;

  private sortBy: string[] = [];

  private sortDesc: boolean[] = [];

  private loadingExport = false;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
    {
      text: 'Список программ',
      href: '/program',
      disabled: true,
    },
  ];

  private firstName: FormField = {
    value: null,
    name: 'name',
    placeholder: 'Введите данные',
    error: '',
    label: 'Имя',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  }

  private lastName: FormField = {
    value: null,
    name: 'lastName',
    placeholder: 'Введите данные',
    error: '',
    label: 'Фамилия',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  }

  private secondName: FormField = {
    value: null,
    name: 'secondName',
    placeholder: 'Введите данные',
    error: '',
    label: 'Отчество',
    fieldType: FormFieldTypeEnum.TEXT,
    validation: ValidationEnum.CYRILLIC,
  }

  private category: FormFieldCheckboxGroup = {
    label: 'Категория',
    value: [],
    name: 'category',
    error: '',
    fieldType: FormFieldTypeEnum.SELECT,
    placeholder: 'Выберите из списка',
    multiple: true,
  }

  private organization: FormFieldCheckboxGroup = {
    label: 'Организация',
    value: [],
    name: 'organization',
    error: '',
    fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
    placeholder: 'Выберите из списка',
    multiple: true,
  }

  private status: FormFieldCheckboxGroup = {
    label: 'Статус',
    value: [],
    name: 'status',
    error: '',
    options: [
      {
        label: ProgramStatusText.ACTIVE,
        value: ProgramStatus.ACTIVE,
      },
      {
        label: ProgramStatusText.ARCHIVE,
        value: ProgramStatus.ARCHIVE,
      },
    ],
    fieldType: FormFieldTypeEnum.SELECT,
    placeholder: 'Выберите из списка',
    multiple: false,
  }

  private settingsSelect: FormFieldSelectStringMultiple = {
    label: 'Настройки колонок',
    name: 'column_settings',
    value: [],
    error: '',
  }

  private allFields = [
    this.lastName,
    this.category,
    this.firstName,
    this.organization,
    this.secondName,
    this.status,
  ];

  private defaultTableHeaders: DataTableHeader[] = [
    {
      text: 'ID',
      value: 'id',
      width: '5%',
      class: 'custom-table-heading',
    },
    {
      text: 'Дата завершения',
      value: 'ends_at',
      width: '15%',
      class: 'custom-table-heading',
    },
    {
      text: 'Фамилия',
      value: 'last_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Имя',
      value: 'first_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Отчество',
      value: 'second_name',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Услуги (шт.)',
      value: 'services_count',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Организация',
      value: 'organisation',
      width: '12%',
      class: 'custom-table-heading',
    },
    {
      text: 'Статус',
      value: 'status',
      width: '12%',
      class: 'custom-table-heading',
    },
  ];

  private currentProgramId: number | null = null;

  /**
   * Получить идентификацию фильтров
   */
  private get entityFilters(): FiltersEntityEnum {
    return FiltersEntityEnum.PROGRAMS;
  }

  /**
   * Получить заголовки для таблицы в соответствии с настройками
   * @return {DataTableHeader[]}
   */
  private get tableHeaders(): DataTableHeader[] {
    return this.defaultTableHeaders.filter((header) => {
      if (this.settingsSelect.value) {
        return this.settingsSelect.value.indexOf(header.value) !== -1;
      }
      return true;
    });
  }

  private tableItems: Array<Program.Item> = [];

  private itemCounts = {
    from: 0,
    to: 0,
    total: 0,
  }

  private pagesCount = 0;

  private currentPage = 1;

  private isDeleteConfirmDialogVisible = false;

  mounted(): void {
    const storeSettings = ListPageSettingsService.getListPageSettingsFromStore(
      CurrentPageListNames.PROGRAM,
    );
    this.currentPage = storeSettings?.currentPage ?? 1;
    this.sortBy = storeSettings?.sort?.sortBy ?? [];
    this.sortDesc = storeSettings?.sort?.sortDesc ?? [];
    this.getHandbookFullData();
    this.getCompanies();
    this.addEventListeners();
    this.setStatusExportFile();
  }

  beforeDestroy(): void {
    this.unbindEvents();
  }

  /**
   * Определить может ли управлять программой
   * @private
   */
  private get canManageProgram(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.PROGRAM_MANAGE) !== -1;
  }

  /**
   * Добавить обработчики
   * @private
   */
  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Получить список организаций
   */
  private getCompanies(): void {
    this.$organizationApi.getShortList().then((response) => {
      this.organization.options = response.map((item) => ({
        value: item.id,
        text: item.name,
        label: item.name,
      }));
    }).catch((error) => {
      window.console.log(error);
    });
  }

  /**
   * Создать параметры запроса
   */
  private createRequest(): Nullable<Program.ListRequest> {
    let sort = '';
    if (typeof this.sortDesc[0] !== 'undefined' && typeof this.sortBy[0] !== 'undefined') {
      const sortDirection = this.sortDesc[0] ? '-' : '';
      sort = `${sortDirection}${this.sortBy}`;
    }
    return {
      page: this.currentPage,
      sort,
      'participant-first_name': this.firstName.value,
      'participant-last_name': this.lastName.value,
      'participant-second_name': this.secondName.value,
      'participant-organisation_id': this.organization.value?.join(',') || null,
      'participant-individual_category-category': this.category.value?.join(',') || null,
      status: this.status.value?.toString() ?? '',
    };
  }

  /**
   * Получить список программ
   */
  private getItems(): void {
    this.loading = true;
    this.$programApi.getList(this.createRequest()).then((response) => {
      this.tableItems = response.data;
      this.itemCounts = {
        from: response.from,
        to: response.to,
        total: response.total,
      };
      this.pagesCount = response.last_page;
      this.currentPage = response.current_page;
      this.setStoreSettingsPage();
    }).finally(() => {
      this.loading = false;
    });
  }

  /**
   * Получить трансформированные данные для списка
   */
  private get listItems(): Array<{
    id: number,
    ends_at: string,
    last_name: string,
    first_name: string,
    second_name: string,
    services_count: number,
    organisation: string,
  }> {
    return this.tableItems.map((item) => ({
      id: item.id,
      ends_at: item.ends_at,
      last_name: item.participant.last_name,
      first_name: item.participant.first_name,
      second_name: item.participant.second_name || '',
      services_count: item.services_count,
      organisation: item.participant?.organisation.name || '',
      status: item.status === ProgramStatus.ACTIVE
        ? ProgramStatusText.ACTIVE : ProgramStatusText.ARCHIVE,
    }));
  }

  /**
   * Обработчик клика по кнопке поиска
   */
  private applyFilter(): void {
    this.currentPage = 1;
    this.getItems();
  }

  /**
   * Сбросить фильтр
   */
  private resetFilter(): void {
    this.allFields.forEach((field) => {
      field.value = null;
    });
    this.applyFilter();
  }

  /**
   * Обработчик клика показа фильтров
   * @private
   */
  private toggleFilterVisibility(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * Обработка изменения настроек таблицы
   * @param data
   */
  private changeSettingsHandler(data: string[]): void {
    this.settingsSelect.value = data;

    TablePropertyService.setSettings(this.settingsSelect.name, data);
  }

  /**
   * Получить все данные по спискам справочникам
   */
  private getHandbookFullData(): void {
    this.loadingStartConfig = true;
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.loadingStartConfig = false;
        this.handbookFullData = response;
        this.createFieldsOptions();
      });
  }

  /**
   * Создать опции для поля
   */
  private createOptions(code: HandbookCode): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }
    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(code, this.handbookFullData),
    );
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.category.options = this.createOptions(HandbookCode.INDIVIDUAL_CATEGORY);

    this.settingsSelect.options = TablePropertyService.getOptionsFromTableHeaders(
      this.defaultTableHeaders,
    );

    const defaultSettings = TablePropertyService
      .getSetting((this.settingsSelect.name as SettingsName));
    if (defaultSettings && JSON.parse(defaultSettings).length) {
      const allValue = this.settingsSelect.options?.map((option) => (option.value));

      if (typeof allValue === 'undefined') {
        return;
      }
      this.settingsSelect.value = (JSON.parse(defaultSettings) as Array<string>)
        .filter((value) => allValue.indexOf(value) !== -1);
      this.changeSettingsHandler(this.settingsSelect.value);
    } else {
      this.settingsSelect.value = this.defaultTableHeaders.map((header) => header.value);
    }
  }

  /**
   * Экспорт списка
   */
  private exportList(): void {
    this.loadingExport = true;
    const exportParams = ExportsHelper.getExportParams(properties, this.settingsSelect.value);
    const requestParams = {
      ...this.createRequest(),
      ...exportParams,
    } as Nullable<Program.ListRequest> & ExportParams;

    this.$programApi.exportList(
      requestParams,
    ).then((response) => {
      this.$exportFileService.startUnLoading(this.NAME_EXPORT_FILE, {
        exportId: response.exportId,
        extension: response.extension,
      });
    });
  }

  /**
   * обработчик клика по строке таблицы
   */
  private clickTableRowHandler(programId: number): void {
    this.$router.push({ name: 'program-detail', params: { id: programId.toString() } });
  }

  /**
   * Клик по кнопке сбросить
   */
  private clickResetFilterHandler(): void {
    this.resetFilter();
  }

  /**
   * Клик по кнопке отправить
   */
  private clickSubmitFilterHandler(): void {
    this.applyFilter();
  }

  /**
   * Клик на кнопку экспорта
   */
  private clickExportHandler(): void {
    this.exportList();
  }

  /**
   * Загрузки фильтров
   */
  private loadedFilterHandler(): void {
    this.loadingFilters = false;
    this.$nextTick(() => {
      this.getItems();
    });
  }

  /**
   * Удаление программы
   */
  private deleteProgram(programId: number): void {
    this.$programApi.deleteProgram({
      id: programId,
    }).then((response) => {
      if (response.success) {
        this.hideDeleteConfirmDialog();
        this.getItems();
      }
    });
  }

  /**
   * Показать диалог подтверждения
   * @param programId
   */
  private showDeleteConfirmDialog(programId: number): void {
    this.currentProgramId = programId;
    this.isDeleteConfirmDialogVisible = true;
  }

  /**
   * Скрыть диалог подтверждения
   */
  private hideDeleteConfirmDialog(): void {
    this.currentProgramId = null;
    this.isDeleteConfirmDialogVisible = false;
  }

  /**
   * Обработчик сортировки
   * @param sort
   * @private
   */
  private changeSortHandler(sort: {sortBy: string[], sortDesc: boolean[]}): void {
    this.sortBy = sort.sortBy;
    this.sortDesc = sort.sortDesc;

    this.setStoreSettingsPage();
    this.getItems();
  }

  /**
   * Устанавливаем статус экспорта файла
   */
  private setStatusExportFile(): void {
    this.toggleLoadingExportFile(
      this.$exportFileService.getStatusLoadingExportFileFromCache(this.NAME_EXPORT_FILE),
    );
  }

  /**
   * Меняем лоадер експорта файла
   * @param isLoading
   * @private
   */
  private toggleLoadingExportFile(isLoading: boolean):void {
    this.loadingExport = isLoading;
  }

  /**
   * Убиваем обработчики
   * @private
   */
  private unbindEvents():void {
    this.$eventBus.$off(this.$eventBus.ALREADY_UNLOADING,
      () => this.toggleLoadingExportFile(false));
  }

  /**
   * Устанавливаем в стор, номер текущей страницы
   * @private
   */
  setStoreSettingsPage():void {
    this.$store.dispatch('setListPageSetting', {
      pageTitle: CurrentPageListNames.PROGRAM,
      currentPage: this.currentPage,
      sort: {
        sortDesc: this.sortDesc,
        sortBy: this.sortBy,
      },
    });
  }
}
