import {
  DetailMembers,
  DetailedResponseType,
  HandbookFullDataResponse,
  ParticipantInfoBase,
  AddressDetails,
  HandbooksFormFieldValue, FamilyMember, FamilyMemberRequest, HealthStatusResponse,
  OrganisationResponse, ParticipantInfo, Company, IndividualCategoryResponse,
} from '@/types';
import MembersDetailsSchema from '@/data/participants/MembersDetailsSchema';
import FormFieldService from '@/services/FormFieldService';
import HandbookHelper, { HandbookCode } from '@/enums/HandbookList';
import tools from '@/tools';
import HandbookService from '@/services/HandbookService';
import { StatusCode } from '@/enums/ParticipantStatus';
import ParticipantService from '@/services/ParticipantService';

type DataTypeInfo = ParticipantInfoBase & {
  organisation: Company,
}
type DataTypeFamilyMember = FamilyMember;

type DataTypeHealthStatus = HealthStatusResponse;

type DataTypeIndividualCategory = IndividualCategoryResponse;

type DataTypeParticipantArchiveStatus = ParticipantInfo;

type getOneTierDataFunction = {
  (response: ParticipantInfo, type: DetailedResponseType): DataTypeInfo|DataTypeHealthStatus
}

/**
 * Класс возвращает модельку для компонента DetailedInformation
 */
export default class DetailedMembersTransform {
  /**
   * Метод возвращает одноуровневую структуру данных
   * @param response
   * @param type
   */
  private static getOneTierData: getOneTierDataFunction = (
    response,
    type,
  ) => {
    const { info, organisation, health_status } = response;

    switch (type) {
      case 'info':
        return {
          ...info,
          organisation,
        };
      case 'healthStatus':
        return ({
          ...health_status,
        } as DataTypeHealthStatus);
      default:
        return ({} as DataTypeInfo);
    }
  }

  /**
   * Метод возвращает item для деталки, из схема строки
   * @param data
   * @param rows
   * @param handbooks
   * @private
   */
  private static getDetailMembersItemFromSchemaRow(
    data: DataTypeInfo
      |DataTypeFamilyMember
      |DataTypeHealthStatus
      |DataTypeIndividualCategory
      |DataTypeParticipantArchiveStatus,
    rows: string[],
    handbooks: HandbookFullDataResponse,
  ): DetailMembers.Item[] {
    const result: DetailMembers.Item[] = rows.map((row) => {
      const allHandbookNames = HandbookHelper.getAllHandBookName();
      let value: unknown = '';
      const item: DetailMembers.Item = {
        text: '-',
        title: {
          text: FormFieldService.getLabelField(row),
          tooltip: '',
        },
      };

      Object.keys(data).forEach((key) => {
        const currentKey: keyof typeof data = (key as never);

        if (currentKey !== row) {
          return;
        }

        value = data[currentKey];
      });

      if (typeof value === 'boolean') {
        item.text = value ? 'Да' : 'Нет';
        return item;
      }

      const isDisabilityEndValue = row === 'disability_date_end';
      const isIndefiniteDisability = Object.prototype.hasOwnProperty
        .call(data, 'indefinite_disability') ? (data as HealthStatusResponse).indefinite_disability : false;

      if (isDisabilityEndValue && isIndefiniteDisability) {
        item.text = 'Бессрочно';
        return item;
      }

      if (!value || typeof value === 'undefined') {
        return item;
      }

      const isOrganization = row === 'organisation';
      const isAddress = row === 'address' || row === 'registration_address';
      const isHandbooksValue = allHandbookNames.indexOf((row as string)) !== -1;
      const isAdditionalHandbooksValue = !!HandbookHelper.getOriginalHandbooksName(row);
      const isStatus = row === 'status';

      if (!isOrganization
        && !isAddress
        && !isHandbooksValue
        && !isAdditionalHandbooksValue
        && !isStatus
      ) {
        item.text = (value as string).toString();

        return item;
      }

      if (isStatus) {
        item.text = ParticipantService.getItemStatus(value as StatusCode);
      }

      if (isOrganization) {
        item.text = (value as OrganisationResponse).name;
      }

      if (isAddress) {
        item.text = tools.getLabelFromAddressDetailType((value as AddressDetails));
      }

      if (isHandbooksValue || isAdditionalHandbooksValue) {
        const handbookCode = isAdditionalHandbooksValue
          ? HandbookHelper.getOriginalHandbooksName(row) : row;
        item.text = HandbookService.getTitleFromValues(
          handbooks,
          (handbookCode as HandbookCode),
          (value as HandbooksFormFieldValue),
        );
      }

      return item;
    });

    return result;
  }

  /**
   * Получить данные для члена семьи
   * @param member
   * @param handbooks
   * @param type
   * @private
   */
  private static getDataFromFamilyMember(
    member: FamilyMemberRequest,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    const schema = MembersDetailsSchema.getSchema(type);

    const result = schema.map((schemaItem) => ({
      title: schemaItem.title,
      items: this.getDetailMembersItemFromSchemaRow(
        (member as DataTypeFamilyMember),
        schemaItem.rows,
        handbooks,
      ),
    }));

    return {
      payload: result,
    };
  }

  /**
   * Получить данные для статусы здровья
   * @param healthStatus
   * @param handbooks
   * @param type
   * @private
   */
  private static getDataFromHealthStatus(
    healthStatus: HealthStatusResponse,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    const schema = MembersDetailsSchema.getSchema(type);

    const result = schema.map((schemaItem) => ({
      title: schemaItem.title,
      items: this.getDetailMembersItemFromSchemaRow(healthStatus, schemaItem.rows, handbooks),
    }));

    return {
      payload: result,
    };
  }

  /**
   * Получить данные для индивидуальной категории
   * @param individualCategory
   * @param handbooks
   * @param type
   * @private
   */
  private static getDataFromIndividualCategory(
    individualCategory: IndividualCategoryResponse,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    const schema = MembersDetailsSchema.getSchema(type);

    const result = schema.map((schemaItem) => ({
      title: schemaItem.title,
      items: this.getDetailMembersItemFromSchemaRow(individualCategory, schemaItem.rows, handbooks),
    }));

    return {
      payload: result,
    };
  }

  /**
   * Получить данные для индвидуальных данных участника
   * @param response
   * @param handbooks
   * @param type
   * @private
   */
  private static getDataFromInfo(
    response: ParticipantInfo,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    const data = this.getOneTierData(response, type);
    const schema = MembersDetailsSchema.getSchema(type);

    const result = schema.map((schemaItem) => ({
      title: schemaItem.title,
      items: this.getDetailMembersItemFromSchemaRow(data, schemaItem.rows, handbooks),
    }));

    return {
      payload: result,
    };
  }

  /**
   * Детальные данные для статуса
   * @param response
   * @param handbooks
   * @param type
   * @private
   */
  private static getDataFromStatus(
    response: ParticipantInfo,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    const schema = MembersDetailsSchema.getSchema(type);

    const result = schema.map((schemaItem) => ({
      title: schemaItem.title,
      items: this.getDetailMembersItemFromSchemaRow(response, schemaItem.rows, handbooks),
    }));

    return {
      payload: result,
    };
  }

  /**
   * Получить данные для деталки
   * @param response
   * @param handbooks
   * @param type
   */
  static getDataFromDetailsView(
    response: ParticipantInfo|FamilyMemberRequest|HealthStatusResponse|IndividualCategoryResponse,
    handbooks: HandbookFullDataResponse,
    type: DetailedResponseType,
  ): DetailMembers.Data {
    switch (type) {
      case 'info':
        return this.getDataFromInfo((response as ParticipantInfo), handbooks, type);
      case 'familyMembers':
        return this.getDataFromFamilyMember((response as FamilyMemberRequest), handbooks, type);
      case 'healthStatus':
        return this.getDataFromHealthStatus((response as HealthStatusResponse), handbooks, type);
      case 'individualCategory':
        return this.getDataFromIndividualCategory(
          (response as IndividualCategoryResponse),
          handbooks,
          type,
        );
      case 'participantStatus':
        return this.getDataFromStatus(
          (response as ParticipantInfo), handbooks, type,
        );
      default:
        return ({} as DetailMembers.Data);
    }
  }
}
