export enum ParticipantFormType {
  MANUAL = 'manual',
  FILE_IMPORT = 'file-import',
}

export enum ParticipantIndividualCategory {
  DEPENDENT= 1,
  MIDDLE = 2,
  ACTIVE = 3,
}

/**
 * Получить класс для секции шага в деталки участника
 * @param step
 */
export const getSectionClassByStepNumber = (step: number): string => {
  switch (step) {
    case 1:
      return 'step-way-to-add';
    case 2:
      return 'step-personal-information';
    case 3:
      return 'step-family-members';
    case 4:
      return 'step-health-status';
    case 5:
      return 'step-individual-category';
    case 6:
      return 'step-status';
    default:
      return '';
  }
};
