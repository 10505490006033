import Vue from 'vue';
import { AxiosError } from 'axios';

/**
 * Апи по работе с ошибками
 */

export default class ErrorApi extends Vue {
  public static readonly ERROR_VALIDATION = 2;

  public static readonly ERROR_UNAUTHORIZED = 401;

  public static readonly ERROR_FORBIDDEN = 403;

  public readonly EVENT_SHOW_SERVER_ERROR = 'show_server_error'

  public readonly EVENT_SHOW_UNAUTHORIZED_ERROR = 'show_unauthorized_error';

  public readonly EVENT_SHOW_FORBIDDEN_ERROR = 'show_forbidden_error';

  /**
   * Показать серверную ошибку
   * @param message
   * @private
   */
  private showServerError(message: string): void {
    this.$eventBus.$emit(this.$errorApi.EVENT_SHOW_SERVER_ERROR, message);
  }

  /**
   * Показать ошибку авторизации
   * @param message
   * @private
   */
  private showUnauthorizedError(message: string): void {
    this.$eventBus.$emit(this.$errorApi.EVENT_SHOW_UNAUTHORIZED_ERROR, message);
  }

  /**
   * Показать ошибку отсутвия прав
   * @param message
   * @private
   */
  private showForbiddenError(message: string): void {
    this.$eventBus.$emit(this.$errorApi.EVENT_SHOW_FORBIDDEN_ERROR, message);
  }

  /**
   * Обработать ошибки
   * @param error
   */
  public processErrors(error: AxiosError): void {
    if (typeof error.response === 'undefined') {
      this.showServerError('Неизвестная ошибка');
      return;
    }

    const { status } = error.response;

    if (status === ErrorApi.ERROR_UNAUTHORIZED) {
      this.showUnauthorizedError('Ошибка авторизации');
      return;
    }

    if (status === ErrorApi.ERROR_FORBIDDEN) {
      this.showForbiddenError('Ошибка прав доступа');
      return;
    }

    const errorMessage = error.response.data?.error
      || error.response.data?.message
      || `Произошла ошибка\n${error}`;

    this.showServerError(errorMessage);
  }
}
