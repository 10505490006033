





































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    text: {
      type: String,
      default: 'Внесеные изменения не сохранятся',
    },
    saveCallback: {
      type: Function,
      required: true,
    },
    notSaveCallback: {
      type: Function,
      required: true,
    },
  },
})

export default class DataWillNotSaved extends Vue {
  private closeModalHandler(): void {
    this.$emit('close-modal');
  }
}
