






















































































































import ParticipantSection from '@/components/participant/ParticipantSection.vue';
import Component from 'vue-class-component';
import {
  CalculateIndividualCategoryResponse,
  DetailMembers,
  ErrorResponse,
  FormFieldCheckbox,
  FormFieldSelect,
  IndividualCategoryResponse,
  ParticipantIndividualCategoryCreateOrUpdateRequest,
} from '@/types';
import { HandbookCode } from '@/enums/HandbookList';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import TextField from '@/components/form-fields/TextField.vue';
import ErrorApi from '@/api/errorApi';
import RowText from '@/components/RowText.vue';
import DataWillNotSaved from '@/components/modals/DataWillNotSaved.vue';
import FormActions from '@/components/buttons/FormActions.vue';
import DetailedMembersTransform from '@/services/transform-response/DetailedMembers';
import DetailedInformation from '@/components/DetailedInformation.vue';
import HandbookService from '@/services/HandbookService';

@Component({
  components: {
    DetailedInformation,
    RowText,
    TextField,
    CustomSelect,
    DataWillNotSaved,
    FormActions,
  },
  props: {
    individualCategoryData: {
      type: Object,
      default: null,
    },
  },
})

export default class ParticipantSectionIndividualCategory extends ParticipantSection {
    private individualCategoryData!: IndividualCategoryResponse|null;

    private assistanceProgram: FormFieldCheckbox = {
      label: 'Включен в программу помощи маломобильным гражданам на дому',
      name: 'assistance_program',
      error: '',
      value: null,
    }

  private categoryId: number|null = null;

  private loading = false;

  private physicalActivityGroup: FormFieldSelect = {
    name: 'physical_activity_group',
    value: null,
    error: '',
    label: 'Группа физической активности',
    required: true,
  }

  private autonomyGroup: FormFieldSelect = {
    name: 'autonomy_group',
    value: null,
    error: '',
    label: 'Группа автономности',
    required: true,
  }

  private categoryValue = '';

  public allFields = [
    this.autonomyGroup,
    this.assistanceProgram,
    this.physicalActivityGroup,
  ]

  created(): void {
    this.createFieldsOptions();
    this.fillData();
  }

  /**
   * Получить заголовок секции
   */
  private get sectionTitle(): string {
    return this.participantIsActive
      ? '4. Категория физического лица'
      : 'Шаг 5. Категория физического лица';
  }

  private get detailedData(): DetailMembers.Data|null {
    if (!this.individualCategoryData) {
      return null;
    }

    return DetailedMembersTransform.getDataFromDetailsView(
      this.individualCategoryData,
      this.handBooksData,
      'individualCategory',
    );
  }

  /**
   * Заполнить форму данными
   */
  private fillData(): void {
    if (!this.individualCategoryData) {
      return;
    }
    const {
      physical_activity_group: physicalValue,
      autonomy_group: autonomyValue,
      assistance_program: assistanceValue,
      category,
    } = (this.individualCategoryData as IndividualCategoryResponse);
    this.physicalActivityGroup.value = physicalValue;
    this.autonomyGroup.value = autonomyValue;
    this.assistanceProgram.value = assistanceValue;
    this.categoryId = category;
    this.categoryValue = HandbookService.getTitleFromValues(
      this.handBooksData,
      HandbookCode.INDIVIDUAL_CATEGORY,
      category,
    );
  }

  /**
   * Посчитать категорию
   */
  private async calculateCategory(): Promise<void> {
    if (!this.autonomyGroup.value || !this.physicalActivityGroup.value) {
      return;
    }
    const params = {
      physical_activity_group: +this.physicalActivityGroup.value,
      autonomy_group: +this.autonomyGroup.value,
    };

    try {
      const category: CalculateIndividualCategoryResponse = await this
        .$participantApi.calculateIndividualCategory(params);
      this.categoryId = category.category;

      this.categoryValue = HandbookService.getTitleFromValues(
        this.handBooksData,
        HandbookCode.INDIVIDUAL_CATEGORY,
        category.category,
      );
      this.categoryId = category.category;
    } catch (e) {
      this.categoryValue = '';
    }
  }

  /**
   * Создать опции для всех полей
   */
  private createFieldsOptions(): void {
    this.physicalActivityGroup.options = this.createOptions(HandbookCode.PHYSICAL_ACTIVITY);
    this.autonomyGroup.options = this.createOptions(HandbookCode.AUTONOMY);
  }

  /**
   * Создать запрос
   */
  private createRequest(): ParticipantIndividualCategoryCreateOrUpdateRequest {
    const individualId = this.individualCategoryData ? this.individualCategoryData.id : '';

    return {
      id: (individualId as number),
      physical_activity_group: this.physicalActivityGroup.value
        ? +this.physicalActivityGroup.value : null,
      assistance_program: this.assistanceProgram.value ? '1' : '0',
      autonomy_group: this.autonomyGroup.value ? +this.autonomyGroup.value : null,
      category: this.categoryId,
    };
  }

  /**
   * Отправить форму
   */
  private submitForm(): Promise<void> {
    return new Promise((resolve) => {
      this.loading = true;
      this.resetError();
      this.$participantApi.createOrUpdateIndividualCategory(
        {
          id: +this.participantId,
          data: this.createRequest(),
        },
      ).then((response) => {
        if (response.success) {
          resolve();
          if (!this.participantIsActive || !this.individualCategoryData) {
            this.$emit('ready-last-step');
            this.$toast.success('Новый участник сохранен');
            return;
          }
          this.$emit('show-save-popup');
        }
      })
        .catch((err: ErrorResponse) => {
          if (err.code === ErrorApi.ERROR_VALIDATION && typeof err.message !== 'undefined') {
            this.showNotSaveDialog = false;
            this.setError(err.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }

  /**
   * Установить ошибки в полях
   * @param message
   * @private
   */
  private setError(message: {[key: string]: Array<string>}): void {
    this.allFields.forEach((field) => {
      const { name } = field;

      const currentField = field;

      currentField.error = '';

      if (typeof message[name] === 'undefined') {
        return;
      }

      currentField.error = message[name];
    });
  }

  /**
   * На изменения полей
   * @private
   */
  private onChangeField(): void {
    if (!this.autonomyGroup.value || !this.physicalActivityGroup.value) {
      this.categoryValue = '';
      return;
    }
    this.calculateCategory();
  }

  /**
   * Обработчик клика по кнопке сохранить в режиме созданого юзера
   * @private
   */
  private clickSaveButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.statusBlock = 'readonly';
        this.showNotSaveDialog = false;
        this.emitEventEndEdit();
      });
  }

  /**
   * Клик по кнопке принять
   * @private
   */
  private clickApplyButtonHandle(): void {
    this.submitForm()
      .then(() => {
        this.showNotSaveDialog = false;
      });
  }

  /**
   * Клик по кнопке отмена
   * @private
   */
  private closeClickHandle(): void {
    this.showNotSaveDialog = true;
  }

  /**
   * Клик по кнопке не сохранять
   * @private
   */
  private clickNotSaveHandle(): void {
    this.fillData();
    this.emitEventEndEdit();
    this.showNotSaveDialog = false;
    this.statusBlock = 'readonly';
  }
}
