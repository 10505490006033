

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmationDialog extends Vue {
  @Prop() private readonly text!: string;

  @Prop({
    default: 420,
  }) private readonly width!: number;

  /**
   * Текст диалога
   * @return {string}
   */
  private get dialogText(): string {
    return this.text ?? 'Вы уверены, что хотите удалить элемент?';
  }

  /**
   * Обработка нажатия на кнопку "Да"
   */
  private onConfirm(): void {
    this.$emit('confirm');
  }

  /**
   * Обработка нажатия на кнопку "Нет"
   */
  private onCancel(): void {
    this.$emit('cancel');
  }
}
