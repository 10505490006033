import { SelectOption, TProperty } from '@/types';
import { DataTableHeader } from 'vuetify';
import SettingsName from '@/enums/SettingsName';

interface GetOptionFunction {
  (properties: TProperty): SelectOption[];
}

interface GetHeadingsFunction {
  <T extends TProperty, K extends keyof T>(properties: T, keys: K[]|null): DataTableHeader[],
}

/**
 * Сервис для работы с настройками колонок таблиц
 */
export default class TablePropertyService {
  /**
   * Установить настройки в локал стораж
   * @param name
   * @param data
   */
  static setSettings(name: SettingsName|string, data: string[]|null): void {
    if (data) {
      localStorage.setItem(name, JSON.stringify(data));
    } else {
      localStorage.removeItem(name);
    }
  }

  /**
   * Получить настройки из локал стоража
   * @param name
   */
  static getSetting(name: SettingsName|string): string|null {
    const data = localStorage.getItem(name);

    return data;
  }

  /**
   * получить опции для выпадашки настроек
   * @param properties
   */
  static getOptions: GetOptionFunction = (properties) => {
    const options: SelectOption[] = [];
    Object.keys(properties).forEach((key) => {
      options.push({
        value: key,
        label: properties[key].headerItem.text,
      });
    });

    return options;
  }

  /**
   * Получить опции для списка настроек из массива заголовков таблицы
   * @param headers
   * @return {{label: string, value: string}[]}
   */
  static getOptionsFromTableHeaders = (
    headers: DataTableHeader[],
  ): SelectOption[] => headers.map((header) => ({
    value: header.value,
    label: header.text,
  }))

  /**
   * Получить заголовки для таблицы
   * @param properties
   * @param keys
   */
  static getHeadings: GetHeadingsFunction = (properties, keys) => {
    const result: DataTableHeader[] = [];

    if (!keys || !keys.length) {
      return result;
    }

    Object.keys(properties).forEach((key) => {
      if ((<string[]>keys).indexOf(key) !== -1) {
        result.push(properties[key].headerItem);
      }
    });

    return result;
  }
}
