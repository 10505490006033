



















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import {
  AddressDetails,
  BreadcrumbsItem,
  Company,
  CompanyCreateRequest,
  FormField,
  FormFieldSelect,
  FormFieldWithAjaxSearch,
  HandbookFullDataItem, HandbookFullDataResponse,
  SelectOption,
  UserInfo, VersionData, VersionClickData,
} from '@/types';
import PermissionEnums from '@/enums/PermissionEnums';
import tools from '@/tools';
import { HandbookCode } from '@/enums/HandbookList';
import SelectWithSearchAddress from '@/components/form-fields/SelectWithSearchAddress.vue';
import CustomSelect from '@/components/form-fields/CustomSelect.vue';
import TextField from '@/components/form-fields/TextField.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    Breadcrumbs,
    TextField,
    CustomSelect,
    SelectWithSearchAddress,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})

export default class OrganisationDetailed extends Vue {
  private versionData: VersionData|null = null;

  private versionBarIsOpen = false;

  private user!: UserInfo;

  private companyData: Company|null = null;

  private isEditPage = !!this.$route.params.id;

  private isSearchButtonDisabled = false;

  private showApplyDialog = false;

  private hasChanges = false;

  private showDialog = false;

  private isLoading = false;

  private isSkipStatistics = false;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
      exact: true,
    },
    {
      text: 'Список организаций',
      href: '/companies',
      disabled: false,
      exact: true,
    },
    {
      text: `${this.isEditPage ? this.shortNameString || 'Редактирование организации' : 'Добавление организации'}`,
      href: '',
      disabled: true,
    },
  ];

  private taxNumberSearch: FormField = {
    placeholder: 'Введите ИНН',
    value: '',
    error: '',
    name: 'innSearch',
    label: 'Автоматическое заполнение реквизитов по ИНН ',
    disabled: !this.canManageOrganisations,
  };

  private shortName: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'name',
    label: 'Наименование (краткое)',
    required: true,
    disabled: !this.canManageOrganisations,
  };

  private fullName: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'full_name',
    label: 'Наименование (полное)',
    required: true,
    disabled: !this.canManageOrganisations,
  };

  private polygon: FormFieldSelect = {
    value: null,
    name: 'polygon',
    placeholder: 'Выберите из списка',
    error: '',
    label: 'Полигон',
    required: true,
    options: [],
  }

  private taxNumber: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'inn',
    label: 'ИНН',
    required: true,
    disabled: !this.canManageOrganisations,
  };

  private kpp: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'kpp',
    label: 'КПП',
    required: true,
    disabled: !this.canManageOrganisations,
  };

  private ogrn: FormField = {
    placeholder: 'Введите данные',
    value: '',
    error: '',
    name: 'ogrn',
    label: 'ОГРН',
    required: true,
    disabled: !this.canManageOrganisations,
  };

  private address: FormFieldWithAjaxSearch = {
    placeholder: 'Введите адрес',
    value: null,
    error: '',
    name: 'physical_address',
    label: 'Индекс, город, улица, дом, помещение',
    disabled: !this.canManageOrganisations,
    search: '',
    required: true,
  };

  private compositeAddress: AddressDetails = {
    postal_code: '',
    region: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    floor: '',
    block: '',
  };

  private fieldsRender = [
    this.shortName,
    this.fullName,
    this.taxNumber,
    this.polygon,
    this.kpp,
    this.ogrn,
    this.address,
  ];

  private allFields = [
    ...this.fieldsRender,
  ];

  private toUrl: Route|null = null;

  private handbookFullData: HandbookFullDataItem[]|null = null;

  /**
   * Хук для обработки ухода со страницы
   * если это страница редактирования, и есть изменения - нужно показать диалоговое окно
   * @param to
   * @param from
   * @param next
   */
  beforeRouteLeave(to: Route, from: Route, next: () => void): void {
    if (!this.hasChanges || !this.isEditPage) {
      next();
    }
    this.toUrl = to;
    this.showDialog = true;
  }

  mounted(): void {
    this.$handbookApi.getFullData()
      .then((response: HandbookFullDataResponse) => {
        this.handbookFullData = response;
        this.polygon.options = this.polygonOptions;
      });

    if (this.companyId) {
      this.isLoading = true;
      this.getCompanyData();
      this.addEventListeners();
    }
  }

  beforeDestroy(): void {
    this.$eventBus.$off(this.$eventBus.VERSION_PANEL_OPEN);

    this.$eventBus.$off(this.$eventBus.VERSION_PANEL_CLOSE);
  }

  private addEventListeners(): void {
    this.$eventBus.$on(this.$eventBus.VERSION_PANEL_CLOSE, () => {
      this.versionBarIsOpen = false;
      this.versionData = null;
      this.isLoading = true;
      this.resetSuccessFields();
      this.getCompanyData();
    });

    this.$eventBus.$on(this.$eventBus.VERSION_PANEL_OPEN, (data: VersionData) => {
      this.versionData = data;
    });

    this.$eventBus.$on(this.$eventBus.VERSION_ITEM_CLICK, this.clickHistoryItemHandler);
  }

  /**
   * Опции для фильтра полигона
   */
  private get polygonOptions(): SelectOption[] {
    if (!this.handbookFullData || !this.handbookFullData.length) {
      return [];
    }

    return tools.transformHandbookValueToOption(
      tools.getHandbookValues(HandbookCode.POLYGON, this.handbookFullData),
    );
  }

  /**
   * Получить идентификатор организации
   * @private
   */
  private get companyId(): string | null {
    return this.$route?.params?.id || null;
  }

  private resetSuccessFields(): void {
    this.allFields.forEach((field) => {
      field.success = false;
      field.successMessages = '';
    });
  }

  /**
   * Заполнить форму в соответствии с полученными данными
   * @param data
   */
  private fillFormData(data: Company): void {
    const addr = data.address ?? data.physical_address;

    this.shortName.value = data.name;
    this.fullName.value = data.full_name;
    this.taxNumber.value = data.inn.toString();
    this.polygon.value = data.polygon ? data.polygon : null;
    this.kpp.value = data.kpp.toString();
    this.ogrn.value = data.ogrn.toString();
    if (addr) {
      this.compositeAddress = addr;
      this.address.search = tools.getLabelFromAddressDetailType(addr);
      this.address.value = {
        value: addr,
        label: tools.getLabelFromAddressDetailType(addr),
      };
    }

    this.isSkipStatistics = data?.skip_statistics ?? false;

    this.isLoading = false;
  }

  /**
   * Получить краткое название компании
   * @return {string}
   */
  private get shortNameString(): string {
    return this.shortName?.value || '';
  }

  /**
   * Получить информацию о компании
   */
  private getCompanyData(): void {
    if (this.isEditPage && this.companyId) {
      this.isLoading = true;
      this.$organizationApi.getInfo(+this.companyId).then((response) => {
        this.companyData = response;
        this.fillFormData(response);
        this.isLoading = false;
      });
    }
  }

  /**
   * Получить данные организации по ИНН
   */
  private getCompanyDataByINN(): void {
    if (this.taxNumberSearch.value) {
      this.isSearchButtonDisabled = true;
      this.$organizationApi.getOrganisationByInn(this.taxNumberSearch.value).then((response) => {
        if (response) {
          this.fillFormData(response);
          this.companyData = response;
        } else {
          this.isSearchButtonDisabled = false;
        }
      }).catch(() => {
        this.isSearchButtonDisabled = false;
      });
    }
  }

  /**
   * Очистить ошибки в полях
   * @private
   */
  private resetError(): void {
    this.allFields.forEach((field) => {
      field.error = '';
    });
  }

  /**
   * Установить ошибки в полях
   * @param errors
   * @private
   */
  private setError(errors: Record<string, string[]>): void {
    this.resetError();

    Object.keys(errors).forEach((key) => {
      let currentField = this.allFields.find((field) => field.name === key);
      let message = errors[key].join(', ');
      if (key.includes('address')) {
        currentField = this.address;
        message = 'Проверьте формат: индекс, регион, город, дом, помещение';
      }
      if (currentField && message) {
        currentField.error = message;
      }
    });
  }

  /**
   * Определить может ли пользователь управлять организациями
   * @private
   */
  private get canManageOrganisations(): boolean {
    return this.user.permissions.indexOf(PermissionEnums.ORGANISATION_MANAGEMENT) !== -1;
  }

  /**
   * Пометить форму, как содержащую изменения
   */
  private markAsChanged(): void {
    this.hasChanges = true;
  }

  /**
   * Обработчик клика по "не сохранять" в диалоговом окне
   * @private
   */
  private clickNotSaveDialogButtonHandler(): void {
    if (!this.toUrl) {
      return;
    }
    this.hasChanges = false;
    const toUrlName = this.toUrl.name;

    if (toUrlName && typeof toUrlName !== 'undefined') {
      this.$router.push({ name: toUrlName });
    }
  }

  /**
   * Получить данные для запроса на создание/обновление организации
   * @returns {CompanyCreateRequest}
   */
  private get requestData(): CompanyCreateRequest {
    return {
      inn: this.taxNumber.value || '',
      kpp: this.kpp.value || '',
      ogrn: this.ogrn.value || '',
      name: this.shortName.value || '',
      full_name: this.fullName.value || '',
      polygon: this.polygon.value ? +this.polygon.value : null,
      physical_address: this.address.value ? this.address.value.value : null,
      mailing_address: this.address.value ? this.address.value.value : null,
      skip_statistics: this.isSkipStatistics,
    };
  }

  /**
   * Обработка нажатия на кнопку "Применить"
   */
  private applyButtonClickHandler(): void {
    if (!this.companyId) {
      return;
    }
    this.$organizationApi.updateCompany({
      id: +this.companyId,
      ...this.requestData,
    }).then((response) => {
      if (response.success) {
        this.$toast.success('Данные успешно обновлены');
        this.getCompanyData();
      }
    }).catch((error) => {
      window.console.log(error);
      if (error.message) {
        this.setError(error.message);
      }
    });
  }

  /**
   * Обработка нажатия на кнопку "Сохранить"
   */
  private saveButtonClickHandler(): void {
    let request;
    if (this.companyId) {
      request = this.$organizationApi.updateCompany({
        id: +this.companyId,
        ...this.requestData,
      });
    } else {
      request = this.$organizationApi.createCompany(this.requestData);
    }
    request.then((result) => {
      if (result.success) {
        this.$router.push({ name: 'companies' });
      }
    }).catch((error) => {
      window.console.log(error);
      if (error.message) {
        this.setError(error.message);
      }
    });
  }

  private clickHistoryItemHandler(data: VersionClickData): void {
    if (!this.companyData) {
      return;
    }

    this.fillFormData(this.companyData);
    this.resetSuccessFields();

    const company = { ...this.companyData };

    data.payload.forEach((diff) => {
      if (!company) {
        return;
      }

      Object.keys(company).forEach((key) => {
        const newKey: keyof typeof company = (key as never);

        if (newKey !== diff.attribute) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        company[newKey] = diff.new_value;

        this.allFields.forEach((field) => {
          if (field.name === diff.attribute) {
            field.success = true;
          }
        });
      });
    });

    this.isLoading = true;
    setTimeout(() => {
      this.fillFormData(company);
    }, 0);
  }
}

