














































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormField } from '@/types';

@Component({})
export default class RecoverProfile extends Vue {
  private email: FormField = {
    value: '',
    name: 'email',
    placeholder: 'Ведите e-mail',
    error: '',
  }

  private loading = false;

  private success = false;

  /**
   * Отправить форму
   * @private
   */
  private onSubmit():void {
    this.loading = true;
    this.$authApi.forgotPassword({
      email: this.email.value ? this.email.value : '',
    })
      .then(() => {
        this.success = true;
      })
      .catch((err) => {
        this.clearError();
        if (typeof err.message !== 'undefined') {
          this.setError(err.message);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Установить ошибки
   * @param errors
   * @private
   */
  private setError(errors: {[key: string]: Array<string>}): void {
    if (typeof errors[this.email.name] === 'undefined') {
      this.email.error = '';
      return;
    }
    this.email.error = errors[this.email.name];
  }

  /**
   * Очистить ошибки
   * @private
   */
  private clearError(): void {
    this.email.error = '';
  }
}
