











































































import {
  Vue, Component, Prop, VModel,
} from 'vue-property-decorator';
import tools from '@/tools';
import { FrequencyDate } from '@/types';
import { Frequency } from '@/enums/Frequency';
import FrequencyService from '@/services/FrequencyService';

@Component({})
export default class FrequencyItem extends Vue {
  @Prop({
    required: true,
  }) private readonly type!: string;

  @VModel()
  private frequencyDate!: Array<FrequencyDate>;

  private get isNthDay(): boolean {
    return this.type === Frequency.DateType.DAY;
  }

  private get isNthDayOfWeek(): boolean {
    return this.type === Frequency.DateType.DAY_OF_WEEK;
  }

  private get isNthDayInMonth(): boolean {
    return this.type === Frequency.DateType.DAY_IN_MONTH;
  }

  private get isNthDayOfWeekInMonth(): boolean {
    return this.type === Frequency.DateType.DAY_OF_WEEK_IN_MONTH;
  }

  private get nthPropertyKey(): string {
    return (this.isNthDayOfWeek || this.isNthDayOfWeekInMonth) ? 'n' : 'day_of_month';
  }

  private nthOptions = tools.createNumericSelectOptions(
    this.isNthDay || this.isNthDayInMonth ? 31 : 5, // до 31 дня в месяце, или до 5 недель в месяце
  );

  private dayOfWeekOptions = FrequencyService.getDaysOptions();

  private monthOptions = FrequencyService.getMonthOptions();

  private isLast(index: number): boolean {
    return index === this.frequencyDate.length - 1;
  }

  /**
   * Добавить дату
   */
  private addDate(): void {
    this.changeFrequencyItemHandler();
    this.frequencyDate.push({
      n: '1',
      month: '1',
      day_of_week: '1',
      day_of_month: '1',
    });
  }

  /**
   * Удалить дату
   */
  private deleteDate(index: number): void {
    this.changeFrequencyItemHandler();
    this.frequencyDate.splice(index, 1);
  }

  private changeFrequencyItemHandler(): void {
    this.$emit('change-frequency-item');
  }
}
