
















import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { BreadcrumbsItem } from '@/types';
import { Help } from '@/types/help';
import { Watch } from 'vue-property-decorator';
import tools from '@/tools';

@Component({
  components: { ConfirmationDialog, Breadcrumbs },
  props: {},
})
export default class HelpDetail extends Vue {
  private loadingStartConfig = true;

  private loading = false

  private helpData: Help.Item | null = null;

  private breadcrumbsItems: BreadcrumbsItem[] = [
    {
      text: 'Главная',
      href: '/',
      disabled: false,
    },
  ]

  mounted(): void {
    this.getLelpData();
  }

  private getId(): string | null {
    return this.$route?.params?.id || null;
  }

  /**
   * Получить информацию о статье
   */
  private getLelpData(): void {
    const id = this.getId();
    if (id) {
      this.loading = true;
      this.$helpAPi.getDetailPublished(+id).then((response) => {
        if (response) {
          this.helpData = response;
          this.loading = false;
        }
      });
    }
  }

  private findItemMenu(id: string): void {
    tools.scrollTo(id);
  }

  @Watch('$route')
  onFieldValueChange(): void {
    this.$nextTick(() => {
      this.getLelpData();
    });
  }
}
