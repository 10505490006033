import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticStyle:{"border":"1px solid #BFC5CF"},attrs:{"loading":_vm.loading,"page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-key":"id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"show-select":_vm.selectable,"value":_vm.selected,"search":_vm.search},on:{"update:page":function($event){_vm.currentPage=$event},"input":_vm.selectItemHandler,"click:row":_vm.clickRowHandler,"update:sort-by":_vm.changeSortByHandler,"update:sort-desc":_vm.changeSortDescHandler},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.handleRepetitionsClick)?_c(VBtn,{attrs:{"title":"Повторения","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.clickRepetitionsHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-repeat")])],1):_vm._e(),(_vm.hasPencil)?_c(VBtn,{attrs:{"title":"Изменить","icon":"","color":"blue-grey lighten-1"}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.handleSettingsClick)?_c(VBtn,{attrs:{"title":"Настройка","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.clickSettingsHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-cog")])],1):_vm._e(),(_vm.handleDeletion)?_c(VBtn,{attrs:{"title":"Удалить","icon":"","color":"blue-grey lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDeleteHandler(item.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}])}),(_vm.hasPagination)?_c(VPagination,{staticClass:"mt-4",attrs:{"length":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }