import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

export default class NotificationService {
  /**
  * Показать ошибку сервера
  * @param title
  * @param message
  */
  public error(title: string, message = ''): void {
    this.notify(title, message, 'error');
  }

  /**
    * Показать уведомление
  * @param title
  * @param message
  * @param type
  */
  public notify(title: string, message = '', type = 'notify'): void {
    Vue.notify({
      title,
      text: message,
      type,
      duration: 2000,
    });
  }
}
