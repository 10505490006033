

























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    text: {
      type: String,
      default: 'Неизвестная ошибка',
    },
  },
})

export default class ErrorMessage extends Vue {
  private closeModalHandler(): void {
    this.$emit('close-modal');
  }
}
