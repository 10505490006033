








































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ChangePasswordRequest, FormField } from '@/types';

@Component({})
export default class RestoreProfile extends Vue {
  private password: FormField = {
    value: '',
    name: 'password',
    placeholder: 'Ведите пароль',
    error: '',
  }

  private passwordConfirmation: FormField = {
    value: '',
    name: 'passwordConfirmation',
    placeholder: 'Повторите пароль',
    error: '',
  }

  private loading = false;

  private fields = [
    this.password,
    this.passwordConfirmation,
  ]

  /**
   * Отправить форму
   * @private
   */
  private onSubmit():void {
    this.loading = true;
    const requestParams: ChangePasswordRequest = {
      id: Number(this.$route.params.id),
      hash: this.$route.params.hash,
      password: this.password.value ? this.password.value : '',
      password_confirmation: this.passwordConfirmation.value ? this.passwordConfirmation.value : '',
    };
    this.$authApi.changePassword(requestParams)
      .then(() => {
        this.$router.push({
          name: '/',
        });
      })
      .catch((err) => {
        this.clearError();
        if (typeof err.message !== 'undefined') {
          this.setError(err.message);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Установить ошибки
   * @param errors
   * @private
   */
  private setError(errors: {[key: string]: Array<string>}): void {
    this.fields.forEach((field: FormField) => {
      const currentField = field;

      const { name } = currentField;

      if (typeof errors[name] === 'undefined') {
        currentField.error = '';
        return;
      }

      currentField.error = errors[name];
    });
  }

  /**
   * Очистить ошибки
   * @private
   */
  private clearError(): void {
    this.password.error = '';
    this.passwordConfirmation.error = '';
  }
}
